import React from 'react';
import styled, { css } from 'styled-components';

const Component = styled.div<{ $isShow: boolean }>`
  position: absolute;
  width: 100%;
  border: dashed 2px #fafbc9;
  z-index: 20;

  ${(props) => {
    if (props.$isShow) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

interface ScrollHeatmapCrossHairProps {
  crossHairRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  isShow: boolean;
}

const ScrollHeatmapCrossHair = ({
  crossHairRef,
  className,
  isShow,
}: ScrollHeatmapCrossHairProps) => {
  return (
    <Component ref={crossHairRef} className={className} $isShow={isShow} />
  );
};

export default ScrollHeatmapCrossHair;
