import React from "react";
import styled from "styled-components";
import { WeeklyUserCountPayload } from "../../../api/serviceAnalytics/userAnalysis/type";
import CardLayout from "../../Atoms/Layout/Card/CardLayout";
import CardHeaderLayout from "../../Atoms/Layout/Card/CardHeaderLayout";
import CardBodyLayout from "../../Atoms/Layout/Card/CardBodyLayout";

import DefaultCardHeading from "../../Molecule/Heading/Card/DefaultCardHeading";
import StackedColumnsChart from "../../Molecule/Chart/StackedColumnsChart";
import Spinner from "../../Molecule/Spinner";

const Component = styled(CardLayout)`
  height: 497px;
`;

const Body = styled(CardBodyLayout)`
  margin-top: 40px;
`;

export interface StackedColumnsChartCardProps {
  Data: WeeklyUserCountPayload | null;
  bar1Label: string;
  bar2Label: string;
  loading: boolean;
}

const StackedColumnsChartCard = ({
  Data,
  bar1Label,
  bar2Label,
  loading,
}: StackedColumnsChartCardProps) => {
  return (
    <Component>
      <CardHeaderLayout>
        <DefaultCardHeading title="요일별 그래프" />
      </CardHeaderLayout>
      <Body>
        {loading ? (
          <Spinner />
        ) : (
          <StackedColumnsChart
            Data={Data}
            bar1Label={bar1Label}
            bar2Label={bar2Label}
          />
        )}
      </Body>
    </Component>
  );
};

export default StackedColumnsChartCard;
