import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RankPageViewCount } from '../../../api/serviceAnalytics/pageviewAnalysis/type';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import { H4, P2 } from '../../Atoms/Typo';

import DefaultPieChart from '../../Molecule/Chart/PieChart';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import Spinner from '../../Molecule/Spinner';
import Table, { TableProps } from '../../Molecule/Table';

const Component = styled(CardLayout)`
  min-height: 200px;
`;

const Body = styled(CardBodyLayout)`
  margin-top: 40px;
`;

const GroupItem = styled(P2)`
  width: 155px;
  color: ${(props) => props.theme.colors.grey700};
`;

const PageViewItem = styled(H4)`
  width: 155px;
`;

const PageURLItem = styled.a`
  width: 10px;
  white-space: normal;
  word-break: break-all;
  text-decoration: underline;
  text-decoration-color: ${(props) => props.theme.colors.grey800};
`;
const PageRateItem = styled.div`
  height: 116px;
  /* width: 464px; */
  /* @media ${(props) => props.theme.device.$desktop} {
    width: 464px;
  }
  @media ${(props) => props.theme.device.$bigscreen} {
    width: 464px;
  } */
`;

interface PageViewTableCardProps {
  Data: RankPageViewCount[] | null;
  loading: boolean;
}

const PageViewTableCard = ({ Data, loading }: PageViewTableCardProps) => {
  const [rebuildData, setRebuildData] = useState<TableProps['data']>([]);

  useEffect(() => {
    if (Data) {
      const data_temp: TableProps['data'] = [];
      Data.forEach((element, index) => {
        data_temp.push({
          id: `${index}`,
          page_url: (
            <PageURLItem href={element.page_referrer} target="_blank">
              <P2>{element.page_referrer}</P2>
            </PageURLItem>
          ),
          user_group: {
            all: <GroupItem>전체 사용자</GroupItem>,
            return: <GroupItem>재방문 사용자</GroupItem>,
            new: <GroupItem>신규 사용자</GroupItem>,
          },
          page_view: {
            all_pv: (
              <PageViewItem>
                {element.new_count + element.old_count}
              </PageViewItem>
            ),
            return_pv: <PageViewItem>{element.old_count}</PageViewItem>,
            new_pv: <PageViewItem>{element.new_count}</PageViewItem>,
          },
          rate: (
            <PageRateItem>
              <DefaultPieChart
                data={[
                  { name: '재방문 사용자', value: Number(element.old_rate) },
                  { name: '신규 사용자', value: Number(element.new_rate) },
                ]}
              />
            </PageRateItem>
          ),
        });
      });
      setRebuildData(data_temp);
    }
  }, [Data]);
  return (
    <Component>
      <CardHeaderLayout>
        <DefaultCardHeading title="URL별 페이지뷰 수" />
      </CardHeaderLayout>
      <Body>
        {loading ? (
          <Spinner />
        ) : (
          <Table
            columns={[
              {
                title: '페이지 URL',
                id: 'page_url',
                isSorting: false,
              },
              {
                title: '사용자 그룹',
                id: 'user_group',
                isSorting: false,
                subColumns: [
                  {
                    title: '전체 사용자',
                    id: 'all',
                    isSorting: false,
                  },
                  {
                    title: '재방문 사용자',
                    id: 'return',
                    isSorting: false,
                  },
                  {
                    title: '신규 사용자',
                    id: 'new',
                    isSorting: false,
                  },
                ],
              },
              {
                title: '페이지뷰',
                id: 'page_view',
                isSorting: true,
                isAsc: false,
                subColumns: [
                  {
                    title: '전체 사용자',
                    id: 'all_pv',
                    isSorting: true,
                  },
                  {
                    title: '재방문 사용자',
                    id: 'return_pv',
                    isSorting: false,
                  },
                  {
                    title: '신규 사용자',
                    id: 'new_pv',
                    isSorting: false,
                  },
                ],
              },
              {
                title: '전체 대비 비율',
                id: 'rate',
                isSorting: false,
              },
            ]}
            data={rebuildData}
          />
          // <PageViewTable Data={Data} loading={loading} />
        )}
      </Body>
    </Component>
  );
};

export default PageViewTableCard;
