import React from 'react';
import styled from 'styled-components';
import { P3 } from '../../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  ${P3} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const URL = styled(P3)`
  color: ${(props) => props.theme.colors.grey500};
`;

interface PageThumbnailCardHeadingProps {
  title: string;
  url: string;
  className?: string;
}

const PageThumbnailCardHeading = ({
  title,
  url,
  className,
}: PageThumbnailCardHeadingProps) => {
  return (
    <Component className={className}>
      <Header>
        <P3>{title}</P3>
      </Header>
      <URL>{url}</URL>
    </Component>
  );
};

export default PageThumbnailCardHeading;
