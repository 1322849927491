import React, { ReactNodeArray } from 'react';
import styled, { css } from 'styled-components';
import { ScrollEventsPayload } from '../../../api/pageAnalytics/uxAnalytics/scroll/type';

const Component = styled.svg`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
`;

const HeatmapStopGradient = styled.stop<{ $user_rate: number }>`
  ${(props) => {
    let linear_gradient = '';

    if (props.$user_rate > 94) {
      linear_gradient = props.theme.colors.heatmap01;
    } else if (props.$user_rate > 88) {
      linear_gradient = props.theme.colors.heatmap02;
    } else if (props.$user_rate > 82) {
      linear_gradient = props.theme.colors.heatmap03;
    } else if (props.$user_rate > 76) {
      linear_gradient = props.theme.colors.heatmap04;
    } else if (props.$user_rate > 70) {
      linear_gradient = props.theme.colors.heatmap05;
    } else if (props.$user_rate > 64) {
      linear_gradient = props.theme.colors.heatmap06;
    } else if (props.$user_rate > 58) {
      linear_gradient = props.theme.colors.heatmap07;
    } else if (props.$user_rate > 52) {
      linear_gradient = props.theme.colors.heatmap08;
    } else if (props.$user_rate > 46) {
      linear_gradient = props.theme.colors.heatmap09;
    } else if (props.$user_rate > 40) {
      linear_gradient = props.theme.colors.heatmap10;
    } else if (props.$user_rate > 34) {
      linear_gradient = props.theme.colors.heatmap11;
    } else if (props.$user_rate > 28) {
      linear_gradient = props.theme.colors.heatmap12;
    } else if (props.$user_rate > 22) {
      linear_gradient = props.theme.colors.heatmap13;
    } else if (props.$user_rate > 16) {
      linear_gradient = props.theme.colors.heatmap14;
    } else if (props.$user_rate > 10) {
      linear_gradient = props.theme.colors.heatmap15;
    } else {
      linear_gradient = props.theme.colors.heatmap15;
    }

    return css`
      stop-color: ${linear_gradient};
    `;
  }}
`;

interface ScrollHeatmapLayerProps {
  isImgLoad: boolean;
  data: ScrollEventsPayload | null;
  gradientWidth: number;
  barHeight: number;
  opacity: number;
}

const ScrollHeatmapLayer = ({
  isImgLoad,
  data,
  gradientWidth,
  barHeight,
  opacity,
}: ScrollHeatmapLayerProps) => {
  const printHeatmapGradient = () => {
    const result: ReactNodeArray = [];
    const stopGradientArr: ReactNodeArray = [];
    const user_rate_arr: number[] = [];
    if (isImgLoad && data) {
      data.heatmap.forEach((element, index) => {
        const key = index.toString();
        stopGradientArr.push(
          <HeatmapStopGradient
            key={key}
            offset={`${index + 1}%`}
            $user_rate={element.rate}
          />
        );
        user_rate_arr.push(element.rate);
      });

      let sumHeight = 0;
      let d = 'M';

      data.scroll_usability.forEach((element, index) => {
        const realWidth = gradientWidth * (element.view_rate / 100);

        const pointer = `0,${sumHeight} ${gradientWidth},${sumHeight} ${gradientWidth},${
          sumHeight + barHeight
        } ${0},${sumHeight + barHeight} `;

        d += pointer;

        sumHeight += barHeight;

        if (data.scroll_usability.length - 1 === index) {
          d += 'z';
        }
      });

      result.push(
        <defs key={JSON.stringify(data.attention_section)}>
          <linearGradient
            id="voda_scroll_heatmap_linearGradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            {stopGradientArr}
          </linearGradient>
        </defs>
      );
      result.push(
        <path
          key={JSON.stringify(data.height)}
          d={d}
          fill="url(#voda_scroll_heatmap_linearGradient)"
          fillOpacity={opacity}
        />
      );
    }
    return result;
  };
  return <Component>{printHeatmapGradient()}</Component>;
};

export default ScrollHeatmapLayer;
