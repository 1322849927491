import axios from 'axios';
import {
  GetSessionCountParamType,
  GetSessionCountResponseType,
  GetWeeklySessionCountParamType,
  GetWeeklySessionCountResponseType,
} from './type';

import config from '../../config-api.json';

// axios.defaults.withCredentials = true;
const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetSessionCount(info: GetSessionCountParamType) {
  const response = await axios.get<GetSessionCountResponseType>(
    `${apiInfo.api_url}/session_count/`,
    {
      params: {
        unit: info.unit,
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetWeeklySessionCount(
  info: GetWeeklySessionCountParamType
) {
  const response = await axios.get<GetWeeklySessionCountResponseType>(
    `${apiInfo.api_url}/weekly_session_count/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
