import { createAction, createAsyncAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  DeletePageClickStaticsResponse,
  GetPageMouseStaticsResponseType,
  GetPageMouseStaticsParamType,
  GetPageElementDataParamType,
  GetPageElementDataResponseType,
  GetEffectiveClickRateParamType,
  GetEffectiveClickRateResponseType,
  GetPageDurationResponseType,
  GetPageDurationParamType,
  GetPageAttentionScreensCountParamType,
  GetPageAttentionScreensCountResponseType,
} from '../../../api/pageAnalytics/uxAnalytics/type';

export const SET_ELEMENT_IMAGE = 'pageAnalytics/UXAnalytics/SET_ELEMENT_IMAGE' as const;

export const GET_PAGE_MOUSE_STATICS = 'pageAnalytics/UXAnalytics/GET_PAGE_MOUSE_STATICS' as const;
export const GET_PAGE_MOUSE_STATICS_SUCCESS = 'pageAnalytics/UXAnalytics/GET_PAGE_MOUSE_STATICS_SUCCESS' as const;
export const GET_PAGE_MOUSE_STATICS_ERROR = 'pageAnalytics/UXAnalytics/GET_PAGE_MOUSE_STATICS_ERROR' as const;

export const DELETE_PAGE_CLICK_STATICS = 'pageAnalytics/UXAnalytics/DELETE_PAGE_CLICK_STATICS' as const;
export const DELETE_PAGE_CLICK_STATICS_SUCCESS = 'pageAnalytics/UXAnalytics/DELETE_PAGE_CLICK_STATICS_SUCCESS' as const;
export const DELETE_PAGE_CLICK_STATICS_ERROR = 'pageAnalytics/UXAnalytics/DELETE_PAGE_CLICK_STATICS_ERROR' as const;

export const GET_PAGE_ELEMENT_DATA = 'pageAnalytics/UXAnalytics/GET_PAGE_ELEMENT_DATA' as const;
export const GET_PAGE_ELEMENT_DATA_SUCCESS = 'pageAnalytics/UXAnalytics/GET_PAGE_ELEMENT_DATA_SUCCESS' as const;
export const GET_PAGE_ELEMENT_DATA_ERROR = 'pageAnalytics/UXAnalytics/GET_PAGE_ELEMENT_DATA_ERROR' as const;

export const GET_EFFECTIVE_CLICK_RATE = 'pageAnalytics/UXAnalytics/GET_EFFECTIVE_CLICK_RATE' as const;
export const GET_EFFECTIVE_CLICK_RATE_SUCCESS = 'pageAnalytics/UXAnalytics/GET_EFFECTIVE_CLICK_RATE_SUCCESS' as const;
export const GET_EFFECTIVE_CLICK_RATE_ERROR = 'pageAnalytics/UXAnalytics/GET_EFFECTIVE_CLICK_RATE_ERROR' as const;

export const GET_PAGE_ATTENTION_SCREENS_COUNT = 'pageAnalytics/UXAnalytics/GET_PAGE_ATTENTION_SCREENS_COUNT' as const;
export const GET_PAGE_ATTENTION_SCREENS_COUNT_SUCCESS = 'pageAnalytics/UXAnalytics/GET_PAGE_ATTENTION_SCREENS_COUNT_SUCCESS' as const;
export const GET_PAGE_ATTENTION_SCREENS_COUNT_ERROR = 'pageAnalytics/UXAnalytics/GET_PAGE_ATTENTION_SCREENS_COUNT_ERROR' as const;

export const GET_PAGE_DURATION = 'pageAnalytics/UXAnalytics/GET_PAGE_DURATION' as const;
export const GET_PAGE_DURATION_SUCCESS = 'pageAnalytics/UXAnalytics/GET_PAGE_DURATION_SUCCESS' as const;
export const GET_PAGE_DURATION_ERROR = 'pageAnalytics/UXAnalytics/GET_PAGE_DURATION_ERROR' as const;

export const SET_TARGET_ELEMENT_ID = 'pageAnalytics/UXAnalytics/SET_TARGET_ELEMENT_ID' as const;

export const SET_TOOLTIP_INFO = 'pageAnalytics/UXAnalytics/SET_TOOLTIP_INFO' as const;

export const SET_TOOLTIP_INFO_MOUSE_FLOW = 'pageAnalytics/UXAnalytics/SET_TOOLTIP_INFO_MOUSE_FLOW' as const;

export const SET_ERROR_NULL_UX_ANALYTICS =
  'pageAnalytics/UXAnalytics/SET_ERROR_NULL_UX_ANALYTICS';

export const setElementImage = createAction(SET_ELEMENT_IMAGE)<string[]>();

export const setTargetElementId = createAction(SET_TARGET_ELEMENT_ID)<string>();

export const setTooltipInfo = createAction(SET_TOOLTIP_INFO)<{
  pathId: string;
  title1: number;
  title2: number;
  title3: number;
}>();

export const setTooltipInfoMouseFlow = createAction(
  SET_TOOLTIP_INFO_MOUSE_FLOW
)<{
  pathId: string;
  rank: number;
  duration: number;
  rate: number;
}>();

export const getPageMouseStaticsAsync = createAsyncAction(
  GET_PAGE_MOUSE_STATICS,
  GET_PAGE_MOUSE_STATICS_SUCCESS,
  GET_PAGE_MOUSE_STATICS_ERROR
)<
  GetPageMouseStaticsParamType,
  GetPageMouseStaticsResponseType,
  AxiosErrorType
>();

export const deletePageClickStaticsAsync = createAsyncAction(
  DELETE_PAGE_CLICK_STATICS,
  DELETE_PAGE_CLICK_STATICS_SUCCESS,
  DELETE_PAGE_CLICK_STATICS_ERROR
)<void, DeletePageClickStaticsResponse, AxiosErrorType>();

export const getPageElementDataAsync = createAsyncAction(
  GET_PAGE_ELEMENT_DATA,
  GET_PAGE_ELEMENT_DATA_SUCCESS,
  GET_PAGE_ELEMENT_DATA_ERROR
)<
  GetPageElementDataParamType,
  GetPageElementDataResponseType,
  AxiosErrorType
>();

export const getEffectiveClickRateAsync = createAsyncAction(
  GET_EFFECTIVE_CLICK_RATE,
  GET_EFFECTIVE_CLICK_RATE_SUCCESS,
  GET_EFFECTIVE_CLICK_RATE_ERROR
)<
  GetEffectiveClickRateParamType,
  GetEffectiveClickRateResponseType,
  AxiosErrorType
>();

export const getPageAttentionScreensCountAsync = createAsyncAction(
  GET_PAGE_ATTENTION_SCREENS_COUNT,
  GET_PAGE_ATTENTION_SCREENS_COUNT_SUCCESS,
  GET_PAGE_ATTENTION_SCREENS_COUNT_ERROR
)<
  GetPageAttentionScreensCountParamType,
  GetPageAttentionScreensCountResponseType,
  AxiosErrorType
>();

export const getPageDurationAsync = createAsyncAction(
  GET_PAGE_DURATION,
  GET_PAGE_DURATION_SUCCESS,
  GET_PAGE_DURATION_ERROR
)<GetPageDurationParamType, GetPageDurationResponseType, AxiosErrorType>();

export const setErrorNullUxAnalytics = createAction(
  SET_ERROR_NULL_UX_ANALYTICS
)();
