import React from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import { H3, H4, P3 } from '../../../Atoms/Typo';
import Count from '../../Count/NumberToKorean';

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  svg {
    width: 16px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

const Title = styled(P3)`
  color: ${(props) => props.theme.colors.grey500};
`;

const Value = styled.div`
  margin-top: 4px;
  .voda_num_to_kor_count {
    ${H4} {
      font-size: 20px;
    }
  }
`;

interface HeaderProps {
  Icon?: StyledComponent<
    React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >,
    DefaultTheme,
    any,
    never
  >;
  title: string;
  value: number;
}

const Header = ({ Icon, title, value }: HeaderProps) => {
  return (
    <Component>
      <IconWrapper>{Icon ? <Icon /> : null}</IconWrapper>
      <TitleWrapper>
        <Title>{title}</Title>
        <Value>
          <Count value={value} />
        </Value>
      </TitleWrapper>
    </Component>
  );
};

export default Header;
