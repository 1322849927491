import React from 'react';
import styled from 'styled-components';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';

import RateCount from '../../Molecule/Count/RateCount';
import TimeCount from '../../Molecule/Count/TimeCount';
import ValueCount from '../../Molecule/Count/ValueCount';
import EngKorCardHeading from '../../Molecule/Heading/Card/EngKorCardHeading';
import Spinner from '../../Molecule/Spinner';

const Component = styled(CardLayout)`
  padding: 24px;
`;

const Body = styled(CardBodyLayout)`
  margin-top: 24px;
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface DashboardDefaultCardProps {
  titleEng: string;
  titleKor: string;
  isTimeCount: boolean;
  isLoading: boolean;
  value?: number;
  rate?: number;
  time?: number;
}

const DashboardDefaultCard = ({
  titleEng,
  titleKor,
  isTimeCount,
  isLoading,
  value,
  rate,
  time,
}: DashboardDefaultCardProps) => {
  return (
    <Component>
      <CardHeaderLayout>
        <EngKorCardHeading titleEng={titleEng} titleKor={titleKor} />
      </CardHeaderLayout>
      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <CountWrapper>
            {isTimeCount ? (
              <TimeCount time={time} />
            ) : (
              <ValueCount value={value} />
            )}
            <RateCount rate={rate} />
          </CountWrapper>
        )}
      </Body>
    </Component>
  );
};
export default DashboardDefaultCard;
