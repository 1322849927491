import { createAction, createAsyncAction } from 'typesafe-actions';
import { GetForecastDateResponseType } from '../../api/datePicker/type';
import { AxiosErrorType } from '../createRequestSaga';

export const SET_START_DATE = 'datePicker/SET_START_DATE' as const;
export const SET_END_DATE = 'datePicker/SET_END_DATE' as const;
export const SET_IS_DATE_PICKER_TOGGLE = 'datePicker/SET_IS_DATE_PICKER_TOGGLE' as const;

export const GET_FORECAST_DATE = 'datePicker/GET_FORECAST_DATE' as const;
export const GET_FORECAST_DATE_SUCCESS = 'datePicker/GET_FORECAST_DATE_SUCCESS' as const;
export const GET_FORECAST_DATE_ERROR = 'datePicker/GET_FORECAST_DATE_ERROR' as const;

export const setStartDate = createAction(SET_START_DATE)<string>();
export const setEndDate = createAction(SET_END_DATE)<string>();
export const setIsDatePickerToggle = createAction(
  SET_IS_DATE_PICKER_TOGGLE
)<boolean>();

export const getForecastDateAsync = createAsyncAction(
  GET_FORECAST_DATE,
  GET_FORECAST_DATE_SUCCESS,
  GET_FORECAST_DATE_ERROR
)<void, GetForecastDateResponseType, AxiosErrorType>();
