import React from 'react';
import styled from 'styled-components';
import { H3, P1 } from '../../../Atoms/Typo';

const Component = styled.div`
  width: 100%;
  margin-top: 24px;
`;

interface ReportSummaryProps {
  children: React.ReactNode;
}

const ReportSummary = ({ children }: ReportSummaryProps) => {
  return <Component>{children}</Component>;
};

export default ReportSummary;
