import axios from 'axios';
import {
  SetLoginParamType,
  SetLoginResponseType,
  GetCheckSessionResponseType,
  SetLogoutResponseType,
  GetIsAIVORYAvailableResponseType,
  GetVodaHealthResponseType,
  GetVodaSessionHealthParamType,
  GetVodaSessionHealthResponseType,
  GetPVUsageResponseType,
} from './type';
import config from '../config-api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
  // localStorage.setItem('voda_tenant', 'amWJ8DM3AfDdWTpm');
  localStorage.setItem('voda_tenant', 'mu66uYUHlzLGcjlU');
}

// axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

function getCookie(cookieName: string) {
  let cookieValue = null;
  if (document.cookie) {
    const array = document.cookie.split(`${escape(cookieName)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
}

export async function apiSetLogin(info: SetLoginParamType) {
  const response = await axios.post<SetLoginResponseType>(
    `${apiInfo.api_url}/login/`,
    {
      username: info.username,
      password: info.password,
    },
    {
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetCheckSession() {
  const response = await axios.get<GetCheckSessionResponseType>(
    `${apiInfo.api_url}/check_session/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );

  return response.data;
}

export async function apiSetLogout() {
  // deleteCookie('sessionid', '.nerdfactory.ai', '/');
  const response = await axios.post<SetLogoutResponseType>(
    `${apiInfo.api_url}/logout/`,
    {},
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
        'X-CSRFToken': getCookie('csrftoken'),
      },
      withCredentials: true,
    }
  );

  return response.data;
}

export async function apiIsAIVORYAvailable() {
  const response = await axios.get<GetIsAIVORYAvailableResponseType>(
    `${apiInfo.api_url}/aivory/is_aivory_available/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );

  return response.data;
}

export async function apiGetVodaHealth() {
  const response = await axios.get<GetVodaHealthResponseType>(
    `${apiInfo.api_url}/health/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: false,
      timeout: 10000,
    }
  );

  return response.data;
}

export async function apiGetVodaSessionHealth(
  info: GetVodaSessionHealthParamType
) {
  const response = await axios.get<GetVodaSessionHealthResponseType>(
    `${apiInfo.api_url}/?/`,
    {
      params: {
        ...info,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: false,
      timeout: 10000,
    }
  );

  return response.data;
}

export async function apiGetPVUsage() {
  const response = await axios.get<GetPVUsageResponseType>(
    `${apiInfo.api_url}/pv_usage/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
      timeout: 10000,
    }
  );

  return response.data;
}
