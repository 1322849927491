import React from 'react';
import styled, { css } from 'styled-components';

const Component = styled.div<{ $isColumn: boolean }>`
  background-color: ${(props) => props.theme.colors.grey200};

  ${(props) => {
    if (props.$isColumn) {
      return css`
        width: 100%;
        height: 1px;
        margin-top: 16px;
        margin-bottom: 24px;
      `;
    }
    return css`
      width: 1px;
      height: 100%;
    `;
  }}
`;

interface LineProps {
  isColumn: boolean;
  className?: string;
}

const Line = ({ isColumn, className }: LineProps) => {
  return <Component $isColumn={isColumn} className={className} />;
};

export default Line;
