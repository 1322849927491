import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyle, theme } from './global-styles';

import Routes from '../__Routes';
// import 'antd/dist/antd.css';

const App = () => {
  return (
    <ThemeProvider theme={{ ...theme }}>
      <GlobalStyle />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
