import React, {
  useEffect,
  useRef,
  useState,
  ReactNodeArray,
  useMemo,
} from 'react';
import styled from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';

import FullPageScreenShot from '../../../Molecule/FullPageScreenShot';
import HeatmapElementBox from '../../../Molecule/HeatmapElementBox';
import HeatmapLayer from '../../../Molecule/HeatmapLayer';
import MouseHeatmapTooltip, {
  MouseHeatmapTooltipProps,
  MouseHeatmapTooltipType,
} from '../../../Molecule/Tooltip/MouseHeatmapTooltip';
import Spinner from '../../../Molecule/Spinner';

import { PageElementDataPayload } from '../../../../api/pageAnalytics/uxAnalytics/type';
import { uxAnalyticsState } from '../../../../modules/PageAnalytics/UXAnalytics';
import OpacitySlider from '../../../Atoms/Slider';

const Component = styled(CardLayout)`
  padding: 0px;
  position: relative;
`;
const SpinnerWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 200px;
  z-index: 20;
`;

interface MouseHeatmapCardState {
  eventCoordinates: {
    x: number;
    y: number;
    value: number;
    // radius: number;
  }[];
  convertEventData: { [key: string]: { [key: string]: number } };
}

export interface MouseHeatmapCardProps {
  pageId: string;
  pageEventStaticsData: uxAnalyticsState['mouseStaticsData'];
  pageElementData: uxAnalyticsState['elementData'];
  clickedElementPath: string;
  mouseoverElementPath: string;

  clickTooltipInfo: MouseHeatmapTooltipType | null;
  isActiveDataType: number;
  mouseoverTooltipInfo: MouseHeatmapTooltipType | null;
  isLoading: boolean;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
  setMouseoverElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;

  setMouseoverTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
}

const MouseHeatmapCard = ({
  pageId,
  pageEventStaticsData,
  pageElementData,
  clickedElementPath,
  mouseoverElementPath,
  isLoading,
  clickTooltipInfo,
  isActiveDataType,
  mouseoverTooltipInfo,
  setClickedElementPath,
  setMouseoverElementPath,
  setClickTooltipInfo,

  setMouseoverTooltipInfo,
}: MouseHeatmapCardProps) => {
  const fullPageScreenShotEl = useRef<HTMLImageElement>(null);
  const [isImgLoad, setIsImgLoad] = useState(false);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [rebuildClickData, setRebuildClickData] = useState<{
    data: {
      x: number;
      y: number;
      value: number;
      // radius: number;
    }[];
    max: number;
  }>();
  const [rebuildElementData, setRebuildElementData] = useState<
    uxAnalyticsState['elementData'] | null
  >(null);
  const [heatmapOpacity, setHeatmapOpacity] = useState(1);

  const calculateElementCoordinates = (imgWidth: number, imgHeight: number) => {
    const temp: uxAnalyticsState['elementData'] = [];
    if (pageElementData.length > 0) {
      pageElementData.forEach((element) => {
        if (
          !(
            element.x < 0 ||
            element.y < 0 ||
            element.x > 1 ||
            element.y > 1 ||
            element.width > 1 ||
            element.height > 1 ||
            element.width < 0 ||
            element.height < 0 ||
            element.x + element.width > 1
          )
        ) {
          const x = element.x * imgWidth;
          const y = element.y * imgHeight;
          const width = element.width * imgWidth;
          const height = element.height * imgHeight;
          const pushdata = {
            x,
            y,
            width,
            height,
            depth: element.depth,
            element_path: element.element_path,
            cnt: element.cnt,
            rate: element.rate,
            rank: element.rank,
            duration: element.duration,
          };

          temp.push(pushdata);
        }
      });
    }
    if (temp.length > 0) {
      setRebuildElementData(temp);
    }
  };

  const calculateClickCoordinates = (imgWidth: number, imgHeight: number) => {
    const temp: MouseHeatmapCardState['eventCoordinates'] = [];
    let max = 1;
    if (pageEventStaticsData) {
      const xCategory = Object.keys(pageEventStaticsData.data);

      xCategory.forEach((elementX) => {
        if (elementX !== 'null') {
          const a = pageEventStaticsData.data[elementX];
          const yCategory = Object.keys(a);

          yCategory.forEach((elementY) => {
            if (max < a[elementY]) {
              max = a[elementY];
            }
            temp.push({
              x: Math.floor(Number(elementX) * imgWidth),
              y: Math.floor(Number(elementY) * imgHeight),
              value: a[elementY],
            });
          });
        }
      });
    }

    return { data: temp, max };
  };

  const handleImageLoaded = () => {
    setIsImgLoad(true);
  };

  const handleImageLoadStart = () => {
    setIsImgLoad(false);
  };

  const onClickElementBox = (element: PageElementDataPayload) => {
    setClickedElementPath(element.element_path);
    // if (isActiveDataType === 0) {
    //   setClickTooltipInfo({
    //     data: [
    //       {
    //         id: 0,
    //         title: '클릭 순위',
    //         value: `${element.rank}위`,
    //       },
    //       {
    //         id: 1,
    //         title: '클릭 횟수',
    //         value: `${element.cnt || 0}회`,
    //       },
    //       {
    //         id: 2,
    //         title: '클릭 비율',
    //         value: `${element.rate}%`,
    //       },
    //     ],
    //     img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
    //       'voda_tenant'
    //     )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
    //     isOpenTooltip: true,
    //     elementX: element.x / imgSize.width,
    //     elementY: element.y / imgSize.height,
    //     elementHeight: element.height / imgSize.height,
    //     elementWidth: element.width / imgSize.width,
    //   });
    // }
    if (isActiveDataType === 1) {
      setClickTooltipInfo({
        data: [
          {
            id: 0,
            title: '클릭 순위',
            value: `${element.rank}위`,
          },
          {
            id: 1,
            title: '클릭 횟수',
            value: `${element.cnt || 0}회`,
          },
          {
            id: 2,
            title: '클릭 비율',
            value: `${element.rate}%`,
          },
        ],
        img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
        isOpenTooltip: true,
        elementX: element.x / imgSize.width,
        elementY: element.y / imgSize.height,
        elementHeight: element.height / imgSize.height,
        elementWidth: element.width / imgSize.width,
      });
      // setClickTooltipInfo({
      //   data: [
      //     {
      //       id: 0,
      //       title: '관심 순위',
      //       value: `${element.rank}위`,
      //     },
      //     {
      //       id: 1,
      //       title: '평균 체류 시간',
      //       value: `${element.duration?.toFixed(2) || 0}초`,
      //     },
      //     {
      //       id: 2,
      //       title: '관심 비율',
      //       value: `${element.rate}%`,
      //     },
      //   ],
      //   img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
      //     'voda_tenant'
      //   )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
      //   isOpenTooltip: true,
      //   elementX: element.x / imgSize.width,
      //   elementY: element.y / imgSize.height,
      //   elementHeight: element.height / imgSize.height,
      //   elementWidth: element.width / imgSize.width,
      // });
    }
  };

  const onMouseOverElementBox = (element: PageElementDataPayload) => {
    setMouseoverElementPath(element.element_path);
    if (isActiveDataType === 1) {
      setMouseoverTooltipInfo({
        data: [
          {
            id: 0,
            title: '클릭 순위',
            value: `${element.rank}위`,
          },
          {
            id: 1,
            title: '클릭 횟수',
            value: `${element.cnt || 0}회`,
          },
          {
            id: 2,
            title: '클릭 비율',
            value: `${element.rate}%`,
          },
        ],
        img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
        isOpenTooltip: true,
        elementX: element.x / imgSize.width,
        elementY: element.y / imgSize.height,
        elementHeight: element.height / imgSize.height,
        elementWidth: element.width / imgSize.width,
      });
    }
    // else if (isActiveDataType === 1) {
    //   setMouseoverTooltipInfo({
    //     data: [
    //       {
    //         id: 0,
    //         title: '관심 순위',
    //         value: `${element.rank}위`,
    //       },
    //       {
    //         id: 1,
    //         title: '평균 체류 시간',
    //         value: `${element.duration?.toFixed(2) || 0}초`,
    //       },
    //       {
    //         id: 2,
    //         title: '관심 비율',
    //         value: `${element.rate}%`,
    //       },
    //     ],
    //     img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
    //       'voda_tenant'
    //     )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
    //     isOpenTooltip: true,
    //     elementX: element.x / imgSize.width,
    //     elementY: element.y / imgSize.height,
    //     elementHeight: element.height / imgSize.height,
    //     elementWidth: element.width / imgSize.width,
    //   });
    // }
  };

  const printElementBox = useMemo(() => {
    const result: ReactNodeArray = [];
    if (rebuildElementData) {
      rebuildElementData.forEach((element) => {
        result.push(
          <HeatmapElementBox
            key={element.element_path}
            isClicked={clickedElementPath === element.element_path}
            isMouseOver={mouseoverElementPath === element.element_path}
            x={element.x}
            y={element.y}
            width={element.width}
            height={element.height}
            depth={element.depth}
            onClick={() => {
              onClickElementBox(element);
            }}
            onMouseLeave={() => {
              setMouseoverElementPath('');
              setMouseoverTooltipInfo(null);
            }}
            onMouseOver={() => {
              onMouseOverElementBox(element);
            }}
          />
        );
      });
    }
    return result;
  }, [rebuildElementData, clickedElementPath, mouseoverElementPath]);

  const printTooltip = useMemo(() => {
    if (mouseoverTooltipInfo) {
      return (
        <MouseHeatmapTooltip {...mouseoverTooltipInfo} imgSize={imgSize} />
      );
    }
    if (clickTooltipInfo) {
      return <MouseHeatmapTooltip {...clickTooltipInfo} imgSize={imgSize} />;
    }
    return null;
  }, [mouseoverTooltipInfo, clickTooltipInfo]);

  const handleOpacity = (val: number) => {
    setHeatmapOpacity(val / 10);
  };

  useEffect(() => {
    if (isImgLoad && fullPageScreenShotEl.current) {
      const width = fullPageScreenShotEl.current.offsetWidth;
      const height = fullPageScreenShotEl.current.offsetHeight;
      setImgSize({ width, height });
      setRebuildClickData(calculateClickCoordinates(width, height));
      calculateElementCoordinates(width, height);
    }
  }, [
    isImgLoad,
    fullPageScreenShotEl.current,
    pageEventStaticsData,
    pageElementData,
  ]);

  return (
    <Component>
      <FullPageScreenShot
        img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${pageId}/${encodeURIComponent('page_screenshot')}`}
        imgRef={fullPageScreenShotEl}
        onLoad={handleImageLoaded}
        onLoadStart={handleImageLoadStart}
      />
      {isLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <HeatmapLayer
            data={rebuildClickData?.data}
            max={rebuildClickData?.max}
            width={imgSize.width}
            height={imgSize.height}
            opacity={heatmapOpacity}
          />
          <OpacitySlider
            type="mouse"
            defaultVal={10}
            opacityVal={handleOpacity}
          />
          {printElementBox}
          {printTooltip}
        </>
      )}
    </Component>
  );
};

export default MouseHeatmapCard;
