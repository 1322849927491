import axios from 'axios';
import {
  GetTotalUserCountParamType,
  GetTotalUserCountResponseType,
  GetWeeklyUserCountParamType,
  GetWeeklyUserCountResponseType,
} from './type';

import config from '../../config-api.json';

// axios.defaults.withCredentials = true;
const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetTotalUserCount(info: GetTotalUserCountParamType) {
  const response = await axios.get<GetTotalUserCountResponseType>(
    `${apiInfo.api_url}/user_count/`,
    {
      params: {
        unit: info.unit,
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetWeeklyUserCount(info: GetWeeklyUserCountParamType) {
  const response = await axios.get<GetWeeklyUserCountResponseType>(
    `${apiInfo.api_url}/weekly_user_count/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
