import React from 'react';
import styled from 'styled-components';
import Header from '../../../Molecule/Card/CardHeader';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle2';
import SearchRank from '../../../Molecule/Rank/SearchRank';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  width: 100%;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

interface SearchWordCardProps {
  data: {
    rank: number;
    title: string;
    value: number;
  }[];
  isGrowth: boolean;
  title: string;
}

const SearchWordCard = ({ title, isGrowth, data }: SearchWordCardProps) => {
  return (
    <Component>
      <Header>
        <Title title={title} />
      </Header>
      <Body>
        {data.map((element) => {
          return (
            <Item key={element.rank}>
              <SearchRank
                isUp={isGrowth}
                rank={element.rank}
                title={element.title}
                value={element.value}
              />
            </Item>
          );
        })}
      </Body>
    </Component>
  );
};

export default SearchWordCard;
