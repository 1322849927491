import { createReducer } from 'typesafe-actions';
import {
  GET_PAGE_VIEW_COUNT,
  GET_PAGE_VIEW_COUNT_ERROR,
  GET_PAGE_VIEW_COUNT_SUCCESS,
  GET_WEEKLY_PAGE_VIEW_COUNT,
  GET_WEEKLY_PAGE_VIEW_COUNT_SUCCESS,
  GET_WEEKLY_PAGE_VIEW_ERROR,
  GET_RANK_PAGE_VIEW_COUNT,
  GET_RANK_PAGE_VIEW_COUNT_SUCCESS,
  GET_RANK_PAGE_VIEW_ERROR,
  SET_ERROR_NULL_SERVICE_PV,
} from './actions';
import { pageViewAnalysisAction, pageViewAnalysisState } from './types';

const initialState: pageViewAnalysisState = {
  isGetPVCLoading: false,
  isGetWPVCLoading: false,
  isGetRPVCLoading: false,
  PVCData: null,
  WPVCData: null,
  RPVCData: null,
  pageViewAnalysisError: null,
};

const reducer = createReducer<pageViewAnalysisState, pageViewAnalysisAction>(
  initialState,
  {
    [GET_PAGE_VIEW_COUNT]: (state) => ({
      ...state,
      isGetPVCLoading: true,
      PVCData: null,
      pageViewAnalysisError: null,
    }),
    [GET_PAGE_VIEW_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      isGetPVCLoading: false,
      PVCData: action.payload.payload,
    }),
    [GET_PAGE_VIEW_COUNT_ERROR]: (state, action) => ({
      ...state,
      isGetPVCLoading: false,
      PVCData: null,
      pageViewAnalysisError: action.payload,
    }),

    [GET_WEEKLY_PAGE_VIEW_COUNT]: (state) => ({
      ...state,
      isGetWPVCLoading: true,
      WPVCData: null,
      pageViewAnalysisError: null,
    }),
    [GET_WEEKLY_PAGE_VIEW_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      isGetWPVCLoading: false,
      WPVCData: action.payload.payload,
    }),
    [GET_WEEKLY_PAGE_VIEW_ERROR]: (state, action) => ({
      ...state,
      isGetWPVCLoading: false,
      WPVCData: null,
      pageViewAnalysisError: action.payload,
    }),

    [GET_RANK_PAGE_VIEW_COUNT]: (state) => ({
      ...state,
      isGetRPVCLoading: true,
      RPVCData: null,
      pageViewAnalysisError: null,
    }),
    [GET_RANK_PAGE_VIEW_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      isGetRPVCLoading: false,
      RPVCData: action.payload.payload,
    }),
    [GET_RANK_PAGE_VIEW_ERROR]: (state, action) => ({
      ...state,
      isGetRPVCLoading: false,
      RPVCData: null,
      pageViewAnalysisError: action.payload,
    }),

    [SET_ERROR_NULL_SERVICE_PV]: (state) => ({
      ...state,
      pageViewAnalysisError: null,
    }),
  }
);

export default reducer;
