import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

import { WeeklyUserCountPayload } from '../../../../api/serviceAnalytics/userAnalysis/type';
import { tooltip } from '../../../Atoms/ChartTooltip/HeatmapChartTooltip';

const Component = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  .apexcharts-svg {
    overflow: initial;
    .apexcharts-inner {
      .apexcharts-heatmap-series {
        rect {
          filter: none;
        }
      }
    }
  }

  ${tooltip};
`;

interface HeatmapChartProps {
  Data: WeeklyUserCountPayload | null;
  label1: string;
  label2: string;
  totalLabel: string;
  setHeatmapMax: React.Dispatch<React.SetStateAction<number>>;
  setHeatmapMin: React.Dispatch<React.SetStateAction<number>>;
}

const HeatmapChart = ({
  Data,
  label1,
  label2,
  totalLabel,
  setHeatmapMax,
  setHeatmapMin,
}: HeatmapChartProps) => {
  const yAxisCategory = ['일', '토', '금', '목', '수', '화', '월'];
  const xAxisCategory = [
    '00',
    '02',
    '04',
    '06',
    '08',
    '10',
    '12',
    '14',
    '16',
    '18',
    '20',
    '22',
  ];
  let prevFill = '';

  function printCustomTooltip({ series, seriesIndex, dataPointIndex, w }: any) {
    if (Data && series[seriesIndex][dataPointIndex] !== null) {
      const lineValue = series[seriesIndex][dataPointIndex];
      const yAxisKey = yAxisCategory[seriesIndex];
      const xAxisKey = xAxisCategory[dataPointIndex];
      const barValue1 = Data.hourly[yAxisKey][xAxisKey].old;
      const barValue2 = Data.hourly[yAxisKey][xAxisKey].new;

      const returnRate = Math.floor((barValue1 / lineValue) * 100);
      const newRate = Math.floor((barValue2 / lineValue) * 100);

      return `<div class="tooltip">
              <div class="innerBox">
                <div class="title">${totalLabel}</div>
                <div class="value">${lineValue}</div>
              </div>
              <div class="innerBox">
                <div class="title">${label1}</div>
                <div class="value">${barValue1}</div>
                <div class="rate">(${returnRate || 0}%)</div>
              </div>
              <div class="innerBox">
                <div class="title">${label2}</div>
                <div class="value">${barValue2}</div>
                <div class="rate">(${newRate || 0}%)</div>
              </div>
            </div>`;
    }
    return ``;
  }

  const BarOnMouseEnter = (e: any, chartContext: any, config: any) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (target.nodeName === 'rect') {
        const fill = target.getAttribute('fill');
        if (fill) {
          prevFill = fill;
        }

        target.style.fill = '#7851e7';
      }
    }
  };

  const BarOnMouseLeave = (e: any, chartContext: any, config: any) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (target.nodeName === 'rect') {
        target.style.fill = prevFill;
      }
    }
  };

  const options = {
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },

    noData: {
      text: '데이터를 불러오지 못했습니다.',
      align: 'center' as const,
      verticalAlign: 'middle' as const,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#424242',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      redrawOnParentResize: false,
      redrawOnWindowResize: true,
      events: {
        dataPointMouseLeave: BarOnMouseLeave,
        dataPointMouseEnter: BarOnMouseEnter,
      },
    },
    xaxis: {
      categories: xAxisCategory,
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          fontWeight: 400,
          fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
          fontSize: '14px',
          colors: '#757575',
        },
      },

      position: 'top',
    },
    plotOptions: {
      heatmap: {
        enableShades: true,
        shadeIntensity: 0,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 10000,
              color: '#e8dcfd',
            },
          ],
        },
      },
    },
    stroke: {
      width: 4,
    },
    grid: {
      padding: {
        right: -8,
        top: -15,
        bottom: -25,
      },
    },
    yaxis: {
      labels: {
        show: true,
        offsetX: -14,
        offsetY: 0,
        rotate: 0,
        style: {
          fontWeight: 400,
          fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
          fontSize: '14px',
          colors: '#757575',
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: printCustomTooltip,
    },
  };

  const ChartRef = useRef<any>(null);
  const [valueRange, setValueRange] = useState({ min: 0, max: 0 });
  const [rebuildSeries, setRebuildSeries] = useState<
    {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[]
  >([]);
  useEffect(() => {
    const { current } = ChartRef;
    if (Data && current) {
      const seriesTemp: {
        name: string;
        data: {
          x: string;
          y: number;
        }[];
      }[] = [];
      let min = 0;
      let max = 0;

      yAxisCategory.forEach((element, index) => {
        const dataTemp: {
          x: string;
          y: number;
        }[] = [];
        xAxisCategory.forEach((xAxisElement, xAxisIndex) => {
          const y =
            Data.hourly[element][xAxisElement].new +
            Data.hourly[element][xAxisElement].old;
          dataTemp.push({
            x: `${xAxisElement}시`,
            y,
          });
          if (index === 0 && xAxisIndex === 0) {
            min = y;
            max = y;
          } else {
            if (max < y) {
              max = y;
            }
            if (min > y) {
              min = y;
            }
          }
        });
        seriesTemp.push({
          name: element,
          data: dataTemp,
        });
      });
      setHeatmapMax(max);
      setHeatmapMin(min);

      setRebuildSeries(seriesTemp);
      setValueRange({
        min,
        max,
      });
    }
  }, [Data]);

  useEffect(() => {
    const { current } = ChartRef;
    if (ChartRef.current && rebuildSeries.length > 0) {
      current.chart.updateOptions({
        plotOptions: {
          heatmap: {
            enableShades: true,
            shadeIntensity: 0.1,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: '#757575',
                },
                {
                  from: 0.0001,
                  to: valueRange.max * 0.25,
                  color: '#e8dcfd',
                },
                {
                  from: valueRange.max * 0.25,
                  to: valueRange.max * 0.5,
                  color: '#d1bafc',
                },
                {
                  from: valueRange.max * 0.5,
                  to: valueRange.max * 0.75,
                  color: '#b597f7',
                },
                {
                  from: valueRange.max * 0.75,
                  to: valueRange.max,
                  color: '#9d7bf0',
                },
              ],
            },
          },
        },
      });
    }
  }, [ChartRef, valueRange, rebuildSeries]);

  return (
    <Component>
      <Chart
        type="heatmap"
        series={rebuildSeries}
        options={options}
        width="100%"
        height="100%"
        ref={ChartRef}
      />
    </Component>
  );
};

export default HeatmapChart;
