import { takeLatest } from 'redux-saga/effects';
import {
  apiGetTotalUserStatistics,
  apiGetTotalNewUserStatistics,
  apiGetTotalPageViewStatistics,
  apiGetTotalSessionStatistics,
  apiGetPVMeanStatistics,
  apiGetUVMeanStatistics,
  apiGetUsageMeanStatistics,
} from '../../../api/serviceAnalytics/dashboard/api';

import createRequestSaga from '../../createRequestSaga';
import {
  GET_TOTAL_USER_STATISTICS,
  GET_TOTAL_NEW_USER_STATISTICS,
  GET_TOTAL_PAGE_VIEW_STATISTICS,
  GET_TOTAL_SESSION_STATISTICS,
  GET_PV_PER_SESSION,
  GET_SESSION_PER_UV,
  GET_SESSION_DURATION_PER_SESSION,
  getPVPerSessionAsync,
  getSessionPerUVAsync,
  getSessionDurationPerSessionAsync,
  getTotalUserStatisticsAsync,
  getTotalNewUserStatisticsAsync,
  getTotalPageViewStatisticsAsync,
  getTotalSessionStatisticsAsync,
} from './actions';

const getTotalUserStatisticsSaga = createRequestSaga(
  getTotalUserStatisticsAsync,
  apiGetTotalUserStatistics
);

const getTotalNewUserStatisticsSaga = createRequestSaga(
  getTotalNewUserStatisticsAsync,
  apiGetTotalNewUserStatistics
);

const getTotalPageViewStatisticsSaga = createRequestSaga(
  getTotalPageViewStatisticsAsync,
  apiGetTotalPageViewStatistics
);

const getTotalSessionStatisticsSaga = createRequestSaga(
  getTotalSessionStatisticsAsync,
  apiGetTotalSessionStatistics
);

const getPVPerSessionSaga = createRequestSaga(
  getPVPerSessionAsync,
  apiGetPVMeanStatistics
);

const getSessionPerUVSaga = createRequestSaga(
  getSessionPerUVAsync,
  apiGetUVMeanStatistics
);

const getSessionDurationPerSessionSaga = createRequestSaga(
  getSessionDurationPerSessionAsync,
  apiGetUsageMeanStatistics
);

export function* serviceDashboardSaga() {
  yield takeLatest(GET_TOTAL_USER_STATISTICS, getTotalUserStatisticsSaga);
  yield takeLatest(
    GET_TOTAL_NEW_USER_STATISTICS,
    getTotalNewUserStatisticsSaga
  );
  yield takeLatest(
    GET_TOTAL_PAGE_VIEW_STATISTICS,
    getTotalPageViewStatisticsSaga
  );
  yield takeLatest(GET_TOTAL_SESSION_STATISTICS, getTotalSessionStatisticsSaga);
  yield takeLatest(GET_PV_PER_SESSION, getPVPerSessionSaga);
  yield takeLatest(GET_SESSION_PER_UV, getSessionPerUVSaga);
  yield takeLatest(
    GET_SESSION_DURATION_PER_SESSION,
    getSessionDurationPerSessionSaga
  );
}

export { serviceDashboardSaga as default };
