import { createReducer } from 'typesafe-actions';
import {
  GET_AIVORY_TOPIC_USAGE_WORDCLOUD,
  GET_AIVORY_TOPIC_USAGE_WORDCLOUD_ERROR,
  GET_AIVORY_TOPIC_USAGE_WORDCLOUD_SUCCESS,
  SET_ERROR_NULL_AIVORY_CONTENT_KEYWORD,
} from './actions';
import {
  contentKeywordAnalysisAction,
  contentKeywordAnalysisState,
} from './types';

const initialState: contentKeywordAnalysisState = {
  isLoadingTopicUsageWordcloud: false,
  topicUsageWordcloudData: null,
  contentKeywordAnalysisError: null,
};

const reducer = createReducer<
  contentKeywordAnalysisState,
  contentKeywordAnalysisAction
>(initialState, {
  [GET_AIVORY_TOPIC_USAGE_WORDCLOUD]: (state) => ({
    ...state,
    isLoadingTopicUsageWordcloud: true,
    topicUsageWordcloudData: null,
    contentKeywordAnalysisError: null,
  }),
  [GET_AIVORY_TOPIC_USAGE_WORDCLOUD_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingTopicUsageWordcloud: false,
    topicUsageWordcloudData: action.payload.payload,
  }),
  [GET_AIVORY_TOPIC_USAGE_WORDCLOUD_ERROR]: (state, action) => ({
    ...state,
    isLoadingTopicUsageWordcloud: false,
    topicUsageWordcloudData: null,
    contentKeywordAnalysisError: action.payload,
  }),

  [SET_ERROR_NULL_AIVORY_CONTENT_KEYWORD]: (state) => ({
    ...state,
    contentKeywordAnalysisError: null,
  }),
});

export default reducer;
