import { createReducer } from 'typesafe-actions';
import {
  GET_PV_PER_SESSION,
  GET_PV_PER_SESSION_SUCCESS,
  GET_PV_PER_SESSION_ERROR,
  GET_SESSION_PER_UV,
  GET_SESSION_PER_UV_SUCCESS,
  GET_SESSION_PER_UV_ERROR,
  GET_SESSION_DURATION_PER_SESSION,
  GET_SESSION_DURATION_PER_SESSION_SUCCESS,
  GET_SESSION_DURATION_PER_SESSION_ERROR,
  GET_TOTAL_USER_STATISTICS,
  GET_TOTAL_USER_STATISTICS_SUCCESS,
  GET_TOTAL_USER_STATISTICS_ERROR,
  GET_TOTAL_NEW_USER_STATISTICS,
  GET_TOTAL_NEW_USER_STATISTICS_ERROR,
  GET_TOTAL_NEW_USER_STATISTICS_SUCCESS,
  GET_TOTAL_PAGE_VIEW_STATISTICS,
  GET_TOTAL_PAGE_VIEW_STATISTICS_ERROR,
  GET_TOTAL_PAGE_VIEW_STATISTICS_SUCCESS,
  GET_TOTAL_SESSION_STATISTICS,
  GET_TOTAL_SESSION_STATISTICS_ERROR,
  GET_TOTAL_SESSION_STATISTICS_SUCCESS,
  SET_ERROR_NULL_SERVICE_DASHBOARD,
} from './actions';
import { dashboardAction, dashboardState } from './types';

const initialState: dashboardState = {
  isGetTNUSLoading: false,
  isGetTPVSLoading: false,
  isGetTSSLoading: false,
  isGetTUSLoading: false,

  isGetPPSLoading: false,
  isGetSPULoading: false,
  isGetSDPSLoading: false,

  TNUSData: null,
  TPVSData: null,
  TSSData: null,
  TUSData: null,

  PPSData: null,
  SPUData: null,
  SDPSData: null,

  dashboardError: null,
};

const reducer = createReducer<dashboardState, dashboardAction>(initialState, {
  [GET_TOTAL_USER_STATISTICS]: (state) => ({
    ...state,
    isGetTUSLoading: true,
    TUSData: null,
    dashboardError: null,
  }),
  [GET_TOTAL_USER_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isGetTUSLoading: false,
    TUSData: action.payload.payload,
  }),
  [GET_TOTAL_USER_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isGetTUSLoading: false,
    TUSData: null,
    dashboardError: action.payload,
  }),

  [GET_TOTAL_NEW_USER_STATISTICS]: (state) => ({
    ...state,
    isGetTNUSLoading: true,
    TNUSData: null,
    dashboardError: null,
  }),
  [GET_TOTAL_NEW_USER_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isGetTNUSLoading: false,
    TNUSData: action.payload.payload,
  }),
  [GET_TOTAL_NEW_USER_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isGetTNUSLoading: false,
    TNUSData: null,
    dashboardError: action.payload,
  }),

  [GET_TOTAL_PAGE_VIEW_STATISTICS]: (state) => ({
    ...state,
    isGetTPVSLoading: true,
    TPVSData: null,
    dashboardError: null,
  }),
  [GET_TOTAL_PAGE_VIEW_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isGetTPVSLoading: false,
    TPVSData: action.payload.payload,
  }),
  [GET_TOTAL_PAGE_VIEW_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isGetTPVSLoading: false,
    TPVSData: null,
    dashboardError: action.payload,
  }),

  [GET_TOTAL_SESSION_STATISTICS]: (state) => ({
    ...state,
    isGetTSSLoading: true,
    TSSData: null,
    dashboardError: null,
  }),
  [GET_TOTAL_SESSION_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isGetTSSLoading: false,
    TSSData: action.payload.payload,
  }),
  [GET_TOTAL_SESSION_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isGetTSSLoading: false,
    TSSData: null,
    dashboardError: action.payload,
  }),

  [GET_PV_PER_SESSION]: (state) => ({
    ...state,
    isGetPPSLoading: true,
    PPSData: null,
    dashboardError: null,
  }),
  [GET_PV_PER_SESSION_SUCCESS]: (state, action) => ({
    ...state,
    isGetPPSLoading: false,
    PPSData: action.payload.payload,
  }),
  [GET_PV_PER_SESSION_ERROR]: (state, action) => ({
    ...state,
    isGetPPSLoading: false,
    PPSData: null,
    dashboardError: action.payload,
  }),

  [GET_SESSION_PER_UV]: (state) => ({
    ...state,
    isGetSPULoading: true,
    SPUData: null,
    dashboardError: null,
  }),
  [GET_SESSION_PER_UV_SUCCESS]: (state, action) => ({
    ...state,
    isGetSPULoading: false,
    SPUData: action.payload.payload,
  }),
  [GET_SESSION_PER_UV_ERROR]: (state, action) => ({
    ...state,
    isGetSPULoading: false,
    SPUData: null,
    dashboardError: action.payload,
  }),

  [GET_SESSION_DURATION_PER_SESSION]: (state) => ({
    ...state,
    isGetSDPSLoading: true,
    SDPSData: null,
    dashboardError: null,
  }),
  [GET_SESSION_DURATION_PER_SESSION_SUCCESS]: (state, action) => ({
    ...state,
    isGetSDPSLoading: false,
    SDPSData: action.payload.payload,
  }),
  [GET_SESSION_DURATION_PER_SESSION_ERROR]: (state, action) => ({
    ...state,
    isGetSDPSLoading: false,
    SDPSData: null,
    dashboardError: action.payload,
  }),

  [SET_ERROR_NULL_SERVICE_DASHBOARD]: (state) => ({
    ...state,
    dashboardError: null,
  }),
});

export default reducer;
