import React from 'react';
import styled from 'styled-components';

const Component = styled.tbody`
  width: 100%;
`;

interface TableBodyProps {
  className?: string;
  children?: React.ReactNode;
}

const TableBody = ({ className, children }: TableBodyProps) => {
  return <Component className={className}>{children}</Component>;
};

export default TableBody;
