import { takeLatest } from 'redux-saga/effects';
import {
  apiGetSessionCount,
  apiGetWeeklySessionCount,
} from '../../../api/serviceAnalytics/sessionAnalysis/api';

import createRequestSaga from '../../createRequestSaga';
import {
  GET_SESSION_COUNT,
  GET_WEEKLY_SESSION_COUNT,
  getSessionCountAsync,
  getWeeklySessionCountAsync,
} from './actions';

const getSessionCountSaga = createRequestSaga(
  getSessionCountAsync,
  apiGetSessionCount
);

const getWeeklySessionCountSaga = createRequestSaga(
  getWeeklySessionCountAsync,
  apiGetWeeklySessionCount
);

export function* serviceSessionAnalysisSaga() {
  yield takeLatest(GET_SESSION_COUNT, getSessionCountSaga);
  yield takeLatest(GET_WEEKLY_SESSION_COUNT, getWeeklySessionCountSaga);
}

export { serviceSessionAnalysisSaga as default };
