import React, { ReactNodeArray, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// redux
import { RootState } from '../../../modules';
import { GetPageFlowParamType } from '../../../api/pageAnalytics/userFlowAnalysis/type';
import {
  getPageFlowConversionAsync,
  getPageFlowInflowAsync,
} from '../../../modules/PageAnalytics/UserFlowAnalysis';

// component

import FunnelCard from '../../../components/Molecule/FunnelCard/FunnelCard';
import FunnelTable from '../../../components/Molecule/Table/FunnelTable';

// layout

import {
  ETCModalHeader,
  ConversionLineWrapper,
  ConversionStraightLineWrapper,
  ETCModalContainer,
  ETCModalHeaderExit,
  ETCModalHeaderTitle,
  FunnelCardWrapper,
  FunnelModalBackground,
  FunnelPanelDot,
  FunnelPanelTitle,
  FunnelPanelTitleBox,
  FunnelPanelWrapper,
  IconArrow,
  IconClose,
  InflowArrow,
  InflowLineWrapper,
  InflowStraightLineWrapper,
  PaginationWrapper,
  ThumbnailImageBox,
  ThumbnailTitle,
  ThumbnailURL,
  ThumbnailWrapper,
  DataError,
} from './styled';
import Header from '../../../components/Organisms/Header';
import DefaultPagenation from '../../../components/Molecule/Pagenation';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';

interface UserFlowAnalysisProps extends RouteComponentProps {}

interface UserFlowAnalysisStates {
  dataList: {
    url: string;
    uv: number;
    rate: number;
  }[];
  lineInfo: {
    width: number;
    height: number;
    isUpper: boolean;
    isExit?: boolean;
  }[];
}

const UserFlowAnalysis = ({ location }: UserFlowAnalysisProps) => {
  // redux
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const { conversionData, inflowData } = useSelector(
    (state: RootState) => state.userFlowAnalysis
  );

  const loading = useSelector((state: RootState) => state.loading);
  const dispatch = useDispatch();

  const onGetPageFlowInflow = (info: GetPageFlowParamType) => {
    dispatch(getPageFlowInflowAsync.request(info));
  };
  const onGetPageFlowConversion = (info: GetPageFlowParamType) => {
    dispatch(getPageFlowConversionAsync.request(info));
  };

  // state
  const [windowWidth, setWindowWidth] = useState(0);
  const [pageId, setPageId] = useState('');
  const [pageName, setPageName] = useState('');
  const [pageLocation, setPageLocation] = useState('');
  const [lastPageNumber, setLastPageNumber] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [inflowETCInfo, setInflowETCInfo] = useState({ uv: 0, rate: 0 });
  const [conversionETCInfo, setConversionETCInfo] = useState({
    uv: 0,
    rate: 0,
  });
  const [inflowDataList, setInflowDataList] = useState<
    UserFlowAnalysisStates['dataList']
  >([]);
  const [conversionDataList, setConversionDataList] = useState<
    UserFlowAnalysisStates['dataList']
  >([]);
  const [inflowETCData, setInfolowETCData] = useState<
    UserFlowAnalysisStates['dataList']
  >([]);
  const [conversionETCData, setConversionETCData] = useState<
    UserFlowAnalysisStates['dataList']
  >([]);
  const [ETCPageData, setETCPageData] = useState<
    UserFlowAnalysisStates['dataList']
  >([]);
  const [exitDataList, setExitDataList] = useState<
    UserFlowAnalysisStates['dataList']
  >([]);

  const [isOpenModal, setIsOpenModal] = useState({
    inflow: false,
    conversion: false,
  });
  const inflowArrowEl = useRef<HTMLDivElement | null>(null);
  const ThumbnailWrapperEl = useRef<HTMLDivElement | null>(null);
  const inflowFunnelCardWrapperEl = useRef<HTMLDivElement | null>(null);
  const conversionFunnelCardWrapperEl = useRef<HTMLDivElement | null>(null);
  const [inflowLineInfo, setInflowLineInfo] = useState<
    UserFlowAnalysisStates['lineInfo']
  >([]);
  const [conversionLineInfo, setConversionLineInfo] = useState<
    UserFlowAnalysisStates['lineInfo']
  >([]);

  const generateTablePageData = (cardType: string) => {
    let selectData: UserFlowAnalysisStates['dataList'] = [];
    const tempData: UserFlowAnalysisStates['dataList'] = [];
    switch (cardType) {
      case 'inflow':
        selectData = inflowETCData;
        break;
      case 'conversion':
        selectData = conversionETCData;
        break;
      default:
        break;
    }

    for (
      let index = (pageNumber - 1) * 10;
      index <= pageNumber * 10 - 1;
      index += 1
    ) {
      if (index > selectData.length - 1) {
        break;
      }
      const element = selectData[index];
      tempData.push(element);
    }
    setETCPageData(tempData);
  };

  const onClickCloseHandle = () => {
    setIsOpenModal({
      inflow: false,
      conversion: false,
    });
    setPageNumber(1);
  };
  const onClickOpenHandle = (cardType: string) => {
    switch (cardType) {
      case 'inflow':
        setLastPageNumber(Math.ceil(inflowETCData.length / 10));
        generateTablePageData(cardType);
        setIsOpenModal({
          inflow: true,
          conversion: false,
        });
        break;
      case 'conversion':
        setLastPageNumber(Math.ceil(conversionETCData.length / 10));
        generateTablePageData(cardType);
        setIsOpenModal({
          inflow: false,
          conversion: true,
        });
        break;
      default:
        break;
    }
  };
  const printFunnelCard = (cardType: string) => {
    const result: ReactNodeArray = [];

    switch (cardType) {
      case 'inflow':
        if (inflowDataList.length === 0) {
          result.push(
            <DataError key="inflow_data_error">
              조회한 기간에 유입 데이터가 없습니다.
            </DataError>
          );
        }
        inflowDataList.forEach((element) => {
          result.push(
            <FunnelCard
              onClickOpenHandle={onClickOpenHandle}
              className={`${cardType}FunnelCard`}
              url={element.url}
              count={element.uv}
              rate={element.rate}
              cardType={cardType}
              key={element.url + element.uv}
            />
          );
        });
        if (inflowETCData.length > 0) {
          result.push(
            <FunnelCard
              onClickOpenHandle={onClickOpenHandle}
              className={`${cardType}FunnelCard`}
              url="ETC"
              count={inflowETCInfo.uv}
              rate={inflowETCInfo.rate}
              cardType={cardType}
              key="inflow_etc"
            />
          );
        }
        break;
      case 'conversion':
        if (conversionDataList.length === 0 && exitDataList.length === 0) {
          result.push(
            <DataError key="conversion_data_error">
              조회한 기간에 전환 데이터가 없습니다.
            </DataError>
          );
        }
        conversionDataList.forEach((element) => {
          result.push(
            <FunnelCard
              onClickOpenHandle={onClickOpenHandle}
              className={`${cardType}FunnelCard`}
              url={element.url}
              count={element.uv}
              rate={element.rate}
              cardType={cardType}
              key={element.url + element.uv}
            />
          );
        });
        if (conversionETCData.length > 0) {
          result.push(
            <FunnelCard
              onClickOpenHandle={onClickOpenHandle}
              className={`${cardType}FunnelCard`}
              url="ETC"
              count={conversionETCInfo.uv}
              rate={conversionETCInfo.rate}
              cardType={cardType}
              key="conversion_etc"
            />
          );
        }
        if (exitDataList.length > 0) {
          exitDataList.forEach((element) => {
            result.push(
              <FunnelCard
                onClickOpenHandle={onClickOpenHandle}
                className="exitFunnelCard"
                url="이탈"
                count={element.uv}
                rate={element.rate}
                cardType="exit"
                key="conversion_exit"
              />
            );
          });
        }

        break;
      default:
        break;
    }

    if (result.length > 0) {
      return result;
    }
    return null;
  };
  const generateConversionLine = () => {
    const testData: UserFlowAnalysisStates['lineInfo'] = [];

    const ThumbnailWrapperElInfo = {
      element_x: 0,
      element_y: 0,
      element_width: 0,
      element_height: 0,
    };

    if (ThumbnailWrapperEl) {
      const { current } = ThumbnailWrapperEl;

      if (current) {
        ThumbnailWrapperElInfo.element_x =
          window.pageXOffset + current.getBoundingClientRect().left;
        ThumbnailWrapperElInfo.element_y =
          window.pageYOffset + current.getBoundingClientRect().top;
        ThumbnailWrapperElInfo.element_width = current.offsetWidth;
        ThumbnailWrapperElInfo.element_height = current.offsetHeight;
      }
    }

    const conversionLineElArr = document.getElementsByClassName(
      'conversionFunnelCard'
    );

    const exitLineElArr = document.getElementsByClassName('exitFunnelCard');

    if (conversionLineElArr) {
      for (let index = 0; index < conversionLineElArr.length; index += 1) {
        const element = conversionLineElArr.item(index);
        if (element) {
          const element_x =
            window.pageXOffset + element.getBoundingClientRect().left;
          const element_y =
            window.pageYOffset + element.getBoundingClientRect().top;
          const element_height = (element as HTMLElement).offsetHeight;

          const endX = element_x;
          const endY = element_y + element_height / 2;
          const height =
            ThumbnailWrapperElInfo.element_y +
            ThumbnailWrapperElInfo.element_height / 2 -
            endY;
          const width =
            endX -
            (ThumbnailWrapperElInfo.element_x +
              ThumbnailWrapperElInfo.element_width);

          testData.push({
            width,
            height,
            isUpper: height > 0,
          });
        }
      }
      setConversionLineInfo(testData);
    }

    if (exitLineElArr) {
      for (let index = 0; index < exitLineElArr.length; index += 1) {
        const element = exitLineElArr.item(index);
        if (element) {
          const element_x =
            window.pageXOffset + element.getBoundingClientRect().left;
          const element_y =
            window.pageYOffset + element.getBoundingClientRect().top;
          const element_height = (element as HTMLElement).offsetHeight;

          const endX = element_x;
          const endY = element_y + element_height / 2;
          const height =
            ThumbnailWrapperElInfo.element_y +
            ThumbnailWrapperElInfo.element_height / 2 -
            endY;
          const width =
            endX -
            (ThumbnailWrapperElInfo.element_x +
              ThumbnailWrapperElInfo.element_width);

          testData.push({
            width,
            height,
            isExit: true,
            isUpper: height > 0,
          });
        }
      }
      setConversionLineInfo(testData);
    }
  };
  const generateInflowLine = () => {
    const testData: UserFlowAnalysisStates['lineInfo'] = [];

    const arrowInfo = {
      element_x: 0,
      element_y: 0,
      element_width: 0,
      element_height: 0,
    };

    if (inflowArrowEl) {
      const { current } = inflowArrowEl;
      if (current) {
        arrowInfo.element_x =
          window.pageXOffset + current.getBoundingClientRect().left;
        arrowInfo.element_y =
          window.pageYOffset + current.getBoundingClientRect().top;
        arrowInfo.element_width = current.offsetWidth;
        arrowInfo.element_height = current.offsetHeight;
      }
    }
    const inflowFunnelArr = document.getElementsByClassName('inflowFunnelCard');
    if (inflowFunnelArr) {
      for (let index = 0; index < inflowFunnelArr.length; index += 1) {
        const element = inflowFunnelArr.item(index);
        if (element) {
          const element_x =
            window.pageXOffset + element.getBoundingClientRect().left;
          const element_y =
            window.pageYOffset + element.getBoundingClientRect().top;
          const element_width = (element as HTMLElement).offsetWidth;
          const element_height = (element as HTMLElement).offsetHeight;
          const startX = element_x + element_width;
          const startY = element_y + element_height / 2;
          const height =
            arrowInfo.element_y + arrowInfo.element_height / 2 - startY;

          testData.push({
            width: arrowInfo.element_x - startX,
            height,
            isUpper: height > 0,
          });
        }
      }
      setInflowLineInfo(testData);
    }
  };
  const printInflowLine = () => {
    const result: ReactNodeArray = [];

    if (inflowLineInfo.length > 0) {
      inflowLineInfo.forEach((element) => {
        if (element.height === 0) {
          result.push(
            <InflowStraightLineWrapper
              $width={element.width}
              key={element.height + element.width}
            />
          );
        } else {
          result.push(
            <InflowLineWrapper
              className="voda_inflow_line"
              $width={element.width}
              $height={element.height}
              $isUpper={element.isUpper}
              key={JSON.stringify(element)}
            >
              {element.isUpper ? (
                <path
                  d={`M0 0 C ${element.width / 2} 0, ${element.width / 2} ${
                    element.height
                  }, ${element.width} ${element.height}`}
                  fill="none"
                  stroke="#b0b900"
                  strokeWidth="2px"
                />
              ) : (
                <path
                  d={`M0 ${-element.height} 
                C ${element.width / 2} ${-element.height}, ${
                    element.width / 2
                  } 0 ${element.width} 0`}
                  fill="none"
                  stroke="#b0b900"
                  strokeWidth="2px"
                />
              )}
            </InflowLineWrapper>
          );
        }
      });
    }

    if (result.length > 0) {
      return result;
    }
    return null;
  };
  const printConversionLine = () => {
    const result: ReactNodeArray = [];
    if (conversionLineInfo.length > 0) {
      conversionLineInfo.forEach((element) => {
        if (element.height === 0) {
          if (element.isExit) {
            result.push(
              <ConversionStraightLineWrapper
                className="voda_conversion_line"
                $width={element.width}
                $isExit={element.isExit}
                key={JSON.stringify(element)}
              />
            );
          } else {
            result.push(
              <ConversionStraightLineWrapper
                className="voda_conversion_line"
                $width={element.width}
                key={JSON.stringify(element)}
              />
            );
          }
        } else {
          result.push(
            <ConversionLineWrapper
              className="voda_conversion_line"
              $width={element.width}
              $height={element.height}
              $isUpper={element.isUpper}
              key={JSON.stringify(element)}
            >
              {element.isUpper ? (
                <path
                  d={`M0 ${element.height} 
                C ${element.width / 2} ${element.height}, ${
                    element.width / 2
                  } 0, 
                ${element.width} 0`}
                  fill="none"
                  stroke={element.isExit ? '#f16464' : '#fdb67d'}
                  strokeWidth="2px"
                />
              ) : (
                <path
                  d={`M0 0 
                C ${element.width / 2} 0, ${
                    element.width / 2
                  } ${-element.height}
                ${element.width} ${-element.height}`}
                  fill="none"
                  stroke={element.isExit ? '#f16464' : '#fdb67d'}
                  strokeWidth="2px"
                />
              )}
            </ConversionLineWrapper>
          );
        }
      });
    }
    if (result.length > 0) {
      return result;
    }
    return null;
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const params: { [key: string]: string } = {};
    location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      (str: string, key: string, value: string) => {
        params[key] = value;
        return value;
      }
    );
    setPageId(params.pageid);
    setPageName(params.pagename);
    setPageLocation(params.pagelocation);
  }, []);
  useEffect(() => {
    if (!isDatePickerToggle && pageLocation !== '') {
      onGetPageFlowInflow({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        page_location: pageLocation,
        position: 'previous',
      });
      onGetPageFlowConversion({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        page_location: pageLocation,
        position: 'next',
      });
    }
  }, [isDatePickerToggle, startDate, endDate, pageLocation]);
  useEffect(() => {
    const inflowETCDataTemp: UserFlowAnalysisStates['dataList'] = [];
    const inflowDataTemp: UserFlowAnalysisStates['dataList'] = [];

    let inflowUVSum = 0;
    let inflowRateSum = 0;

    inflowData.forEach((element, index) => {
      if (index > 3) {
        inflowUVSum += element.cnt;
        inflowRateSum += element.rate;
        inflowETCDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      } else {
        inflowDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      }
    });

    setInflowETCInfo({
      uv: inflowUVSum,
      rate: inflowRateSum,
    });

    setInflowDataList(inflowDataTemp);
    setInfolowETCData(inflowETCDataTemp);
  }, [inflowData]);

  useEffect(() => {
    const conversionETCDataTemp: UserFlowAnalysisStates['dataList'] = [];
    const conversionDataTemp: UserFlowAnalysisStates['dataList'] = [];
    const exitDataTemp: UserFlowAnalysisStates['dataList'] = [];

    let conversionUVSum = 0;
    let conversionRateSum = 0;

    conversionData.forEach((element, index) => {
      if (element.page === 'exit') {
        exitDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      } else if (index > 3) {
        conversionUVSum += element.cnt;
        conversionRateSum += element.rate;
        conversionETCDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      } else {
        conversionDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      }
    });
    setExitDataList(exitDataTemp);
    setConversionETCInfo({
      uv: conversionUVSum,
      rate: conversionRateSum,
    });
    setConversionDataList(conversionDataTemp);
    setConversionETCData(conversionETCDataTemp);
  }, [conversionData]);

  useEffect(() => {
    generateInflowLine();
  }, [inflowFunnelCardWrapperEl.current, inflowDataList, windowWidth]);

  useEffect(() => {
    generateConversionLine();
  }, [conversionFunnelCardWrapperEl.current, conversionDataList, windowWidth]);

  useEffect(() => {
    if (isOpenModal.inflow) {
      generateTablePageData('inflow');
    }
    if (isOpenModal.conversion) {
      generateTablePageData('conversion');
    }
  }, [pageNumber]);

  useEffect(() => {
    window.addEventListener(
      'resize',
      _.debounce((event) => {
        setWindowWidth(window.innerWidth);
      }, 1000)
    );
  }, []);
  return (
    <ContentsLayout>
      <FunnelModalBackground
        $isOpen={isOpenModal.inflow || isOpenModal.conversion}
      />
      <ETCModalContainer $isOpen={isOpenModal.inflow}>
        <ETCModalHeader>
          <ETCModalHeaderTitle>유입 채널 기타</ETCModalHeaderTitle>
          <ETCModalHeaderExit onClick={onClickCloseHandle}>
            <IconClose />
          </ETCModalHeaderExit>
        </ETCModalHeader>
        <FunnelTable data={ETCPageData} />
        <PaginationWrapper>
          {isOpenModal.inflow ? (
            <DefaultPagenation count={lastPageNumber} onChange={onChangePage} />
          ) : null}
        </PaginationWrapper>
      </ETCModalContainer>
      <ETCModalContainer $isOpen={isOpenModal.conversion}>
        <ETCModalHeader>
          <ETCModalHeaderTitle>전환 채널 기타</ETCModalHeaderTitle>
          <ETCModalHeaderExit onClick={onClickCloseHandle}>
            <IconClose />
          </ETCModalHeaderExit>
        </ETCModalHeader>
        <FunnelTable data={ETCPageData} />
        <PaginationWrapper>
          {isOpenModal.conversion ? (
            <DefaultPagenation count={lastPageNumber} onChange={onChangePage} />
          ) : null}
        </PaginationWrapper>
      </ETCModalContainer>

      <Header title="사용 흐름 분석" isDatePicker />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={4}>
          <FunnelPanelWrapper>
            <FunnelPanelTitleBox>
              <FunnelPanelDot $cardType="inflow" />
              <FunnelPanelTitle>유입</FunnelPanelTitle>
            </FunnelPanelTitleBox>
            <FunnelCardWrapper ref={inflowFunnelCardWrapperEl}>
              {printFunnelCard('inflow')}
            </FunnelCardWrapper>
          </FunnelPanelWrapper>
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <FunnelPanelWrapper>
            <FunnelPanelTitleBox />
            <FunnelCardWrapper>
              <ThumbnailWrapper ref={ThumbnailWrapperEl}>
                <InflowArrow ref={inflowArrowEl}>
                  <IconArrow $isVisible={inflowLineInfo.length > 0} />
                  {printInflowLine()}
                </InflowArrow>
                {printConversionLine()}
                <ThumbnailImageBox>
                  <img
                    src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                      'voda_tenant'
                    )}/auto/${pageId}/${encodeURIComponent('page_screenshot')}`}
                    alt="..."
                  />
                </ThumbnailImageBox>
                <ThumbnailTitle>{pageName}</ThumbnailTitle>
                <ThumbnailURL>{pageLocation}</ThumbnailURL>
              </ThumbnailWrapper>
            </FunnelCardWrapper>
          </FunnelPanelWrapper>
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <FunnelPanelWrapper>
            <FunnelPanelTitleBox>
              <FunnelPanelDot $cardType="conversion" />
              <FunnelPanelTitle>전환</FunnelPanelTitle>
            </FunnelPanelTitleBox>
            <FunnelCardWrapper ref={conversionFunnelCardWrapperEl}>
              {printFunnelCard('conversion')}
            </FunnelCardWrapper>
          </FunnelPanelWrapper>
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default withRouter(UserFlowAnalysis);
