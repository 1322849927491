import axios from 'axios';

import config from '../../config-api.json';
import {
  GetAIVORYTopicUsageWordcloudParamType,
  GetAIVORYTopicUsageWordcloudResponseType,
} from './type';

// axios.defaults.withCredentials = true;
const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetAIVORYTopicUsageWordcloud(
  info: GetAIVORYTopicUsageWordcloudParamType
) {
  const response = await axios.get<GetAIVORYTopicUsageWordcloudResponseType>(
    `${apiInfo.api_url}/aivory/topic_usage_wordcloud/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export { apiGetAIVORYTopicUsageWordcloud as defaults };
