import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from '../../Atoms/Button';
import { IconSMArrowDown } from '../../Atoms/Icon';
import { P4 } from '../../Atoms/Typo';

const Component = styled(Button)<{ $isAsc: boolean }>`
  display: flex;
  align-items: center;
  background-color: transparent;

  ${P4} {
    color: ${(props) => props.theme.colors.grey500};
  }

  svg {
    width: 12px;
    height: 7px;
    margin-left: 4px;
    ${(props) => {
      if (props.$isAsc) {
        return css`
          transform: rotate(180deg);
        `;
      }
      return css``;
    }}
  }
`;

interface SortButtonProps {
  title: string;
  isAsc: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SortButton = ({ title, isAsc, className, onClick }: SortButtonProps) => {
  return (
    <Component className={className} onClick={onClick} $isAsc={isAsc} isBlock>
      <P4>{title}</P4>

      <IconSMArrowDown className="disabled" />
    </Component>
  );
};

export default SortButton;
