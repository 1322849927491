import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import configureStore from './modules/configureStore';

import App from './containers/App';
import history from './modules/history';

import reportWebVitals from './reportWebVitals';

// axios.defaults.withCredentials = true;
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
