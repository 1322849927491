import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetPageFlowParamType,
  GetPageFlowResponseType,
} from '../../../api/pageAnalytics/userFlowAnalysis/type';

export const GET_PAGE_FLOW_INFLOW = 'pageAnalytics/UserFlowAnalysis/GET_PAGE_FLOW_INFLOW' as const;
export const GET_PAGE_FLOW_INFLOW_SUCCESS = 'pageAnalytics/UserFlowAnalysis/GET_PAGE_FLOW_INFLOW_SUCCESS' as const;
export const GET_PAGE_FLOW_INFLOW_ERROR = 'pageAnalytics/UserFlowAnalysis/GET_PAGE_FLOW_INFLOW_ERROR' as const;

export const GET_PAGE_FLOW_CONVERSION = 'pageAnalytics/UserFlowAnalysis/GET_PAGE_FLOW_CONVERSION' as const;
export const GET_PAGE_FLOW_CONVERSION_SUCCESS = 'pageAnalytics/UserFlowAnalysis/GET_PAGE_FLOW_CONVERSION_SUCCESS' as const;
export const GET_PAGE_FLOW_CONVERSION_ERROR = 'pageAnalytics/UserFlowAnalysis/GET_PAGE_FLOW_CONVERSION_ERROR' as const;

export const SET_ERROR_NULL_USER_FLOW =
  'pageAnalytics/UserFlowAnalysis/SET_ERROR_NULL_USER_FLOW';

export const getPageFlowInflowAsync = createAsyncAction(
  GET_PAGE_FLOW_INFLOW,
  GET_PAGE_FLOW_INFLOW_SUCCESS,
  GET_PAGE_FLOW_INFLOW_ERROR
)<GetPageFlowParamType, GetPageFlowResponseType, AxiosErrorType>();

export const getPageFlowConversionAsync = createAsyncAction(
  GET_PAGE_FLOW_CONVERSION,
  GET_PAGE_FLOW_CONVERSION_SUCCESS,
  GET_PAGE_FLOW_CONVERSION_ERROR
)<GetPageFlowParamType, GetPageFlowResponseType, AxiosErrorType>();

export const setErrorNullUserFlow = createAction(SET_ERROR_NULL_USER_FLOW)();
