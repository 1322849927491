import React, { ReactNodeArray } from 'react';
import styled from 'styled-components';
import {
  ScrollEventsPayload,
  UserUsabilityDatum,
} from '../../../../api/pageAnalytics/uxAnalytics/scroll/type';

const Component = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const HeatmapBarItem = styled.div<{ $height: number; $width: number }>`
  height: ${(props) => props.$height}px;
  width: 100%;
  /* width: ${(props) => props.$width}%; */
  /* border-bottom: 1px solid black; */
  z-index: 0;
`;

interface UXHeatmapBarChartProps {
  data: ScrollEventsPayload | null;
  lastBarHeight: number;
  barHeight: number;
  barCnt: number;
  className?: string;
  onMouseMove: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    dataLength: number,
    element: UserUsabilityDatum,
    index: number
  ) => void;
}

const UXHeatmapBarChart = ({
  barHeight,
  data,
  lastBarHeight,
  barCnt,
  className,
  onMouseMove,
}: UXHeatmapBarChartProps) => {
  const printHeatmapBar = () => {
    const result: ReactNodeArray = [];

    if (data) {
      data.scroll_usability.forEach((element, index) => {
        const dataLength = data.scroll_usability.length;
        result.push(
          <HeatmapBarItem
            key={
              element.attention_rate +
              element.avg_attention_time +
              element.view_rate
            }
            $width={element.view_rate}
            $height={index !== barCnt - 1 ? barHeight : lastBarHeight}
            onMouseMove={(event) => {
              onMouseMove(event, dataLength, element, index);
            }}
          />
        );
      });
    }

    return result;
  };

  return <Component>{printHeatmapBar()}</Component>;
};

export default UXHeatmapBarChart;
