import { takeLatest } from 'redux-saga/effects';

import { apiGetForecastDate } from '../../api/datePicker/api';
import createRequestSaga from '../createRequestSaga';
import { GET_FORECAST_DATE, getForecastDateAsync } from './actions';

const getForecastDateSaga = createRequestSaga(
  getForecastDateAsync,
  apiGetForecastDate
);

export function* datePickerSaga() {
  yield takeLatest(GET_FORECAST_DATE, getForecastDateSaga);
}

export { datePickerSaga as default };
