import React from 'react';
import styled from 'styled-components';
import { H2, P3, H4 } from '../../Atoms/Typo';
import BarChart from '../../Molecule/Chart/BarChart';
import Count from '../../Molecule/Count/NumberToKorean';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 40px;
`;

const Title = styled.div`
  ${P3} {
    color: ${(props) => props.theme.colors.grey500};
  } ;
`;

const Value = styled.div`
  margin-top: 4px;

  ${H4} {
    font-size: 24px;
  }
  ${H2} {
    color: ${(props) => props.theme.colors.grey800};
  }

  .voda_num_to_kor_count {
    ${H2} {
      color: ${(props) => props.theme.colors.grey800};
    }
  }
`;

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  } ;
`;

export interface BarChartCardProps {
  title: string;
  value?: number;
  valueString?: string;
  desc: string;
  data: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
  isLabelInChart: boolean;
  isTime?: boolean;
  isRate?: boolean;
  isForecast?: boolean;
}

const BarChartCard = ({
  title,
  value,
  valueString,
  desc,
  data,
  isLabelInChart,
  isTime,
  isRate,
  isForecast,
}: BarChartCardProps) => {
  const convertSec = (sec: number) => {
    const cvMin = Math.floor(sec / 60);
    const cvSec = Math.floor(sec % 60);

    if (cvMin === 0) {
      return `${cvSec}초`;
    }
    return `${cvMin}분 ${cvSec}초`;
  };

  return (
    <Component>
      <Body>
        <BarChart
          isRate={isRate}
          data={data}
          isLabelInChart={false}
          isTime={isTime}
          isForecast={isForecast}
        />
      </Body>
      <Title>
        <P3>{title}</P3>
      </Title>
      {value !== undefined ? (
        <>
          {isLabelInChart ? (
            <Value>
              <H2>{valueString || `상위 ${value}%`}</H2>
            </Value>
          ) : (
            <Value>
              <H2>{isTime ? convertSec(value) : <Count value={value} />}</H2>
            </Value>
          )}
        </>
      ) : null}

      <Desc>
        {desc.split(`\n`).map((line) => {
          return <P3 key={line}>{line}</P3>;
        })}
      </Desc>
    </Component>
  );
};

export default BarChartCard;
