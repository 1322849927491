import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import {
  NewVSReturnDurationPayload,
  NewVSReturnPVPayload,
  NewVSReturnUVPayload,
} from '../../../../api/report/type';
import DefaultLink from '../../../Atoms/Link';
import { H3, P1, P3 } from '../../../Atoms/Typo';
import Header from '../../../Molecule/Card/CardHeader';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import Spinner from '../../../Molecule/Spinner';
import BarChartCard from '../BarChartCard';

const Component = styled.div`
  width: 100%;
  height: 531px;
  display: flex;
  border-radius: 8px;
  background-color: white;
`;

const InnerComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  padding: 24px 24px 40px 24px;
`;

const Split = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const ReportSummaryWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const ReportSummaryRaw = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
`;

const PlusRate = styled(H3)`
  color: ${(props) => props.theme.colors.success5};
  margin-right: 4px;
`;

const MinusRate = styled(H3)`
  color: ${(props) => props.theme.colors.danger5};
  margin-right: 4px;
`;

/* infinity 관련 */
const NoRate = styled(H3)`
  color: ${(props) => props.theme.colors.danger5};
  margin-right: 4px;
`;

const LinkWrapper = styled.div`
  width: fit-content;

  a {
    ${P3} {
      color: ${(props) => props.theme.colors.grey500};
    }

    :hover {
      ${P3} {
        color: ${(props) => props.theme.colors.grey500};
      }
    }
  }
  margin-top: 24px;
`;

const Underline = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey500};
  margin-top: 4px;
`;

interface NewVSReturnReportCardProps {
  isLoading: boolean;
  uvData: NewVSReturnUVPayload | null;
  durationData: NewVSReturnDurationPayload | null;
  pvData: NewVSReturnPVPayload | null;
}

interface NewVSReturnReportCardState {
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

const NewVSReturnReportCard = ({
  uvData,
  durationData,
  pvData,
  isLoading,
}: NewVSReturnReportCardProps) => {
  const [diffDuration, setDiffDuration] = useState(0);
  const [diffPV, setDiffPV] = useState(0);

  const [mainUVUser, setMainUVUser] = useState<'new' | 'return'>('new');
  const [mainUV, setMainUV] = useState(0);
  const [mainDurationUser, setMainDurationUser] = useState<'new' | 'return'>(
    'new'
  );
  const [mainDuration, setMainDuration] = useState(0);
  const [mainPVUser, setMainPVUser] = useState<'new' | 'return'>('new');
  const [mainPV, setMainPV] = useState(0);

  const [rebuildUVData, setRebuildUVData] = useState<
    NewVSReturnReportCardState['rebuildData']
  >([]);
  const [rebuildDurationData, setRebuildDurationData] = useState<
    NewVSReturnReportCardState['rebuildData']
  >([]);
  const [rebuildPVData, setRebuildPVData] = useState<
    NewVSReturnReportCardState['rebuildData']
  >([]);

  const [uvReport, setUVReport] = useState('');
  const [uvDurationReport, setDurationReport] = useState('');
  const [pvReport, setPVReport] = useState('');
  const [compareRateDuration, setCompareRateDuration] = useState(0);
  const [compareRatePV, setCompareRatePV] = useState(0);

  /* 수정 */
  const printRate = (rate: number) => {
    if (Number.isFinite(rate)) {
      if (rate > 0) {
        return <PlusRate>{`(+ ${rate.toFixed(0)}%)`}</PlusRate>;
      }
      return <MinusRate>{`(- ${Math.abs(rate).toFixed(0)}%)`}</MinusRate>;
    }
    return <NoRate> </NoRate>;
  };

  const convertSec = (sec: number) => {
    const cvMin = Math.floor(sec / 60);
    const cvSec = Math.floor(sec % 60);

    if (cvMin === 0) {
      return `${cvSec}초`;
    }
    return `${cvMin}분 ${cvSec}초`;
  };

  useEffect(() => {
    if (uvData) {
      if (uvData.new_uv_count > uvData.re_uv_count) {
        setMainUV(uvData.new_uv_count);
        setMainUVUser('new');
        setUVReport(
          `신규 사용자가\n${uvData.dif_percent}% 더 많이 방문했습니다.`
        );
      } else {
        setMainUV(uvData.re_uv_count);
        setMainUVUser('return');
        setUVReport(
          `재방문 사용자가\n${uvData.dif_percent}% 더 많이 방문했습니다.`
        );
      }

      const rebuildData: NewVSReturnReportCardState['rebuildData'] = [];

      rebuildData.push({
        '신규 사용자': uvData.new_uv_count,
        isHighlight: uvData.new_uv_count > uvData.re_uv_count,
      });

      rebuildData.push({
        '재방문 사용자': uvData.re_uv_count,
        isHighlight: uvData.new_uv_count <= uvData.re_uv_count,
      });

      setRebuildUVData(rebuildData);
    }
  }, [uvData]);

  useEffect(() => {
    if (durationData) {
      const rebuildData: NewVSReturnReportCardState['rebuildData'] = [];

      const diff_duration = Math.abs(
        durationData.new_usage - durationData.re_usage
      );

      if (durationData.new_usage > durationData.re_usage) {
        setMainDuration(durationData.new_usage);
        setMainDurationUser('new');
        setDurationReport(
          `신규 사용자가\n${convertSec(diff_duration)} 더 오래 머뭅니다.`
        );
      } else {
        setMainDuration(durationData.re_usage);
        setMainDurationUser('return');
        setDurationReport(
          `재방문 사용자가\n${convertSec(diff_duration)} 더 오래 머뭅니다.`
        );
      }

      setCompareRateDuration(
        ((durationData.new_usage - durationData.re_usage) /
          durationData.re_usage) *
          100
      );

      rebuildData.push({
        '신규 사용자': durationData.new_usage,
        isHighlight: durationData.new_usage > durationData.re_usage,
      });

      rebuildData.push({
        '재방문 사용자': durationData.re_usage,
        isHighlight: durationData.new_usage <= durationData.re_usage,
      });

      setRebuildDurationData(rebuildData);
    }
  }, [durationData]);

  useEffect(() => {
    if (pvData) {
      const rebuildData: NewVSReturnReportCardState['rebuildData'] = [];

      const diff_pv = Math.abs(pvData.new_pv - pvData.re_pv);

      if (pvData.new_pv > pvData.re_pv) {
        setMainPV(pvData.new_pv);
        setMainPVUser('new');
        setPVReport(`신규 사용자가\n${diff_pv}개 더 많이 이용해요.`);
      } else {
        setMainPV(pvData.re_pv);
        setMainPVUser('return');
        setPVReport(`재방문 사용자가\n${diff_pv}개 더 많이 이용해요.`);
      }

      setCompareRatePV(((pvData.new_pv - pvData.re_pv) / pvData.re_pv) * 100);

      rebuildData.push({
        '신규 사용자': pvData.new_pv,
        isHighlight: pvData.new_pv > pvData.re_pv,
      });

      rebuildData.push({
        '재방문 사용자': pvData.re_pv,
        isHighlight: pvData.new_pv <= pvData.re_pv,
      });

      setRebuildPVData(rebuildData);
    }
  }, [pvData]);

  useEffect(() => {
    if (durationData && pvData) {
      if (mainUVUser === 'new') {
        if (durationData.new_usage > durationData.re_usage) {
          setDiffDuration(durationData.more_use);
        } else {
          setDiffDuration(-durationData.more_use);
        }

        if (pvData.new_pv > pvData.re_pv) {
          setDiffPV(pvData.dif_pv);
        } else {
          setDiffPV(-pvData.dif_pv);
        }
      } else {
        if (durationData.re_usage > durationData.new_usage) {
          setDiffDuration(durationData.more_use);
        } else {
          setDiffDuration(-durationData.more_use);
        }

        if (pvData.re_pv > pvData.new_pv) {
          setDiffPV(pvData.dif_pv);
        } else {
          setDiffPV(-pvData.dif_pv);
        }
      }
    }
  }, [mainUVUser, durationData, pvData]);

  return (
    <Component>
      <InnerComponent>
        <Header>
          <Title title="신규 사용자 vs 재방문 사용자" />
        </Header>
        {isLoading ? (
          <Spinner />
        ) : (
          <ReportSummaryWrapper>
            <P1>
              {mainUVUser === 'return' ? '재방문 사용자' : '신규 사용자'}가
            </P1>
            <ReportSummaryRaw>
              <H3>{convertSec(Math.abs(diffDuration))}</H3>
              {printRate(compareRateDuration)}
              <P1>{diffDuration > 0 ? '더 오래 머물고' : '짧게 머물고'}</P1>
            </ReportSummaryRaw>
            <ReportSummaryRaw>
              <H3>{Math.abs(diffPV)}개</H3>
              {printRate(compareRatePV)}
              <P1>
                {diffPV > 0
                  ? '더 많은 페이지를 이용했어요.'
                  : '적은 페이지를 이용했어요.'}
              </P1>
            </ReportSummaryRaw>

            <LinkWrapper>
              <DefaultLink to="/serviceAnalytics/userAnalytics">
                <P3>사용자 수 분석으로 이동</P3>
              </DefaultLink>
              <Underline />
            </LinkWrapper>
            <LinkWrapper>
              <DefaultLink to="/serviceAnalytics/pageViewAnalytics">
                <P3>페이지뷰 수 분석으로 이동</P3>
              </DefaultLink>
              <Underline />
            </LinkWrapper>
          </ReportSummaryWrapper>
        )}
      </InnerComponent>
      <Split />
      <InnerComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title={mainUVUser === 'return' ? '재방문 사용자' : '신규 사용자'}
            value={mainUV}
            desc={uvReport}
            data={rebuildUVData}
            isLabelInChart={false}
          />
        )}
      </InnerComponent>
      <Split />
      <InnerComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title={
              mainDurationUser === 'return'
                ? '재방문 사용자 평균 이용 시간'
                : '신규 사용자 평균 이용 시간'
            }
            value={mainDuration}
            desc={uvDurationReport}
            data={rebuildDurationData}
            isLabelInChart={false}
            isTime
          />
        )}
      </InnerComponent>
      <Split />
      <InnerComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title={
              mainPVUser === 'return'
                ? '재방문 사용자 평균 이용 페이지 수'
                : '신규 사용자 평균 이용 페이지 수'
            }
            value={mainPV}
            desc={pvReport}
            data={rebuildPVData}
            isLabelInChart={false}
          />
        )}
      </InnerComponent>
    </Component>
  );
};

export default NewVSReturnReportCard;
