import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 16px;
`;

const BlurWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  border-radius: 16px;
`;

interface FullPageScreenShotProps {
  img_src: string;
  imgRef: React.RefObject<HTMLImageElement>;
  className?: string;

  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onLoadStart?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const FullPageScreenShot = ({
  img_src,
  imgRef,
  className,

  onLoad,
  onLoadStart,
}: FullPageScreenShotProps) => {
  return (
    <Component className={className}>
      <Image
        src={img_src}
        onLoad={onLoad}
        onLoadStart={onLoadStart}
        ref={imgRef}
      />
      <BlurWrapper />
    </Component>
  );
};

export default FullPageScreenShot;
