import { createReducer } from 'typesafe-actions';
import { myPageAction, myPageState } from './types';

import {
  GET_CONFIRM_PASSWORD,
  GET_CONFIRM_PASSWORD_ERROR,
  GET_CONFIRM_PASSWORD_SUCCESS,
  SET_USER_INFO,
  SET_USER_INFO_ERROR,
  SET_USER_INFO_SUCCESS,
  SET_USER_PASSWORD,
  SET_USER_PASSWORD_ERROR,
  SET_USER_PASSWORD_SUCCESS,
  GET_CONFIRM_PASSWORD_CHANGE,
  GET_CONFIRM_PASSWORD_CHANGE_ERROR,
  GET_CONFIRM_PASSWORD_CHANGE_SUCCESS,
  SET_IS_CONFIRM_PASSWORD,
  SET_ERROR_NULL_MYPAGE,
  SET_IS_SEND_STATUS,
} from './actions';

const initialState: myPageState = {
  isConfirmPassword: false,
  isConfirmPasswordChange: null,
  getConfirmPasswordError: null,
  getConfirmPasswordErrorMessage: {
    '4000102': '비밀번호를 다시 확인하여주세요.',
    '4000100':
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },
  setUserInfoError: null,
  setUserInfoErrorMessage: {
    '4000200':
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },
  setUserPasswordError: null,
  setUserPasswordErrorMessage: {
    '4000100':
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },
  isSetUserPassword: false,
  isSetUserInfo: false,
};

const reducer = createReducer<myPageState, myPageAction>(initialState, {
  [GET_CONFIRM_PASSWORD]: (state) => ({
    ...state,
    getConfirmPasswordError: null,
  }),
  [GET_CONFIRM_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    isConfirmPassword: true,
  }),
  [GET_CONFIRM_PASSWORD_ERROR]: (state, action) => ({
    ...state,
    isConfirmPassword: false,
    getConfirmPasswordError: action.payload,
  }),

  [GET_CONFIRM_PASSWORD_CHANGE]: (state) => ({
    ...state,
  }),
  [GET_CONFIRM_PASSWORD_CHANGE_SUCCESS]: (state) => ({
    ...state,
    isConfirmPasswordChange: true,
  }),
  [GET_CONFIRM_PASSWORD_CHANGE_ERROR]: (state) => ({
    ...state,
    isConfirmPasswordChange: false,
  }),

  [SET_USER_INFO]: (state) => ({
    ...state,
    isSetUserInfo: false,
    setUserInfoError: null,
  }),
  [SET_USER_INFO_SUCCESS]: (state) => ({
    ...state,
    isSetUserInfo: true,
  }),
  [SET_USER_INFO_ERROR]: (state, action) => ({
    ...state,
    isSetUserInfo: false,
    setUserInfoError: action.payload,
  }),

  [SET_USER_PASSWORD]: (state) => ({
    ...state,
    isSetUserPassword: false,
    setUserPasswordError: null,
  }),
  [SET_USER_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    isSetUserPassword: true,
  }),
  [SET_USER_PASSWORD_ERROR]: (state, action) => ({
    ...state,
    isSetUserPassword: false,
    setUserPasswordError: action.payload,
  }),

  [SET_IS_CONFIRM_PASSWORD]: (state, action) => ({
    ...state,
    isConfirmPassword: action.payload,
    isConfirmPasswordChange: null,
  }),

  [SET_ERROR_NULL_MYPAGE]: (state) => ({
    ...state,
    setUserInfoError: null,
    setUserPasswordError: null,
    getConfirmPasswordError: null,
  }),

  [SET_IS_SEND_STATUS]: (state) => ({
    ...state,
    isSetUserPassword: false,
    isSetUserInfo: false,
  }),
});

export default reducer;
