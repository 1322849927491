import axios from 'axios';

import config from '../../config-api.json';
import {
  GetAIVORYSearchUsageAnalysisParamType,
  GetAIVORYSearchUsageAnalysisResponseType,
} from './type';

// axios.defaults.withCredentials = true;
const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetAIVORYSearchUsageAnalysis(
  info: GetAIVORYSearchUsageAnalysisParamType
) {
  const response = await axios.get<GetAIVORYSearchUsageAnalysisResponseType>(
    `${apiInfo.api_url}/aivory/search_usage_analysis/`,
    {
      params: {
        unit: info.unit,
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetAIVORYContentsUsageAnalysis(
  info: GetAIVORYSearchUsageAnalysisParamType
) {
  const response = await axios.get<GetAIVORYSearchUsageAnalysisResponseType>(
    `${apiInfo.api_url}/aivory/contents_usage_analysis/`,
    {
      params: {
        unit: info.unit,
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
