import React from 'react';
import styled from 'styled-components';

const Component = styled.thead`
  width: 100%;
`;

interface TableHeadProps {
  className?: string;
  children?: React.ReactNode;
}

const TableHead = ({ className, children }: TableHeadProps) => {
  return (
    <Component className={className}>
      <tr>{children}</tr>
    </Component>
  );
};

export default TableHead;
