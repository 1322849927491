import { createReducer } from 'typesafe-actions';
import { FINISH_LOADING, START_LOADING } from './actions';
import { loadingAction, loadingState } from './types';

const initialState: loadingState = {
  none: false,
};

const reducer = createReducer<loadingState, loadingAction>(initialState, {
  [START_LOADING]: (state, action) => ({
    ...state,
    [action.payload]: true,
  }),
  [FINISH_LOADING]: (state, action) => ({
    ...state,
    [action.payload]: false,
  }),
});

export default reducer;
