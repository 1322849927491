import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import {
  PopularPageGrowthPayload,
  PopularPageMostPayload,
} from '../../../../api/report/type';
import { IconEyeOn, IconGraph1 } from '../../../Atoms/Icon';
import Header from '../../../Molecule/Card/CardHeader';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import Spinner from '../../../Molecule/Spinner';

import PopularPageCard from './PopularPageCard';

const Component = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 40px 24px;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
`;

const Body = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 24px;
  white-space: pre-wrap;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
  margin-top: 24px;
`;

const Icon1 = styled(IconEyeOn)`
  path {
    fill: ${(props) => props.theme.colors.grey900};
  }
`;

const Icon2 = styled(IconGraph1)`
  path {
    &:last-child {
      fill: ${(props) => props.theme.colors.grey900};
    }
  }
`;

interface PopularPageReportCardProps {
  mostPopularData: PopularPageMostPayload | null;
  growthPopularData: PopularPageGrowthPayload | null;
  isLoading: boolean;
}

interface PopularPageReportCardState {
  rebuildData: {
    cnt: number;
    page_id: number;
    page_title: string;
    page_url: string;
  };
}

const PopularPageReportCard = ({
  mostPopularData,
  growthPopularData,
  isLoading,
}: PopularPageReportCardProps) => {
  const [rebuildMostData, setRebuildMostData] = useState<
    PopularPageReportCardState['rebuildData']
  >({
    cnt: 0,
    page_id: 0,
    page_title: '',
    page_url: '',
  });

  const [rebuildGrowthData, setRebuildGrowthData] = useState<
    PopularPageReportCardState['rebuildData']
  >({ cnt: 0, page_id: 0, page_title: '', page_url: '' });

  useEffect(() => {
    if (mostPopularData) {
      if (mostPopularData.page_id === 0) {
        setRebuildMostData({
          cnt: mostPopularData.montly_count,
          page_id: mostPopularData.page_id,
          page_title:
            '페이지를 수집 중입니다.\n문제가 지속될 경우 관리자에게 문의해주세요.',
          page_url: mostPopularData.simple_url,
        });
      } else {
        setRebuildMostData({
          cnt: mostPopularData.montly_count,
          page_id: mostPopularData.page_id,
          page_title: mostPopularData.page_name,
          page_url: mostPopularData.simple_url,
        });
      }
    }
  }, [mostPopularData]);
  useEffect(() => {
    if (growthPopularData) {
      if (growthPopularData.page_id === 0) {
        setRebuildGrowthData({
          cnt: growthPopularData.growth_count,
          page_id: growthPopularData.page_id,
          page_title:
            '페이지를 수집 중입니다.\n문제가 지속될 경우 관리자에게 문의해주세요.',
          page_url: growthPopularData.simple_url,
        });
      } else {
        setRebuildGrowthData({
          cnt: growthPopularData.growth_count,
          page_id: growthPopularData.page_id,
          page_title: growthPopularData.page_name,
          page_url: growthPopularData.simple_url,
        });
      }
    }
  }, [growthPopularData]);

  function NoMostdata() {
    if (
      rebuildMostData.page_id === 0 ||
      rebuildMostData.page_id === undefined
    ) {
      return (
        <PopularPageCard
          cardTitle="가장 많이 본 페이지"
          cardValue={rebuildMostData.cnt}
          imgUrl={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
            'voda_tenant'
          )}/auto/${rebuildMostData.page_id}/${encodeURIComponent(
            'page_screenshot'
          )}`}
          pageTitle={rebuildMostData.page_title}
          pageUrl={rebuildMostData.page_url}
          Icon={Icon1}
          noData
        />
      );
    }
    return (
      <PopularPageCard
        cardTitle="가장 많이 본 페이지"
        cardValue={rebuildMostData.cnt}
        imgUrl={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${rebuildMostData.page_id}/${encodeURIComponent(
          'page_screenshot'
        )}`}
        pageTitle={rebuildMostData.page_title}
        pageUrl={rebuildMostData.page_url}
        Icon={Icon1}
      />
    );
  }

  function NoGrowthData() {
    if (
      rebuildGrowthData.page_id === 0 ||
      rebuildGrowthData.page_id === undefined
    ) {
      return (
        <PopularPageCard
          cardTitle="인기 상승 페이지"
          cardValue={rebuildGrowthData.cnt}
          imgUrl={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
            'voda_tenant'
          )}/auto/${rebuildGrowthData.page_id}/${encodeURIComponent(
            'page_screenshot'
          )}`}
          pageTitle={rebuildGrowthData.page_title}
          pageUrl={rebuildGrowthData.page_url}
          Icon={Icon2}
          noData
        />
      );
    }
    return (
      <PopularPageCard
        cardTitle="인기 상승 페이지"
        cardValue={rebuildGrowthData.cnt}
        imgUrl={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${rebuildGrowthData.page_id}/${encodeURIComponent(
          'page_screenshot'
        )}`}
        pageTitle={rebuildGrowthData.page_title}
        pageUrl={rebuildGrowthData.page_url}
        Icon={Icon2}
      />
    );
  }

  return (
    <Component>
      <Header>
        <Title title="어떤 페이지가 인기 있나요?" />
      </Header>
      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          NoMostdata()
          // <PopularPageCard
          //   cardTitle="가장 많이 본 페이지"
          //   cardValue={rebuildMostData.cnt}
          //   imgUrl={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
          //     'voda_tenant'
          //   )}/auto/${rebuildMostData.page_id}/${encodeURIComponent(
          //     'page_screenshot'
          //   )}`}
          //   pageTitle={rebuildMostData.page_title}
          //   pageUrl={rebuildMostData.page_url}
          //   Icon={Icon1}
          // />
        )}
      </Body>
      <Split />
      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          NoGrowthData()
          // <PopularPageCard
          //   cardTitle="인기 상승 페이지"
          //   cardValue={rebuildGrowthData.cnt}
          //   imgUrl={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
          //     'voda_tenant'
          //   )}/auto/${rebuildGrowthData.page_id}/${encodeURIComponent(
          //     'page_screenshot'
          //   )}`}
          //   pageTitle={rebuildGrowthData.page_title}
          //   pageUrl={rebuildGrowthData.page_url}
          //   Icon={Icon2}
          // />
        )}
      </Body>
    </Component>
  );
};

export default PopularPageReportCard;
