import { createReducer } from 'typesafe-actions';
import {
  GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD,
  GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_ERROR,
  GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_SUCCESS,
  GET_AIVORY_SEARCH_KETWORD_NETWORK,
  GET_AIVORY_SEARCH_KETWORD_NETWORK_ERROR,
  GET_AIVORY_SEARCH_KETWORD_NETWORK_SUCCESS,
  GET_AIVORY_SEARCH_USAGE_WORDCLOUD,
  GET_AIVORY_SEARCH_USAGE_WORDCLOUD_ERROR,
  GET_AIVORY_SEARCH_USAGE_WORDCLOUD_SUCCESS,
  SET_ERROR_NULL_AIVORY_POPULAR_SEARCH,
} from './actions';
import {
  popularSearchAnalysisAction,
  popularSearchAnalysisState,
} from './types';

const initialState: popularSearchAnalysisState = {
  isLoadingSearchKeywordNetwork: false,
  isLoadingSearchUsageWordcloud: false,
  isLoadingContentUsageBySearchWord: false,

  searchKeywordNetworkData: null,
  searchUsageWordcloudData: null,
  contentUsageBySearchWordData: null,

  aivoryPopularSearchAnalysisError: null,
};

const reducer = createReducer<
  popularSearchAnalysisState,
  popularSearchAnalysisAction
>(initialState, {
  [GET_AIVORY_SEARCH_USAGE_WORDCLOUD]: (state) => ({
    ...state,
    isLoadingSearchUsageWordcloud: true,
    searchUsageWordcloudData: null,
    aivoryPopularSearchAnalysisError: null,
  }),
  [GET_AIVORY_SEARCH_USAGE_WORDCLOUD_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingSearchUsageWordcloud: false,
    searchUsageWordcloudData: action.payload.payload,
  }),
  [GET_AIVORY_SEARCH_USAGE_WORDCLOUD_ERROR]: (state, action) => ({
    ...state,
    isLoadingSearchUsageWordcloud: false,
    searchUsageWordcloudData: null,
    aivoryPopularSearchAnalysisError: action.payload,
  }),

  [GET_AIVORY_SEARCH_KETWORD_NETWORK]: (state) => ({
    ...state,
    isLoadingSearchKeywordNetwork: true,
    searchKeywordNetworkData: null,
    aivoryPopularSearchAnalysisError: null,
  }),
  [GET_AIVORY_SEARCH_KETWORD_NETWORK_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingSearchKeywordNetwork: false,
    searchKeywordNetworkData: action.payload.payload,
  }),
  [GET_AIVORY_SEARCH_KETWORD_NETWORK_ERROR]: (state, action) => ({
    ...state,
    isLoadingSearchKeywordNetwork: false,
    searchKeywordNetworkData: null,
    aivoryPopularSearchAnalysisError: action.payload,
  }),

  [GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD]: (state) => ({
    ...state,
    isLoadingContentUsageBySearchWord: true,
    contentUsageBySearchWordData: null,
    aivoryPopularSearchAnalysisError: null,
  }),
  [GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingContentUsageBySearchWord: false,
    contentUsageBySearchWordData: action.payload.payload,
  }),
  [GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_ERROR]: (state, action) => ({
    ...state,
    isLoadingContentUsageBySearchWord: false,
    contentUsageBySearchWordData: null,
    aivoryPopularSearchAnalysisError: action.payload,
  }),

  [SET_ERROR_NULL_AIVORY_POPULAR_SEARCH]: (state) => ({
    ...state,
    aivoryPopularSearchAnalysisError: null,
  }),
});

export default reducer;
