import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

interface ContentsLayoutProps {
  ContentsLayoutRef?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  className?: string;
}

const ContentsLayout = ({
  children,
  className,
  ContentsLayoutRef,
}: ContentsLayoutProps) => {
  return (
    <Component ref={ContentsLayoutRef} className={className}>
      {children}
    </Component>
  );
};

export default ContentsLayout;
