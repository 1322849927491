import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetAIVORYDashboardStaticsParamType,
  GetAIVORYDashboardStaticsResponseType,
} from '../../../api/aivoryUsageAnalytics/dashboard/type';

export const GET_AIVORY_DASHBOARD_STATICS = 'aivoryUsageAnalytics/dashboard/GET_AIVORY_DASHBOARD_STATICS' as const;
export const GET_AIVORY_DASHBOARD_STATICS_SUCCESS = 'aivoryUsageAnalytics/dashboard/GET_AIVORY_DASHBOARD_STATICS_SUCCESS' as const;
export const GET_AIVORY_DASHBOARD_STATICS_ERROR = 'aivoryUsageAnalytics/dashboard/GET_AIVORY_DASHBOARD_STATICS_ERROR' as const;

export const SET_ERROR_NULL_AIVORY_DASHBOARD =
  'aivoryUsageAnalytics/dashboard/SET_ERROR_NULL_AIVORY_DASHBOARD';

export const getAIVORYDashboardStaticsAsync = createAsyncAction(
  GET_AIVORY_DASHBOARD_STATICS,
  GET_AIVORY_DASHBOARD_STATICS_SUCCESS,
  GET_AIVORY_DASHBOARD_STATICS_ERROR
)<
  GetAIVORYDashboardStaticsParamType,
  GetAIVORYDashboardStaticsResponseType,
  AxiosErrorType
>();

export const setErrorNullAivoryDashboard = createAction(
  SET_ERROR_NULL_AIVORY_DASHBOARD
)();
