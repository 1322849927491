import React from 'react';
import styled from 'styled-components';
import { H3 } from '../../../Atoms/Typo';

const Component = styled(H3)`
  color: ${(props) => props.theme.colors.grey500};
`;

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => {
  return <Component>{title}</Component>;
};

export default Title;
