import { createReducer } from 'typesafe-actions';
import {
  GET_PAGE_FLOW_CONVERSION,
  GET_PAGE_FLOW_CONVERSION_SUCCESS,
  GET_PAGE_FLOW_CONVERSION_ERROR,
  GET_PAGE_FLOW_INFLOW,
  GET_PAGE_FLOW_INFLOW_ERROR,
  GET_PAGE_FLOW_INFLOW_SUCCESS,
  SET_ERROR_NULL_USER_FLOW,
} from './actions';
import { userFlowAnalysisAction, userFlowAnalysisState } from './types';

const initialState: userFlowAnalysisState = {
  conversionData: [],
  inflowData: [],
  userFlowAnalysisError: null,
};

const reducer = createReducer<userFlowAnalysisState, userFlowAnalysisAction>(
  initialState,
  {
    [GET_PAGE_FLOW_INFLOW]: (state) => ({
      ...state,
      userFlowAnalysisError: null,
    }),
    [GET_PAGE_FLOW_INFLOW_SUCCESS]: (state, action) => ({
      ...state,
      inflowData: action.payload.payload,
    }),
    [GET_PAGE_FLOW_INFLOW_ERROR]: (state, action) => ({
      ...state,
      userFlowAnalysisError: action.payload,
    }),

    [GET_PAGE_FLOW_CONVERSION]: (state) => ({
      ...state,
      userFlowAnalysisError: null,
    }),
    [GET_PAGE_FLOW_CONVERSION_SUCCESS]: (state, action) => ({
      ...state,
      conversionData: action.payload.payload,
    }),
    [GET_PAGE_FLOW_CONVERSION_ERROR]: (state, action) => ({
      ...state,
      userFlowAnalysisError: action.payload,
    }),

    [SET_ERROR_NULL_USER_FLOW]: (state) => ({
      ...state,
      userFlowAnalysisError: null,
    }),
  }
);

export default reducer;
