import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface CardBodyLayoutProps {
  className?: string;
  children?: React.ReactNode;
}

const CardBodyLayout = ({ className, children }: CardBodyLayoutProps) => {
  return <Component className={className}>{children}</Component>;
};

export default CardBodyLayout;
