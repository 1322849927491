import { createReducer } from 'typesafe-actions';

import { sidebarNavAction, sidebarNavState } from './types';
import { SET_IS_GNB_FOLD, SET_SUB_MENU_LINK_COMPONENT } from './actions';

const initialState: sidebarNavState = {
  subMenuLinkComponent: null,
  gnbIsFold: null,
};

const reducer = createReducer<sidebarNavState, sidebarNavAction>(initialState, {
  [SET_SUB_MENU_LINK_COMPONENT]: (state, action) => ({
    ...state,
    subMenuLinkComponent: action.payload,
  }),
  [SET_IS_GNB_FOLD]: (state, action) => ({
    ...state,
    gnbIsFold: action.payload,
  }),
});

export default reducer;
