import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { H3, H5, P2 } from '../../../components/Atoms/Typo';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';

export const Component = styled(ContentsLayout)`
  position: relative;
`;

export const TabWrapper = styled.div`
  display: flex;
`;

export const TabButtonTitle = styled(H3)`
  width: 100%;
  color: ${(props) => props.theme.colors.grey500};
  margin-bottom: 4px;
`;

export const TabButtonBorderBottom = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.grey300};
`;

export const TabButton = styled.button<{ $isSelect: boolean }>`
  display: flex;
  flex-direction: column;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 24px;
  &:last-child {
    margin-right: 0px;
  }

  ${(props) => {
    if (props.$isSelect) {
      return css`
        ${TabButtonTitle} {
          color: ${props.theme.colors.grey800};
        }
        ${TabButtonBorderBottom} {
          background-color: ${props.theme.colors.primary};
        }
      `;
    }
    return css``;
  }}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 516px;
  margin-top: 40px;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  &:nth-child(3) {
    margin-bottom: 80px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const FormItemTitle = styled(H5)`
  margin-bottom: 8px;
`;

export const FormButtonWrapper = styled.div`
  display: flex;
`;

export const FormButtonCancel = styled(NavLink)<{ $isCancel: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 78px;
  height: 56px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.grey900};
  border: none;
  cursor: pointer;
  margin-right: 24px;
  ${P2} {
    color: white;
  }
  ${(props) => {
    if (props.$isCancel) {
      return css`
        background-color: ${props.theme.colors.grey500};
      `;
    }
    return css``;
  }}
  :hover {
    ${P2} {
      color: white;
    }
  }
`;

export const FormButton = styled.button<{ $isCancel: boolean }>`
  width: 78px;
  height: 56px;
  border-radius: 8px;
  background-color: #333333;
  border: none;
  cursor: pointer;
  margin-right: 24px;
  ${P2} {
    color: white;
  }
  ${(props) => {
    if (props.$isCancel) {
      return css`
        background-color: ${props.theme.colors.grey500};
      `;
    }
    return css``;
  }}
`;

export const FormInputWrapper = styled.div`
  width: 100%;
  height: 56px;
`;

export const ModalBackBlur = styled.div<{ $isModalOpen: boolean }>`
  position: fixed;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  left: 240px;
  top: 0px;

  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 100;
`;

export const ModalWrapper = styled.div<{ $isModalOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 322px;
  height: 157px;
  background-color: white;
  border-radius: 8px;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 200;
  overflow: hidden;
`;

export const ModalBody = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${(props) => props.theme.colors.grey800};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`;

export const ModalSplit = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

export const Modalbutton = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  border: none;
  background-color: transparent;
  :hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;
