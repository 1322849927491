import React from 'react';
import styled from 'styled-components';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';

import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';

const Component = styled(CardLayout)`
  padding: 24px;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
  display: flex;
`;

const Header = styled(CardHeaderLayout)`
  padding-bottom: 14px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey300};
`;

const P2 = styled.p`
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
`;

interface SupportCardProps {
  header: string;
  bodyText?: string;
}

const SupportCard = ({ header, bodyText }: SupportCardProps) => {
  return (
    <Component>
      <Header>
        <DefaultCardHeading title={header} />
      </Header>
      <CardBodyLayout>
        <P2>{bodyText}</P2>
      </CardBodyLayout>
    </Component>
  );
};

export default SupportCard;
