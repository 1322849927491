import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { tooltip } from '../../../Atoms/ChartTooltip/StackedColumnsChart';
import { WeeklyUserCountPayload } from '../../../../api/serviceAnalytics/userAnalysis/type';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  ${tooltip};
`;

interface StackedColumnsChartProps {
  Data: WeeklyUserCountPayload | null;
  bar1Label: string;
  bar2Label: string;
}

const StackedColumnsChart = ({
  Data,
  bar1Label,
  bar2Label,
}: StackedColumnsChartProps) => {
  const [series, setSeries] = useState<
    {
      name: string;
      ReVisit: number;
      NewVisit: number;
    }[]
  >([]);
  const stackedChartEl = useRef<any>(null);
  const reChartWrapper = useRef<any>(null);

  const roundPath = (x: number, y: number, width: number, height: number) => {
    return `M${x + width / 2},${y} a8,8 0 0,1 8 8 v${height - 8}h${-width}v${
      -height + 8
    } a8,8 0 0,1 8 -8Z`;
  };

  const roundPathNewPath = (
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    return `M${x + width / 2},${
      y - 8
    } a8,8 0 0,1 8 8 v${height}h${-width}v${-height} a8,8 0 0,1 8 -8Z`;
  };

  const normalPath = (x: number, y: number, width: number, height: number) => {
    return `M${x} ${y} h${width} v${height} h${-width} Z`;
  };

  const NewVisitCustom = (props: any) => {
    const { fill, x, y, width, height, NewVisit } = props;

    if (NewVisit === 0) {
      return '';
    }

    return (
      <path
        d={roundPathNewPath(x, y, width, height)}
        stroke="none"
        fill={fill}
      />
    );
  };

  const ReVisitCustom = (props: any) => {
    const { fill, x, y, width, height, NewVisit } = props;

    if (NewVisit === 0) {
      return (
        <path
          d={roundPath(x, y - 6, width, height + 6)}
          stroke="none"
          fill={fill}
        />
      );
    }

    return (
      <path d={normalPath(x, y, width, height)} stroke="none" fill={fill} />
    );
  };

  const renderCustomizedLabel = ({ x, y, width, index }: any) => {
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 18}
          fill="#757575"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="14px"
          fontWeight={400}
          fontFamily='Roboto, "Noto Sans KR", sans-serif'
        >
          {series[index].NewVisit + series[index].ReVisit}
        </text>
      </g>
    );
  };

  useEffect(() => {
    if (Data) {
      const categoryArr = Object.keys(Data.weekday);
      const DataTemp: {
        name: string;
        ReVisit: number;
        NewVisit: number;
      }[] = [];
      const weekcategory = ['월', '화', '수', '목', '금', '토', '일'];
      for (let index = 0; index < 7; index += 1) {
        DataTemp.push({
          name: weekcategory[0],
          ReVisit: 0,
          NewVisit: 0,
        });
      }
      categoryArr.forEach((element) => {
        switch (element) {
          case '월':
            DataTemp[0] = {
              name: element,
              ReVisit: Data.weekday[element].old,
              NewVisit: Data.weekday[element].new,
            };

            break;
          case '화':
            DataTemp[1] = {
              name: element,
              ReVisit: Data.weekday[element].old,
              NewVisit: Data.weekday[element].new,
            };
            break;
          case '수':
            DataTemp[2] = {
              name: element,
              ReVisit: Data.weekday[element].old,
              NewVisit: Data.weekday[element].new,
            };
            break;
          case '목':
            DataTemp[3] = {
              name: element,
              ReVisit: Data.weekday[element].old,
              NewVisit: Data.weekday[element].new,
            };
            break;
          case '금':
            DataTemp[4] = {
              name: element,
              ReVisit: Data.weekday[element].old,
              NewVisit: Data.weekday[element].new,
            };
            break;
          case '토':
            DataTemp[5] = {
              name: element,
              ReVisit: Data.weekday[element].old,
              NewVisit: Data.weekday[element].new,
            };
            break;
          case '일':
            DataTemp[6] = {
              name: element,
              ReVisit: Data.weekday[element].old,
              NewVisit: Data.weekday[element].new,
            };
            break;
          default:
            break;
        }
      });
      setSeries(DataTemp);
    }
  }, [Data]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    const { current } = stackedChartEl;
    const reChartWrapperCurrent = reChartWrapper.current;
    const xaxisCategory = ['월', '화', '수', '목', '금', '토', '일'];
    let index = 0;

    if (active) {
      let returnRate = 0;
      let newRate = 0;
      const total = payload[0].payload.ReVisit + payload[1].payload.NewVisit;
      if (total !== 0) {
        returnRate = Math.floor((payload[0].payload.ReVisit / total) * 100);
        newRate = Math.floor((payload[1].payload.NewVisit / total) * 100);
      }

      xaxisCategory.some((element, categoryIndex) => {
        if (label === element) {
          index = categoryIndex;
          return true;
        }
        return false;
      });
      if (current && reChartWrapperCurrent) {
        const list = current.container.getElementsByClassName(
          'recharts-label-list'
        )[0] as HTMLElement;

        const recharts_tooltip_wrapper = current.container.getElementsByClassName(
          'recharts-tooltip-wrapper'
        )[0] as HTMLElement;

        const rechartsWrapper = reChartWrapperCurrent.container.getElementsByClassName(
          'recharts-wrapper'
        )[0] as HTMLElement;

        const rechartsWrapperLeft = rechartsWrapper.getBoundingClientRect()
          .left;
        const rechartsWrapperTop = rechartsWrapper.getBoundingClientRect().top;

        if (list && list.hasChildNodes()) {
          const labelEl = list.childNodes[index] as HTMLElement;
          const labelTop = labelEl.getBoundingClientRect().top;
          const labelLeft = labelEl.getBoundingClientRect().left;
          recharts_tooltip_wrapper.style.zIndex = '999';
          recharts_tooltip_wrapper.style.top = `${
            labelTop - rechartsWrapperTop
          }px`;
          recharts_tooltip_wrapper.style.left = `${
            labelLeft - rechartsWrapperLeft + 32
          }px`;
        }
      }

      return (
        <div className="custom-tooltip">
          <div className="innerBox">
            <div className="dot-return" />
            <div className="title">{bar1Label}</div>
            <div className="value">{payload[0].payload.ReVisit}</div>
            <div className="rate-return">({returnRate}%)</div>
          </div>
          <div className="innerBox">
            <div className="dot-new" />
            <div className="title">{bar2Label}</div>
            <div className="value">{payload[1].payload.NewVisit}</div>
            <div className="rate-new">({newRate}%)</div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Component>
      {Data ? (
        <ResponsiveContainer ref={reChartWrapper}>
          <BarChart
            data={series}
            margin={{ top: 30, right: 0, left: 0, bottom: 0 }}
            ref={stackedChartEl}
          >
            <XAxis
              tickLine={false}
              axisLine={{ stroke: '#e0e0e0', strokeWidth: 2 }}
              dataKey="name"
              tick={{
                fill: '#757575',
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
              }}
            />

            <Tooltip cursor={false} content={<CustomTooltip />} />

            <Bar
              shape={ReVisitCustom}
              dataKey="ReVisit"
              stackId="a"
              barSize={16}
              fill="#d0d47c"
              name={bar1Label}
            />
            <Bar
              shape={NewVisitCustom}
              dataKey="NewVisit"
              stackId="a"
              barSize={16}
              fill="#fdb67d"
              name={bar2Label}
            >
              <LabelList
                dataKey="NewVisit"
                position="top"
                content={renderCustomizedLabel}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <DataError />
      )}
    </Component>
  );
};

export default StackedColumnsChart;
