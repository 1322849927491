import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../modules';
import { setIsConfirmPassword } from '../../modules/myPage';

import ConfirmPassword from './ConfirmPassword';
import EditUserInfo from './EditUserInfo';

const MyPage = () => {
  const { isConfirmPassword } = useSelector((state: RootState) => state.myPage);
  const dispatch = useDispatch();

  const onSetIsConfirmPassword = (info: boolean) => {
    dispatch(setIsConfirmPassword(info));
  };

  useEffect(() => {
    return () => {
      onSetIsConfirmPassword(false);
    };
  }, []);
  return <>{isConfirmPassword ? <EditUserInfo /> : <ConfirmPassword />}</>;
};

export default MyPage;
