import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetPageListParamType,
  GetPageListResponseType,
} from '../../../api/pageAnalytics/pageList/type';

export const GET_PAGE_LIST = 'pageAnalytics/pageList/GET_PAGE_LIST' as const;
export const GET_PAGE_LIST_SUCCESS = 'pageAnalytics/pageList/GET_PAGE_LIST_SUCCESS' as const;
export const GET_PAGE_LIST_ERROR = 'pageAnalytics/pageList/GET_PAGE_LIST_ERROR' as const;

export const SET_ERROR_NULL_PAGE_LIST = 'pageAnalytics/pageList/SET_ERROR_NULL_PAGE_LIST' as const;

export const getPageListAsync = createAsyncAction(
  GET_PAGE_LIST,
  GET_PAGE_LIST_SUCCESS,
  GET_PAGE_LIST_ERROR
)<GetPageListParamType, GetPageListResponseType, AxiosErrorType>();

export const setErrorNullPageList = createAction(SET_ERROR_NULL_PAGE_LIST)();
