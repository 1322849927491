import axios from 'axios';
import {
  GetConfirmPasswordParamType,
  GetConfirmPasswordResponseType,
  SetUserInfoParamType,
  SetUserInfoResponseType,
  SetUserPasswordParamType,
  SetUserPasswordResponseType,
} from './type';

import config from '../config-api.json';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetConfirmPassword(info: GetConfirmPasswordParamType) {
  const response = await axios.post<GetConfirmPasswordResponseType>(
    `${apiInfo.api_url}/password/`,
    {
      password: info.password,
    },
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiSetUserInfo(info: SetUserInfoParamType) {
  const response = await axios.put<SetUserInfoResponseType>(
    `${apiInfo.api_url}/userinfo/`,
    {
      name: info.name,
      email: info.email,
    },
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiSetUserPassword(info: SetUserPasswordParamType) {
  const response = await axios.put<SetUserPasswordResponseType>(
    `${apiInfo.api_url}/new_password/`,
    {
      password: info.password,
      password_confirm: info.password_confirm,
    },
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
