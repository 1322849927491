import React from 'react';
import styled from 'styled-components';
import { H2, P1 } from '../../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainTitle = styled(P1)`
  color: ${(props) => props.theme.colors.grey800};
`;

const SubTitle = styled(H2)`
  color: ${(props) => props.theme.colors.grey900};
  margin-top: 4px;
`;

interface TitleProps {
  mainTitle: string;
  subTitle: string;
}

const Title = ({ mainTitle, subTitle }: TitleProps) => {
  return (
    <Component>
      <MainTitle>{mainTitle}</MainTitle>
      <SubTitle>{subTitle}</SubTitle>
    </Component>
  );
};

export default Title;
