import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { SearchWordPayload } from '../../../../api/report/type';
import { IconSearch } from '../../../Atoms/Icon';
import Header from '../../../Molecule/Card/CardHeader/ReportHeader';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import Spinner from '../../../Molecule/Spinner';

import SearchWordCard from './SearchWordCard';

const Component = styled.div`
  width: 100%;
  height: 100%;
  min-height: 956px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 40px 24px;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
  .voda_report_title {
    margin-bottom: 24px;
  }
`;

const Body = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Icon1 = styled(IconSearch)`
  path {
    fill: ${(props) => props.theme.colors.grey900};
  }
`;

interface SearchWordReportCardProps {
  mostSearchData: SearchWordPayload | null;
  growthSearchData: SearchWordPayload | null;
  isLoading: boolean;
  title: string;
}

interface SearchWordReportCardState {
  rebuildData: {
    rank: number;
    title: string;
    value: number;
  }[];
}

const SearchWordReportCard = ({
  title,
  mostSearchData,
  growthSearchData,
  isLoading,
}: SearchWordReportCardProps) => {
  const [totalSearchCnt, setTotalSearchCnt] = useState(0);
  const [rebuildMostData, setRebuildMostData] = useState<
    SearchWordReportCardState['rebuildData']
  >([]);
  const [rebuildGrowthData, setRebuildGrowthData] = useState<
    SearchWordReportCardState['rebuildData']
  >([]);

  useEffect(() => {
    if (mostSearchData) {
      const rebuild_data: SearchWordReportCardState['rebuildData'] = [];

      setTotalSearchCnt(mostSearchData?.monthly_search_count || 0);
      mostSearchData.result.forEach((element, index) => {
        rebuild_data.push({
          rank: index + 1,
          title: element.keyword,
          value: element.count,
        });
      });
      setRebuildMostData(rebuild_data);
    }
  }, [mostSearchData]);
  useEffect(() => {
    if (growthSearchData) {
      const rebuild_data: SearchWordReportCardState['rebuildData'] = [];

      growthSearchData.result.forEach((element, index) => {
        rebuild_data.push({
          rank: index + 1,
          title: element.keyword,
          value: element.count,
        });
      });
      setRebuildGrowthData(rebuild_data);
    }
  }, [growthSearchData]);
  return (
    <Component>
      <Title title={title} />
      <Header Icon={Icon1} title="총 검색 횟수" value={totalSearchCnt} />

      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <SearchWordCard
              data={rebuildMostData}
              isGrowth
              title="인기 상승 검색어"
            />
            <Split />
            <SearchWordCard
              data={rebuildGrowthData}
              isGrowth={false}
              title="가장 많이 검색한"
            />
          </>
        )}
      </Body>
    </Component>
  );
};

export default SearchWordReportCard;
