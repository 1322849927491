import styled, { css } from 'styled-components';

import { ReactComponent as IconArrowFunnel } from '../../../assets/icons/icon-arrow-funnel.svg';
import { ReactComponent as IconCloseSVG } from '../../../assets/icons/icon-close.svg';

// icon
export const IconArrow = styled(IconArrowFunnel)<{
  $isVisible: boolean;
}>`
  ${(props) => {
    if (props.$isVisible) {
      return css`
        visibility: visible;
      `;
    }
    return css`
      visibility: hidden;
    `;
  }}
`;
export const IconClose = styled(IconCloseSVG)``;

export const FunnelPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
`;

export const FunnelModalBackground = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  left: 0px;
  height: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 30;
  ${(props) => {
    if (props.$isOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

export const FunnelPanelTitleBox = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
`;

export const FunnelPanelTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${(props) => props.theme.colors.grey800};
`;

export const FunnelPanelDot = styled.div<{ $cardType: string }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 4px;
  ${(props) => {
    switch (props.$cardType) {
      case 'inflow':
        return css`
          background-color: ${props.theme.colors.green5};
        `;
      case 'conversion':
        return css`
          background-color: ${props.theme.colors.orange5};
        `;
      default:
        return css``;
    }
  }}
`;

export const FunnelCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 486px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const InflowArrow = styled.div`
  position: absolute;
  top: 50%;
  width: 16px;
  height: 24px;
  left: -4px;
  z-index: 40;
  transform: translate(-100%, -50%);
  ${IconArrow} {
    width: 16px;
    height: 24px;
  }
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  width: 100%;
  /* height: 486px; */
  /* justify-content: center; */
  /* Desktop */
  @media ${(props) => props.theme.device.$desktop} {
    /* width: 344px; */
    height: 299px;
  }

  /* BigScreen */
  @media ${(props) => props.theme.device.$bigscreen} {
    /* width: 517px; */
    height: 396px;
  }
`;

export const ThumbnailImageBox = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: gray;
  margin-bottom: 16px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${(props) => props.theme.device.$desktop} {
    height: 194px;
  }

  /* BigScreen */
  @media ${(props) => props.theme.device.$bigscreen} {
    height: 291px;
  }
`;
export const ThumbnailTitle = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey800};
  font-size: 14px;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin-bottom: 8px;
  padding: 0px 24px;
`;
export const ThumbnailURL = styled.div`
  width: 100%;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey500};
  font-size: 14px;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0px 24px;
`;

export const InflowStraightLineWrapper = styled.div<{
  $width: number;
}>`
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translate(-100%, -50%);
  height: 2px;
  background-color: ${(props) => props.theme.colors.green5};

  ${(props) => {
    if (props.$width) {
      return css`
        width: ${props.$width}px;
      `;
    }
    return css``;
  }};
`;

export const ConversionStraightLineWrapper = styled.div<{
  $width: number;
  $isExit?: boolean;
}>`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(100%, -50%);
  height: 2px;
  background-color: ${(props) => props.theme.colors.orange5};

  ${(props) => {
    if (props.$isExit) {
      return css`
        background-color: ${props.theme.colors.danger5};
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$width) {
      return css`
        width: ${props.$width}px;
      `;
    }
    return css``;
  }};
`;

export const InflowLineWrapper = styled.svg<{
  $width: number;
  $height: number;
  $isUpper: boolean;
}>`
  position: absolute;
  left: 0px;
  transform: translate(-100%);

  ${(props) => {
    if (props.$height < 0) {
      return css`
        width: ${props.$width}px;
        height: ${-props.$height}px;
      `;
    }
    return css`
      width: ${props.$width}px;
      height: ${props.$height}px;
    `;
  }}
  ${(props) => {
    if (props.$isUpper) {
      return css`
        bottom: 50%;
      `;
    }
    return css`
      top: 50%;
    `;
  }}
`;

export const ConversionLineWrapper = styled.svg<{
  $width: number;
  $height: number;
  $isUpper: boolean;
}>`
  position: absolute;
  right: 0px;
  transform: translate(100%);

  ${(props) => {
    if (props.$height < 0) {
      return css`
        width: ${props.$width}px;
        height: ${-props.$height}px;
      `;
    }
    return css`
      width: ${props.$width}px;
      height: ${props.$height}px;
    `;
  }}
  ${(props) => {
    if (props.$isUpper) {
      return css`
        bottom: 50%;
      `;
    }
    return css`
      top: 50%;
    `;
  }}
`;

export const ETCModalContainer = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  flex-direction: column;
  width: 948px;
  height: 787px;
  padding: 24px 24px 40px 24px;
  background-color: white;
  border-radius: 16px;
  ${(props) => {
    if (props.$isOpen) {
      return css`
        display: flex;
      `;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 50;
`;

export const ETCModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ETCModalHeaderTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey800};
  line-height: normal;
`;

export const ETCModalHeaderExit = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`;
export const DataError = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: ${(props) => props.theme.colors.grey800};
`;
