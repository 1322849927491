import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetTotalUserCountParamType,
  GetTotalUserCountResponseType,
  GetWeeklyUserCountParamType,
  GetWeeklyUserCountResponseType,
} from '../../../api/serviceAnalytics/userAnalysis/type';

export const GET_TOTAL_USER_COUNT = 'serviceAnalytics/userAnalysis/GET_TOTAL_USER_COUNT' as const;
export const GET_TOTAL_USER_COUNT_SUCCESS = 'serviceAnalytics/userAnalysis/GET_TOTAL_USER_COUNT_SUCCESS' as const;
export const GET_TOTAL_USER_COUNT_ERROR = 'serviceAnalytics/userAnalysis/GET_TOTAL_USER_COUNT_ERROR' as const;

export const GET_WEEKLY_USER_COUNT = 'serviceAnalytics/userAnalysis/GET_WEEKLY_USER_COUNT' as const;
export const GET_WEEKLY_USER_COUNT_SUCCESS = 'serviceAnalytics/userAnalysis/GET_WEEKLY_USER_COUNT_SUCCESS' as const;
export const GET_WEEKLY_STATISTICS_ERROR = 'serviceAnalytics/userAnalysis/GET_WEEKLY_STATISTICS_ERROR' as const;

export const SET_ERROR_NULL_SERVICE_UV =
  'serviceAnalytics/userAnalysis/SET_ERROR_NULL_SERVICE_UV';

export const getTotalUserCountAsync = createAsyncAction(
  GET_TOTAL_USER_COUNT,
  GET_TOTAL_USER_COUNT_SUCCESS,
  GET_TOTAL_USER_COUNT_ERROR
)<GetTotalUserCountParamType, GetTotalUserCountResponseType, AxiosErrorType>();

export const getWeeklyUserCountAsync = createAsyncAction(
  GET_WEEKLY_USER_COUNT,
  GET_WEEKLY_USER_COUNT_SUCCESS,
  GET_WEEKLY_STATISTICS_ERROR
)<
  GetWeeklyUserCountParamType,
  GetWeeklyUserCountResponseType,
  AxiosErrorType
>();

export const setErrorNullServiceUV = createAction(SET_ERROR_NULL_SERVICE_UV)();
