import { call, put, delay } from 'redux-saga/effects';
import { PayloadActionCreator } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { startLoading, finishLoading } from './loading';

export type AxiosErrorType =
  | AxiosError['response']
  | AxiosError['request']
  | AxiosError['message']
  | null;

interface createRequestSagaParam<P, R> {
  createAsyncActionType: {
    request: PayloadActionCreator<string, P>;
    success: PayloadActionCreator<string, R>;
    failure: PayloadActionCreator<string, AxiosErrorType>;
  };
}

function createRequestSaga<P, R>(
  requestAsync: createRequestSagaParam<P, R>['createAsyncActionType'],
  request: (info: P) => Promise<R>
) {
  return function* generatorSaga(
    action: ReturnType<PayloadActionCreator<string, P>>
  ) {
    yield put(startLoading(action.type));
    try {
      yield delay(500);
      const response: R = yield call(request, action.payload);

      yield put(requestAsync.success(response));
    } catch (error) {
      const e = error as AxiosError;
      if (e.response) {
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        yield put(requestAsync.failure(e.response));
      } else if (e.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        yield put(requestAsync.failure(e.request));
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        yield put(requestAsync.failure(e.message));
      }
    }
    yield put(finishLoading(action.type));
  };
}

export default createRequestSaga;
