import React from 'react';
import styled from 'styled-components';
import { H4 } from '../../Atoms/Typo';

const Component = styled.div``;

interface CountProps {
  value: number;
  className?: string;
}

const Count = ({ value, className }: CountProps) => {
  function numberFormat(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const numberToKorean = (number: number) => {
    const inputNumber = number;
    const unitWords = ['', '만', '억', '조', '경'];
    const splitUnit = 10000;
    const splitCount = unitWords.length;
    const resultArray = [];
    let resultString = '';

    for (let i = 0; i < splitCount; i += 1) {
      let unitResult = (inputNumber % splitUnit ** (i + 1)) / splitUnit ** i;
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (let i = 0; i < resultArray.length; i += 1) {
      if (resultArray[i]) {
        resultString = `${
          String(numberFormat(resultArray[i])) + unitWords[i]
        } ${resultString}`;
      }
    }

    return resultString;
  };

  return (
    <Component className="voda_num_to_kor_count">
      <H4>{value === 0 ? 0 : numberToKorean(value)}</H4>
    </Component>
  );
};

export default Count;
