import { createReducer } from 'typesafe-actions';
import { uxAnalyticsAction, uxAnalyticsState } from './types';

import {
  SET_ELEMENT_IMAGE,
  GET_PAGE_MOUSE_STATICS,
  GET_PAGE_MOUSE_STATICS_ERROR,
  GET_PAGE_MOUSE_STATICS_SUCCESS,
  DELETE_PAGE_CLICK_STATICS,
  DELETE_PAGE_CLICK_STATICS_ERROR,
  DELETE_PAGE_CLICK_STATICS_SUCCESS,
  SET_TARGET_ELEMENT_ID,
  GET_PAGE_ELEMENT_DATA,
  GET_PAGE_ELEMENT_DATA_SUCCESS,
  GET_PAGE_ELEMENT_DATA_ERROR,
  SET_TOOLTIP_INFO,
  SET_TOOLTIP_INFO_MOUSE_FLOW,
  SET_ERROR_NULL_UX_ANALYTICS,
  GET_EFFECTIVE_CLICK_RATE,
  GET_EFFECTIVE_CLICK_RATE_SUCCESS,
  GET_EFFECTIVE_CLICK_RATE_ERROR,
  GET_PAGE_ATTENTION_SCREENS_COUNT,
  GET_PAGE_ATTENTION_SCREENS_COUNT_SUCCESS,
  GET_PAGE_ATTENTION_SCREENS_COUNT_ERROR,
  GET_PAGE_DURATION_ERROR,
  GET_PAGE_DURATION_SUCCESS,
  GET_PAGE_DURATION,
} from './actions';

const initialState: uxAnalyticsState = {
  elementImageArr: [],
  targetElementID: '',
  elementData: [],
  mouseStaticsData: null,
  tooltipInfo: {
    pathId: '',
    title1: 0,
    title2: 0,
    title3: 0,
  },
  tooltipInfoMouseFlow: {
    duration: 0,
    pathId: '',
    rank: 0,
    rate: 0,
  },
  uxAnalyticsError: null,
  effectiveClickRate: null,
  pageAttentionScreensCount: null,
  pageDuration: null,
};

const reducer = createReducer<uxAnalyticsState, uxAnalyticsAction>(
  initialState,
  {
    [SET_ELEMENT_IMAGE]: (state, action) => ({
      ...state,
      elementImageArr: action.payload,
      uxAnalyticsError: null,
    }),
    [SET_TOOLTIP_INFO]: (state, action) => ({
      ...state,
      tooltipInfo: action.payload,
    }),
    [SET_TOOLTIP_INFO_MOUSE_FLOW]: (state, action) => ({
      ...state,
      tooltipInfoMouseFlow: action.payload,
    }),

    [GET_PAGE_MOUSE_STATICS]: (state) => ({
      ...state,
      mouseStaticsData: null,
      uxAnalyticsError: null,
    }),
    [GET_PAGE_MOUSE_STATICS_SUCCESS]: (state, action) => ({
      ...state,
      mouseStaticsData: action.payload.payload,
    }),
    [GET_PAGE_MOUSE_STATICS_ERROR]: (state, action) => ({
      ...state,
      mouseStaticsData: null,
      uxAnalyticsError: action.payload,
    }),

    [DELETE_PAGE_CLICK_STATICS]: (state) => ({
      ...state,
      uxAnalyticsError: null,
    }),
    [DELETE_PAGE_CLICK_STATICS_SUCCESS]: (state) => ({
      ...state,
      pageClickStaticsData: null,
    }),
    [DELETE_PAGE_CLICK_STATICS_ERROR]: (state, action) => ({
      ...state,
      uxAnalyticsError: action.payload,
    }),

    [SET_TARGET_ELEMENT_ID]: (state, action) => ({
      ...state,
      targetElementID: action.payload,
    }),

    [GET_PAGE_ELEMENT_DATA]: (state) => ({
      ...state,
      elementData: [],
      uxAnalyticsError: null,
    }),
    [GET_PAGE_ELEMENT_DATA_SUCCESS]: (state, action) => ({
      ...state,
      elementData: action.payload.payload,
    }),
    [GET_PAGE_ELEMENT_DATA_ERROR]: (state, action) => ({
      ...state,
      elementData: [],
      uxAnalyticsError: action.payload,
    }),

    [GET_EFFECTIVE_CLICK_RATE]: (state) => ({
      ...state,
      effectiveClickRate: null,
      uxAnalyticsError: null,
    }),
    [GET_EFFECTIVE_CLICK_RATE_SUCCESS]: (state, action) => ({
      ...state,
      effectiveClickRate: action.payload.payload,
    }),
    [GET_EFFECTIVE_CLICK_RATE_ERROR]: (state, action) => ({
      ...state,
      effectiveClickRate: null,
      uxAnalyticsError: action.payload,
    }),

    [GET_PAGE_ATTENTION_SCREENS_COUNT]: (state) => ({
      ...state,
      pageAttentionScreensCount: null,
      uxAnalyticsError: null,
    }),
    [GET_PAGE_ATTENTION_SCREENS_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      pageAttentionScreensCount: action.payload.payload.attention_section_cnt,
    }),
    [GET_PAGE_ATTENTION_SCREENS_COUNT_ERROR]: (state, action) => ({
      ...state,
      pageAttentionScreensCount: null,
      uxAnalyticsError: action.payload,
    }),

    [GET_PAGE_DURATION]: (state) => ({
      ...state,
      pageDuration: null,
      uxAnalyticsError: null,
    }),
    [GET_PAGE_DURATION_SUCCESS]: (state, action) => ({
      ...state,
      pageDuration: action.payload.payload.total_duration,
    }),
    [GET_PAGE_DURATION_ERROR]: (state, action) => ({
      ...state,
      pageDuration: null,
      uxAnalyticsError: action.payload,
    }),

    [SET_ERROR_NULL_UX_ANALYTICS]: (state) => ({
      ...state,
      uxAnalyticsError: null,
    }),
  }
);

export default reducer;
