import React from 'react';
import styled from 'styled-components';
import { IconRadio } from '../Icon';

const Component = styled.button`
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: none;
  background-color: transparent;

  svg {
    width: 100%;
    height: 100%;
  }
`;

interface RadioButtonProps {
  isCheck: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const RadioButton = ({ isCheck, onClick }: RadioButtonProps) => {
  return (
    <Component
      type="button"
      onClick={onClick}
      className="voda_default_radio_button"
    >
      {isCheck ? <IconRadio className="checked" /> : <IconRadio />}
    </Component>
  );
};

export default RadioButton;
