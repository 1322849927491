import { createReducer } from 'typesafe-actions';
import { pageListAction, pageListState } from './types';

import {
  GET_PAGE_LIST,
  GET_PAGE_LIST_ERROR,
  GET_PAGE_LIST_SUCCESS,
  SET_ERROR_NULL_PAGE_LIST,
} from './actions';

const initialState: pageListState = {
  pageListData: [],
  pageListError: null,
};

const reducer = createReducer<pageListState, pageListAction>(initialState, {
  [GET_PAGE_LIST]: (state, action) => ({
    ...state,
    pageListData: [],
    pageListError: null,
  }),
  [GET_PAGE_LIST_SUCCESS]: (state, action) => ({
    ...state,
    pageListData: action.payload.payload,
  }),
  [GET_PAGE_LIST_ERROR]: (state, action) => ({
    ...state,
    pageListData: [],
    pageListError: action.payload,
  }),

  [SET_ERROR_NULL_PAGE_LIST]: (state) => ({
    ...state,
    pageListError: null,
  }),
});

export default reducer;
