import React from 'react';
import styled from 'styled-components';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';

import IllustNoData from '../../../assets/images/illust-nodata.png';

import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';

const Component = styled(CardLayout)`
  padding: 24px;
  width: 100%;
  height: 757px;
  display: flex;
`;

const Header = styled(CardHeaderLayout)`
  margin: 40px 44px 8px 45px;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #424242;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = styled(CardBodyLayout)`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImgBody = styled.img`
  /* width: 182px;
  height: 220px;
  display: flex;
  margin: 0 80px 40px;
  object-fit: contain; */
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const P2 = styled.p`
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
`;

interface NoDataCardProps {
  header: string;
  bodyText?: string;
}

const NoDataCard = ({ header, bodyText }: NoDataCardProps) => {
  return (
    <Component>
      <Body>
        <ImgBody src={IllustNoData} alt="" />
        <Header>
          <DefaultCardHeading title={header} />
        </Header>
        <P2>{bodyText}</P2>
      </Body>
    </Component>
  );
};

export default NoDataCard;
