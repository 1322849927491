import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';

import {
  GetAIVORYSearchUsageAnalysisParamType,
  GetAIVORYSearchUsageAnalysisResponseType,
} from '../../../api/aivoryUsageAnalytics/searchUsageAnalysis/type';

export const GET_AIVORY_SEARCH_USAGE_ANALYSIS = 'aivoryUsageAnalytics/SearchUsageAnalysis/GET_AIVORY_SEARCH_USAGE_ANALYSIS' as const;
export const GET_AIVORY_SEARCH_USAGE_ANALYSIS_SUCCESS = 'aivoryUsageAnalytics/SearchUsageAnalysis/GET_AIVORY_SEARCH_USAGE_ANALYSIS_SUCCESS' as const;
export const GET_AIVORY_SEARCH_USAGE_ANALYSIS_ERROR = 'aivoryUsageAnalytics/SearchUsageAnalysis/GET_AIVORY_SEARCH_USAGE_ANALYSIS_ERROR' as const;

export const GET_AIVORY_CONTENTS_USAGE_ANALYSIS = 'aivoryUsageAnalytics/SearchUsageAnalysis/GET_AIVORY_CONTENTS_USAGE_ANALYSIS' as const;
export const GET_AIVORY_CONTENTS_USAGE_ANALYSIS_SUCCESS = 'aivoryUsageAnalytics/SearchUsageAnalysis/GET_AIVORY_CONTENTS_USAGE_ANALYSIS_SUCCESS' as const;
export const GET_AIVORY_CONTENTS_USAGE_ANALYSIS_ERROR = 'aivoryUsageAnalytics/SearchUsageAnalysis/GET_AIVORY_CONTENTS_USAGE_ANALYSIS_ERROR' as const;

export const SET_ERROR_NULL_AIVORY_SEARCH_USAGE =
  'aivoryUsageAnalytics/SearchUsageAnalysis/SET_ERROR_NULL_AIVORY_SEARCH_USAGE';

export const getAIVORYSearchUsageAnalysisAsync = createAsyncAction(
  GET_AIVORY_SEARCH_USAGE_ANALYSIS,
  GET_AIVORY_SEARCH_USAGE_ANALYSIS_SUCCESS,
  GET_AIVORY_SEARCH_USAGE_ANALYSIS_ERROR
)<
  GetAIVORYSearchUsageAnalysisParamType,
  GetAIVORYSearchUsageAnalysisResponseType,
  AxiosErrorType
>();

export const getAIVORYContentsUsageAnalysisAsync = createAsyncAction(
  GET_AIVORY_CONTENTS_USAGE_ANALYSIS,
  GET_AIVORY_CONTENTS_USAGE_ANALYSIS_SUCCESS,
  GET_AIVORY_CONTENTS_USAGE_ANALYSIS_ERROR
)<
  GetAIVORYSearchUsageAnalysisParamType,
  GetAIVORYSearchUsageAnalysisResponseType,
  AxiosErrorType
>();

export const setErrorNullAivorySearchUsage = createAction(
  SET_ERROR_NULL_AIVORY_SEARCH_USAGE
)();
