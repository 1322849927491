import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../modules';
import {
  getPageListAsync,
  GET_PAGE_LIST,
} from '../../../modules/PageAnalytics/PageList';
import { GetPageListParamType } from '../../../api/pageAnalytics/pageList/type';

import PageListTemplate from '../../../components/Templates/PageDetailAnalysisTemplates/PageListTemplate';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

const PageList = () => {
  // redux
  const loading = useSelector((state: RootState) => state.loading);
  const { pageListData } = useSelector((state: RootState) => state.pageList);
  const dispatch = useDispatch();

  const onGetPageList = (info: GetPageListParamType) => {
    dispatch(getPageListAsync.request(info));
  };

  useEffect(() => {
    onGetPageList({});
  }, []);

  return (
    <Component>
      <PageListTemplate
        headerTitle="사용 흐름 분석"
        pageListData={pageListData}
        isLoading={loading[GET_PAGE_LIST]}
        isPageUXAnalysis={false}
      />
    </Component>
  );
};

export default PageList;
