import { takeLatest } from 'redux-saga/effects';
import { apiGetScrollEvents } from '../../../../api/pageAnalytics/uxAnalytics/scroll/api';

import createRequestSaga from '../../../createRequestSaga';
import { getScrollEventsAsync, GET_SCROLL_EVENTS } from './actions';

const getScrollEventsSaga = createRequestSaga(
  getScrollEventsAsync,
  apiGetScrollEvents
);

export function* scrollSaga() {
  yield takeLatest(GET_SCROLL_EVENTS, getScrollEventsSaga);
}

export { scrollSaga as default };
