import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules';

// Private Public components
import PublicRoute from '../_PublicRoute';
import LoginPage from '../LoginPage';
import NotfoundPage from '../../components/NotfoundPage';

// Private components
import PrivateRoute from '../_PrivateRoute';
import ServiceDashboard from '../ServiceAnalytics/Dashboard';
import ServiceUserAnalytics from '../ServiceAnalytics/UserAnalytics';
import ServicePageViewAnalytics from '../ServiceAnalytics/PageViewAnalytics';
import ServiceSessionAnalytics from '../ServiceAnalytics/SessionAnalytics';
import ServiceEnvironmentAnalysis from '../ServiceAnalytics/EnvironmentAnalysis';

import PageUXAnalysisList from '../PageAnalytics/PageList/PageUXAnalysis';
import UserFlowAnalysisList from '../PageAnalytics/PageList/UserFlowAnalysis';
import UXAnalysis from '../PageAnalytics/UXAnalysis';
import PageUserFlowAnalysis from '../PageAnalytics/UserFlowAnalysis';

import AIVORYDashboard from '../AIVORYUsageAnalytics/Dashboard';
import SearchUsageAnalysis from '../AIVORYUsageAnalytics/SearchUsageAnalysis';
import PopularSearchAnalysis from '../AIVORYUsageAnalytics/PopularSearchAnalysis';
import ContentKeywordAnalysis from '../AIVORYUsageAnalytics/ContentKeywordAnalysis';

import Report from '../Report';
import MyPage from '../MyPage';
import Support from '../Support';
import Dashboard from '../Dashboard';

const env = process.env.NODE_ENV;
let isDevelopment = false;
if (env === 'development') {
  isDevelopment = true;
}
const Routes = () => {
  // redux
  const { isLogin } = useSelector((state: RootState) => state.authentication);

  return (
    <>
      {isLogin && localStorage.getItem('voda_tenant') ? (
        <Switch>
          <PrivateRoute exact path="/" title="대시보드" Component={Dashboard} />

          <PrivateRoute
            exact
            path="/sessionAnalytics/uniqueVisitor"
            title="사용자 수 분석"
            Component={ServiceUserAnalytics}
          />
          <PrivateRoute
            exact
            path="/sessionAnalytics/pageView"
            title="페이지뷰 분석"
            Component={ServicePageViewAnalytics}
          />
          <PrivateRoute
            exact
            path="/serviceAnalytics/session"
            title="방문횟수(session) 분석"
            Component={ServiceSessionAnalytics}
          />

          <PrivateRoute
            exact
            path="/userAnalytics/inflowPath"
            title="유입경로 분석"
            Component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/userAnalytics/contents"
            title="콘텐츠 분석"
            Component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/userAnalytics/region"
            title="지역별 분석"
            Component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/userAnalytics/environment"
            title="사용 환경 분석"
            Component={Dashboard}
          />

          <PrivateRoute
            exact
            path="/pageAnalytics/uxAnalysisList"
            title="페이지 UX 분석"
            Component={PageUXAnalysisList}
          />
          <PrivateRoute
            exact
            path="/pageAnalytics/userFlowAnalysisList"
            title="사용 흐름 분석"
            Component={UserFlowAnalysisList}
          />
          <PrivateRoute
            exact
            path="/pageAnalytics/uxAnalysisList/uxAnalysis"
            title="UX 분석"
            Component={UXAnalysis}
          />
          <PrivateRoute
            exact
            path="/pageAnalytics/userFlowAnalysisList/userFlowAnalysis"
            title="사용자 흐름 분석"
            Component={PageUserFlowAnalysis}
          />

          <PrivateRoute
            exact
            path="/aivoryUsageAnalytics"
            title="AIVORY 이용 평균 분석"
            Component={AIVORYDashboard}
          />
          <PrivateRoute
            exact
            path="/aivoryUsageAnalytics/searchKeyword"
            title="AIVORY 이용 평균 분석"
            Component={AIVORYDashboard}
          />

          <PrivateRoute
            exact
            path="/myPage"
            title="마이페이지"
            Component={MyPage}
          />

          <PrivateRoute
            exact
            path="/report"
            title="월간 분석 리포트"
            Component={Report}
          />

          <PrivateRoute
            exact
            path="/support"
            title="고객센터"
            Component={Support}
          />

          {/* 사용하지 않는 컴포넌트 */}
          {/* <PrivateRoute
          exact
          path="/serviceAnalytics/environmentAnalysis"
          title="사용환경 분석"
          Component={ServiceEnvironmentAnalysis}
        />

        <PrivateRoute
          exact
          path="/aivoryUsageAnalytics"
          title="AIVORY 이용 분석"
          Component={AIVORYDashboard}
        />
        <PrivateRoute
          exact
          path="/aivoryUsageAnalytics/searchUsageAnalysis"
          title="검색 이용 분석"
          Component={SearchUsageAnalysis}
        />
        <PrivateRoute
          exact
          path="/aivoryUsageAnalytics/popularSearchAnalysis"
          title="인기검색어 분석"
          Component={PopularSearchAnalysis}
        />
        <PrivateRoute
          exact
          path="/aivoryUsageAnalytics/contentKeywordAnalysis"
          title="콘텐츠 키워드 분석"
          Component={ContentKeywordAnalysis}
        /> */}

          <PrivateRoute exact path="*" title="404" Component={NotfoundPage} />
        </Switch>
      ) : (
        <Switch>
          <PublicRoute exact path="*" component={LoginPage} />
          <Route component={NotfoundPage} />
        </Switch>
      )}
    </>
  );
};

export default Routes;
