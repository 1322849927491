import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Slider } from '@material-ui/core';

const Component = styled.div`
  position: absolute;
  top: 50px;
  left: 95%;
  width: 25px;
  height: 200px;
`;

interface OpacitySliderProps {
  sliderRef?: React.RefObject<HTMLDivElement>;
  type: string;
  defaultVal: number;
  opacityVal: (val: number) => void;
}

const OpacitySlider = ({
  sliderRef,
  type,
  defaultVal,
  opacityVal,
}: OpacitySliderProps) => {
  const [value, setValue] = useState<number>(6);

  const handleChange = (event: any, val: number | number[]) => {
    if (typeof val === 'number') {
      setValue(val);
    }
  };

  useEffect(() => {
    opacityVal(value);
  }, [value]);

  useEffect(() => {
    switch (type) {
      case 'mouse':
        setValue(10);
        break;
      case 'scroll':
        setValue(6);
        break;

      default:
        break;
    }
  }, []);

  return (
    <Component ref={sliderRef}>
      <Slider
        orientation="vertical"
        aria-labelledby="vertical-slider"
        defaultValue={defaultVal}
        onChange={handleChange}
        min={1}
        max={10}
        aria-label="Default"
        valueLabelDisplay="off"
      />
    </Component>
  );
};

export default OpacitySlider;
