import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Chart from 'react-apexcharts';
import ko from 'apexcharts/dist/locales/ko.json';
import { tooltip } from '../../../Atoms/ChartTooltip/MultiLineChartTooltip';
import { IconSMArrowLeft, IconSMArrowRight } from '../../../Atoms/Icon';

const Component = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 400px; */
  padding-left: 52px;
  padding-right: 52px;
  .apexcharts-svg {
    overflow: initial;
  }
  ${tooltip};

  .apexcharts-bar-series {
    .apexcharts-series {
      transform: translate(-4px, 0%);
      &:last-child {
        transform: translate(4px, 0%);
      }
    }
  }
`;

const LeftZoomButton = styled.div<{
  $isEnd: boolean;
}>`
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 12px;
  height: 16px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;

    path {
      ${(props) => {
        if (props.$isEnd) {
          return css`
            fill: ${props.theme.colors.grey500};
          `;
        }
        return css`
          fill: ${props.theme.colors.grey900};
        `;
      }}
    }
  }
`;
const RightZoomButton = styled.div<{ $isEnd: boolean }>`
  position: absolute;
  right: 0;
  bottom: 12px;
  width: 12px;
  height: 16px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;

    path {
      ${(props) => {
        if (props.$isEnd) {
          return css`
            fill: ${props.theme.colors.grey500};
          `;
        }
        return css`
          fill: ${props.theme.colors.grey900};
        `;
      }}
    }
  }
`;

interface MultiLineChartProps {
  Data: {
    [key: string]: {
      total: number;
      old: number;
      new: number;
      is_future?: boolean;
    };
  } | null;
  selectedTimeSort: string;

  lineLabel: string;
  bar1Label?: string;
  bar2Label?: string;
  isReport?: boolean;
}
interface MultiLineChartState {
  seriesType:
    | {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          isFuture: boolean;
        }[];
      }[]
    | null;
}

const MultiLineChart = ({
  Data,
  selectedTimeSort,

  lineLabel,
  bar1Label,
  bar2Label,
  isReport,
}: MultiLineChartProps) => {
  const [stateStartIter, setStartIter] = useState(0);
  const [stateEndIter, setEndIter] = useState(-1);
  const [endIndex, setEndIndex] = useState(-1);

  const ChartRef = useRef<any>(null);

  const [rebuildSeries, setSeries] =
    useState<MultiLineChartState['seriesType']>(null);

  const options = {
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: '데이터를 불러오지 못했습니다.',
      align: 'center' as const,
      verticalAlign: 'middle' as const,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#424242',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Pretendard',
      },
    },
    chart: {
      locales: [ko],
      defaultLocale: 'ko',
      animations: {
        enabled: true,
        easing: 'easeinout' as const,
        speed: 100,
        animateGradually: {
          enabled: false,
          delay: 0,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 200,
        },
      },

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        mouseMove(event: any, chartContext: any, config: any) {
          const apexcharts_tooltip = chartContext.el.querySelector(
            '.apexcharts-tooltip'
          );
          const canvasEl = chartContext.el.querySelector('.apexcharts-canvas');
          const marker =
            chartContext.el.getElementsByClassName('apexcharts-marker');
          const dataPointIndex =
            config.dataPointIndex === -1 ? 0 : config.dataPointIndex;
          if (apexcharts_tooltip && canvasEl && marker[dataPointIndex]) {
            const canvasTop =
              canvasEl.getBoundingClientRect().top + window.pageYOffset;

            const canvasLeft =
              canvasEl.getBoundingClientRect().left + window.pageXOffset;

            const width = canvasEl.clientWidth;

            const markerTop =
              marker[dataPointIndex].getBoundingClientRect().top +
              window.pageYOffset;
            const markerLeft =
              marker[dataPointIndex].getBoundingClientRect().left +
              window.pageXOffset;

            if (canvasLeft + width / 2 < markerLeft) {
              apexcharts_tooltip.style.top = `${markerTop - canvasTop + 5}px`;
              apexcharts_tooltip.style.left = `${
                markerLeft - canvasLeft - apexcharts_tooltip.clientWidth + 5
              }px`;
            } else {
              apexcharts_tooltip.style.top = `${markerTop - canvasTop + 5}px`;
              apexcharts_tooltip.style.left = `${
                markerLeft - canvasLeft + 5
              }px`;
            }
          }
        },
        updated(chartContext: any, config: any) {
          const seriesData = config.config.series[0].data;
          const seriesLineWrapper = chartContext.el
            .getElementsByClassName('apexcharts-line-series')
            .item(0);
          const seriesBarWrapper = chartContext.el
            .getElementsByClassName('apexcharts-bar-series')
            .item(0) as HTMLElement;
          const coordinate: { cx: string; cy: string }[] = [];

          if (seriesBarWrapper) {
            if (seriesBarWrapper.children.length > 1) {
              const bar1 = seriesBarWrapper.children.item(0)?.childNodes;
              const bar2 = seriesBarWrapper.children.item(1)?.childNodes;
              if (bar1 && bar2) {
                seriesData.forEach((element: any, index: number) => {
                  if (element.isFuture) {
                    const bar1path = bar1.item(index) as HTMLElement;
                    const bar2path = bar2.item(index) as HTMLElement;
                    bar1path.setAttribute('stroke-width', '2');
                    bar2path.setAttribute('stroke-width', '2');
                    bar1path.setAttribute('stroke', '#d0d47c');
                    bar2path.setAttribute('stroke', '#fdb67d');
                    bar1path.setAttribute('fill', '#fafbc9');
                    bar2path.setAttribute('fill', '#fff2d5');
                  }
                });
              }
            }
          }

          if (seriesLineWrapper) {
            const pathWrapper = seriesLineWrapper
              .getElementsByClassName('apexcharts-series')
              .item(0) as HTMLElement;
            if (pathWrapper) {
              pathWrapper.getElementsByTagName('path').item(0)?.remove();
            }

            const marker =
              chartContext.el.getElementsByClassName('apexcharts-marker');

            if (marker) {
              for (let index = 0; index < marker.length; index += 1) {
                const element = marker.item(index) as HTMLElement;

                if (
                  seriesData[index] &&
                  'isFuture' in seriesData[index] &&
                  seriesData[index].isFuture
                ) {
                  element.setAttribute(
                    'fill',
                    isReport ? '#b597f7' : '#bdbdbd'
                  );
                }
                // element.setAttribute('default-marker-size', '4');

                coordinate.push({
                  cx: element.getAttribute('cx') || '0',
                  cy: element.getAttribute('cy') || '0',
                });
              }
            }
          }
          if (coordinate.length > 0) {
            const pathWrapper = seriesLineWrapper
              .getElementsByClassName('apexcharts-series')
              .item(0) as HTMLElement;

            if (pathWrapper) {
              const markerWrapper = pathWrapper
                .getElementsByClassName('apexcharts-series-markers-wrap')
                .item(0);
              if (markerWrapper) {
                coordinate.forEach((element, index) => {
                  const path = document.createElementNS(
                    'http://www.w3.org/2000/svg',
                    'path'
                  );

                  path.setAttribute('fill', 'none');
                  path.setAttribute('stroke-width', '2');
                  path.setAttribute('stroke-opacity', '1');
                  if (seriesData[index] && seriesData[index].isFuture) {
                    path.setAttribute(
                      'stroke',
                      isReport ? '#b597f7' : '#bdbdbd'
                    );
                    path.setAttribute('stroke-dasharray', '8');
                  } else {
                    path.setAttribute('stroke', '#7851E7');
                  }

                  if (index !== 0) {
                    path.setAttribute(
                      'd',
                      `M ${coordinate[index - 1].cx} ${
                        coordinate[index - 1].cy
                      } L ${element.cx} ${element.cy}`
                    );
                    pathWrapper.insertBefore(path, markerWrapper);
                  }
                });
              }
            }
          }
        },
      },
    },

    legend: {
      show: false,
    },

    stroke: {
      width: 1,
    },

    markers: {
      size: 4,
      colors: ['#7851E7'],
      strokeColors: '#fff',
      strokeWidth: 1,
      strokeOpacity: 1,
      hover: {
        size: 8,
      },
    },
    colors: ['#7851E7', '#3FECB7', '#1389FF'],
    tooltip: {
      enabled: true,
      followCursor: false,
      shared: true,
      intersect: false,
      fixed: {
        enabled: true,
        position: 'topLeft',
      },

      custom({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: any;
        seriesIndex: any;
        dataPointIndex: any;
        w: any;
      }) {
        if (series[0] && series[1] && series[2]) {
          const seriesData = w.config.series[0].data;

          const lineValue = series[0][dataPointIndex];
          const barValue1 = series[1][dataPointIndex];
          const barValue2 = series[2][dataPointIndex];
          let returnRate = 0;
          let newRate = 0;
          let result = '';
          if (lineValue !== 0) {
            returnRate = Math.floor((barValue1 / lineValue) * 100);
            newRate = Math.floor((barValue2 / lineValue) * 100);
          }

          if (bar1Label !== undefined && bar2Label !== undefined) {
            if (seriesData[dataPointIndex].isFuture) {
              result = `<div class="tooltip">
              <div class="innerBox">
                <div class="dot-all dot-all-future"></div>
                <div class="title">${lineLabel}(추정)</div>
                <div class="value">${lineValue}</div>
              </div>
              <div class="innerBox">
                <div class="dot-return dot-return-future"></div>
                <div class="title">${bar1Label}(추정)</div>
                <div class="value">${barValue1}</div>
                <div class="rate-return">(${returnRate}%)</div>
              </div>
              <div class="innerBox">
                <div class="dot-new dot-new-future"></div>
                <div class="title">${bar2Label}(추정)</div>
                <div class="value">${barValue2}</div>
                <div class="rate-new">(${newRate}%)</div>
              </div>
            </div>`;
            } else {
              result = `<div class="tooltip">
              <div class="innerBox">
                <div class="dot-all"></div>
                <div class="title">${lineLabel}</div>
                <div class="value">${lineValue}</div>
              </div>
              <div class="innerBox">
                <div class="dot-return"></div>
                <div class="title">${bar1Label}</div>
                <div class="value">${barValue1}</div>
                <div class="rate-return">(${returnRate}%)</div>
              </div>
              <div class="innerBox">
                <div class="dot-new"></div>
                <div class="title">${bar2Label}</div>
                <div class="value">${barValue2}</div>
                <div class="rate-new">(${newRate}%)</div>
              </div>
            </div>`;
            }
          } else if (seriesData[dataPointIndex].isFuture) {
            result = `<div class="tooltip">
              <div class="innerBox">
                <div class="dot-all dot-all-future"></div>
                <div class="title">${lineLabel}(추정)</div>
                <div class="value">${lineValue}</div>
              </div>
            </div>`;
          } else {
            result = `<div class="tooltip">
              <div class="innerBox">
                <div class="dot-all"></div>
                <div class="title">${lineLabel}</div>
                <div class="value">${lineValue}</div>
              </div>
            </div>`;
          }
          return result;
        }
        if (series[0]) {
          const seriesData = w.config.series[0].data;
          const lineValue = series[0][dataPointIndex];
          if (seriesData[dataPointIndex].isFuture) {
            return `<div class="tooltip">
              <div class="innerBox">
                <div class="dot-all dot-all-future"></div>
                <div class="title">${lineLabel}(추정)</div>
                <div class="value">${lineValue}</div>
              </div>
            </div>`;
          }

          return `<div class="tooltip">
              <div class="innerBox">
                <div class="dot-all"></div>
                <div class="title">${lineLabel}</div>
                <div class="value">${lineValue}</div>
              </div>
            </div>`;
        }
        return ``;
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        right: -15,
        left: -30,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: '#e0e0e0',
        width: '100%',
      },
      labels: {
        rotate: 0,

        style: {
          fontFamily: 'Pretendard',
          fontWeight: 400,
          fontSize: '14px',
          colors: '#757575',
        },
        formatter(value: string) {
          return ``;
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: 0,
      max: 100,
    },
    fill: {
      colors: ['#7851E7', '#3FECB7', '#1389FF'],
      type: ['gradient', 'solid', 'solid'],
      gradient: {
        shade: 'dark',
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          [
            {
              offset: 0,
              color: '#7851E7',
              opacity: 1,
            },
            {
              offset: 18,
              color: '#7851E7',
              opacity: 1,
            },
            {
              offset: 18,
              color: '#bdbdbd',
              opacity: 1,
            },
          ],
        ],
      },
      pattern: {
        style: 'slantedLines',
        width: 1,
        height: 10,
        strokeWidth: 1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 4,
      },
    },
    annotations: {
      position: 'back',
    },
  };

  const [optionsState, setOptions] = useState(options);

  const tempSeries = [{ name: '', type: 'line', data: [] }];

  // 멀티차트 화살표 버튼 클릭 시 series data update를 위한 functino
  const updateSeries = (
    series: MultiLineChartState['seriesType'],
    startIter: number,
    endIter: number
  ) => {
    const { current } = ChartRef;

    if (series && current) {
      const seriesData0: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];
      const seriesData1: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];
      const seriesData2: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];

      for (let index = startIter; index <= endIter; index += 1) {
        seriesData0.push(series[0].data[index]);
        if (bar1Label !== undefined && bar2Label !== undefined) {
          seriesData1.push(series[1].data[index]);
          seriesData2.push(series[2].data[index]);
        }
      }

      if (bar1Label !== undefined && bar2Label !== undefined) {
        current.chart.updateSeries(
          [
            {
              name: lineLabel,
              type: 'line',
              data: seriesData0,
            },
            {
              name: bar1Label,
              type: 'column',
              data: seriesData1,
            },
            {
              name: bar2Label,
              type: 'column',
              data: seriesData2,
            },
          ],
          true
        );
      } else {
        current.chart.updateSeries(
          [
            {
              name: lineLabel,
              type: 'line',
              data: seriesData0,
            },
          ],
          true
        );
      }
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  const updateOption = (
    series: MultiLineChartState['seriesType'],
    startIter: number,
    endIter: number,
    yAxisMin: number,
    yAxisMax: number,
    max: number,
    min: number,
    avg: number
  ) => {
    const { current } = ChartRef;
    if (series && current) {
      const seriesData0: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];
      const seriesData1: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];
      const seriesData2: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];

      for (let index = startIter; index <= endIter; index += 1) {
        seriesData0.push(series[0].data[index]);
        if (bar1Label !== undefined && bar2Label !== undefined) {
          seriesData1.push(series[1].data[index]);
          seriesData2.push(series[2].data[index]);
        }
      }

      const chartEl = current.chartRef.current as HTMLElement;
      const dataLength = seriesData0.length;
      const chartWidth =
        chartEl.getBoundingClientRect().right -
        chartEl.getBoundingClientRect().left;

      const barWidth = chartWidth / (dataLength * 2);
      const barWidthPercentage = 800 / barWidth;
      let updateTempSeries: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          isFuture: boolean;
        }[];
      }[] = [];

      if (bar1Label !== undefined && bar2Label !== undefined) {
        updateTempSeries = [
          {
            name: lineLabel,
            type: 'line',
            data: seriesData0,
          },

          {
            name: bar1Label,
            type: 'column',
            data: seriesData1,
          },
          {
            name: bar2Label,
            type: 'column',
            data: seriesData2,
          },
        ];
      } else {
        updateTempSeries = [
          {
            name: lineLabel,
            type: 'line',
            data: seriesData0,
          },
        ];
      }

      const tempOptions = {
        ...optionsState,
        series: updateTempSeries,

        yaxis: {
          ...optionsState.yaxis,
          min: yAxisMin - 0.01,
          max: yAxisMax + 1,
        },
        xaxis: {
          ...optionsState.xaxis,

          labels: {
            ...optionsState.xaxis.labels,
            formatter(value: string) {
              if (value) {
                switch (selectedTimeSort) {
                  case 'hour':
                    if (value.split(' ')[1] === '00') {
                      return `${value.split(' ')[0].split('-')[2]}일 ${
                        value.split(' ')[1]
                      }시`;
                    }
                    return `${value.split(' ')[1]}시`;
                  case 'day':
                    if (value.split('-')[2] === '01') {
                      return `${value.split('-')[1]}월 ${
                        value.split('-')[2]
                      }일`;
                    }
                    return `${value.split('-')[2]}일`;
                  case 'week':
                    if (value.split(' ')[1] === '1') {
                      return `${value.split(' ')[0].split('-')[1]}월 ${
                        value.split(' ')[1]
                      }주차`;
                    }
                    return `${value.split(' ')[1]}주차`;
                  case 'month':
                    if (value.split('-')[1] === '01') {
                      return `${value.split('-')[0]}년 ${
                        value.split('-')[1]
                      }월`;
                    }
                    return `${value.split('-')[1]}월`;
                  default:
                    break;
                }
              }
              return ``;
            },
          },
        },
        annotations: {
          ...optionsState.annotations,
          yaxis: [
            {
              y: max,
              strokeDashArray: 0,
              borderColor: '#eeeeee',
              opacity: 1,
              width: '100%',
              offsetX: 0,
              label: {
                textAnchor: 'start',
                borderColor: 'transparent',
                borderWidth: 0,

                style: {
                  fontFamily: 'Pretendard',
                  fontWeight: 500,
                  background: 'transparent',
                  color: '#767676',
                  fontSize: '15px',
                },
                position: 'left',
                offsetX: -42,
                offsetY: 8,
                text: `${max}`,
              },
            },
            {
              y: max / 2,
              strokeDashArray: 0,
              borderColor: '#eeeeee',
              opacity: 1,
              width: '100%',
              offsetX: 0,
              label: {
                textAnchor: 'middle',
                borderColor: 'transparent',
                borderWidth: 0,

                style: {
                  fontFamily: 'Pretendard',
                  fontWeight: 500,
                  background: 'transparent',
                  color: '#767676',
                  fontSize: '15px',
                },
                position: 'left',
                offsetX: -25,
                offsetY: 8,
                text: `${max / 2}`,
              },
            },
          ],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: `${barWidthPercentage}%`,
            borderRadius: 4,
          },
        },
      };

      current.chart.updateOptions(tempOptions);
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  const rebuildData = () => {
    const { current } = ChartRef;
    if (Data && current) {
      const categoryArr = Object.keys(Data);
      const lineData: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];
      const barData1: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];
      const barData2: {
        x: string;
        y: number;
        isFuture: boolean;
      }[] = [];

      if (selectedTimeSort === 'week') {
        categoryArr.sort((a, b) => {
          const a_arr = a.split(' ');
          const b_arr = b.split(' ');
          const a_date = new Date(a_arr[0]);
          const b_date = new Date(b_arr[0]);

          const case1 = Number(a_date) - Number(b_date);
          if (case1 === 0) {
            return Number(a_arr[1]) - Number(b_arr[1]);
          }
          return case1;
        });
      }

      categoryArr.forEach((element) => {
        const isFuture = Data[element].is_future || false;
        // const isFuture = false;

        lineData.push({
          x: element,
          y: Data[element].total,
          isFuture,
        });
        barData1.push({
          x: element,
          y: Data[element].old,
          isFuture,
        });
        barData2.push({
          x: element,
          y: Data[element].new,
          isFuture,
        });
      });

      let series: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          isFuture: boolean;
        }[];
      }[] = [];

      if (bar1Label !== undefined && bar2Label !== undefined) {
        series = [
          { name: lineLabel, type: 'line', data: lineData },
          { name: bar1Label, type: 'column', data: barData1 },
          { name: bar2Label, type: 'column', data: barData2 },
        ];
      } else {
        series = [{ name: lineLabel, type: 'line', data: lineData }];
      }

      setSeries(series);
      setEndIndex(series[0].data.length - 1);

      let startIterTemp = 0;
      let endIterTemp = 0;

      if (series[0].data.length < 20) {
        setStartIter(0);
        setEndIter(series[0].data.length - 1);
        startIterTemp = 0;
        endIterTemp = series[0].data.length - 1;
      } else {
        setStartIter(series[0].data.length - 20);
        setEndIter(series[0].data.length - 1);
        startIterTemp = series[0].data.length - 20;
        endIterTemp = series[0].data.length - 1;
      }

      const series0 = series[0].data;
      let sum = 0;
      let max = 0;
      let min = 0;
      let avg = 0;
      series0.forEach((element, index) => {
        sum += element.y;
        if (index === 0) {
          max = element.y;
          min = element.y;
        }
        if (element.y > max) {
          max = element.y;
        }

        if (element.y < min) {
          min = element.y;
        }
      });
      avg = Math.floor(sum / series0.length);

      let yAxisMin = 0;
      let yAxisMax = 1;
      series.forEach((element, index) => {
        element.data.forEach((dataElement) => {
          if (dataElement.y > yAxisMax) {
            yAxisMax = dataElement.y;
          }
          if (dataElement.y < yAxisMin) {
            yAxisMin = dataElement.y;
          }
        });
      });
      updateOption(
        series,
        startIterTemp,
        endIterTemp,
        yAxisMin,
        yAxisMax,
        max,
        min,
        avg
      );
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  useEffect(() => {
    if (Data) {
      rebuildData();
    }
  }, [Data]);

  const onClickNext = () => {
    const { current } = ChartRef;
    let startIterTemp = 0;
    let endIterTemp = 0;

    if (current && rebuildSeries) {
      const startIter = stateEndIter + 1;
      startIterTemp = stateEndIter + 1;
      if (startIter > rebuildSeries[0].data.length - 1) {
        setEndIter(stateEndIter);
        endIterTemp = stateEndIter;
      } else if (startIter + 19 > rebuildSeries[0].data.length - 1) {
        if (rebuildSeries[0].data.length - 20 > -1) {
          setStartIter(rebuildSeries[0].data.length - 20);
          startIterTemp = rebuildSeries[0].data.length - 20;
        } else {
          setStartIter(startIter);
          startIterTemp = startIter;
        }
        setEndIter(rebuildSeries[0].data.length - 1);
        endIterTemp = rebuildSeries[0].data.length - 1;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      } else {
        setStartIter(startIter);
        setEndIter(startIter + 19);
        startIterTemp = startIter;
        endIterTemp = startIter + 19;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      }
    }
  };

  const onClickPre = () => {
    const { current } = ChartRef;
    let startIterTemp = 0;
    let endIterTemp = 0;

    if (current && rebuildSeries) {
      const endIter = stateStartIter - 1;
      endIterTemp = stateStartIter - 1;
      if (endIter === -1) {
        setStartIter(stateStartIter);
        startIterTemp = stateStartIter;
      } else if (endIter - 19 < 0) {
        setStartIter(0);
        startIterTemp = 0;
        if (rebuildSeries[0].data.length - 1 > 19) {
          setEndIter(19);
          endIterTemp = 19;
        } else {
          setEndIter(endIter);
          endIterTemp = endIter;
        }
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      } else {
        setStartIter(endIter - 19);
        setEndIter(endIter);
        startIterTemp = endIter - 19;
        endIterTemp = endIter;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      }
    }
  };

  return (
    <Component>
      <Chart
        type={bar1Label === undefined ? 'line' : 'bar'}
        series={tempSeries}
        options={optionsState}
        width="100%"
        height="100%"
        ref={ChartRef}
      />
      <LeftZoomButton
        onClick={() => {
          onClickPre();
        }}
        $isEnd={stateStartIter === 0}
      >
        <IconSMArrowLeft />
      </LeftZoomButton>
      <RightZoomButton
        onClick={() => {
          onClickNext();
        }}
        $isEnd={stateEndIter === endIndex}
      >
        <IconSMArrowRight />
      </RightZoomButton>
    </Component>
  );
};

export default MultiLineChart;
