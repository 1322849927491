import { createAsyncAction, createAction } from 'typesafe-actions';

import { AxiosErrorType } from '../../createRequestSaga';

import {
  GetDashboardStatisticsParamType,
  GetDashboardStatisticsResponseType,
  GetPVMeanStatisticsParamType,
  GetPVMeanStatisticsResponseType,
  GetUVMeanStatisticsParamType,
  GetUVMeanStatisticsResponseType,
  GetUsageMeanStatisticsParamType,
  GetUsageMeanStatisticsResponseType,
} from '../../../api/serviceAnalytics/dashboard/type';

export const GET_TOTAL_USER_STATISTICS = 'serviceAnalytics/dashboard/GET_TOTAL_USER_STATISTICS' as const;
export const GET_TOTAL_USER_STATISTICS_SUCCESS = 'serviceAnalytics/dashboard/GET_TOTAL_USER_STATISTICS_SUCCESS' as const;
export const GET_TOTAL_USER_STATISTICS_ERROR = 'serviceAnalytics/dashboard/GET_TOTAL_USER_STATISTICS_ERROR' as const;

export const GET_TOTAL_NEW_USER_STATISTICS = 'serviceAnalytics/dashboard/GET_TOTAL_NEW_USER_STATISTICS' as const;
export const GET_TOTAL_NEW_USER_STATISTICS_SUCCESS = 'serviceAnalytics/dashboard/GET_TOTAL_NEW_USER_STATISTICS_SUCCESS' as const;
export const GET_TOTAL_NEW_USER_STATISTICS_ERROR = 'serviceAnalytics/dashboard/GET_TOTAL_NEW_USER_STATISTICS_ERROR' as const;

export const GET_TOTAL_PAGE_VIEW_STATISTICS = 'serviceAnalytics/dashboard/GET_TOTAL_PAGE_VIEW_STATISTICS' as const;
export const GET_TOTAL_PAGE_VIEW_STATISTICS_SUCCESS = 'serviceAnalytics/dashboard/GET_TOTAL_PAGE_VIEW_STATISTICS_SUCCESS' as const;
export const GET_TOTAL_PAGE_VIEW_STATISTICS_ERROR = 'serviceAnalytics/dashboard/GET_TOTAL_PAGE_VIEW_STATISTICS_ERROR' as const;

export const GET_TOTAL_SESSION_STATISTICS = 'serviceAnalytics/dashboard/GET_TOTAL_SESSION_STATISTICS' as const;
export const GET_TOTAL_SESSION_STATISTICS_SUCCESS = 'serviceAnalytics/dashboard/GET_TOTAL_SESSION_STATISTICS_SUCCESS' as const;
export const GET_TOTAL_SESSION_STATISTICS_ERROR = 'serviceAnalytics/dashboard/GET_TOTAL_SESSION_STATISTICS_ERROR' as const;

export const GET_PV_PER_SESSION = 'serviceAnalytics/dashboard/GET_PV_PER_SESSION' as const;
export const GET_PV_PER_SESSION_SUCCESS = 'serviceAnalytics/dashboard/GET_PV_PER_SESSION_SUCCESS' as const;
export const GET_PV_PER_SESSION_ERROR = 'serviceAnalytics/dashboard/GET_PV_PER_SESSION_ERROR' as const;

export const GET_SESSION_PER_UV = 'serviceAnalytics/dashboard/GET_SESSION_PER_UV' as const;
export const GET_SESSION_PER_UV_SUCCESS = 'serviceAnalytics/dashboard/GET_SESSION_PER_UV_SUCCESS' as const;
export const GET_SESSION_PER_UV_ERROR = 'serviceAnalytics/dashboard/GET_SESSION_PER_UV_ERROR' as const;

export const GET_SESSION_DURATION_PER_SESSION = 'serviceAnalytics/dashboard/GET_SESSION_DURATION_PER_SESSION' as const;
export const GET_SESSION_DURATION_PER_SESSION_SUCCESS = 'serviceAnalytics/dashboard/GET_SESSION_DURATION_PER_SESSION_SUCCESS' as const;
export const GET_SESSION_DURATION_PER_SESSION_ERROR = 'serviceAnalytics/dashboard/GET_SESSION_DURATION_PER_SESSION_ERROR' as const;

export const SET_ERROR_NULL_SERVICE_DASHBOARD =
  'serviceAnalytics/dashboard/SET_ERROR_NULL_SERVICE_DASHBOARD';

export const getTotalUserStatisticsAsync = createAsyncAction(
  GET_TOTAL_USER_STATISTICS,
  GET_TOTAL_USER_STATISTICS_SUCCESS,
  GET_TOTAL_USER_STATISTICS_ERROR
)<
  GetDashboardStatisticsParamType,
  GetDashboardStatisticsResponseType,
  AxiosErrorType
>();

export const getTotalNewUserStatisticsAsync = createAsyncAction(
  GET_TOTAL_NEW_USER_STATISTICS,
  GET_TOTAL_NEW_USER_STATISTICS_SUCCESS,
  GET_TOTAL_NEW_USER_STATISTICS_ERROR
)<
  GetDashboardStatisticsParamType,
  GetDashboardStatisticsResponseType,
  AxiosErrorType
>();

export const getTotalPageViewStatisticsAsync = createAsyncAction(
  GET_TOTAL_PAGE_VIEW_STATISTICS,
  GET_TOTAL_PAGE_VIEW_STATISTICS_SUCCESS,
  GET_TOTAL_PAGE_VIEW_STATISTICS_ERROR
)<
  GetDashboardStatisticsParamType,
  GetDashboardStatisticsResponseType,
  AxiosErrorType
>();

export const getTotalSessionStatisticsAsync = createAsyncAction(
  GET_TOTAL_SESSION_STATISTICS,
  GET_TOTAL_SESSION_STATISTICS_SUCCESS,
  GET_TOTAL_SESSION_STATISTICS_ERROR
)<
  GetDashboardStatisticsParamType,
  GetDashboardStatisticsResponseType,
  AxiosErrorType
>();

export const getPVPerSessionAsync = createAsyncAction(
  GET_PV_PER_SESSION,
  GET_PV_PER_SESSION_SUCCESS,
  GET_PV_PER_SESSION_ERROR
)<
  GetPVMeanStatisticsParamType,
  GetPVMeanStatisticsResponseType,
  AxiosErrorType
>();

export const getSessionPerUVAsync = createAsyncAction(
  GET_SESSION_PER_UV,
  GET_SESSION_PER_UV_SUCCESS,
  GET_SESSION_PER_UV_ERROR
)<
  GetUVMeanStatisticsParamType,
  GetUVMeanStatisticsResponseType,
  AxiosErrorType
>();

export const getSessionDurationPerSessionAsync = createAsyncAction(
  GET_SESSION_DURATION_PER_SESSION,
  GET_SESSION_DURATION_PER_SESSION_SUCCESS,
  GET_SESSION_DURATION_PER_SESSION_ERROR
)<
  GetUsageMeanStatisticsParamType,
  GetUsageMeanStatisticsResponseType,
  AxiosErrorType
>();

export const setErrorNullServiceDashboard = createAction(
  SET_ERROR_NULL_SERVICE_DASHBOARD
)();
