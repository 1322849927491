import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';

import {
  GetAIVORYTopicUsageWordcloudParamType,
  GetAIVORYTopicUsageWordcloudResponseType,
} from '../../../api/aivoryUsageAnalytics/contentKeywordAnalysis/type';

export const GET_AIVORY_TOPIC_USAGE_WORDCLOUD = 'aivoryUsageAnalytics/contentKeywordAnalysis/GET_AIVORY_TOPIC_USAGE_WORDCLOUD' as const;
export const GET_AIVORY_TOPIC_USAGE_WORDCLOUD_SUCCESS = 'aivoryUsageAnalytics/contentKeywordAnalysis/GET_AIVORY_TOPIC_USAGE_WORDCLOUD_SUCCESS' as const;
export const GET_AIVORY_TOPIC_USAGE_WORDCLOUD_ERROR = 'aivoryUsageAnalytics/contentKeywordAnalysis/GET_AIVORY_TOPIC_USAGE_WORDCLOUD_ERROR' as const;

export const SET_ERROR_NULL_AIVORY_CONTENT_KEYWORD =
  'aivoryUsageAnalytics/contentKeywordAnalysis/SET_ERROR_NULL_AIVORY_CONTENT_KEYWORD';

export const getAIVORYTopicUsageWordcloudAsync = createAsyncAction(
  GET_AIVORY_TOPIC_USAGE_WORDCLOUD,
  GET_AIVORY_TOPIC_USAGE_WORDCLOUD_SUCCESS,
  GET_AIVORY_TOPIC_USAGE_WORDCLOUD_ERROR
)<
  GetAIVORYTopicUsageWordcloudParamType,
  GetAIVORYTopicUsageWordcloudResponseType,
  AxiosErrorType
>();

export const setErrorNullAivoryContentKeyword = createAction(
  SET_ERROR_NULL_AIVORY_CONTENT_KEYWORD
)();
