import React, { useState, memo, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ScrollEventsPayload } from '../../../../api/pageAnalytics/uxAnalytics/scroll/type';

const ani = ($length: number) => keyframes`
  0% {
    stroke-dashoffset: ${$length}; 
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const FadeinAni = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Component = styled.svg<{ $pathLength: number }>`
  ${(props) => {
    if (props.$pathLength > 0) {
      return css`
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        stroke-dasharray: ${props.$pathLength};
        stroke-dashoffset: 0;
        /* animation: ${ani(props.$pathLength)} 3s linear alternate; */
        animation: ${ani(props.$pathLength)} 0.3s linear alternate;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

const FadeInCompo = styled.svg`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  animation: ${FadeinAni} 3s alternate;
`;

interface UXHeatmapLineChartProps {
  data: ScrollEventsPayload | null;
  barHeight: number;
  gradientWidth: number;
}

const UXHeatmapLineChart = ({
  data,
  barHeight,
  gradientWidth,
}: UXHeatmapLineChartProps) => {
  const ComponentEl = useRef<SVGPathElement>(null);

  const [pathLength, setPathLength] = useState(0);

  const genLine = () => {
    let d = 'M1,0';
    const max = 0.8;
    let maxTime = 0;
    let sumHeight = 0;
    let prevX = 1;
    let prevY = 0;
    if (data) {
      data.scroll_usability.forEach((element) => {
        maxTime = Math.max(element.avg_attention_time, maxTime);
      });
      data.scroll_usability.forEach((element, index) => {
        const timeRate = (element.avg_attention_time / maxTime) * 100;
        let realWidth = (gradientWidth * (timeRate * max)) / 100;

        if (realWidth === 0) {
          realWidth = 1;
        }

        // d += `L${realWidth},${barHeight / 2 + sumHeight} `;

        d += `C${prevX},${prevY + 10} ${realWidth},${
          barHeight / 2 + sumHeight - 10
        } ${realWidth},${barHeight / 2 + sumHeight} `;

        if (data.scroll_usability.length - 1 === index) {
          d += `L1,${sumHeight + barHeight} `;
        }

        prevX = realWidth;
        prevY = barHeight / 2 + sumHeight;
        sumHeight += barHeight;
      });
    }
    return d;
  };

  useEffect(() => {
    const { current } = ComponentEl;
    if (current) {
      setPathLength(current.getTotalLength());
    }
  }, [ComponentEl.current]);

  return (
    // <Component $pathLength={pathLength}>
    //   <path
    //     ref={ComponentEl}
    //     d={genLine()}
    //     stroke="white"
    //     strokeWidth="2"
    //     fill="none"
    //   />
    // </Component>
    <FadeInCompo>
      <path d={genLine()} stroke="white" strokeWidth="2" fill="none" />
    </FadeInCompo>
  );
};

export default memo(UXHeatmapLineChart);
