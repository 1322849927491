import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../modules';

import {
  getConfirmPasswordAsync,
  GET_CONFIRM_PASSWORD,
} from '../../../modules/myPage';
import { GetConfirmPasswordParamType } from '../../../api/myPage/type';

import Header from '../../../components/Organisms/Header';
import Input from '../../../components/Atoms/Input';
import { P2, P3, H5 } from '../../../components/Atoms/Typo';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Spinner from '../../../components/Molecule/Spinner';

const WarningMessage = styled.div`
  display: flex;
  flex-direction: column;
  height: 45px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 516px;
  margin-top: 40px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  &:nth-child(2) {
    margin-bottom: 80px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

const FormItemTitle = styled(H5)`
  margin-bottom: 8px;
`;

const FormButton = styled.button`
  ${P2} {
    color: white;
  }
  width: 100%;
  height: 55px;
  border-radius: 8px;
  background-color: #333333;
  border: none;
  cursor: pointer;
`;

const ConfirmPassword = () => {
  const loading = useSelector((state: RootState) => state.loading);
  const { userId } = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch();

  const onGetConfirmPassword = (info: GetConfirmPasswordParamType) => {
    dispatch(getConfirmPasswordAsync.request(info));
  };

  const [password, setPassword] = useState('');
  const onClickSubmitButton = () => {
    onGetConfirmPassword({
      password,
    });
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  return (
    <ContentsLayout>
      <Header title="비밀번호 확인" isDatePicker={false} />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <WarningMessage>
            <P3>
              회원님의 소중한 개인정보 보호를 위해 비밀번호를 한번 더 확인하고
              있습니다.
            </P3>
            <P3>
              공공장소에서 PC를 사용중일 경우, 비밀번호가 타인에게 노출되지
              않도록 주의해 주시기 바랍니다.
            </P3>
          </WarningMessage>
          <Form>
            <FormItem>
              <FormItemTitle>아이디</FormItemTitle>

              <Input
                value={userId}
                errorMessage=""
                placeholder={userId}
                isDisabled
                type="text"
                name="voda_user_id"
                size="large"
                isBlock
              />
            </FormItem>
            <FormItem>
              <FormItemTitle>비밀번호</FormItemTitle>

              <Input
                value={password}
                errorMessage="비밀번호를 확인해주세요."
                type="password"
                name="voda_password"
                onChange={onChangePassword}
                size="large"
                isBlock
              />
            </FormItem>
            <FormItem>
              {loading[GET_CONFIRM_PASSWORD] ? (
                <Spinner />
              ) : (
                <FormButton
                  type="submit"
                  onClick={(event) => {
                    event.preventDefault();
                    onClickSubmitButton();
                  }}
                >
                  <P2>확인</P2>
                </FormButton>
              )}
            </FormItem>
          </Form>
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default ConfirmPassword;
