import React, { ReactNodeArray, useEffect, useState } from 'react';
import produce from 'immer';
import { useSelector, useDispatch } from 'react-redux';

// component

import Input from '../../../components/Atoms/Input';
import Spinner from '../../../components/Molecule/Spinner';

// redux
import { RootState } from '../../../modules';
import {
  getConfirmPasswordChangeAsync,
  GET_CONFIRM_PASSWORD_CHANGE,
  setIsSendStatus,
  setUserInfoAsync,
  setUserPasswordAsync,
  SET_USER_INFO,
  SET_USER_PASSWORD,
} from '../../../modules/myPage';
import {
  GetConfirmPasswordParamType,
  SetUserInfoParamType,
  SetUserPasswordParamType,
} from '../../../api/myPage/type';
import {
  getCheckSessionAsync,
  setLogoutAsync,
} from '../../../modules/authentication';

// style
import {
  Form,
  FormButton,
  FormButtonCancel,
  FormButtonWrapper,
  FormItem,
  FormItemTitle,
  ModalBackBlur,
  ModalBody,
  ModalSplit,
  ModalWrapper,
  Modalbutton,
  TabButton,
  TabButtonBorderBottom,
  TabButtonTitle,
  TabWrapper,
} from './styled';
import Header from '../../../components/Organisms/Header';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import { P2 } from '../../../components/Atoms/Typo';

const EditUserInfo = () => {
  const { userId, user_name, user_email } = useSelector(
    (state: RootState) => state.authentication
  );
  const loading = useSelector((state: RootState) => state.loading);
  const {
    isConfirmPasswordChange,
    isSetUserPassword,
    isSetUserInfo,
  } = useSelector((state: RootState) => state.myPage);
  const dispatch = useDispatch();

  const onGetConfirmPasswordChange = (info: GetConfirmPasswordParamType) => {
    dispatch(getConfirmPasswordChangeAsync.request(info));
  };

  const onSetUserInfo = (info: SetUserInfoParamType) => {
    dispatch(setUserInfoAsync.request(info));
  };

  const onSetUserPassword = (info: SetUserPasswordParamType) => {
    dispatch(setUserPasswordAsync.request(info));
  };

  const onGetCheckSession = () => {
    dispatch(getCheckSessionAsync.request());
  };

  const onSetLogout = () => {
    dispatch(setLogoutAsync.request());
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordChangeMenu, setIsPasswordChangeMenu] = useState(false);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [editInfoinputRule, setEditInfoInputRule] = useState<{
    voda_user_name: null | boolean;
    voda_user_email: null | boolean;
  }>({
    voda_user_name: null,
    voda_user_email: null,
  });

  const [changePasswordinputRule, setChangePasswordInputRule] = useState<{
    voda_password: null | boolean;
    voda_password_new: null | boolean;
    voda_password_confirm: null | boolean;
  }>({
    voda_password: null,
    voda_password_new: null,
    voda_password_confirm: null,
  });

  const [userNameInputErrorMessage, setUserNameInpuErrorMessage] = useState(
    '필수사항 입니다.'
  );

  const [emailInputErrorMessage, setEmailInpuErrorMessage] = useState(
    '올바른 이메일 형식이 아닙니다.'
  );

  const onClickCancel = () => {};

  useEffect(() => {
    setUserName(user_name);
    setEmail(user_email);
  }, [user_name, user_email]);

  useEffect(() => {
    return () => {
      setIsModalOpen(false);
      dispatch(setIsSendStatus());
    };
  }, []);

  useEffect(() => {
    if (!loading[SET_USER_INFO] && isSetUserInfo) {
      setIsModalOpen(true);
    }
  }, [loading[SET_USER_INFO], isSetUserInfo]);

  useEffect(() => {
    if (!loading[SET_USER_PASSWORD] && isSetUserPassword) {
      setIsModalOpen(true);
    }
  }, [loading[SET_USER_PASSWORD], isSetUserPassword]);

  useEffect(() => {
    if (isConfirmPasswordChange) {
      setChangePasswordInputRule(
        produce((draft) => {
          draft.voda_password = null;
        })
      );
      if (
        changePasswordinputRule.voda_password_new !== false &&
        changePasswordinputRule.voda_password_confirm !== false
      ) {
        onSetUserPassword({
          password: newPassword,
          password_confirm: confirmPassword,
        });
      }
    } else if (isConfirmPasswordChange === false) {
      setChangePasswordInputRule(
        produce((draft) => {
          draft.voda_password = false;
        })
      );
    }
  }, [isConfirmPasswordChange]);

  // const validationCheckCurrentPassword = (value: string): boolean => {
  //   return true;
  // };

  const validationCheckUserName = (value: string): boolean | null => {
    const blank_pattern = /([^ ])/g;

    if (value.length > 50) {
      setUserNameInpuErrorMessage('50자 이하로 입력하세요.');
      return false;
    }
    if (value !== '' && blank_pattern.test(value)) {
      return null;
    }
    setUserNameInpuErrorMessage('필수사항 입니다.');
    return false;
  };

  const validationCheckEmail = (value: string): boolean | null => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (value.length > 150) {
      setEmailInpuErrorMessage('150자 이하로 입력하세요.');
      return false;
    }
    if (pattern.test(value)) {
      return null;
    }
    setEmailInpuErrorMessage('올바른 이메일 형식이 아닙니다.');
    return false;
  };

  const validationCheckNewPassword = (value: string): boolean => {
    const password_policy1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]).{8,16}$/;
    const password_policy2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,16}$/;
    const password_policy3 = /^(?=.*[a-z])(?=.*[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"])(?=.*[0-9]).{8,16}$/;
    const password_policy4 = /^(?=.*[A-Z])(?=.*[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"])(?=.*[0-9]).{8,16}$/;

    if (
      !(
        password_policy1.test(value) ||
        password_policy2.test(value) ||
        password_policy3.test(value) ||
        password_policy4.test(value)
      )
    ) {
      return false;
    }
    return true;
  };

  const validationCheckConfirmPassword = (
    newValue: string,
    confirmValue: string
  ): boolean => {
    if (newValue === confirmValue) {
      return true;
    }
    return false;
  };

  const onClickTabButton = (isPasswordChangeClick: boolean) => {
    setIsPasswordChangeMenu(isPasswordChangeClick);
    setUserName(user_name);
    setEmail(user_email);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const onChangeUserNameInput = (value: string) => {
    setUserName(value);
    setEditInfoInputRule(
      produce((draft) => {
        draft.voda_user_name = validationCheckUserName(value);
      })
    );
  };

  const onChangeEMailInput = (value: string) => {
    setEmail(value);
    setEditInfoInputRule(
      produce((draft) => {
        draft.voda_user_email = validationCheckEmail(value);
      })
    );
  };

  const onChangeNewPasswordInput = (value: string) => {
    setNewPassword(value);

    setChangePasswordInputRule(
      produce((draft) => {
        draft.voda_password_new = validationCheckNewPassword(value);
      })
    );

    setChangePasswordInputRule(
      produce((draft) => {
        draft.voda_password_confirm = validationCheckConfirmPassword(
          value,
          confirmPassword
        );
      })
    );
  };

  const onChangeConfirmPasswordInput = (value: string) => {
    setConfirmPassword(value);
    setChangePasswordInputRule(
      produce((draft) => {
        draft.voda_password_confirm = validationCheckConfirmPassword(
          newPassword,
          value
        );
      })
    );
  };

  const onChangeCurrentPasswordInput = (value: string) => {
    setCurrentPassword(value);
  };

  const onClickEditInformationSubmitButton = () => {
    if (
      validationCheckUserName(userName) !== false &&
      validationCheckEmail(email) !== false
    ) {
      onSetUserInfo({
        email,
        name: userName,
      });
    } else {
      onChangeUserNameInput(userName);
      onChangeEMailInput(email);
    }
  };

  const onClickChangePasswordSubmitButton = () => {
    onChangeNewPasswordInput(newPassword);
    onChangeConfirmPasswordInput(confirmPassword);
    onGetConfirmPasswordChange({ password: currentPassword });
  };

  const printMenuContents = () => {
    const result: ReactNodeArray = [];
    if (isPasswordChangeMenu) {
      result.push(
        <Form>
          <FormItem>
            <FormItemTitle>기존 비밀번호</FormItemTitle>
            <Input
              value={currentPassword}
              errorMessage="잘못된 비밀번호 입니다. 다시 입력해주세요."
              type="password"
              name="voda_password"
              size="large"
              isBlock
              status={
                changePasswordinputRule.voda_password === null
                  ? 'default'
                  : 'error'
              }
              onChange={onChangeCurrentPasswordInput}
            />
          </FormItem>
          <FormItem>
            <FormItemTitle>신규 비밀번호</FormItemTitle>
            <Input
              value={newPassword}
              errorMessage="영문 대소문자, 숫자, 특수문자를 조합하여 입력해주세요."
              type="password"
              name="voda_password_new"
              size="large"
              isBlock
              status={
                changePasswordinputRule.voda_password_new ? 'default' : 'error'
              }
              onChange={onChangeNewPasswordInput}
            />
          </FormItem>
          <FormItem>
            <FormItemTitle>신규 비밀번호 확인</FormItemTitle>
            <Input
              value={confirmPassword}
              errorMessage={
                changePasswordinputRule.voda_password_confirm
                  ? '입력한 신규 비밀번호와 일치합니다.'
                  : '입력한 신규비밀번호와 일치하지 않습니다'
              }
              type="password"
              name="voda_password_confirm"
              size="large"
              isBlock
              status={
                changePasswordinputRule.voda_password_confirm
                  ? 'success'
                  : 'error'
              }
              onChange={onChangeConfirmPasswordInput}
            />
          </FormItem>
          <FormItem>
            <FormButtonWrapper>
              <FormButtonCancel
                to="/"
                $isCancel
                onClick={() => {
                  onClickCancel();
                }}
              >
                <P2>취소</P2>
              </FormButtonCancel>
              <FormButton
                $isCancel={false}
                type="submit"
                onClick={(event) => {
                  event.preventDefault();
                  onClickChangePasswordSubmitButton();
                }}
              >
                {loading[SET_USER_PASSWORD] ||
                loading[GET_CONFIRM_PASSWORD_CHANGE] ? (
                  <Spinner />
                ) : (
                  <P2>확인</P2>
                )}
              </FormButton>
            </FormButtonWrapper>
          </FormItem>
        </Form>
      );
    } else {
      result.push(
        <Form>
          <FormItem>
            <FormItemTitle>아이디</FormItemTitle>
            <Input
              errorMessage=""
              value={userId}
              isDisabled
              type="text"
              name="voda_user_id"
              size="large"
              isBlock
              placeholder=""
            />
          </FormItem>
          <FormItem>
            <FormItemTitle>이름</FormItemTitle>
            <Input
              value={userName}
              errorMessage={userNameInputErrorMessage}
              type="text"
              name="voda_user_name"
              size="large"
              status={
                editInfoinputRule.voda_user_name === null ? 'default' : 'error'
              }
              isBlock
              onChange={onChangeUserNameInput}
            />
          </FormItem>
          <FormItem>
            <FormItemTitle>이메일</FormItemTitle>
            <Input
              value={email}
              errorMessage={emailInputErrorMessage}
              type="email"
              name="voda_user_email"
              size="large"
              status={
                editInfoinputRule.voda_user_email === null ? 'default' : 'error'
              }
              isBlock
              onChange={onChangeEMailInput}
            />
          </FormItem>
          <FormItem>
            <FormButtonWrapper>
              <FormButtonCancel
                to="/"
                $isCancel
                onClick={() => {
                  onClickCancel();
                }}
              >
                <P2>취소</P2>
              </FormButtonCancel>
              <FormButton
                $isCancel={false}
                type="submit"
                onClick={(event) => {
                  event.preventDefault();
                  onClickEditInformationSubmitButton();
                }}
              >
                {loading[SET_USER_INFO] ? <Spinner /> : <P2>확인</P2>}
              </FormButton>
            </FormButtonWrapper>
          </FormItem>
        </Form>
      );
    }
    return result;
  };
  return (
    <ContentsLayout>
      <ModalBackBlur $isModalOpen={isModalOpen} />
      <ModalWrapper $isModalOpen={isModalOpen}>
        <ModalBody>저장되었습니다.</ModalBody>
        <ModalSplit />
        <Modalbutton
          to="/"
          onClick={() => {
            if (isPasswordChangeMenu) {
              setIsModalOpen(false);
              onSetLogout();
            } else {
              setIsModalOpen(false);
              onGetCheckSession();
            }
          }}
        >
          확인
        </Modalbutton>
      </ModalWrapper>
      <Header title="마이페이지" isDatePicker={false} />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <TabWrapper>
            <TabButton
              $isSelect={!isPasswordChangeMenu}
              onClick={() => {
                onClickTabButton(false);
              }}
            >
              <TabButtonTitle>개인정보 수정</TabButtonTitle>
              <TabButtonBorderBottom />
            </TabButton>
            <TabButton
              $isSelect={isPasswordChangeMenu}
              onClick={() => {
                onClickTabButton(true);
              }}
            >
              <TabButtonTitle>비밀번호 변경</TabButtonTitle>
              <TabButtonBorderBottom />
            </TabButton>
          </TabWrapper>

          {printMenuContents()}
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default EditUserInfo;
