import { takeLatest } from 'redux-saga/effects';

import {
  getAIVORYSearchUsageAnalysisAsync,
  getAIVORYContentsUsageAnalysisAsync,
  GET_AIVORY_SEARCH_USAGE_ANALYSIS,
  GET_AIVORY_CONTENTS_USAGE_ANALYSIS,
} from './actions';

import {
  apiGetAIVORYContentsUsageAnalysis,
  apiGetAIVORYSearchUsageAnalysis,
} from '../../../api/aivoryUsageAnalytics/searchUsageAnalysis/api';

import createRequestSaga from '../../createRequestSaga';

const getAIVORYSearchUsageAnalysisSaga = createRequestSaga(
  getAIVORYSearchUsageAnalysisAsync,
  apiGetAIVORYSearchUsageAnalysis
);

const getAIVORYContentsUsageAnalysisSaga = createRequestSaga(
  getAIVORYContentsUsageAnalysisAsync,
  apiGetAIVORYContentsUsageAnalysis
);

export function* aivorySearchUsageAnalysisSaga() {
  yield takeLatest(
    GET_AIVORY_SEARCH_USAGE_ANALYSIS,
    getAIVORYSearchUsageAnalysisSaga
  );
  yield takeLatest(
    GET_AIVORY_CONTENTS_USAGE_ANALYSIS,
    getAIVORYContentsUsageAnalysisSaga
  );
}

export { aivorySearchUsageAnalysisSaga as default };
