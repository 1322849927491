import { takeLatest } from 'redux-saga/effects';
import { apiGetPageElementsFlow } from '../../../../api/pageAnalytics/uxAnalytics/mouseflow/api';

import createRequestSaga from '../../../createRequestSaga';
import { getPageElementsFlowAsync, GET_PAGE_ELEMENTS_FLOW } from './actions';

const getPageElementsFlowSaga = createRequestSaga(
  getPageElementsFlowAsync,
  apiGetPageElementsFlow
);

export function* mouseFlowSaga() {
  yield takeLatest(GET_PAGE_ELEMENTS_FLOW, getPageElementsFlowSaga);
}

export { mouseFlowSaga as default };
