import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import {
  ExternalNewsKeywordNetworkPayload,
  ExternalNewsKeywordWordcloudPayload,
} from '../../../../api/report/type';
import { P1, P3 } from '../../../Atoms/Typo';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import NetworkReportChart from '../../../Molecule/Chart/NetworkReportChart';
import WordCloudChart from '../../../Molecule/Chart/WordCloudChart';
import Spinner from '../../../Molecule/Spinner';

const Component = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 40px 24px;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
  .voda_report_title {
    margin-bottom: 24px;
  }
`;

const HashTagWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HashTag = styled(P1)`
  margin-bottom: 8px;
`;

const Body = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
`;

const InnerBody = styled.div`
  width: 100%;
  height: 100%;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
  margin-top: 24px;
  margin-bottom: 24px;
`;

interface ExternalReportCardProps {
  wordcloudData: ExternalNewsKeywordWordcloudPayload | null;
  networkData: ExternalNewsKeywordNetworkPayload | null;
  isLoading: boolean;
}

interface ExternalReportCardState {
  rebuildWordcloudData: {
    word: string;
    count: number;
    value: number;
  }[];
}

const ExternalReportCard = ({
  networkData,
  wordcloudData,
  isLoading,
}: ExternalReportCardProps) => {
  const [wordData, setWordData] = useState<
    ExternalReportCardState['rebuildWordcloudData']
  >([]);

  const [hashtag, setHashTag] = useState<string[]>([]);

  useEffect(() => {
    if (wordcloudData) {
      const hashTemp: string[] = [];
      wordcloudData.wordcloud.some((element, index) => {
        if (index > 2) {
          return true;
        }
        hashTemp.push(element.word);
        return false;
      });
      setHashTag(hashTemp);
      setWordData(wordcloudData.wordcloud);
    }
  }, [wordcloudData]);

  return (
    <Component>
      <Title title="우리는 외부에 어떤 모습일까요?" />
      <HashTagWrapper>
        <HashTag>
          {hashtag.map((element) => {
            return `#${element} `;
          })}
        </HashTag>
      </HashTagWrapper>
      {isLoading ? (
        <Spinner />
      ) : (
        <Body>
          <InnerBody>
            <WordCloudChart data={wordData} />
          </InnerBody>
          <Split />
          <InnerBody>
            <NetworkReportChart propsData={networkData || undefined} />
          </InnerBody>
        </Body>
      )}
    </Component>
  );
};

export default ExternalReportCard;
