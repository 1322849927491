import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetNationalSessionEnvStatisticsParamType,
  GetNationalSessionEnvStatisticsResponseType,
  GetNationalDeviceEnvStaticsParamType,
  GetNationalDeviceEnvStaticsResponseType,
  GetNationalOSEnvStaticsParamType,
  GetNationalOSEnvStaticsResponseType,
  GetNationalBrowserEnvStaticsParamType,
  GetNationalBrowserEnvStaticsResponseType,
  GetGlobalSessionEnvStatisticsParamType,
  GetGlobalSessionEnvStatisticsResponseType,
  GetGlobalDeviceEnvStaticsParamType,
  GetGlobalDeviceEnvStaticsResponseType,
  GetGlobalOSEnvStaticsParamType,
  GetGlobalOSEnvStaticsResponseType,
  GetGlobalBrowserEnvStaticsParamType,
  GetGlobalBrowserEnvStaticsResponseType,
} from '../../../api/serviceAnalytics/environmentAnalysis/type';

export const GET_NATIONAL_SESSION_ENV_STATISTICS = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_SESSION_ENV_STATISTICS' as const;
export const GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS' as const;
export const GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR' as const;

export const GET_NATIONAL_DEVICE_ENV_STATICS = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_DEVICE_ENV_STATICS' as const;
export const GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS' as const;
export const GET_NATIONAL_DEVICE_ENV_STATICS_ERROR = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_DEVICE_ENV_STATICS_ERROR' as const;

export const GET_NATIONAL_OS_ENV_STATICS = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_OS_ENV_STATICS' as const;
export const GET_NATIONAL_OS_ENV_STATICS_SUCCESS = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_OS_ENV_STATICS_SUCCESS' as const;
export const GET_NATIONAL_OS_ENV_STATICS_ERROR = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_OS_ENV_STATICS_ERROR' as const;

export const GET_NATIONAL_BROWSER_ENV_STATICS = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_BROWSER_ENV_STATICS' as const;
export const GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS' as const;
export const GET_NATIONAL_BROWSER_ENV_STATICS_ERROR = 'serviceAnalytics/environmentAnalysis/GET_NATIONAL_BROWSER_ENV_STATICS_ERROR' as const;

export const GET_GLOBAL_SESSION_ENV_STATISTICS = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_SESSION_ENV_STATISTICS' as const;
export const GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS' as const;
export const GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR' as const;

export const GET_GLOBAL_DEVICE_ENV_STATICS = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_DEVICE_ENV_STATICS' as const;
export const GET_GLOBAL_DEVICE_ENV_STATICS_SUCCESS = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_DEVICE_ENV_STATICS_SUCCESS' as const;
export const GET_GLOBAL_DEVICE_ENV_STATICS_ERROR = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_DEVICE_ENV_STATICS_ERROR' as const;

export const GET_GLOBAL_OS_ENV_STATICS = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_OS_ENV_STATICS' as const;
export const GET_GLOBAL_OS_ENV_STATICS_SUCCESS = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_OS_ENV_STATICS_SUCCESS' as const;
export const GET_GLOBAL_OS_ENV_STATICS_ERROR = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_OS_ENV_STATICS_ERROR' as const;

export const GET_GLOBAL_BROWSER_ENV_STATICS = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_BROWSER_ENV_STATICS' as const;
export const GET_GLOBAL_BROWSER_ENV_STATICS_SUCCESS = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_BROWSER_ENV_STATICS_SUCCESS' as const;
export const GET_GLOBAL_BROWSER_ENV_STATICS_ERROR = 'serviceAnalytics/environmentAnalysis/GET_GLOBAL_BROWSER_ENV_STATICS_ERROR' as const;

export const SET_ERROR_NULL_SERVICE_ENV =
  'serviceAnalytics/environmentAnalysis/SET_ERROR_NULL_SERVICE_ENV';

export const getNationalSessionEnvStatisticsAsync = createAsyncAction(
  GET_NATIONAL_SESSION_ENV_STATISTICS,
  GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS,
  GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR
)<
  GetNationalSessionEnvStatisticsParamType,
  GetNationalSessionEnvStatisticsResponseType,
  AxiosErrorType
>();

export const getNationalDeviceEnvStaticsAsync = createAsyncAction(
  GET_NATIONAL_DEVICE_ENV_STATICS,
  GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS,
  GET_NATIONAL_DEVICE_ENV_STATICS_ERROR
)<
  GetNationalDeviceEnvStaticsParamType,
  GetNationalDeviceEnvStaticsResponseType,
  AxiosErrorType
>();

export const getNationalOSEnvStaticsAsync = createAsyncAction(
  GET_NATIONAL_OS_ENV_STATICS,
  GET_NATIONAL_OS_ENV_STATICS_SUCCESS,
  GET_NATIONAL_OS_ENV_STATICS_ERROR
)<
  GetNationalOSEnvStaticsParamType,
  GetNationalOSEnvStaticsResponseType,
  AxiosErrorType
>();

export const getNationalBrowserEnvStaticsAsync = createAsyncAction(
  GET_NATIONAL_BROWSER_ENV_STATICS,
  GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS,
  GET_NATIONAL_BROWSER_ENV_STATICS_ERROR
)<
  GetNationalBrowserEnvStaticsParamType,
  GetNationalBrowserEnvStaticsResponseType,
  AxiosErrorType
>();

export const getGlobalSessionEnvStatisticsAsync = createAsyncAction(
  GET_GLOBAL_SESSION_ENV_STATISTICS,
  GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS,
  GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR
)<
  GetGlobalSessionEnvStatisticsParamType,
  GetGlobalSessionEnvStatisticsResponseType,
  AxiosErrorType
>();

export const getGlobalDeviceEnvStaticsAsync = createAsyncAction(
  GET_GLOBAL_DEVICE_ENV_STATICS,
  GET_GLOBAL_DEVICE_ENV_STATICS_SUCCESS,
  GET_GLOBAL_DEVICE_ENV_STATICS_ERROR
)<
  GetGlobalDeviceEnvStaticsParamType,
  GetGlobalDeviceEnvStaticsResponseType,
  AxiosErrorType
>();

export const getGlobalOSEnvStaticsAsync = createAsyncAction(
  GET_GLOBAL_OS_ENV_STATICS,
  GET_GLOBAL_OS_ENV_STATICS_SUCCESS,
  GET_GLOBAL_OS_ENV_STATICS_ERROR
)<
  GetGlobalOSEnvStaticsParamType,
  GetGlobalOSEnvStaticsResponseType,
  AxiosErrorType
>();

export const getGlobalBrowserEnvStaticsAsync = createAsyncAction(
  GET_GLOBAL_BROWSER_ENV_STATICS,
  GET_GLOBAL_BROWSER_ENV_STATICS_SUCCESS,
  GET_GLOBAL_BROWSER_ENV_STATICS_ERROR
)<
  GetGlobalBrowserEnvStaticsParamType,
  GetGlobalBrowserEnvStaticsResponseType,
  AxiosErrorType
>();

export const setErrorNullServiceEnv = createAction(
  SET_ERROR_NULL_SERVICE_ENV
)();
