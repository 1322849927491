import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  GetPageElementsFlowParamType,
  GetPageElementsFlowResponseType,
} from '../../../../api/pageAnalytics/uxAnalytics/mouseflow/type';
import { AxiosErrorType } from '../../../createRequestSaga';

export const GET_PAGE_ELEMENTS_FLOW = 'pageAnalytics/UXAnalytics/MouseFlow/GET_PAGE_ELEMENTS_FLOW' as const;
export const GET_PAGE_ELEMENTS_FLOW_SUCCESS = 'pageAnalytics/UXAnalytics/MouseFlow/GET_PAGE_ELEMENTS_FLOW_SUCCESS' as const;
export const GET_PAGE_ELEMENTS_FLOW_ERROR = 'pageAnalytics/UXAnalytics/MouseFlow/GET_PAGE_ELEMENTS_FLOW_ERROR' as const;

export const SET_TARGET_RANK = 'pageAnalytics/UXAnalytics/MouseFlow/SET_TARGET_ELEMENT_ID' as const;

export const SET_ERROR_NULL = 'pageAnalytics/UXAnalytics/MouseFlow/SET_ERROR_NULL' as const;

export const getPageElementsFlowAsync = createAsyncAction(
  GET_PAGE_ELEMENTS_FLOW,
  GET_PAGE_ELEMENTS_FLOW_SUCCESS,
  GET_PAGE_ELEMENTS_FLOW_ERROR
)<
  GetPageElementsFlowParamType,
  GetPageElementsFlowResponseType,
  AxiosErrorType
>();

export const setTargetRank = createAction(SET_TARGET_RANK)<number>();

export const setErrorNull = createAction(SET_ERROR_NULL)();
