import React from 'react';
import styled from 'styled-components';
import { H4, P4 } from '../../../Atoms/Typo';

const Component = styled.div``;

const TitleEng = styled.div`
  ${P4} {
    color: ${(props) => props.theme.colors.grey500};
  }
`;

const TitleKor = styled.div`
  ${H4} {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grey600};
  }
  margin-top: 8px;
`;

interface EngKorCardHeadingProps {
  titleEng: string;
  titleKor: string;
}

const EngKorCardHeading = ({ titleEng, titleKor }: EngKorCardHeadingProps) => {
  return (
    <Component>
      <TitleEng>
        <P4>{titleEng.toUpperCase()}</P4>
      </TitleEng>
      <TitleKor>
        <H4>{titleKor}</H4>
      </TitleKor>
    </Component>
  );
};

export default EngKorCardHeading;
