import { createReducer } from 'typesafe-actions';
import {
  GET_SESSION_COUNT,
  GET_SESSION_COUNT_SUCCESS,
  GET_SESSION_COUNT_ERROR,
  GET_WEEKLY_SESSION_COUNT,
  GET_WEEKLY_SESSION_COUNT_SUCCESS,
  GET_WEEKLY_SESSION_COUNT_ERROR,
  SET_ERROR_NULL_SERVICE_SESSION,
} from './actions';
import { sessionAnalysisAction, sessionAnalysisState } from './types';

const initialState: sessionAnalysisState = {
  isGetSCLoading: false,
  isGetWSCLoading: false,
  SCData: null,
  WSCData: null,
  sessionAnalysisError: null,
};

const reducer = createReducer<sessionAnalysisState, sessionAnalysisAction>(
  initialState,
  {
    [GET_SESSION_COUNT]: (state) => ({
      ...state,
      isGetSCLoading: true,
      SCData: null,
      sessionAnalysisError: null,
    }),
    [GET_SESSION_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      isGetSCLoading: false,
      SCData: action.payload.payload,
    }),
    [GET_SESSION_COUNT_ERROR]: (state, action) => ({
      ...state,
      isGetSCLoading: false,
      SCData: null,
      sessionAnalysisError: action.payload,
    }),

    [GET_WEEKLY_SESSION_COUNT]: (state) => ({
      ...state,
      isGetWSCLoading: true,
      WSCData: null,
      sessionAnalysisError: null,
    }),
    [GET_WEEKLY_SESSION_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      isGetWSCLoading: false,
      WSCData: action.payload.payload,
    }),
    [GET_WEEKLY_SESSION_COUNT_ERROR]: (state, action) => ({
      ...state,
      isGetWSCLoading: false,
      WSCData: null,
      sessionAnalysisError: action.payload,
    }),

    [SET_ERROR_NULL_SERVICE_SESSION]: (state) => ({
      ...state,
      sessionAnalysisError: null,
    }),
  }
);

export default reducer;
