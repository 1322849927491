import { takeLatest } from 'redux-saga/effects';
import {
  apiGetBotTrafficPage,
  apiGetBotTrafficSite,
  apiGetExternalNewsKeywordNetwork,
  apiGetExternalNewsKeywordWordcloud,
  apiGetExternalReferrerSearch,
  apiGetExternalReferrerSNS,
  apiGetForecastPV,
  apiGetForecastUV,
  apiGetLocalPV,
  apiGetLocalRegion,
  apiGetLocalUV,
  apiGetNewVSReturnDuration,
  apiGetNewVSReturnPV,
  apiGetNewVSReturnUV,
  apiGetPageUsabilityClickRate,
  apiGetPageUsabilityPageDuration,
  apiGetPopularPageGrowth,
  apiGetPopularPageMost,
  apiGetSearchWordGrowth,
  apiGetSearchWordMost,
  apiGetServiceActivationPV,
  apiGetServiceActivationUV,
  apiGetSimilarAgencyPV,
  apiGetSimilarAgencyType,
  apiGetSimilarAgencyUV,
  apiGetSimilarScalePV,
  apiGetSimilarScaleType,
} from '../../api/report/api';

import createRequestSaga from '../createRequestSaga';
import {
  getBotTrafficPageAsync,
  getBotTrafficSiteAsync,
  getExternalNewsKeywordNetworkAsync,
  getExternalNewsKeywordWordcloudAsync,
  getExternalReferrerSearchAsync,
  getExternalReferrerSNSAsync,
  getForecastPVAsync,
  getForecastUVAsync,
  getLocalPVAsync,
  getLocalRegionAsync,
  getLocalUVAsync,
  getNewVSReturnDurationAsync,
  getNewVSReturnPVAsync,
  getNewVSReturnUVAsync,
  getPageUsabilityClickRateAsync,
  getPageUsabilityPageDurationAsync,
  getPopularPageGrowthAsync,
  getPopularPageMostAsync,
  getSearchWordGrowthAsync,
  getSearchWordMostAsync,
  getServiceActivationPVAsync,
  getServiceActivationUVAsync,
  getSimilarAgencyPVAsync,
  getSimilarAgencyTypeAsync,
  getSimilarAgencyUVAsync,
  getSimilarScalePVAsync,
  getSimilarScaleTypeAsync,
  GET_BOT_TRAFFIC_PAGE,
  GET_BOT_TRAFFIC_SITE,
  GET_EXTERNAL_NEWS_KEYWORD_NETWORK,
  GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD,
  GET_EXTERNAL_REFERRER_SEARCH,
  GET_EXTERNAL_REFERRER_SNS,
  GET_FORECAST_PV,
  GET_FORECAST_UV,
  GET_LOCAL_PV,
  GET_LOCAL_REGION,
  GET_LOCAL_UV,
  GET_NEW_VS_RETURN_DURATION,
  GET_NEW_VS_RETURN_PV,
  GET_NEW_VS_RETURN_UV,
  GET_PAGE_USABILITY_CLICK_RATE,
  GET_PAGE_USABILITY_PAGE_DURATION,
  GET_POPULAR_PAGE_GROWTH,
  GET_POPULAR_PAGE_MOST,
  GET_SEARCH_WORD_GROWTH,
  GET_SEARCH_WORD_MOST,
  GET_SERVICE_ACTIVATION_PV,
  GET_SERVICE_ACTIVATION_UV,
  GET_SIMILAR_AGENCY_PV,
  GET_SIMILAR_AGENCY_TYPE,
  GET_SIMILAR_AGENCY_UV,
  GET_SIMILAR_SCALE_PV,
  GET_SIMILAR_SCALE_TYPE,
} from './actions';

const getServiceActivationUVSaga = createRequestSaga(
  getServiceActivationUVAsync,
  apiGetServiceActivationUV
);

const getServiceActivationPVSaga = createRequestSaga(
  getServiceActivationPVAsync,
  apiGetServiceActivationPV
);

const getNewVSReturnUVSaga = createRequestSaga(
  getNewVSReturnUVAsync,
  apiGetNewVSReturnUV
);

const getNewVSReturnDurationSaga = createRequestSaga(
  getNewVSReturnDurationAsync,
  apiGetNewVSReturnDuration
);

const getNewVSReturnPVSaga = createRequestSaga(
  getNewVSReturnPVAsync,
  apiGetNewVSReturnPV
);

const getPopularPageMostSaga = createRequestSaga(
  getPopularPageMostAsync,
  apiGetPopularPageMost
);

const getPopularPageGrowthSaga = createRequestSaga(
  getPopularPageGrowthAsync,
  apiGetPopularPageGrowth
);

const getSearchWordMostSaga = createRequestSaga(
  getSearchWordMostAsync,
  apiGetSearchWordMost
);

const getSearchWordGrowthSaga = createRequestSaga(
  getSearchWordGrowthAsync,
  apiGetSearchWordGrowth
);

const getLocalRegionSaga = createRequestSaga(
  getLocalRegionAsync,
  apiGetLocalRegion
);

const getLocalUVSaga = createRequestSaga(getLocalUVAsync, apiGetLocalUV);

const getLocalPVSaga = createRequestSaga(getLocalPVAsync, apiGetLocalPV);

const getSimilarAgencyTypeSaga = createRequestSaga(
  getSimilarAgencyTypeAsync,
  apiGetSimilarAgencyType
);

const getSimilarAgencyUVSaga = createRequestSaga(
  getSimilarAgencyUVAsync,
  apiGetSimilarAgencyUV
);

const getSimilarAgencyPVSaga = createRequestSaga(
  getSimilarAgencyPVAsync,
  apiGetSimilarAgencyPV
);

const getSimilarScaleTypeSaga = createRequestSaga(
  getSimilarScaleTypeAsync,
  apiGetSimilarScaleType
);

const getSimilarScalePVSaga = createRequestSaga(
  getSimilarScalePVAsync,
  apiGetSimilarScalePV
);

const getForecastUVSaga = createRequestSaga(
  getForecastUVAsync,
  apiGetForecastUV
);

const getForecastPVSaga = createRequestSaga(
  getForecastPVAsync,
  apiGetForecastPV
);

const getPageUsabilityClickRateSaga = createRequestSaga(
  getPageUsabilityClickRateAsync,
  apiGetPageUsabilityClickRate
);

const getPageUsabilityPageDurationSaga = createRequestSaga(
  getPageUsabilityPageDurationAsync,
  apiGetPageUsabilityPageDuration
);

const getExternalNewsKeywordWordcloudSaga = createRequestSaga(
  getExternalNewsKeywordWordcloudAsync,
  apiGetExternalNewsKeywordWordcloud
);

const getExternalNewsKeywordNetworkSaga = createRequestSaga(
  getExternalNewsKeywordNetworkAsync,
  apiGetExternalNewsKeywordNetwork
);

const getExternalReferrerSearchSaga = createRequestSaga(
  getExternalReferrerSearchAsync,
  apiGetExternalReferrerSearch
);

const getExternalReferrerSNSSaga = createRequestSaga(
  getExternalReferrerSNSAsync,
  apiGetExternalReferrerSNS
);

const getBotTrafficSiteSaga = createRequestSaga(
  getBotTrafficSiteAsync,
  apiGetBotTrafficSite
);

const getBotTrafficPageSaga = createRequestSaga(
  getBotTrafficPageAsync,
  apiGetBotTrafficPage
);

export function* reportSaga() {
  yield takeLatest(GET_SERVICE_ACTIVATION_UV, getServiceActivationUVSaga);
  yield takeLatest(GET_SERVICE_ACTIVATION_PV, getServiceActivationPVSaga);

  yield takeLatest(GET_NEW_VS_RETURN_UV, getNewVSReturnUVSaga);
  yield takeLatest(GET_NEW_VS_RETURN_DURATION, getNewVSReturnDurationSaga);
  yield takeLatest(GET_NEW_VS_RETURN_PV, getNewVSReturnPVSaga);

  yield takeLatest(GET_POPULAR_PAGE_MOST, getPopularPageMostSaga);
  yield takeLatest(GET_POPULAR_PAGE_GROWTH, getPopularPageGrowthSaga);

  yield takeLatest(GET_SEARCH_WORD_MOST, getSearchWordMostSaga);
  yield takeLatest(GET_SEARCH_WORD_GROWTH, getSearchWordGrowthSaga);

  yield takeLatest(GET_LOCAL_REGION, getLocalRegionSaga);
  yield takeLatest(GET_LOCAL_UV, getLocalUVSaga);
  yield takeLatest(GET_LOCAL_PV, getLocalPVSaga);

  yield takeLatest(GET_SIMILAR_AGENCY_TYPE, getSimilarAgencyTypeSaga);
  yield takeLatest(GET_SIMILAR_AGENCY_UV, getSimilarAgencyUVSaga);
  yield takeLatest(GET_SIMILAR_AGENCY_PV, getSimilarAgencyPVSaga);

  yield takeLatest(GET_SIMILAR_SCALE_TYPE, getSimilarScaleTypeSaga);
  yield takeLatest(GET_SIMILAR_SCALE_PV, getSimilarScalePVSaga);

  yield takeLatest(GET_FORECAST_UV, getForecastUVSaga);
  yield takeLatest(GET_FORECAST_PV, getForecastPVSaga);

  yield takeLatest(
    GET_PAGE_USABILITY_CLICK_RATE,
    getPageUsabilityClickRateSaga
  );
  yield takeLatest(
    GET_PAGE_USABILITY_PAGE_DURATION,
    getPageUsabilityPageDurationSaga
  );

  yield takeLatest(
    GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD,
    getExternalNewsKeywordWordcloudSaga
  );
  yield takeLatest(
    GET_EXTERNAL_NEWS_KEYWORD_NETWORK,
    getExternalNewsKeywordNetworkSaga
  );

  yield takeLatest(GET_EXTERNAL_REFERRER_SEARCH, getExternalReferrerSearchSaga);
  yield takeLatest(GET_EXTERNAL_REFERRER_SNS, getExternalReferrerSNSSaga);

  yield takeLatest(GET_BOT_TRAFFIC_SITE, getBotTrafficSiteSaga);
  yield takeLatest(GET_BOT_TRAFFIC_PAGE, getBotTrafficPageSaga);
}

export { reportSaga as default };
