import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as IconArrowFunnelSVG } from '../../../assets/icons/icon-arrow-funnel.svg';
import { ReactComponent as IconLaunchSVG } from '../../../assets/icons/icon-launch.svg';

// icon
const IconArrowFunnel = styled(IconArrowFunnelSVG)<{ $cardType: string }>`
  width: 8px;
  height: 12px;
  ${(props) => {
    switch (props.$cardType) {
      case 'conversion':
        return css`
          path {
            fill: ${props.theme.colors.orange5};
          }
        `;
      case 'exit':
        return css`
          path {
            fill: ${props.theme.colors.danger5};
          }
        `;
      default:
        return css``;
    }
  }}
`;

const IconLaunch = styled(IconLaunchSVG)`
  width: 100%;
  height: 100%;
`;

const ETCButton = styled.button<{ $isETC: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 24px;
  top: 50%;
  transform: translate(0%, -50%);
  width: 18px;
  height: 18px;
  ${(props) => {
    if (props.$isETC) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const ConversionArrow = styled.div<{ $cardType: string }>`
  position: absolute;
  display: flex;
  left: -5px;
  top: 50%;
  transform: translate(0%, -50%);
  width: 8px;
  height: 12px;
  ${(props) => {
    switch (props.$cardType) {
      case 'conversion':
        return css``;
      case 'exit':
        return css``;
      default:
        return css`
          display: none;
        `;
    }
  }}
`;

const FunnelCardTitle = styled.div`
  width: 100%;
  height: 19px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey800};
  font-size: 14px;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const FunnelCardRate = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.colors.grey500};
  font-size: 14px;
  line-height: normal;
`;

const FunnelCardContainer = styled.div<{ $cardType: string; $rate: number }>`
  position: relative;
  width: 350px;
  height: 78px;
  padding: 16px 24px;
  border-radius: 39px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0px;
  }
  ${FunnelCardTitle} {
    margin-bottom: 8px;
  }

  border: solid 2px;
  width: 70%;

  /* Desktop */
  @media ${(props) => props.theme.device.$desktop} {
    /* width: 280px; */
    height: 78px;
  }

  /* BigScreen */
  @media ${(props) => props.theme.device.$bigscreen} {
    /* width: 350px; */
    height: 78px;
  }

  ${(props) => {
    switch (props.$cardType) {
      case 'inflow':
        return css`
          background: linear-gradient(
            to right,
            rgba(208, 212, 124, 0.4) ${props.$rate}%,
            white ${props.$rate}% 100%
          );

          border-color: ${props.theme.colors.green5};
        `;
      case 'conversion':
        return css`
          background: linear-gradient(
            to right,
            rgba(253, 182, 125, 0.4) ${props.$rate}%,
            white ${props.$rate}% 100%
          );
          border-color: ${props.theme.colors.orange4};
        `;
      case 'exit':
        return css`
          background: linear-gradient(
            to right,
            rgba(241, 100, 100, 0.4) ${props.$rate}%,
            white ${props.$rate}% 100%
          );
          border-color: ${props.theme.colors.danger5};
          ${FunnelCardRate} {
            color: ${props.theme.colors.danger5};
          }
        `;
      default:
        return css``;
    }
  }}
`;

interface FunnelCardProps {
  url: string;
  count: number;
  rate: number;
  cardType: string;
  className: string;
  onClickOpenHandle: (cardType: string) => void;
}

const FunnelCard = ({
  url,
  count,
  rate,
  cardType,
  className,
  onClickOpenHandle,
}: FunnelCardProps) => {
  return (
    <FunnelCardContainer
      $cardType={cardType}
      $rate={rate}
      className={className}
    >
      <ConversionArrow $cardType={cardType}>
        <IconArrowFunnel $cardType={cardType} />
      </ConversionArrow>
      <FunnelCardTitle>
        {url !== 'ETC' && url !== 'direct' && url !== '이탈' ? (
          <a href={url} target="blank">
            {url}
          </a>
        ) : (
          url
        )}
      </FunnelCardTitle>
      <FunnelCardRate>
        {count} ({Math.round(rate * 100) / 100}%)
      </FunnelCardRate>
      <ETCButton
        $isETC={url === 'ETC'}
        onClick={() => {
          onClickOpenHandle(cardType);
        }}
      >
        <IconLaunch />
      </ETCButton>
    </FunnelCardContainer>
  );
};

export default FunnelCard;
