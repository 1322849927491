import { css } from 'styled-components';

const dot = css`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;
`;

export const tooltip = css`
  .recharts-tooltip-wrapper {
    transform: translate(0px, 0px) !important;
  }

  .custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content !important;
    height: 62px;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.grey300};
    background-color: white;
    .innerBox {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
      .dot-return {
        ${dot};
        background-color: ${(props) => props.theme.colors.green4};
      }
      .dot-new {
        ${dot};
        background-color: ${(props) => props.theme.colors.orange4};
      }
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        margin-right: 8px;
        color: ${(props) => props.theme.colors.grey500};
      }
      .value {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        margin-right: 4px;
        color: ${(props) => props.theme.colors.grey700};
      }
      .rate-return {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.colors.green4};
      }
      .rate-new {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.colors.orange4};
      }
    }
  }
`;

export { tooltip as default };
