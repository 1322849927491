import { useEffect, useRef } from 'react';

const useOutsideClick = (onClickOutside: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = ref;
    window.addEventListener('click', (e) => {
      if (current === e.target) {
        onClickOutside();
      }
    });
    return () => window.removeEventListener('click', () => {});
  }, []);

  return ref;
};

export default useOutsideClick;
