import { takeLatest } from 'redux-saga/effects';

import {
  GET_AIVORY_DASHBOARD_STATICS,
  getAIVORYDashboardStaticsAsync,
} from './actions';
import { apiGetAIVORYDashboardStatics } from '../../../api/aivoryUsageAnalytics/dashboard/api';
import createRequestSaga from '../../createRequestSaga';

const getAIVORYDashboardStaticsSaga = createRequestSaga(
  getAIVORYDashboardStaticsAsync,
  apiGetAIVORYDashboardStatics
);

export function* aivoryDashboardSaga() {
  yield takeLatest(GET_AIVORY_DASHBOARD_STATICS, getAIVORYDashboardStaticsSaga);
}

export { aivoryDashboardSaga as default };
