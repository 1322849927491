import React from 'react';
import styled from 'styled-components';
import DataError from '../../Atoms/DataError';
import { H2 } from '../../Atoms/Typo';

const Component = styled.div`
  ${H2} {
    font-size: 32px;
  }
`;

interface TimeCountProps {
  time?: number;
}

const TimeCount = ({ time }: TimeCountProps) => {
  const convertSec = (sec: number) => {
    const cvMin = Math.floor(sec / 60);
    const cvSec = Math.floor(sec % 60);
    return `${cvMin}분 ${cvSec}초`;
  };

  return (
    <Component>
      <H2>
        {time !== undefined && time !== null ? convertSec(time) : <DataError />}
      </H2>
    </Component>
  );
};

export default TimeCount;
