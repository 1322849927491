import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  GetConfirmPasswordParamType,
  GetConfirmPasswordResponseType,
  SetUserInfoParamType,
  SetUserInfoResponseType,
  SetUserPasswordParamType,
  SetUserPasswordResponseType,
} from '../../api/myPage/type';

import { AxiosErrorType } from '../createRequestSaga';

export const GET_CONFIRM_PASSWORD = 'myPage/GET_CONFIRM_PASSWORD' as const;
export const GET_CONFIRM_PASSWORD_SUCCESS = 'myPage/GET_CONFIRM_PASSWORD_SUCCESS' as const;
export const GET_CONFIRM_PASSWORD_ERROR = 'myPage/GET_CONFIRM_PASSWORD_ERROR' as const;

export const GET_CONFIRM_PASSWORD_CHANGE = 'myPage/GET_CONFIRM_PASSWORD_CHANGE' as const;
export const GET_CONFIRM_PASSWORD_CHANGE_SUCCESS = 'myPage/GET_CONFIRM_PASSWORD_CHANGE_SUCCESS' as const;
export const GET_CONFIRM_PASSWORD_CHANGE_ERROR = 'myPage/GET_CONFIRM_PASSWORD_CHANGE_ERROR' as const;

export const SET_USER_INFO = 'myPage/SET_USER_INFO' as const;
export const SET_USER_INFO_SUCCESS = 'myPage/SET_USER_INFO_SUCCESS' as const;
export const SET_USER_INFO_ERROR = 'myPage/SET_USER_INFO_ERROR' as const;

export const SET_USER_PASSWORD = 'myPage/SET_USER_PASSWORD' as const;
export const SET_USER_PASSWORD_SUCCESS = 'myPage/SET_USER_PASSWORD_SUCCESS' as const;
export const SET_USER_PASSWORD_ERROR = 'myPage/SET_USER_PASSWORD_ERROR' as const;

export const SET_IS_CONFIRM_PASSWORD = 'myPage/SET_IS_CONFIRM_PASSWORD' as const;

export const SET_ERROR_NULL_MYPAGE = 'myPage/SET_ERROR_NULL_MYPAGE' as const;

export const SET_IS_SEND_STATUS = 'myPage/SET_IS_SEND_STATUS' as const;

export const getConfirmPasswordAsync = createAsyncAction(
  GET_CONFIRM_PASSWORD,
  GET_CONFIRM_PASSWORD_SUCCESS,
  GET_CONFIRM_PASSWORD_ERROR
)<
  GetConfirmPasswordParamType,
  GetConfirmPasswordResponseType,
  AxiosErrorType
>();

export const getConfirmPasswordChangeAsync = createAsyncAction(
  GET_CONFIRM_PASSWORD_CHANGE,
  GET_CONFIRM_PASSWORD_CHANGE_SUCCESS,
  GET_CONFIRM_PASSWORD_CHANGE_ERROR
)<
  GetConfirmPasswordParamType,
  GetConfirmPasswordResponseType,
  AxiosErrorType
>();

export const setUserInfoAsync = createAsyncAction(
  SET_USER_INFO,
  SET_USER_INFO_SUCCESS,
  SET_USER_INFO_ERROR
)<SetUserInfoParamType, SetUserInfoResponseType, AxiosErrorType>();

export const setUserPasswordAsync = createAsyncAction(
  SET_USER_PASSWORD,
  SET_USER_PASSWORD_SUCCESS,
  SET_USER_PASSWORD_ERROR
)<SetUserPasswordParamType, SetUserPasswordResponseType, AxiosErrorType>();

export const setIsConfirmPassword = createAction(
  SET_IS_CONFIRM_PASSWORD
)<boolean>();

export const setErrorNullMypage = createAction(SET_ERROR_NULL_MYPAGE)();

export const setIsSendStatus = createAction(SET_IS_SEND_STATUS)();
