import React from 'react';
import styled from 'styled-components';
import DataError from '../../Atoms/DataError';
import { H2 } from '../../Atoms/Typo';

const Component = styled.div`
  ${H2} {
    font-size: 32px;
  }
`;

interface ValueCountProps {
  value?: number;
}

const ValueCount = ({ value }: ValueCountProps) => {
  const makeComma = (numberValue: number) => {
    const numberStr = String(Math.round(numberValue * 100) / 100);

    return numberStr.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  };

  return (
    <Component>
      <H2>
        {value !== undefined && value !== null ? (
          makeComma(value)
        ) : (
          <DataError />
        )}
      </H2>
    </Component>
  );
};

export default ValueCount;
