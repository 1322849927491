import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ErrorComponent from './ErrorComponent';
import { RootState } from '../../modules';
import { PublicRouteGrid } from './styled';
import { getCheckSessionAsync } from '../../modules/authentication';

interface PublicRouteProps {
  exact: boolean;
  path: string;
  component: () => JSX.Element;
}

const PublicRoute = ({ exact, path, component }: PublicRouteProps) => {
  const { isLogout, loginLoading } = useSelector(
    (state: RootState) => state.authentication
  );
  const dispatch = useDispatch();
  const onGetCheckSession = () => {
    dispatch(getCheckSessionAsync.request());
  };

  function getCookie(cookieName: string) {
    let cookieValue = null;
    if (document.cookie) {
      const array = document.cookie.split(`${escape(cookieName)}=`);
      if (array.length >= 2) {
        const arraySub = array[1].split(';');
        cookieValue = unescape(arraySub[0]);
      }
    }
    return cookieValue;
  }

  useEffect(() => {
    if (
      isLogout === false &&
      localStorage.getItem('voda_tenant') &&
      getCookie('sessionid')
    ) {
      onGetCheckSession();
    }
  }, []);
  return (
    <PublicRouteGrid>
      {loginLoading ? (
        <div className="preloaderWrap">
          <div className="spinner">
            <div className="double-bounce1" />
            <div className="double-bounce2" />
          </div>
        </div>
      ) : (
        <>
          <ErrorComponent />
          <Route exact={exact} path={path} component={component} />
        </>
      )}
    </PublicRouteGrid>
  );
};

export default PublicRoute;
