import { createReducer } from 'typesafe-actions';
import {
  GET_NATIONAL_SESSION_ENV_STATISTICS,
  GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS,
  GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR,
  GET_GLOBAL_SESSION_ENV_STATISTICS,
  GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS,
  GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR,
  GET_NATIONAL_DEVICE_ENV_STATICS,
  GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS,
  GET_NATIONAL_DEVICE_ENV_STATICS_ERROR,
  GET_NATIONAL_OS_ENV_STATICS,
  GET_NATIONAL_OS_ENV_STATICS_SUCCESS,
  GET_NATIONAL_OS_ENV_STATICS_ERROR,
  GET_NATIONAL_BROWSER_ENV_STATICS,
  GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS,
  GET_NATIONAL_BROWSER_ENV_STATICS_ERROR,
  GET_GLOBAL_DEVICE_ENV_STATICS,
  GET_GLOBAL_DEVICE_ENV_STATICS_SUCCESS,
  GET_GLOBAL_DEVICE_ENV_STATICS_ERROR,
  GET_GLOBAL_OS_ENV_STATICS,
  GET_GLOBAL_OS_ENV_STATICS_SUCCESS,
  GET_GLOBAL_OS_ENV_STATICS_ERROR,
  GET_GLOBAL_BROWSER_ENV_STATICS,
  GET_GLOBAL_BROWSER_ENV_STATICS_SUCCESS,
  GET_GLOBAL_BROWSER_ENV_STATICS_ERROR,
  SET_ERROR_NULL_SERVICE_ENV,
} from './actions';
import { environmentAnalysisAction, environmentAnalysisState } from './types';

const initialState: environmentAnalysisState = {
  isLoadingNational: false,
  isLoadingNationalDevice: false,
  isLoadingNationalOS: false,
  isLoadingNationalBrowser: false,

  isLoadingGlobal: false,
  isLoadingGlobalDevice: false,
  isLoadingGlobalOS: false,
  isLoadingGlobalBrowser: false,

  nationalSessionEnvData: [],
  nationalDeviceEnvData: [],
  nationalOSEnvData: [],
  nationalBrowserEnvData: [],

  globalSessionEnvData: [],
  globalDeviceEnvData: [],
  globalOSEnvData: [],
  globalBrowserEnvData: [],

  environmentAnalysisError: null,
};

const reducer = createReducer<
  environmentAnalysisState,
  environmentAnalysisAction
>(initialState, {
  [GET_NATIONAL_SESSION_ENV_STATISTICS]: (state) => ({
    ...state,
    isLoadingNational: true,
    nationalSessionEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingNational: false,
    nationalSessionEnvData: action.payload.payload,
  }),
  [GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingNational: false,
    nationalSessionEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [GET_NATIONAL_DEVICE_ENV_STATICS]: (state) => ({
    ...state,
    isLoadingNationalDevice: true,
    nationalDeviceEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingNationalDevice: false,
    nationalDeviceEnvData: action.payload.payload,
  }),
  [GET_NATIONAL_DEVICE_ENV_STATICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingNationalDevice: false,
    nationalDeviceEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [GET_NATIONAL_OS_ENV_STATICS]: (state) => ({
    ...state,
    isLoadingNationalOS: true,
    nationalOSEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_NATIONAL_OS_ENV_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingNationalOS: false,
    nationalOSEnvData: action.payload.payload,
  }),
  [GET_NATIONAL_OS_ENV_STATICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingNationalOS: false,
    nationalOSEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [GET_NATIONAL_BROWSER_ENV_STATICS]: (state) => ({
    ...state,
    isLoadingNationalBrowser: true,
    nationalBrowserEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingNationalBrowser: false,
    nationalBrowserEnvData: action.payload.payload,
  }),
  [GET_NATIONAL_BROWSER_ENV_STATICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingNationalBrowser: false,
    nationalBrowserEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [GET_GLOBAL_SESSION_ENV_STATISTICS]: (state) => ({
    ...state,
    isLoadingGlobal: true,
    globalSessionEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingGlobal: false,
    globalSessionEnvData: action.payload.payload,
  }),
  [GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingGlobal: false,
    globalSessionEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [GET_GLOBAL_DEVICE_ENV_STATICS]: (state) => ({
    ...state,
    isLoadingGlobalDevice: true,
    globalDeviceEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_GLOBAL_DEVICE_ENV_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingGlobalDevice: false,
    globalDeviceEnvData: action.payload.payload,
  }),
  [GET_GLOBAL_DEVICE_ENV_STATICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingGlobalDevice: false,
    globalDeviceEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [GET_GLOBAL_OS_ENV_STATICS]: (state) => ({
    ...state,
    isLoadingGlobalOS: true,
    globalOSEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_GLOBAL_OS_ENV_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingGlobalOS: false,
    globalOSEnvData: action.payload.payload,
  }),
  [GET_GLOBAL_OS_ENV_STATICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingGlobalOS: false,
    globalOSEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [GET_GLOBAL_BROWSER_ENV_STATICS]: (state) => ({
    ...state,
    isLoadingGlobalBrowser: true,
    globalBrowserEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_GLOBAL_BROWSER_ENV_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingGlobalBrowser: false,
    globalBrowserEnvData: action.payload.payload,
  }),
  [GET_GLOBAL_BROWSER_ENV_STATICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingGlobalBrowser: false,
    globalBrowserEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [SET_ERROR_NULL_SERVICE_ENV]: (state) => ({
    ...state,
    environmentAnalysisError: null,
  }),
});

export default reducer;
