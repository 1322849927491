import React, { ReactNodeArray } from 'react';
import styled from 'styled-components';

const FunnelTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FunnelTableHeader = styled.div`
  display: flex;
  width: 100%;
`;

const FunnelTableTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: ${(props) => props.theme.colors.grey500};
  display: flex;
  align-items: center;
  height: 54px;
  padding: 16px 24px;
  background-color: ${(props) => props.theme.colors.grey100};
  &.table_title_1 {
    width: 450px;
  }
  &.table_title_2 {
    width: 225px;
  }
  &.table_title_3 {
    width: 225px;
  }
`;

const FunnelTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FunnelTableItem = styled.div`
  display: flex;
  width: 100%;
  height: 53px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
`;

const FunnelTableItemObj = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    color: inherit;
    text-decoration: underline;
  }
  &.table_item_obj_1 {
    width: 450px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.grey800};
  }
  &.table_item_obj_2 {
    width: 225px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.grey800};
  }
  &.table_item_obj_3 {
    width: 225px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.orange5};
  }
`;

interface FunnelTableProps {
  data: { url: string; uv: number; rate: number }[];
}

const FunnelTable = ({ data }: FunnelTableProps) => {
  const printTable = () => {
    const result: ReactNodeArray = [];
    data.forEach((element) => {
      result.push(
        <FunnelTableItem>
          <FunnelTableItemObj className="table_item_obj_1">
            <a href={element.url} target="blank">
              {element.url}
            </a>
          </FunnelTableItemObj>
          <FunnelTableItemObj className="table_item_obj_2">
            {element.uv}
          </FunnelTableItemObj>
          <FunnelTableItemObj className="table_item_obj_3">
            {element.rate}%
          </FunnelTableItemObj>
        </FunnelTableItem>
      );
    });
    if (result.length > 0) {
      return result;
    }
    return null;
  };
  return (
    <FunnelTableContainer>
      <FunnelTableHeader>
        <FunnelTableTitle className="table_title_1">URL</FunnelTableTitle>
        <FunnelTableTitle className="table_title_2">SESSION</FunnelTableTitle>
        <FunnelTableTitle className="table_title_3">
          전체 대비 비율
        </FunnelTableTitle>
      </FunnelTableHeader>
      <FunnelTableBody>{printTable()}</FunnelTableBody>
    </FunnelTableContainer>
  );
};

export default FunnelTable;
