import React from 'react';
import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';

const Component = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;

  div {
    width: 24px !important;
    height: 24px !important;
    svg {
      width: 24px;
      height: 24px;
      circle {
        stroke: #7851e7;
      }
    }
  }
`;

const Spinner = () => {
  return (
    <Component>
      <CircularProgress />
    </Component>
  );
};

export default Spinner;
