import { takeLatest } from 'redux-saga/effects';
import {
  apiDeletePageClickStatics,
  apiGetPageMouseStatics,
  apiGetPageElementData,
  apiGetEffectiveClickRate,
  apiGetPageAttentionScreensCount,
  apiGetPageDuration,
} from '../../../api/pageAnalytics/uxAnalytics/api';

import createRequestSaga from '../../createRequestSaga';
import {
  deletePageClickStaticsAsync,
  getPageMouseStaticsAsync,
  GET_PAGE_MOUSE_STATICS,
  DELETE_PAGE_CLICK_STATICS,
  getPageElementDataAsync,
  GET_PAGE_ELEMENT_DATA,
  getEffectiveClickRateAsync,
  GET_EFFECTIVE_CLICK_RATE,
  getPageAttentionScreensCountAsync,
  getPageDurationAsync,
  GET_PAGE_ATTENTION_SCREENS_COUNT,
  GET_PAGE_DURATION,
} from './actions';

const getPageMouseStaticsSaga = createRequestSaga(
  getPageMouseStaticsAsync,
  apiGetPageMouseStatics
);

const deletePageClickStaticsSaga = createRequestSaga(
  deletePageClickStaticsAsync,
  apiDeletePageClickStatics
);

const getPageElementDataSaga = createRequestSaga(
  getPageElementDataAsync,
  apiGetPageElementData
);

const getEffectiveClickRateSaga = createRequestSaga(
  getEffectiveClickRateAsync,
  apiGetEffectiveClickRate
);

const getPageAttentionScreensCountSaga = createRequestSaga(
  getPageAttentionScreensCountAsync,
  apiGetPageAttentionScreensCount
);

const getPageDurationSaga = createRequestSaga(
  getPageDurationAsync,
  apiGetPageDuration
);

export function* pageUXAnalyticsSaga() {
  yield takeLatest(GET_PAGE_MOUSE_STATICS, getPageMouseStaticsSaga);
  yield takeLatest(DELETE_PAGE_CLICK_STATICS, deletePageClickStaticsSaga);
  yield takeLatest(GET_PAGE_ELEMENT_DATA, getPageElementDataSaga);
  yield takeLatest(GET_EFFECTIVE_CLICK_RATE, getEffectiveClickRateSaga);
  yield takeLatest(
    GET_PAGE_ATTENTION_SCREENS_COUNT,
    getPageAttentionScreensCountSaga
  );
  yield takeLatest(GET_PAGE_DURATION, getPageDurationSaga);
}

export { pageUXAnalyticsSaga as default };
