import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Organisms/Header';
import ServiceActivationReportCard from '../../components/Organisms/Card/Report/ServiceActivationReportCard';
import NewVSReturnReportCard from '../../components/Organisms/Card/Report/NewVSReturnReportCard';
import PopularPageReportCard from '../../components/Organisms/Card/Report/PopularPageReportCard';
import SearchWordReportCard from '../../components/Organisms/Card/Report/SearchWordReportCard';
import ExternalReportCard from '../../components/Organisms/Card/Report/ExternalReportCard';
import LocalReportCard from '../../components/Organisms/Card/Report/LocalReportCard';
import SimilarVSReportCard from '../../components/Organisms/Card/Report/SimilarVSReportCard';
import ForecastReportCard from '../../components/Organisms/Card/Report/ForecastReportCard';
import { RootState } from '../../modules';
import {
  getBotTrafficPageAsync,
  getBotTrafficSiteAsync,
  getExternalNewsKeywordNetworkAsync,
  getExternalNewsKeywordWordcloudAsync,
  getExternalReferrerSearchAsync,
  getExternalReferrerSNSAsync,
  getForecastPVAsync,
  getForecastUVAsync,
  getLocalPVAsync,
  getLocalRegionAsync,
  getLocalUVAsync,
  getNewVSReturnDurationAsync,
  getNewVSReturnPVAsync,
  getNewVSReturnUVAsync,
  getPageUsabilityClickRateAsync,
  getPageUsabilityPageDurationAsync,
  getPopularPageGrowthAsync,
  getPopularPageMostAsync,
  getSearchWordGrowthAsync,
  getSearchWordMostAsync,
  getServiceActivationPVAsync,
  getServiceActivationUVAsync,
  getSimilarAgencyPVAsync,
  getSimilarAgencyTypeAsync,
  getSimilarAgencyUVAsync,
  getSimilarScalePVAsync,
  getSimilarScaleTypeAsync,
  GET_EXTERNAL_NEWS_KEYWORD_NETWORK,
  GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD,
  GET_EXTERNAL_REFERRER_SEARCH,
  GET_EXTERNAL_REFERRER_SNS,
  GET_FORECAST_PV,
  GET_FORECAST_UV,
  GET_LOCAL_PV,
  GET_LOCAL_REGION,
  GET_LOCAL_UV,
  GET_NEW_VS_RETURN_DURATION,
  GET_NEW_VS_RETURN_PV,
  GET_NEW_VS_RETURN_UV,
  GET_PAGE_USABILITY_CLICK_RATE,
  GET_PAGE_USABILITY_PAGE_DURATION,
  GET_POPULAR_PAGE_GROWTH,
  GET_POPULAR_PAGE_MOST,
  GET_SEARCH_WORD_GROWTH,
  GET_SEARCH_WORD_MOST,
  GET_SERVICE_ACTIVATION_PV,
  GET_SERVICE_ACTIVATION_UV,
  GET_SIMILAR_AGENCY_PV,
  GET_SIMILAR_AGENCY_TYPE,
  GET_SIMILAR_AGENCY_UV,
  GET_SIMILAR_SCALE_PV,
  GET_SIMILAR_SCALE_TYPE,
} from '../../modules/report';

import PageUsabilityClickRateReportCard from '../../components/Organisms/Card/Report/PageUsabilityClickRateReportCard';
import PageUsabilityPageDurationReportCard from '../../components/Organisms/Card/Report/PageUsabilityPageDurationReportCard';
import ReferrerReportCard from '../../components/Organisms/Card/Report/ReferrerReportCard';
import BotReportCard from '../../components/Organisms/Card/Report/BotReportCard';
import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';

import NoDataCard from '../../components/Organisms/Card/NoDataCard';

const Report = () => {
  const loading = useSelector((state: RootState) => state.loading);
  const {
    serviceActivationUVData,
    serviceActivationPVData,

    newVSReturnUVData,
    newVSReturnDurationData,
    newVSReturnPVData,

    popularPageMostData,
    popularPageGrowthData,

    searchWordGrowthData,
    searchWordMostData,

    localRegionData,
    localPVData,
    localUVData,

    similarAgencyTypeData,
    similarAgencyUVData,
    similarAgencyPVData,

    similarScaleTypeData,
    similarScaleUVData,
    similarScalePVData,

    forecastPVData,
    forecastUVData,

    pageUsabilityClickRateData,
    pageUsabilityPageDurationData,

    externalNewsKeywordNetworkData,
    externalNewsKeywordWordcloudData,

    externalReferrerSNSData,
    externalReferrerSearchData,

    botTrafficPageData,
    botTrafficSiteData,
  } = useSelector((state: RootState) => state.report);

  const { isAIVORYAvailable, user_name } = useSelector(
    (state: RootState) => state.authentication
  );
  const dispatch = useDispatch();

  const today = new Date();

  // const year = today.getFullYear();
  let month = today.getMonth();
  if (month === 0) {
    month += 12;
  }

  const month_string = `${month}월`;

  const onGetServiceActivation = () => {
    dispatch(getServiceActivationUVAsync.request());
    dispatch(getServiceActivationPVAsync.request());
  };

  const onGetNewVSReturn = () => {
    dispatch(getNewVSReturnUVAsync.request());
    dispatch(getNewVSReturnDurationAsync.request());
    dispatch(getNewVSReturnPVAsync.request());
  };

  const onGetPopularPage = () => {
    dispatch(getPopularPageMostAsync.request());
    dispatch(getPopularPageGrowthAsync.request());
  };

  const onGetSearchWord = () => {
    dispatch(getSearchWordGrowthAsync.request());
    dispatch(getSearchWordMostAsync.request());
  };

  const onGetLocal = () => {
    dispatch(getLocalRegionAsync.request());
    dispatch(getLocalUVAsync.request());
    dispatch(getLocalPVAsync.request());
  };

  const onGetSimilarAgencyData = () => {
    dispatch(getSimilarAgencyTypeAsync.request());
    dispatch(getSimilarAgencyUVAsync.request());
    dispatch(getSimilarAgencyPVAsync.request());
  };

  const onGetSimilarScaleData = () => {
    dispatch(getSimilarScaleTypeAsync.request());
    dispatch(getSimilarScalePVAsync.request());
  };

  const onGetForecastData = () => {
    dispatch(getForecastUVAsync.request());
    dispatch(getForecastPVAsync.request());
  };

  const onGetPageUsabilityData = () => {
    dispatch(getPageUsabilityClickRateAsync.request());
    dispatch(getPageUsabilityPageDurationAsync.request());
  };

  const onGetReferrerData = () => {
    dispatch(getExternalReferrerSearchAsync.request());
    dispatch(getExternalReferrerSNSAsync.request());
  };

  const onGetExternalNewsKeyword = () => {
    // function leftPad(value: number) {
    //   if (value >= 10) {
    //     return value;
    //   }
    //   return `0${value}`;
    // }

    // const info = {
    //   date: `${year}-${leftPad(month)}`,
    //   tenant_id: localStorage.getItem('voda_tenant') || '',
    // };
    dispatch(getExternalNewsKeywordNetworkAsync.request());
    dispatch(getExternalNewsKeywordWordcloudAsync.request());
  };

  const onGetBotTrafficData = () => {
    dispatch(getBotTrafficPageAsync.request());
    dispatch(getBotTrafficSiteAsync.request());
  };

  useEffect(() => {
    onGetServiceActivation();
    onGetNewVSReturn();
    onGetPopularPage();
    onGetSearchWord();
    onGetLocal();
    onGetSimilarAgencyData();
    onGetSimilarScaleData();
    onGetForecastData();
    onGetPageUsabilityData();
    onGetExternalNewsKeyword();
    onGetBotTrafficData();
    onGetReferrerData();
  }, []);

  return (serviceActivationUVData !== null &&
    Object.keys(serviceActivationUVData).length !== 0) ||
    (serviceActivationPVData !== null &&
      Object.keys(serviceActivationPVData).length !== 0) ||
    (newVSReturnUVData !== null &&
      Object.keys(newVSReturnUVData).length !== 0) ||
    (newVSReturnDurationData !== null &&
      Object.keys(newVSReturnDurationData).length !== 0) ||
    (newVSReturnPVData !== null &&
      Object.keys(newVSReturnPVData).length !== 0) ||
    (botTrafficSiteData !== null &&
      Object.keys(botTrafficSiteData).length !== 0) ||
    (forecastUVData !== null && Object.keys(forecastUVData).length !== 0) ||
    (forecastPVData !== null && Object.keys(forecastPVData).length !== 0) ||
    (pageUsabilityClickRateData !== null &&
      Object.keys(pageUsabilityClickRateData).length !== 0) ||
    (pageUsabilityPageDurationData !== null &&
      Object.keys(pageUsabilityPageDurationData).length !== 0) ||
    (externalReferrerSNSData !== null &&
      Object.keys(externalReferrerSNSData).length !== 0) ||
    (externalReferrerSearchData !== null &&
      Object.keys(externalReferrerSearchData).length !== 0) ||
    (localUVData !== null && Object.keys(localUVData).length !== 0) ||
    (localPVData !== null && Object.keys(localPVData).length !== 0) ||
    (similarAgencyUVData !== null &&
      Object.keys(similarAgencyUVData).length !== 0) ||
    (similarAgencyPVData !== null &&
      Object.keys(similarAgencyPVData).length !== 0) ||
    (similarScaleTypeData !== null &&
      Object.keys(similarScaleTypeData).length !== 0) ||
    (popularPageMostData !== null &&
      Object.keys(popularPageMostData).length !== 0) ||
    (popularPageGrowthData !== null &&
      Object.keys(popularPageGrowthData).length !== 0) ||
    (isAIVORYAvailable &&
      searchWordMostData !== null &&
      Object.keys(searchWordMostData).length !== 0) ||
    (searchWordGrowthData !== null &&
      Object.keys(searchWordGrowthData).length !== 0) ||
    (externalNewsKeywordWordcloudData !== null &&
      externalNewsKeywordWordcloudData.wordcloud.length !== 0) ||
    (externalNewsKeywordNetworkData !== null &&
      externalNewsKeywordNetworkData.edge.length,
    externalNewsKeywordNetworkData?.nodes.length !== 0) ? (
    <ContentsLayout>
      <Header
        title={`${user_name}님, ${month_string} 월간 분석 리포트가 도착했습니다!`}
        isDatePicker={false}
      />
      <ContentBodyLayout>
        {(serviceActivationUVData !== null &&
          Object.keys(serviceActivationUVData).length !== 0) ||
        (serviceActivationPVData !== null &&
          Object.keys(serviceActivationPVData).length !== 0) ? (
          <ContentsItemLayout desktop={12}>
            <ServiceActivationReportCard
              isLoading={
                loading[GET_SERVICE_ACTIVATION_UV] ||
                loading[GET_SERVICE_ACTIVATION_PV]
              }
              uvData={serviceActivationUVData}
              pvData={serviceActivationPVData}
            />
          </ContentsItemLayout>
        ) : null}
        {(newVSReturnUVData !== null &&
          Object.keys(newVSReturnUVData).length !== 0) ||
        (newVSReturnDurationData !== null &&
          Object.keys(newVSReturnDurationData).length !== 0) ||
        (newVSReturnPVData !== null &&
          Object.keys(newVSReturnPVData).length !== 0) ? (
          <ContentsItemLayout desktop={12}>
            <NewVSReturnReportCard
              isLoading={
                loading[GET_NEW_VS_RETURN_UV] ||
                loading[GET_NEW_VS_RETURN_DURATION] ||
                loading[GET_NEW_VS_RETURN_PV]
              }
              uvData={newVSReturnUVData}
              durationData={newVSReturnDurationData}
              pvData={newVSReturnPVData}
            />
          </ContentsItemLayout>
        ) : null}
        {botTrafficSiteData !== null &&
        Object.keys(botTrafficSiteData).length !== 0 ? (
          <ContentsItemLayout desktop={12}>
            <BotReportCard
              month={month_string}
              siteData={botTrafficSiteData}
              pageData={botTrafficPageData}
            />
          </ContentsItemLayout>
        ) : null}

        {(forecastUVData !== null &&
          Object.keys(forecastUVData).length !== 0) ||
        (forecastPVData !== null &&
          Object.keys(forecastPVData).length !== 0) ? (
          <ContentsItemLayout desktop={12}>
            <ForecastReportCard
              uvData={forecastUVData}
              pvData={forecastPVData}
              isLoading={loading[GET_FORECAST_UV] || loading[GET_FORECAST_PV]}
            />
          </ContentsItemLayout>
        ) : null}
        {pageUsabilityClickRateData !== null &&
        Object.keys(pageUsabilityClickRateData).length !== 0 ? (
          <ContentsItemLayout desktop={4}>
            <PageUsabilityClickRateReportCard
              month={month_string}
              data={pageUsabilityClickRateData}
              isLoading={loading[GET_PAGE_USABILITY_CLICK_RATE]}
            />
          </ContentsItemLayout>
        ) : null}
        {pageUsabilityPageDurationData !== null &&
        Object.keys(pageUsabilityPageDurationData).length !== 0 ? (
          <ContentsItemLayout desktop={4}>
            <PageUsabilityPageDurationReportCard
              data={pageUsabilityPageDurationData}
              isLoading={loading[GET_PAGE_USABILITY_PAGE_DURATION]}
            />
          </ContentsItemLayout>
        ) : null}
        {(externalReferrerSNSData !== null &&
          Object.keys(externalReferrerSNSData).length !== 0) ||
        (externalReferrerSearchData !== null &&
          Object.keys(externalReferrerSearchData).length !== 0) ? (
          <ContentsItemLayout desktop={4}>
            <ReferrerReportCard
              title="어디서 유입이 됐나요?"
              snsData={externalReferrerSNSData}
              searchData={externalReferrerSearchData}
              isLoading={
                loading[GET_EXTERNAL_REFERRER_SEARCH] ||
                loading[GET_EXTERNAL_REFERRER_SNS]
              }
            />
          </ContentsItemLayout>
        ) : null}

        {(localUVData !== null && Object.keys(localUVData).length !== 0) ||
        (localPVData !== null && Object.keys(localPVData).length !== 0) ? (
          <ContentsItemLayout desktop={12}>
            <LocalReportCard
              uvData={localUVData}
              regionData={localRegionData}
              pvData={localPVData}
              isLoading={
                loading[GET_LOCAL_REGION] ||
                loading[GET_LOCAL_UV] ||
                loading[GET_LOCAL_PV]
              }
            />
          </ContentsItemLayout>
        ) : null}

        {(similarAgencyUVData !== null &&
          Object.keys(similarAgencyUVData).length !== 0) ||
        (similarAgencyPVData !== null &&
          Object.keys(similarAgencyPVData).length !== 0) ? (
          <ContentsItemLayout desktop={12}>
            <SimilarVSReportCard
              group={similarAgencyTypeData}
              title="유사 기관 중 내 웹 사이트는?"
              type="기관 유형"
              uvData={similarAgencyUVData}
              pvData={similarAgencyPVData}
              isLoading={
                loading[GET_SIMILAR_AGENCY_TYPE] ||
                loading[GET_SIMILAR_AGENCY_UV] ||
                loading[GET_SIMILAR_AGENCY_PV]
              }
            />
          </ContentsItemLayout>
        ) : null}

        {similarScaleTypeData !== null &&
        Object.keys(similarScaleTypeData).length !== 0 ? (
          <ContentsItemLayout desktop={12}>
            <SimilarVSReportCard
              group2={similarScaleTypeData}
              title="유사 규모 중 내 웹 사이트는?"
              type="사용자 수"
              scaleUVData={similarScaleUVData}
              pvData={similarScalePVData}
              isLoading={
                loading[GET_SIMILAR_SCALE_TYPE] || loading[GET_SIMILAR_SCALE_PV]
              }
            />
          </ContentsItemLayout>
        ) : null}

        {(popularPageMostData !== null &&
          Object.keys(popularPageMostData).length !== 0) ||
        (popularPageGrowthData !== null &&
          Object.keys(popularPageGrowthData).length !== 0) ? (
          <ContentsItemLayout desktop={4}>
            <PopularPageReportCard
              mostPopularData={popularPageMostData}
              growthPopularData={popularPageGrowthData}
              isLoading={
                loading[GET_POPULAR_PAGE_MOST] ||
                loading[GET_POPULAR_PAGE_GROWTH]
              }
            />
          </ContentsItemLayout>
        ) : null}

        {(isAIVORYAvailable &&
          searchWordMostData !== null &&
          Object.keys(searchWordMostData).length !== 0) ||
        (searchWordGrowthData !== null &&
          Object.keys(searchWordGrowthData).length !== 0) ? (
          <ContentsItemLayout desktop={4}>
            <SearchWordReportCard
              title="무엇을 많이 검색하나요?"
              mostSearchData={searchWordMostData}
              growthSearchData={searchWordGrowthData}
              isLoading={
                loading[GET_SEARCH_WORD_MOST] || loading[GET_SEARCH_WORD_GROWTH]
              }
            />
          </ContentsItemLayout>
        ) : null}

        {(externalNewsKeywordWordcloudData !== null &&
          externalNewsKeywordWordcloudData.wordcloud.length !== 0) ||
        (externalNewsKeywordNetworkData !== null &&
          externalNewsKeywordNetworkData.edge.length,
        externalNewsKeywordNetworkData?.nodes.length !== 0) ? (
          <ContentsItemLayout desktop={4}>
            <ExternalReportCard
              wordcloudData={externalNewsKeywordWordcloudData}
              networkData={externalNewsKeywordNetworkData}
              isLoading={
                loading[GET_EXTERNAL_NEWS_KEYWORD_NETWORK] ||
                loading[GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD]
              }
            />
          </ContentsItemLayout>
        ) : null}
      </ContentBodyLayout>
    </ContentsLayout>
  ) : (
    <ContentsLayout>
      <Header
        title={`${user_name}님, 아직 데이터를 모으고 있습니다!`}
        isDatePicker={false}
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <NoDataCard
            header="아직 데이터를 모으고 있습니다."
            bodyText="서비스 시작일부터 약 30일 후 데이터를 볼 수 있습니다."
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Report;
