import React, { ReactNodeArray } from 'react';
import styled from 'styled-components';

import { PageElementDataPayload } from '../../../../api/pageAnalytics/uxAnalytics/type';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import Line from '../../../Atoms/Line';
import { P3 } from '../../../Atoms/Typo';
import DefaultCardHeading from '../../../Molecule/Heading/Card/DefaultCardHeading';
import SortButton from '../../../Molecule/SortButton';
import { MouseHeatmapTooltipType } from '../../../Molecule/Tooltip/MouseHeatmapTooltip';
import ElementCard from './ElementCard';
import Spinner from '../../../Molecule/Spinner';

const Component = styled(CardLayout)``;

const Header = styled(CardHeaderLayout)`
  align-items: flex-start;
`;

const SubHeader = styled.div`
  margin-top: 8px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  }
`;
const ButtonWrapper = styled.div`
  width: fit-content;
`;

const ItemList = styled.div`
  display: flex;
  width: 100%;
`;

const Divider = styled(Line)`
  margin-left: 24px;
  margin-right: 24px;
  height: inherit;
`;

const ColumnRankTable = styled.div`
  flex: 1;
`;

interface MouseEventRankCardProps {
  elementData: PageElementDataPayload[];
  pageId: string;
  clickedElementPath: string;
  activeDataType: number;
  isLoading: boolean;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
}

const MouseEventRankCard = ({
  elementData,
  pageId,
  clickedElementPath,
  activeDataType,
  isLoading,
  setClickedElementPath,
  setClickTooltipInfo,
}: MouseEventRankCardProps) => {
  const printData = () => {
    const result_left: ReactNodeArray = [];
    const result_right: ReactNodeArray = [];
    elementData.forEach((element, index) => {
      let data: {
        id: number;
        title: string;
        value: string;
      }[] = [];

      let toolipData: MouseHeatmapTooltipType | null = null;

      // if (activeDataType === 0) {
      if (activeDataType === 1) {
        data = [
          {
            id: 0,
            title: '클릭 횟수',
            value: `${element.cnt || 0}회`,
          },
          {
            id: 1,
            title: '클릭 비율',
            value: `${element.rate}%`,
          },
        ];
        toolipData = {
          data: [
            {
              id: 0,
              title: '클릭 순위',
              value: `${element.rank}위`,
            },
            {
              id: 1,
              title: '클릭 횟수',
              value: `${element.cnt || 0}회`,
            },
            {
              id: 2,
              title: '클릭 비율',
              value: `${element.rate}%`,
            },
          ],
          img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
            'voda_tenant'
          )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
          isOpenTooltip: true,
          elementX: element.x,
          elementY: element.y,
          elementHeight: element.height,
          elementWidth: element.width,
        };
      }
      // else if (activeDataType === 1) {
      //   data = [
      //     {
      //       id: 0,
      //       title: '평균 관심 시간',
      //       value: `${Math.floor((element.duration || 0) * 100) / 100}초`,
      //     },
      //     {
      //       id: 1,
      //       title: '관심 비율',
      //       value: `${element.rate}%`,
      //     },
      //   ];
      //   toolipData = {
      //     data: [
      //       {
      //         id: 0,
      //         title: '관심 순위',
      //         value: `${element.rank}위`,
      //       },
      //       {
      //         id: 1,
      //         title: '평균 체류 시간',
      //         value: `${element.duration || 0}초`,
      //       },
      //       {
      //         id: 2,
      //         title: '관심 비율',
      //         value: `${element.rate}%`,
      //       },
      //     ],
      //     img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
      //       'voda_tenant'
      //     )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
      //     isOpenTooltip: true,
      //     elementX: element.x,
      //     elementY: element.y,
      //     elementHeight: element.height,
      //     elementWidth: element.width,
      //   };
      // }

      const Item = (
        <ElementCard
          img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
            'voda_tenant'
          )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`}
          rank={index + 1}
          data={data}
          isClicked={element.element_path === clickedElementPath}
          onClickImage={() => {
            setClickedElementPath(element.element_path);
            setClickTooltipInfo(toolipData);
          }}
          key={element.element_path}
        />
      );
      if (index % 2 === 0) {
        result_left.push(Item);
      } else {
        result_right.push(Item);
      }
    });

    return (
      <ItemList key="rank_card">
        <ColumnRankTable>{result_left}</ColumnRankTable>
        <Divider isColumn={false} />
        <ColumnRankTable>{result_right}</ColumnRankTable>
      </ItemList>
    );
  };

  const printHeading = () => {
    let title = '';
    switch (activeDataType) {
      case 0:
        title = '주목 화면 순위';
        break;
      // case 1:
      //   title = '관심 순위';
      //   break;
      // case 3:
      //   title = '주목 화면 순위';
      //   break;
      case 1:
        title = '클릭 순위';
        break;

      default:
        break;
    }
    return title;
  };
  const printSubHeading = () => {
    let title = '';
    switch (activeDataType) {
      case 0:
        title = '사용자가 해당화면을 본 비율과 머문 시간을 집계합니다.';
        break;
      // case 1:
      //   title = '사용자의 마우스가 머문 시간과 클릭량을 집계합니다.';
      //   break;
      // case 3:
      //   title = '사용자가 해당화면을 본 비율과 머문 시간을 집계합니다.';
      //   break;
      case 1:
        title = '링크 및 클릭만 집계합니다.';
        break;

      default:
        break;
    }
    return title;
  };

  return (
    <Component>
      <Header>
        <DefaultCardHeading title={printHeading()} />
        {/* <ButtonWrapper>
          <SortButton title="내림 차순" isAsc={false} />
        </ButtonWrapper> */}
      </Header>
      <SubHeader>
        <P3>{printSubHeading()}</P3>
      </SubHeader>
      <Line isColumn />
      <CardBodyLayout>{isLoading ? <Spinner /> : printData()}</CardBodyLayout>
    </Component>
  );
};

export default MouseEventRankCard;
