import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Component = styled(Link)`
  display: flex;
  width: fit-content;
`;

interface DefaultLinkProps {
  to: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const DefaultLink = ({
  className,
  to,
  children,
  onClick,
}: DefaultLinkProps) => {
  return (
    <Component className={className} to={to} onClick={onClick}>
      {children}
    </Component>
  );
};

export default DefaultLink;
