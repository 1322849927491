import React from 'react';
import styled from 'styled-components';
import { H2 } from '../../../Atoms/Typo';

const Component = styled.div``;

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => {
  return (
    <Component className="voda_report_title">
      <H2>{title}</H2>
    </Component>
  );
};

export default Title;
