import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';

import {
  GetSessionCountParamType,
  GetWeeklySessionCountParamType,
} from '../../../api/serviceAnalytics/sessionAnalysis/type';
import {
  getSessionCountAsync,
  getWeeklySessionCountAsync,
} from '../../../modules/serviceAnalytics/sessionAnalysis';

import ServiceAnalysisTemplate from '../../../components/Templates/ServiceAnalysisTemplate';

const ServiceSessionAnalytics = () => {
  // redux
  const { SCData, WSCData, isGetSCLoading, isGetWSCLoading } = useSelector(
    (state: RootState) => state.serviceSessionAnalysis
  );
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const dispatch = useDispatch();
  const onGetSessoinCount = (info: GetSessionCountParamType) => {
    dispatch(getSessionCountAsync.request(info));
  };
  const onGetWeeklySessoinCount = (info: GetWeeklySessionCountParamType) => {
    dispatch(getWeeklySessionCountAsync.request(info));
  };

  // state
  const [selectedTimeSort, setSelectedTimeSort] = useState('day');
  const [selectedUserSort, setSelectedUserSort] = useState('all');

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetSessoinCount({
        unit: selectedTimeSort,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetWeeklySessoinCount({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  const onChangeFliter = () => {
    onGetSessoinCount({
      unit: selectedTimeSort,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const total_label = '전체사용자의 방문횟수';
  const label1 = '재방문사용자의 방문횟수';
  const label2 = '신규사용자의 방문횟수';

  return (
    <ServiceAnalysisTemplate
      headerTitle="방문 횟수 분석"
      multiChartData={{
        Data: SCData || null,
        selectedTimeSort,
        selectedUserSort,
        isLoading: isGetSCLoading,
        setSelectedTimeSort,
        setSelectedUserSort,
        onChangeFliter,
        lineLabel: total_label,
        bar1Label: label1,
        bar2Label: label2,
        legendData: [
          {
            title: total_label,
            status: 'all',
          },
          {
            title: label1,
            status: 'return',
          },
          {
            title: label2,
            status: 'new',
          },
          {
            title: `${total_label}(추정)`,
            status: 'all_future',
          },
          {
            title: `${label1}(추정)`,
            status: 'return_future',
          },
          {
            title: `${label2}(추정)`,
            status: 'new_future',
          },
        ],
      }}
      stackedColumnsChartData={{
        Data: WSCData,
        loading: isGetWSCLoading,
        bar1Label: label1,
        bar2Label: label2,
      }}
      heatmapChartData={{
        Data: WSCData,
        loading: isGetWSCLoading,
        totalLabel: total_label,
        label1,
        label2,
      }}
    />
  );
};

export default ServiceSessionAnalytics;
