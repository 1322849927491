import { takeLatest } from 'redux-saga/effects';
import { apiGetPageList } from '../../../api/pageAnalytics/pageList/api';

import createRequestSaga from '../../createRequestSaga';
import { GET_PAGE_LIST, getPageListAsync } from './actions';

const getPageListSaga = createRequestSaga(getPageListAsync, apiGetPageList);

export function* pageListSaga() {
  yield takeLatest(GET_PAGE_LIST, getPageListSaga);
}

export { pageListSaga as default };
