import { takeLatest } from 'redux-saga/effects';
import {
  apiGetConfirmPassword,
  apiSetUserInfo,
  apiSetUserPassword,
} from '../../api/myPage/api';
import createRequestSaga from '../createRequestSaga';

import {
  GET_CONFIRM_PASSWORD,
  SET_USER_INFO,
  SET_USER_PASSWORD,
  getConfirmPasswordAsync,
  setUserInfoAsync,
  setUserPasswordAsync,
  getConfirmPasswordChangeAsync,
  GET_CONFIRM_PASSWORD_CHANGE,
} from './actions';

const getConfirmPasswordSaga = createRequestSaga(
  getConfirmPasswordAsync,
  apiGetConfirmPassword
);

const getConfirmPasswordChangeSaga = createRequestSaga(
  getConfirmPasswordChangeAsync,
  apiGetConfirmPassword
);

const setUserInfoSaga = createRequestSaga(setUserInfoAsync, apiSetUserInfo);

const setUserPasswordSaga = createRequestSaga(
  setUserPasswordAsync,
  apiSetUserPassword
);

export function* myPageSaga() {
  yield takeLatest(GET_CONFIRM_PASSWORD, getConfirmPasswordSaga);
  yield takeLatest(GET_CONFIRM_PASSWORD_CHANGE, getConfirmPasswordChangeSaga);
  yield takeLatest(SET_USER_INFO, setUserInfoSaga);
  yield takeLatest(SET_USER_PASSWORD, setUserPasswordSaga);
}

export { myPageSaga as default };
