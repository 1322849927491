import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { ExternalReferrerPayload } from '../../../../api/report/type';

import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import Spinner from '../../../Molecule/Spinner';

import SearchWordCard from './SearchWordCard';

const Component = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 40px 24px;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
  .voda_report_title {
    margin-bottom: 24px;
  }
`;

const Body = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
  margin-top: 24px;
  margin-bottom: 24px;
`;

interface ReferrerReportCardProps {
  searchData: ExternalReferrerPayload[] | null;
  snsData: ExternalReferrerPayload[] | null;
  isLoading: boolean;
  title: string;
}

interface ReferrerReportCardState {
  rebuildData: {
    rank: number;
    title: string;
    value: number;
  }[];
}

const ReferrerReportCard = ({
  title,
  searchData,
  snsData,

  isLoading,
}: ReferrerReportCardProps) => {
  const [rebuildSearchData, setRebuildSearchData] = useState<
    ReferrerReportCardState['rebuildData']
  >([]);
  const [rebuildSNSData, setRebuildSNSData] = useState<
    ReferrerReportCardState['rebuildData']
  >([]);

  useEffect(() => {
    if (searchData) {
      const rebuild_data: ReferrerReportCardState['rebuildData'] = [];

      searchData.forEach((element, index) => {
        rebuild_data.push({
          rank: index + 1,
          title: element.referrer,
          value: element.count,
        });
      });
      setRebuildSearchData(rebuild_data);
    }
  }, [searchData]);

  useEffect(() => {
    if (snsData) {
      const rebuild_data: ReferrerReportCardState['rebuildData'] = [];

      snsData.forEach((element, index) => {
        rebuild_data.push({
          rank: index + 1,
          title: element.referrer,
          value: element.count,
        });
      });
      setRebuildSNSData(rebuild_data);
    }
  }, [snsData]);
  return (
    <Component>
      <Title title={title} />

      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <SearchWordCard
              data={rebuildSearchData}
              isGrowth={false}
              title="외부 검색 유입"
            />
            <Split />
            <SearchWordCard
              data={rebuildSNSData}
              isGrowth={false}
              title="외부 SNS 유입"
            />
          </>
        )}
      </Body>
    </Component>
  );
};

export default ReferrerReportCard;
