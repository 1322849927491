import React from 'react';
import styled, { css } from 'styled-components';
import { IconDown, IconUp } from '../../Atoms/Icon';
import { P2 } from '../../Atoms/Typo';

const Component = styled.div<{ $isUP: boolean }>`
  display: flex;
  align-items: center;
  height: 21px;

  ${P2} {
    font-weight: 500;
    height: 21px;
    line-height: 1;
    margin-left: 8px;
    ${(props) => {
      if (props.$isUP) {
        return css`
          color: ${props.theme.colors.success5};
        `;
      }
      return css`
        color: ${props.theme.colors.danger5};
      `;
    }}
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

interface RateCountProps {
  rate?: number;
}

const RateCount = ({ rate }: RateCountProps) => {
  const printIcon = () => {
    if (rate) {
      if (rate >= 0) {
        return <IconUp />;
      }
      return <IconDown />;
    }
    return null;
  };

  return (
    <Component $isUP={(rate || 0) >= 0}>
      {rate !== undefined && rate !== null ? (
        <>
          {printIcon()}
          <P2>{Math.round(rate * 100) / 100} %</P2>
        </>
      ) : (
        <P2>-</P2>
      )}
    </Component>
  );
};

export default RateCount;
