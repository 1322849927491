import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { H4, P2 } from '../../../Atoms/Typo';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 120px;
  position: relative;
`;

const PieChartBox = styled(ResponsiveContainer)`
  width: 116px !important;
`;

const PieChartLegendWrapper = styled.div`
  margin-left: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PieChartLegendBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const LegendDot = styled.div<{ $isNew: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${(props) => {
    if (props.$isNew) {
      return props.theme.colors.orange4;
    }
    return props.theme.colors.green4;
  }};
`;

const LegentTitle = styled.div`
  ${P2} {
    color: ${(props) => props.theme.colors.grey700};
  }
  margin-left: 8px;
`;

const LegendRate = styled.div`
  margin-left: 10px;
`;

const COLORS = ['#ced535', '#ffba64'];

interface DefaultPieChartProps {
  data?: {
    name: string;
    value: number;
  }[];
}

const DefaultPieChart = ({ data }: DefaultPieChartProps) => {
  return (
    <Component className="voda_pie_chart">
      {data ? (
        <>
          <PieChartBox>
            <PieChart>
              <Pie data={data} outerRadius={58} dataKey="value">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </PieChartBox>
          <PieChartLegendWrapper>
            {data.map((element, index) => {
              return (
                <PieChartLegendBox key={element.name}>
                  <LegendDot $isNew={index === 1} />
                  <LegentTitle>
                    <P2>{element.name}</P2>
                  </LegentTitle>
                  <LegendRate>
                    <H4>{element.value}%</H4>
                  </LegendRate>
                </PieChartLegendBox>
              );
            })}
          </PieChartLegendWrapper>
        </>
      ) : (
        <DataError />
      )}
    </Component>
  );
};

export default DefaultPieChart;
