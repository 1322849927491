import { createReducer } from 'typesafe-actions';
import {
  GET_AIVORY_DASHBOARD_STATICS,
  GET_AIVORY_DASHBOARD_STATICS_ERROR,
  GET_AIVORY_DASHBOARD_STATICS_SUCCESS,
  SET_ERROR_NULL_AIVORY_DASHBOARD,
} from './actions';
import { dashboardAction, dashboardState } from './types';

const initialState: dashboardState = {
  isLoading: false,
  dashboard_data: null,
  aivoryDashboardError: null,
};

const reducer = createReducer<dashboardState, dashboardAction>(initialState, {
  [GET_AIVORY_DASHBOARD_STATICS]: (state) => ({
    ...state,
    isLoading: true,
    dashboard_data: null,
    aivoryDashboardError: null,
  }),
  [GET_AIVORY_DASHBOARD_STATICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoading: false,
    dashboard_data: action.payload.payload,
  }),
  [GET_AIVORY_DASHBOARD_STATICS_ERROR]: (state, action) => ({
    ...state,
    isLoading: false,
    dashboard_data: null,
    aivoryDashboardError: action.payload,
  }),

  [SET_ERROR_NULL_AIVORY_DASHBOARD]: (state) => ({
    ...state,
    aivoryDashboardError: null,
  }),
});

export default reducer;
