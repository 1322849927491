import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Chart from 'react-apexcharts';
import { H2, P4 } from '../../../Atoms/Typo';
import DataError from '../../../Atoms/DataError';
import RateCount from '../../Count/RateCount';

const Component = styled.div<{ $id: number | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 240px;

  .apexcharts-pie-series {
    filter: none;
    ${(props) => {
      if (props.$id === null) {
        return css``;
      }
      if (props.$id === 0) {
        return css`
          &:nth-child(1) {
            path {
              filter: none;
              fill: ${props.theme.colors.primary};
            }
          }
        `;
      }
      if (props.$id) {
        return css`
          &:nth-child(${props.$id + 1}) {
            path {
              filter: none;
              fill: ${props.theme.colors.grey500};
            }
          }
        `;
      }

      return css``;
    }}
  }
`;

const ChartDataLabelWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartDataLabelTitle = styled(P4)`
  color: ${(props) => props.theme.colors.grey500};
`;

const ChartDataLabelRate = styled(H2)<{ $id: number | null }>`
  font-size: 28px;
`;

interface DounutChartProps {
  data: {
    group: string;
    rate: number;
  }[];
  isBot?: boolean;
  previousMonth?: number;
}

const DounutChart = ({ data, isBot, previousMonth }: DounutChartProps) => {
  const [seriesState, setSeries] = useState<number[]>([]);
  const [mouseOverKey, setMouseOverKey] = useState<number | null>(null);

  const DonutChartOnMouseEnter = (e: any, chartContext: any, config: any) => {
    if (config.dataPointIndex !== -1) {
      const index = config.dataPointIndex;
      setMouseOverKey(index);
    } else {
      setMouseOverKey(null);
    }
  };

  const DonutChartOnMouseLeave = (e: any, chartContext: any, config: any) => {
    setMouseOverKey(null);
  };

  const options = {
    chart: {
      type: 'donut' as const,
      events: {
        dataPointMouseEnter: DonutChartOnMouseEnter,
        dataPointMouseLeave: DonutChartOnMouseLeave,
      },
    },
    colors: [
      '#b597f7',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
      '#e0e0e0',
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,

        donut: {
          size: '80%',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const printChart = useMemo(() => {
    if (seriesState.length > 0) {
      return (
        <Chart
          type="donut"
          series={seriesState}
          options={options}
          width="92%"
          height="92%"
        />
      );
    }
    return <DataError />;
  }, [seriesState]);

  const printDataLabelMemo = useMemo(() => {
    if (isBot && data.length > 0) {
      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelTitle>전월 대비</ChartDataLabelTitle>
          <RateCount rate={previousMonth || 0} />
        </ChartDataLabelWrapper>
      );
    }
    if (mouseOverKey !== null && data.length > 0) {
      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelTitle>{data[mouseOverKey].group}</ChartDataLabelTitle>
          <ChartDataLabelRate $id={mouseOverKey}>
            {data[mouseOverKey].rate}%
          </ChartDataLabelRate>
        </ChartDataLabelWrapper>
      );
    }
    if (data.length > 0) {
      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelTitle>{data[0].group}</ChartDataLabelTitle>
          <ChartDataLabelRate $id={mouseOverKey}>
            {data[0].rate}%
          </ChartDataLabelRate>
        </ChartDataLabelWrapper>
      );
    }
    return [];
  }, [mouseOverKey, data]);

  useEffect(() => {
    if (data.length > 0) {
      const seriesTemp: number[] = [];
      data.some((element) => {
        seriesTemp.push(element.rate);
        return false;
      });
      setSeries(seriesTemp);
    }
  }, [data]);

  return (
    <Component $id={mouseOverKey}>
      {printChart}
      {printDataLabelMemo}
    </Component>
  );
};

export default DounutChart;
