import React from 'react';

import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
// import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../Organisms/Header';

const DashboardTemplate = () => {
  return (
    <ContentsLayout>
      <Header title="대시보드" isDatePicker={false} />
      <ContentBodyLayout />
    </ContentsLayout>
  );
};

export default DashboardTemplate;
