import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  GetBotTrafficPageResponseType,
  GetBotTrafficSiteResponseType,
  GetExternalNewsKeywordNetworkResponseType,
  GetExternalNewsKeywordWordcloudResponseType,
  GetExternalReferrerResponseType,
  GetForecastResponseType,
  GetLocalPVResponseType,
  GetLocalRegionResponseType,
  GetLocalUVResponseType,
  GetNewVSReturnDurationResponseType,
  GetNewVSReturnPVResponseType,
  GetNewVSReturnUVResponseType,
  GetPageUsabilityClickRateResponseType,
  GetPageUsabilityPageDurationResponseType,
  GetPopularPageGrowthResponseType,
  GetPopularPageMostResponseType,
  GetSearchWordResponseType,
  GetServiceActivationResponseType,
  GetSimilarAgencyPVResponseType,
  GetSimilarAgencyTypeResponseType,
  GetSimilarAgencyUVResponseType,
  GetSimilarScalePVResponseType,
  GetSimilarScaleTypeResponseType,
} from '../../api/report/type';
import { AxiosErrorType } from '../createRequestSaga';

export const GET_SERVICE_ACTIVATION_UV = 'report/GET_SERVICE_ACTIVATION_UV' as const;
export const GET_SERVICE_ACTIVATION_UV_SUCCESS = 'report/GET_SERVICE_ACTIVATION_UV_SUCCESS' as const;
export const GET_SERVICE_ACTIVATION_UV_ERROR = 'report/GET_SERVICE_ACTIVATION_UV_ERROR' as const;

export const GET_SERVICE_ACTIVATION_PV = 'report/GET_SERVICE_ACTIVATION_PV' as const;
export const GET_SERVICE_ACTIVATION_PV_SUCCESS = 'report/GET_SERVICE_ACTIVATION_PV_SUCCESS' as const;
export const GET_SERVICE_ACTIVATION_PV_ERROR = 'report/GET_SERVICE_ACTIVATION_PV_ERROR' as const;

export const GET_NEW_VS_RETURN_UV = 'report/GET_NEW_VS_RETURN_UV' as const;
export const GET_NEW_VS_RETURN_UV_SUCCESS = 'report/GET_NEW_VS_RETURN_UV_SUCCESS' as const;
export const GET_NEW_VS_RETURN_UV_ERROR = 'report/GET_NEW_VS_RETURN_UV_ERROR' as const;

export const GET_NEW_VS_RETURN_PV = 'report/GET_NEW_VS_RETURN_PV' as const;
export const GET_NEW_VS_RETURN_PV_SUCCESS = 'report/GET_NEW_VS_RETURN_PV_SUCCESS' as const;
export const GET_NEW_VS_RETURN_PV_ERROR = 'report/GET_NEW_VS_RETURN_PV_ERROR' as const;

export const GET_NEW_VS_RETURN_DURATION = 'report/GET_NEW_VS_RETURN_DURATION' as const;
export const GET_NEW_VS_RETURN_DURATION_SUCCESS = 'report/GET_NEW_VS_RETURN_DURATION_SUCCESS' as const;
export const GET_NEW_VS_RETURN_DURATION_ERROR = 'report/GET_NEW_VS_RETURN_DURATION_ERROR' as const;

export const GET_POPULAR_PAGE_MOST = 'report/GET_POPULAR_PAGE_MOST' as const;
export const GET_POPULAR_PAGE_MOST_SUCCESS = 'report/GET_POPULAR_PAGE_MOST_SUCCESS' as const;
export const GET_POPULAR_PAGE_MOST_ERROR = 'report/GET_POPULAR_PAGE_MOST_ERROR' as const;

export const GET_POPULAR_PAGE_GROWTH = 'report/GET_POPULAR_PAGE_GROWTH' as const;
export const GET_POPULAR_PAGE_GROWTH_SUCCESS = 'report/GET_POPULAR_PAGE_GROWTH_SUCCESS' as const;
export const GET_POPULAR_PAGE_GROWTH_ERROR = 'report/GET_POPULAR_PAGE_GROWTH_ERROR' as const;

export const GET_SEARCH_WORD_MOST = 'report/GET_SEARCH_WORD_MOST' as const;
export const GET_SEARCH_WORD_MOST_SUCCESS = 'report/GET_SEARCH_WORD_MOST_SUCCESS' as const;
export const GET_SEARCH_WORD_MOST_ERROR = 'report/GET_SEARCH_WORD_MOST_ERROR' as const;

export const GET_SEARCH_WORD_GROWTH = 'report/GET_SEARCH_WORD_GROWTH' as const;
export const GET_SEARCH_WORD_GROWTH_SUCCESS = 'report/GET_SEARCH_WORD_GROWTH_SUCCESS' as const;
export const GET_SEARCH_WORD_GROWTH_ERROR = 'report/GET_SEARCH_WORD_GROWTH_ERROR' as const;

export const GET_LOCAL_REGION = 'report/GET_LOCAL_REGION' as const;
export const GET_LOCAL_REGION_SUCCESS = 'report/GET_LOCAL_REGION_SUCCESS' as const;
export const GET_LOCAL_REGION_ERROR = 'report/GET_LOCAL_REGION_ERROR' as const;

export const GET_LOCAL_UV = 'report/GET_LOCAL_UV' as const;
export const GET_LOCAL_UV_SUCCESS = 'report/GET_LOCAL_UV_SUCCESS' as const;
export const GET_LOCAL_UV_ERROR = 'report/GET_LOCAL_UV_ERROR' as const;

export const GET_LOCAL_PV = 'report/GET_LOCAL_PV' as const;
export const GET_LOCAL_PV_SUCCESS = 'report/GET_LOCAL_PV_SUCCESS' as const;
export const GET_LOCAL_PV_ERROR = 'report/GET_LOCAL_PV_ERROR' as const;

export const GET_SIMILAR_AGENCY_TYPE = 'report/GET_SIMILAR_AGENCY_TYPE' as const;
export const GET_SIMILAR_AGENCY_TYPE_SUCCESS = 'report/GET_SIMILAR_AGENCY_TYPE_SUCCESS' as const;
export const GET_SIMILAR_AGENCY_TYPE_ERROR = 'report/GET_SIMILAR_AGENCY_TYPE_ERROR' as const;

export const GET_SIMILAR_AGENCY_UV = 'report/GET_SIMILAR_AGENCY_UV' as const;
export const GET_SIMILAR_AGENCY_UV_SUCCESS = 'report/GET_SIMILAR_AGENCY_UV_SUCCESS' as const;
export const GET_SIMILAR_AGENCY_UV_ERROR = 'report/GET_SIMILAR_AGENCY_UV_ERROR' as const;

export const GET_SIMILAR_AGENCY_PV = 'report/GET_SIMILAR_AGENCY_PV' as const;
export const GET_SIMILAR_AGENCY_PV_SUCCESS = 'report/GET_SIMILAR_AGENCY_PV_SUCCESS' as const;
export const GET_SIMILAR_AGENCY_PV_ERROR = 'report/GET_SIMILAR_AGENCY_PV_ERROR' as const;

export const GET_SIMILAR_SCALE_TYPE = 'report/GET_SIMILAR_SCALE_TYPE' as const;
export const GET_SIMILAR_SCALE_TYPE_SUCCESS = 'report/GET_SIMILAR_SCALE_TYPE_SUCCESS' as const;
export const GET_SIMILAR_SCALE_TYPE_ERROR = 'report/GET_SIMILAR_SCALE_TYPE_ERROR' as const;

export const GET_SIMILAR_SCALE_PV = 'report/GET_SIMILAR_SCALE_PV' as const;
export const GET_SIMILAR_SCALE_PV_SUCCESS = 'report/GET_SIMILAR_SCALE_PV_SUCCESS' as const;
export const GET_SIMILAR_SCALE_PV_ERROR = 'report/GET_SIMILAR_SCALE_PV_ERROR' as const;

export const GET_FORECAST_UV = 'report/GET_FORECAST_UV' as const;
export const GET_FORECAST_UV_SUCCESS = 'report/GET_FORECAST_UV_SUCCESS' as const;
export const GET_FORECAST_UV_ERROR = 'report/GET_FORECAST_UV_ERROR' as const;

export const GET_FORECAST_PV = 'report/GET_FORECAST_PV' as const;
export const GET_FORECAST_PV_SUCCESS = 'report/GET_FORECAST_PV_SUCCESS' as const;
export const GET_FORECAST_PV_ERROR = 'report/GET_FORECAST_PV_ERROR' as const;

export const GET_PAGE_USABILITY_CLICK_RATE = 'report/GET_PAGE_USABILITY_CLICK_RATE' as const;
export const GET_PAGE_USABILITY_CLICK_RATE_SUCCESS = 'report/GET_PAGE_USABILITY_CLICK_RATE_SUCCESS' as const;
export const GET_PAGE_USABILITY_CLICK_RATE_ERROR = 'report/GET_PAGE_USABILITY_CLICK_RATE_ERROR' as const;

export const GET_PAGE_USABILITY_PAGE_DURATION = 'report/GET_PAGE_USABILITY_PAGE_DURATION' as const;
export const GET_PAGE_USABILITY_PAGE_DURATION_SUCCESS = 'report/GET_PAGE_USABILITY_PAGE_DURATION_SUCCESS' as const;
export const GET_PAGE_USABILITY_PAGE_DURATION_ERROR = 'report/GET_PAGE_USABILITY_PAGE_DURATION_ERROR' as const;

export const GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD = 'report/GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD' as const;
export const GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_SUCCESS = 'report/GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_SUCCESS' as const;
export const GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_ERROR = 'report/GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_ERROR' as const;

export const GET_EXTERNAL_NEWS_KEYWORD_NETWORK = 'report/GET_EXTERNAL_NEWS_KEYWORD_NETWORK' as const;
export const GET_EXTERNAL_NEWS_KEYWORD_NETWORK_SUCCESS = 'report/GET_EXTERNAL_NEWS_KEYWORD_NETWORK_SUCCESS' as const;
export const GET_EXTERNAL_NEWS_KEYWORD_NETWORK_ERROR = 'report/GET_EXTERNAL_NEWS_KEYWORD_NETWORK_ERROR' as const;

export const GET_EXTERNAL_REFERRER_SEARCH = 'report/GET_EXTERNAL_REFERRER_SEARCH' as const;
export const GET_EXTERNAL_REFERRER_SEARCH_SUCCESS = 'report/GET_EXTERNAL_REFERRER_SEARCH_SUCCESS' as const;
export const GET_EXTERNAL_REFERRER_SEARCH_ERROR = 'report/GET_EXTERNAL_REFERRER_SEARCH_ERROR' as const;

export const GET_EXTERNAL_REFERRER_SNS = 'report/GET_EXTERNAL_REFERRER_SNS' as const;
export const GET_EXTERNAL_REFERRER_SNS_SUCCESS = 'report/GET_EXTERNAL_REFERRER_SNS_SUCCESS' as const;
export const GET_EXTERNAL_REFERRER_SNS_ERROR = 'report/GET_EXTERNAL_REFERRER_SNS_ERROR' as const;

export const GET_BOT_TRAFFIC_SITE = 'report/GET_BOT_TRAFFIC_SITE' as const;
export const GET_BOT_TRAFFIC_SITE_SUCCESS = 'report/GET_BOT_TRAFFIC_SITE_SUCCESS' as const;
export const GET_BOT_TRAFFIC_SITE_ERROR = 'report/GET_BOT_TRAFFIC_SITE_ERROR' as const;

export const GET_BOT_TRAFFIC_PAGE = 'report/GET_BOT_TRAFFIC_PAGE' as const;
export const GET_BOT_TRAFFIC_PAGE_SUCCESS = 'report/GET_BOT_TRAFFIC_PAGE_SUCCESS' as const;
export const GET_BOT_TRAFFIC_PAGE_ERROR = 'report/GET_BOT_TRAFFIC_PAGE_ERROR' as const;

export const SET_ERROR_NULL = 'report/SET_ERROR_NULL' as const;

export const getServiceActivationUVAsync = createAsyncAction(
  GET_SERVICE_ACTIVATION_UV,
  GET_SERVICE_ACTIVATION_UV_SUCCESS,
  GET_SERVICE_ACTIVATION_UV_ERROR
)<void, GetServiceActivationResponseType, AxiosErrorType>();

export const getServiceActivationPVAsync = createAsyncAction(
  GET_SERVICE_ACTIVATION_PV,
  GET_SERVICE_ACTIVATION_PV_SUCCESS,
  GET_SERVICE_ACTIVATION_PV_ERROR
)<void, GetServiceActivationResponseType, AxiosErrorType>();

export const getNewVSReturnUVAsync = createAsyncAction(
  GET_NEW_VS_RETURN_UV,
  GET_NEW_VS_RETURN_UV_SUCCESS,
  GET_NEW_VS_RETURN_UV_ERROR
)<void, GetNewVSReturnUVResponseType, AxiosErrorType>();

export const getNewVSReturnDurationAsync = createAsyncAction(
  GET_NEW_VS_RETURN_DURATION,
  GET_NEW_VS_RETURN_DURATION_SUCCESS,
  GET_NEW_VS_RETURN_DURATION_ERROR
)<void, GetNewVSReturnDurationResponseType, AxiosErrorType>();

export const getNewVSReturnPVAsync = createAsyncAction(
  GET_NEW_VS_RETURN_PV,
  GET_NEW_VS_RETURN_PV_SUCCESS,
  GET_NEW_VS_RETURN_PV_ERROR
)<void, GetNewVSReturnPVResponseType, AxiosErrorType>();

export const getPopularPageMostAsync = createAsyncAction(
  GET_POPULAR_PAGE_MOST,
  GET_POPULAR_PAGE_MOST_SUCCESS,
  GET_POPULAR_PAGE_MOST_ERROR
)<void, GetPopularPageMostResponseType, AxiosErrorType>();

export const getPopularPageGrowthAsync = createAsyncAction(
  GET_POPULAR_PAGE_GROWTH,
  GET_POPULAR_PAGE_GROWTH_SUCCESS,
  GET_POPULAR_PAGE_GROWTH_ERROR
)<void, GetPopularPageGrowthResponseType, AxiosErrorType>();

export const getSearchWordMostAsync = createAsyncAction(
  GET_SEARCH_WORD_MOST,
  GET_SEARCH_WORD_MOST_SUCCESS,
  GET_SEARCH_WORD_MOST_ERROR
)<void, GetSearchWordResponseType, AxiosErrorType>();

export const getSearchWordGrowthAsync = createAsyncAction(
  GET_SEARCH_WORD_GROWTH,
  GET_SEARCH_WORD_GROWTH_SUCCESS,
  GET_SEARCH_WORD_GROWTH_ERROR
)<void, GetSearchWordResponseType, AxiosErrorType>();

export const getLocalRegionAsync = createAsyncAction(
  GET_LOCAL_REGION,
  GET_LOCAL_REGION_SUCCESS,
  GET_LOCAL_REGION_ERROR
)<void, GetLocalRegionResponseType, AxiosErrorType>();

export const getLocalUVAsync = createAsyncAction(
  GET_LOCAL_UV,
  GET_LOCAL_UV_SUCCESS,
  GET_LOCAL_UV_ERROR
)<void, GetLocalUVResponseType, AxiosErrorType>();

export const getLocalPVAsync = createAsyncAction(
  GET_LOCAL_PV,
  GET_LOCAL_PV_SUCCESS,
  GET_LOCAL_PV_ERROR
)<void, GetLocalPVResponseType, AxiosErrorType>();

export const getSimilarAgencyTypeAsync = createAsyncAction(
  GET_SIMILAR_AGENCY_TYPE,
  GET_SIMILAR_AGENCY_TYPE_SUCCESS,
  GET_SIMILAR_AGENCY_TYPE_ERROR
)<void, GetSimilarAgencyTypeResponseType, AxiosErrorType>();

export const getSimilarAgencyUVAsync = createAsyncAction(
  GET_SIMILAR_AGENCY_UV,
  GET_SIMILAR_AGENCY_UV_SUCCESS,
  GET_SIMILAR_AGENCY_UV_ERROR
)<void, GetSimilarAgencyUVResponseType, AxiosErrorType>();

export const getSimilarAgencyPVAsync = createAsyncAction(
  GET_SIMILAR_AGENCY_PV,
  GET_SIMILAR_AGENCY_PV_SUCCESS,
  GET_SIMILAR_AGENCY_PV_ERROR
)<void, GetSimilarAgencyPVResponseType, AxiosErrorType>();

export const getSimilarScaleTypeAsync = createAsyncAction(
  GET_SIMILAR_SCALE_TYPE,
  GET_SIMILAR_SCALE_TYPE_SUCCESS,
  GET_SIMILAR_SCALE_TYPE_ERROR
)<void, GetSimilarScaleTypeResponseType, AxiosErrorType>();

export const getSimilarScalePVAsync = createAsyncAction(
  GET_SIMILAR_SCALE_PV,
  GET_SIMILAR_SCALE_PV_SUCCESS,
  GET_SIMILAR_SCALE_PV_ERROR
)<void, GetSimilarScalePVResponseType, AxiosErrorType>();

export const getForecastUVAsync = createAsyncAction(
  GET_FORECAST_UV,
  GET_FORECAST_UV_SUCCESS,
  GET_FORECAST_UV_ERROR
)<void, GetForecastResponseType, AxiosErrorType>();

export const getForecastPVAsync = createAsyncAction(
  GET_FORECAST_PV,
  GET_FORECAST_PV_SUCCESS,
  GET_FORECAST_PV_ERROR
)<void, GetForecastResponseType, AxiosErrorType>();

export const getPageUsabilityClickRateAsync = createAsyncAction(
  GET_PAGE_USABILITY_CLICK_RATE,
  GET_PAGE_USABILITY_CLICK_RATE_SUCCESS,
  GET_PAGE_USABILITY_CLICK_RATE_ERROR
)<void, GetPageUsabilityClickRateResponseType, AxiosErrorType>();

export const getPageUsabilityPageDurationAsync = createAsyncAction(
  GET_PAGE_USABILITY_PAGE_DURATION,
  GET_PAGE_USABILITY_PAGE_DURATION_SUCCESS,
  GET_PAGE_USABILITY_PAGE_DURATION_ERROR
)<void, GetPageUsabilityPageDurationResponseType, AxiosErrorType>();

export const getExternalNewsKeywordWordcloudAsync = createAsyncAction(
  GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD,
  GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_SUCCESS,
  GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_ERROR
)<void, GetExternalNewsKeywordWordcloudResponseType, AxiosErrorType>();

export const getExternalNewsKeywordNetworkAsync = createAsyncAction(
  GET_EXTERNAL_NEWS_KEYWORD_NETWORK,
  GET_EXTERNAL_NEWS_KEYWORD_NETWORK_SUCCESS,
  GET_EXTERNAL_NEWS_KEYWORD_NETWORK_ERROR
)<void, GetExternalNewsKeywordNetworkResponseType, AxiosErrorType>();

export const getExternalReferrerSearchAsync = createAsyncAction(
  GET_EXTERNAL_REFERRER_SEARCH,
  GET_EXTERNAL_REFERRER_SEARCH_SUCCESS,
  GET_EXTERNAL_REFERRER_SEARCH_ERROR
)<void, GetExternalReferrerResponseType, AxiosErrorType>();

export const getExternalReferrerSNSAsync = createAsyncAction(
  GET_EXTERNAL_REFERRER_SNS,
  GET_EXTERNAL_REFERRER_SNS_SUCCESS,
  GET_EXTERNAL_REFERRER_SNS_ERROR
)<void, GetExternalReferrerResponseType, AxiosErrorType>();

export const getBotTrafficSiteAsync = createAsyncAction(
  GET_BOT_TRAFFIC_SITE,
  GET_BOT_TRAFFIC_SITE_SUCCESS,
  GET_BOT_TRAFFIC_SITE_ERROR
)<void, GetBotTrafficSiteResponseType, AxiosErrorType>();

export const getBotTrafficPageAsync = createAsyncAction(
  GET_BOT_TRAFFIC_PAGE,
  GET_BOT_TRAFFIC_PAGE_SUCCESS,
  GET_BOT_TRAFFIC_PAGE_ERROR
)<void, GetBotTrafficPageResponseType, AxiosErrorType>();

export const setErrorNull = createAction(SET_ERROR_NULL)();
