import styled, { css } from 'styled-components';

export const TableRow = styled.tr<{ $isHighlight?: boolean }>`
  /* width: 100%; */
  border-bottom: 1px solid ${(props) => props.theme.colors.grey300};
  ${(props) => {
    if (props.$isHighlight) {
      return css`
        background-color: #fdeed6;
      `;
    }
    return css``;
  }}
`;

export const TableSubRow = styled.tr`
  /* width: 100%; */
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  &:last-child {
    border-bottom: none;
  }
`;

export default TableRow;
