import axios from 'axios';
import config from '../../config-api.json';
import { GetPageFlowParamType, GetPageFlowResponseType } from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetPageFlow(info: GetPageFlowParamType) {
  const response = await axios.get<GetPageFlowResponseType>(
    `${apiInfo.api_url}/page_flow/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        position: info.position,
        page_location: info.page_location,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export { apiGetPageFlow as default };
