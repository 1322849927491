import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../modules';

// components

import { GetAIVORYDashboardStaticsParamType } from '../../../api/aivoryUsageAnalytics/dashboard/type';
import { getAIVORYDashboardStaticsAsync } from '../../../modules/aivoryUsageAnalytics/dashboard';
import TabButton from '../../../components/Molecule/TabButton';
import Header from '../../../components/Organisms/Header';
import DashboardDefaultCard from '../../../components/Organisms/Card/DashboardDefaultCard';
import WordCloudChartCard from '../../../components/Organisms/Card/WordCloudChartCard';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';

const AIVORYDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, dashboard_data } = useSelector(
    (state: RootState) => state.aivoryDashboard
  );
  const onGetAIVORYDashboardStatics = (
    info: GetAIVORYDashboardStaticsParamType
  ) => {
    dispatch(getAIVORYDashboardStaticsAsync.request(info));
  };
  const [activeTabID, setActiveTabID] = useState(0);

  const [activeDurationName, setActiveDurationName] = useState('day');

  useEffect(() => {
    onGetAIVORYDashboardStatics({
      unit: activeDurationName,
    });
  }, [activeDurationName]);

  const onClickTab = (id: number) => {
    setActiveTabID(id);
    switch (id) {
      case 0:
        setActiveDurationName('day');
        break;
      case 1:
        setActiveDurationName('week');
        break;
      case 2:
        setActiveDurationName('month');
        break;
      default:
        setActiveDurationName('day');
        break;
    }
  };

  return (
    <ContentsLayout>
      <Header title="AIVORY 이용 분석" isDatePicker={false}>
        <TabButton
          buttonData={[
            {
              id: 0,
              title: '어제',
            },
            {
              id: 1,
              title: '지난주',
            },
            {
              id: 2,
              title: '지난달',
            },
          ]}
          activeID={activeTabID}
          onClickTab={onClickTab}
        />
      </Header>
      <ContentBodyLayout>
        <ContentsItemLayout desktop={3}>
          <DashboardDefaultCard
            titleEng="Search Queries"
            titleKor="검색 이용 횟수"
            rate={dashboard_data ? dashboard_data.search_count.rate : undefined}
            value={
              dashboard_data ? dashboard_data.search_count.count : undefined
            }
            isLoading={isLoading}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={3}>
          <DashboardDefaultCard
            titleEng="Contents Consumption"
            titleKor="콘텐츠 이용 개수"
            rate={
              dashboard_data ? dashboard_data.contents_count.rate : undefined
            }
            value={
              dashboard_data ? dashboard_data.contents_count.count : undefined
            }
            isLoading={isLoading}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={3}>
          <DashboardDefaultCard
            titleEng="Search Queires per UV"
            titleKor="1인당 평균 검색 이용 횟수"
            rate={
              dashboard_data
                ? dashboard_data.search_count_per_session.rate
                : undefined
            }
            value={
              dashboard_data
                ? dashboard_data.search_count_per_session.count
                : undefined
            }
            isLoading={isLoading}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={3}>
          <DashboardDefaultCard
            titleEng="Contents Consumption per UV"
            titleKor="1인당 평균 콘텐츠 이용 개수"
            rate={
              dashboard_data
                ? dashboard_data.contents_count_per_session.rate
                : undefined
            }
            value={
              dashboard_data
                ? dashboard_data.contents_count_per_session.count
                : undefined
            }
            isLoading={isLoading}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={12}>
          <WordCloudChartCard
            isTopic={false}
            isLoading={isLoading}
            data={dashboard_data ? dashboard_data.search_cloud : undefined}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default AIVORYDashboard;
