import { createAction } from 'typesafe-actions';

export const SET_SUB_MENU_LINK_COMPONENT = 'sidebarNav/SET_SUB_MENU_LINK_COMPNENT' as const;
export const SET_IS_GNB_FOLD = 'sidebarNav/SET_IS_GNB_FOLD' as const;

export const setSubMenuLinkComponent = createAction(
  SET_SUB_MENU_LINK_COMPONENT
)<JSX.Element[] | null>();

export const setIsGNBFold = createAction(SET_IS_GNB_FOLD)<boolean | null>();
