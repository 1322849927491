import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from 'antd/lib/date-picker';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import styled, { createGlobalStyle, css } from 'styled-components';
import moment from 'moment';

import 'moment/locale/ko';

import { RangeValue } from 'rc-picker/lib/interface';
import { RangeInfo } from 'rc-picker/lib/RangePicker';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IconCalendar } from '../../Atoms/Icon';

const { RangePicker } = DatePicker;

const SettingDurationWrapper = styled.div`
  width: 304px;
  height: 36px;
  background-color: white;
  border-radius: 4px;

  .ant-picker,
  .ant-picker-range {
    width: 100%;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    .picker-date-text {
      font-weight: 400;
      font-size: 14px;
      color: ${(props) => props.theme.colors.grey500};
      margin-right: 4px;
    }

    .ant-picker-input {
      input {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.colors.grey600};
      }

      width: 55px;
    }

    &.ant-picker-focused {
      .ant-picker-input {
        &.ant-picker-input-active {
          input {
            color: ${(props) => props.theme.colors.primary};
          }
        }
      }
    }
    .ant-picker-suffix {
      order: -1;
      margin-left: 0px;
      margin-right: 8px;
      svg {
        width: 18px;
      }
    }
    .ant-picker-range-separator {
      margin-left: 16px;
      margin-right: 16px;
      padding: 0px;
    }
    .ant-picker-active-bar {
      display: none;
    }
  }
`;

const Separator = styled.div`
  width: 16px;
  height: 2px;
  background-color: ${(props) => props.theme.colors.grey300};
`;

const GlobalStyle = createGlobalStyle`${css`
  .ant-picker-cell {
    padding: 12px 0px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${(props) => props.theme.colors.purple3};
    opacity: 0.2;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${(props) => props.theme.colors.purple3};
    opacity: 0.2;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${(props) => props.theme.colors.primary};
  }

  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-in-range)::before {
    width: 24px;
    height: 27px;
    border-radius: 12px;
    background-color: ${(props) => props.theme.colors.grey900};
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today {
    .ant-picker-cell-inner {
      ::before {
        border: none;
      }
      ::after {
        content: 'TODAY';
        color: ${(props) => props.theme.colors.grey900};
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translate(-50%, 0%);
        font-weight: 400;
        font-size: 10px;
        width: 32px;
        height: 12px;
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-in-range) {
    .ant-picker-cell-inner {
      color: white !important;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
    background: ${(props) => props.theme.colors.purple3};
    opacity: 0.4;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
    background: ${(props) => props.theme.colors.purple3};
    opacity: 0.2;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: ${(props) => props.theme.colors.purple3};
    opacity: 0.2;
  }
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: ${(props) => props.theme.colors.purple3};
    opacity: 0.4;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`}`;

interface DefaultDatePickerProps extends RouteComponentProps {
  startDate?: string;
  endDate?: string;
  createdTime?: string;
  futureDateDays?: number;
  setStartDate?: (date: string) => void;
  setEndDate?: (date: string) => void;
  setIsDatePickerToggle?: (isToggle: boolean) => void;
}

const DefaultDatePicker = ({
  startDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD'),
  endDate = moment().format('YYYY-MM-DD'),
  createdTime = '2021-01-01',
  futureDateDays = 0,
  location,
  setStartDate,
  setEndDate,
  setIsDatePickerToggle,
}: DefaultDatePickerProps) => {
  const date_format = 'YY.MM.DD';
  const start_day_text_el = document.createElement('div');
  start_day_text_el.className = 'picker-date-text';
  start_day_text_el.textContent = '시작일';

  const end_day_text_el = document.createElement('div');
  end_day_text_el.className = 'picker-date-text';
  end_day_text_el.textContent = '종료일';

  const datePickerRef = useRef<HTMLDivElement>(null);

  const [futureRouter] = useState<{ [key: string]: boolean }>({
    '/serviceAnalytics/userAnalytics': true,
    '/serviceAnalytics/pageViewAnalytics': true,
    '/serviceAnalytics/sessionAnalytics': true,
  });

  const defaultStartDate = (start_date: string, created_time: string) => {
    const ct = moment(created_time);
    const four_weeks_ago = moment(start_date);

    if (ct.isAfter(four_weeks_ago)) {
      if (ct.isBefore(moment())) {
        if (setStartDate) {
          setStartDate(created_time);
        }

        return ct;
      }

      if (setStartDate) {
        setStartDate(moment().format('YYYY-MM-DD'));
      }
      return moment();
    }

    return four_weeks_ago;
  };

  useEffect(() => {
    const { current } = datePickerRef;

    if (current) {
      const datepickerEl = current.getElementsByClassName('ant-picker-range');

      if (datepickerEl.length > 0 && datepickerEl[0].hasChildNodes()) {
        if (datepickerEl[0].childElementCount === 5) {
          datepickerEl[0].insertBefore(
            start_day_text_el,
            datepickerEl[0].children[0]
          );

          datepickerEl[0].insertBefore(
            end_day_text_el,
            datepickerEl[0].children[3]
          );
        }
      }
    }
  }, [datePickerRef]);

  const disabledDate = (current: any) => {
    const ct = moment(createdTime);

    if (futureRouter[location.pathname]) {
      return (
        (current &&
          current > moment().endOf('day').add(futureDateDays, 'days')) ||
        current < ct.startOf('day')
      );
    }

    return (
      (current && current > moment().endOf('day')) ||
      current < ct.startOf('day')
    );
  };

  const onCalendarChange = (
    values: RangeValue<moment.Moment>,
    formatString: [string, string],
    info: RangeInfo
  ) => {
    if (info.range === 'start') {
      if (values && values[0] && setStartDate) {
        setStartDate(values[0].format('YYYY-MM-DD'));
      }
    }

    if (info.range === 'end') {
      if (values && values[1] && setEndDate) {
        setEndDate(values[1].format('YYYY-MM-DD'));
      }
    }
  };

  const onOpenChange = (open: boolean) => {
    if (setIsDatePickerToggle) {
      setIsDatePickerToggle(open);
    }
  };

  useEffect(() => {
    if (!futureRouter[location.pathname]) {
      const today = moment();
      const ct = moment(endDate);

      if (ct.isAfter(today) && setEndDate) {
        setEndDate(today.format('YYYY-MM-DD'));
      }
    }
  }, [location]);

  return (
    <SettingDurationWrapper ref={datePickerRef} className="voda_date_picker">
      <GlobalStyle />

      <RangePicker
        defaultValue={[
          moment(defaultStartDate(startDate, createdTime), date_format),
          moment(moment(endDate), date_format),
        ]}
        value={[
          moment(moment(startDate), date_format),
          moment(moment(endDate), date_format),
        ]}
        locale={locale}
        format={date_format}
        disabledDate={disabledDate}
        separator={<Separator />}
        suffixIcon={<IconCalendar className="disabled" />}
        allowEmpty={[false, false]}
        allowClear={false}
        placeholder={['', '']}
        bordered={false}
        onCalendarChange={onCalendarChange}
        onOpenChange={onOpenChange}
        picker="date"
      />
    </SettingDurationWrapper>
  );
};

export default withRouter(DefaultDatePicker);
