import { css } from 'styled-components';

export const tooltip = css`
  .tooltip {
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.grey300};
    .innerBox {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
      .title {
        font-weight: 400;
        font-size: 14px;
        margin-right: 8px;
        color: ${(props) => props.theme.colors.grey500};
      }
      .value {
        font-weight: 400;
        font-size: 14px;
        margin-right: 4px;
        color: ${(props) => props.theme.colors.grey700};
      }
      .rate {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.colors.grey500};
      }
    }
  }
`;

export { tooltip as default };
