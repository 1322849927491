import { createReducer } from 'typesafe-actions';
import {
  GET_BOT_TRAFFIC_PAGE,
  GET_BOT_TRAFFIC_PAGE_ERROR,
  GET_BOT_TRAFFIC_PAGE_SUCCESS,
  GET_BOT_TRAFFIC_SITE,
  GET_BOT_TRAFFIC_SITE_ERROR,
  GET_BOT_TRAFFIC_SITE_SUCCESS,
  GET_EXTERNAL_NEWS_KEYWORD_NETWORK,
  GET_EXTERNAL_NEWS_KEYWORD_NETWORK_ERROR,
  GET_EXTERNAL_NEWS_KEYWORD_NETWORK_SUCCESS,
  GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD,
  GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_ERROR,
  GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_SUCCESS,
  GET_EXTERNAL_REFERRER_SEARCH,
  GET_EXTERNAL_REFERRER_SEARCH_ERROR,
  GET_EXTERNAL_REFERRER_SEARCH_SUCCESS,
  GET_EXTERNAL_REFERRER_SNS,
  GET_EXTERNAL_REFERRER_SNS_ERROR,
  GET_EXTERNAL_REFERRER_SNS_SUCCESS,
  GET_FORECAST_PV,
  GET_FORECAST_PV_ERROR,
  GET_FORECAST_PV_SUCCESS,
  GET_FORECAST_UV,
  GET_FORECAST_UV_ERROR,
  GET_FORECAST_UV_SUCCESS,
  GET_LOCAL_PV,
  GET_LOCAL_PV_ERROR,
  GET_LOCAL_PV_SUCCESS,
  GET_LOCAL_REGION,
  GET_LOCAL_REGION_ERROR,
  GET_LOCAL_REGION_SUCCESS,
  GET_LOCAL_UV,
  GET_LOCAL_UV_ERROR,
  GET_LOCAL_UV_SUCCESS,
  GET_NEW_VS_RETURN_DURATION,
  GET_NEW_VS_RETURN_DURATION_ERROR,
  GET_NEW_VS_RETURN_DURATION_SUCCESS,
  GET_NEW_VS_RETURN_PV,
  GET_NEW_VS_RETURN_PV_ERROR,
  GET_NEW_VS_RETURN_PV_SUCCESS,
  GET_NEW_VS_RETURN_UV,
  GET_NEW_VS_RETURN_UV_ERROR,
  GET_NEW_VS_RETURN_UV_SUCCESS,
  GET_PAGE_USABILITY_CLICK_RATE,
  GET_PAGE_USABILITY_CLICK_RATE_ERROR,
  GET_PAGE_USABILITY_CLICK_RATE_SUCCESS,
  GET_PAGE_USABILITY_PAGE_DURATION,
  GET_PAGE_USABILITY_PAGE_DURATION_ERROR,
  GET_PAGE_USABILITY_PAGE_DURATION_SUCCESS,
  GET_POPULAR_PAGE_GROWTH,
  GET_POPULAR_PAGE_GROWTH_ERROR,
  GET_POPULAR_PAGE_GROWTH_SUCCESS,
  GET_POPULAR_PAGE_MOST,
  GET_POPULAR_PAGE_MOST_ERROR,
  GET_POPULAR_PAGE_MOST_SUCCESS,
  GET_SEARCH_WORD_GROWTH,
  GET_SEARCH_WORD_GROWTH_ERROR,
  GET_SEARCH_WORD_GROWTH_SUCCESS,
  GET_SEARCH_WORD_MOST,
  GET_SEARCH_WORD_MOST_ERROR,
  GET_SEARCH_WORD_MOST_SUCCESS,
  GET_SERVICE_ACTIVATION_PV,
  GET_SERVICE_ACTIVATION_PV_ERROR,
  GET_SERVICE_ACTIVATION_PV_SUCCESS,
  GET_SERVICE_ACTIVATION_UV,
  GET_SERVICE_ACTIVATION_UV_ERROR,
  GET_SERVICE_ACTIVATION_UV_SUCCESS,
  GET_SIMILAR_AGENCY_PV,
  GET_SIMILAR_AGENCY_PV_ERROR,
  GET_SIMILAR_AGENCY_PV_SUCCESS,
  GET_SIMILAR_AGENCY_TYPE,
  GET_SIMILAR_AGENCY_TYPE_ERROR,
  GET_SIMILAR_AGENCY_TYPE_SUCCESS,
  GET_SIMILAR_AGENCY_UV,
  GET_SIMILAR_AGENCY_UV_ERROR,
  GET_SIMILAR_AGENCY_UV_SUCCESS,
  GET_SIMILAR_SCALE_PV,
  GET_SIMILAR_SCALE_PV_ERROR,
  GET_SIMILAR_SCALE_PV_SUCCESS,
  GET_SIMILAR_SCALE_TYPE,
  GET_SIMILAR_SCALE_TYPE_ERROR,
  GET_SIMILAR_SCALE_TYPE_SUCCESS,
  SET_ERROR_NULL,
} from './actions';
import { reportAction, reportState } from './types';

const initialState: reportState = {
  serviceActivationUVData: null,
  serviceActivationPVData: null,

  newVSReturnUVData: null,
  newVSReturnDurationData: null,
  newVSReturnPVData: null,

  popularPageGrowthData: null,
  popularPageMostData: null,

  searchWordGrowthData: null,
  searchWordMostData: null,

  localRegionData: null,
  localUVData: null,
  localPVData: null,

  similarAgencyTypeData: null,
  similarAgencyUVData: null,
  similarAgencyPVData: null,

  similarScaleTypeData: null,
  similarScalePVData: null,
  similarScaleUVData: null,

  forecastPVData: null,
  forecastUVData: null,

  pageUsabilityClickRateData: null,
  pageUsabilityPageDurationData: null,

  externalNewsKeywordNetworkData: null,
  externalNewsKeywordWordcloudData: null,

  externalReferrerSNSData: null,
  externalReferrerSearchData: null,

  botTrafficPageData: null,
  botTrafficSiteData: null,

  reportError: null,
};

const reducer = createReducer<reportState, reportAction>(initialState, {
  [GET_SERVICE_ACTIVATION_UV]: (state) => ({
    ...state,
    serviceActivationUVData: null,
    reportError: null,
  }),
  [GET_SERVICE_ACTIVATION_UV_SUCCESS]: (state, action) => ({
    ...state,
    serviceActivationUVData: action.payload.payload,
  }),
  [GET_SERVICE_ACTIVATION_UV_ERROR]: (state, action) => ({
    ...state,
    serviceActivationUVData: null,
    reportError: action.payload,
  }),

  [GET_SERVICE_ACTIVATION_PV]: (state) => ({
    ...state,
    serviceActivationPVData: null,
    reportError: null,
  }),
  [GET_SERVICE_ACTIVATION_PV_SUCCESS]: (state, action) => ({
    ...state,
    serviceActivationPVData: action.payload.payload,
  }),
  [GET_SERVICE_ACTIVATION_PV_ERROR]: (state, action) => ({
    ...state,
    serviceActivationPVData: null,
    reportError: action.payload,
  }),

  [GET_NEW_VS_RETURN_UV]: (state) => ({
    ...state,
    newVSReturnUVData: null,
    reportError: null,
  }),
  [GET_NEW_VS_RETURN_UV_SUCCESS]: (state, action) => ({
    ...state,
    newVSReturnUVData: action.payload.payload,
  }),
  [GET_NEW_VS_RETURN_UV_ERROR]: (state, action) => ({
    ...state,
    newVSReturnUVData: null,
    reportError: action.payload,
  }),

  [GET_NEW_VS_RETURN_DURATION]: (state) => ({
    ...state,
    newVSReturnDurationData: null,
    reportError: null,
  }),
  [GET_NEW_VS_RETURN_DURATION_SUCCESS]: (state, action) => ({
    ...state,
    newVSReturnDurationData: action.payload.payload,
  }),
  [GET_NEW_VS_RETURN_DURATION_ERROR]: (state, action) => ({
    ...state,
    newVSReturnDurationData: null,
    reportError: action.payload,
  }),

  [GET_NEW_VS_RETURN_PV]: (state) => ({
    ...state,
    newVSReturnPVData: null,
    reportError: null,
  }),
  [GET_NEW_VS_RETURN_PV_SUCCESS]: (state, action) => ({
    ...state,
    newVSReturnPVData: action.payload.payload,
  }),
  [GET_NEW_VS_RETURN_PV_ERROR]: (state, action) => ({
    ...state,
    newVSReturnPVData: null,
    reportError: action.payload,
  }),

  [GET_POPULAR_PAGE_MOST]: (state) => ({
    ...state,
    popularPageMostData: null,
    reportError: null,
  }),
  [GET_POPULAR_PAGE_MOST_SUCCESS]: (state, action) => ({
    ...state,
    popularPageMostData: action.payload.payload,
  }),
  [GET_POPULAR_PAGE_MOST_ERROR]: (state, action) => ({
    ...state,
    popularPageMostData: null,
    reportError: action.payload,
  }),

  [GET_POPULAR_PAGE_GROWTH]: (state) => ({
    ...state,
    popularPageGrowthData: null,
    reportError: null,
  }),
  [GET_POPULAR_PAGE_GROWTH_SUCCESS]: (state, action) => ({
    ...state,
    popularPageGrowthData: action.payload.payload,
  }),
  [GET_POPULAR_PAGE_GROWTH_ERROR]: (state, action) => ({
    ...state,
    popularPageGrowthData: null,
    reportError: action.payload,
  }),

  [GET_SEARCH_WORD_MOST]: (state) => ({
    ...state,
    searchWordMostData: null,
    reportError: null,
  }),
  [GET_SEARCH_WORD_MOST_SUCCESS]: (state, action) => ({
    ...state,
    searchWordMostData: action.payload.payload,
  }),
  [GET_SEARCH_WORD_MOST_ERROR]: (state, action) => ({
    ...state,
    searchWordMostData: null,
    reportError: action.payload,
  }),

  [GET_SEARCH_WORD_GROWTH]: (state) => ({
    ...state,
    searchWordGrowthData: null,
    reportError: null,
  }),
  [GET_SEARCH_WORD_GROWTH_SUCCESS]: (state, action) => ({
    ...state,
    searchWordGrowthData: action.payload.payload,
  }),
  [GET_SEARCH_WORD_GROWTH_ERROR]: (state, action) => ({
    ...state,
    searchWordGrowthData: null,
    reportError: action.payload,
  }),

  [GET_LOCAL_REGION]: (state) => ({
    ...state,
    localRegionData: null,
    reportError: null,
  }),
  [GET_LOCAL_REGION_SUCCESS]: (state, action) => ({
    ...state,
    localRegionData: action.payload.payload,
  }),
  [GET_LOCAL_REGION_ERROR]: (state, action) => ({
    ...state,
    localRegionData: null,
    reportError: action.payload,
  }),

  [GET_LOCAL_UV]: (state) => ({
    ...state,
    localUVData: null,
    reportError: null,
  }),
  [GET_LOCAL_UV_SUCCESS]: (state, action) => ({
    ...state,
    localUVData: action.payload.payload,
  }),
  [GET_LOCAL_UV_ERROR]: (state, action) => ({
    ...state,
    localUVData: null,
    reportError: action.payload,
  }),

  [GET_LOCAL_PV]: (state) => ({
    ...state,
    localPVData: null,
    reportError: null,
  }),
  [GET_LOCAL_PV_SUCCESS]: (state, action) => ({
    ...state,
    localPVData: action.payload.payload,
  }),
  [GET_LOCAL_PV_ERROR]: (state, action) => ({
    ...state,
    localPVData: null,
    reportError: action.payload,
  }),

  [GET_SIMILAR_AGENCY_TYPE]: (state) => ({
    ...state,
    similarAgencyTypeData: null,
    reportError: null,
  }),
  [GET_SIMILAR_AGENCY_TYPE_SUCCESS]: (state, action) => ({
    ...state,
    similarAgencyTypeData: action.payload.payload,
  }),
  [GET_SIMILAR_AGENCY_TYPE_ERROR]: (state, action) => ({
    ...state,
    similarAgencyTypeData: null,
    reportError: action.payload,
  }),

  [GET_SIMILAR_AGENCY_UV]: (state) => ({
    ...state,
    similarAgencyUVData: null,
    reportError: null,
  }),
  [GET_SIMILAR_AGENCY_UV_SUCCESS]: (state, action) => ({
    ...state,
    similarAgencyUVData: action.payload.payload,
  }),
  [GET_SIMILAR_AGENCY_UV_ERROR]: (state, action) => ({
    ...state,
    similarAgencyUVData: null,
    reportError: action.payload,
  }),

  [GET_SIMILAR_AGENCY_PV]: (state) => ({
    ...state,
    similarAgencyPVData: null,
    reportError: null,
  }),
  [GET_SIMILAR_AGENCY_PV_SUCCESS]: (state, action) => ({
    ...state,
    similarAgencyPVData: action.payload.payload,
  }),
  [GET_SIMILAR_AGENCY_PV_ERROR]: (state, action) => ({
    ...state,
    similarAgencyPVData: null,
    reportError: action.payload,
  }),

  [GET_SIMILAR_SCALE_TYPE]: (state) => ({
    ...state,
    similarScaleTypeData: null,
    similarScaleUVData: null,
    reportError: null,
  }),
  [GET_SIMILAR_SCALE_TYPE_SUCCESS]: (state, action) => ({
    ...state,
    similarScaleTypeData: action.payload.payload,
    similarScaleUVData: {
      percent: action.payload.payload.percent,
      count: action.payload.payload.count,
      top_10_per: action.payload.payload.top_10_per,
      top_90_per: action.payload.payload.top_90_per,
    },
  }),
  [GET_SIMILAR_SCALE_TYPE_ERROR]: (state, action) => ({
    ...state,
    similarScaleTypeData: null,
    similarScaleUVData: null,
    reportError: action.payload,
  }),

  [GET_SIMILAR_SCALE_PV]: (state) => ({
    ...state,
    similarScalePVData: null,
    reportError: null,
  }),
  [GET_SIMILAR_SCALE_PV_SUCCESS]: (state, action) => ({
    ...state,
    similarScalePVData: action.payload.payload,
  }),
  [GET_SIMILAR_SCALE_PV_ERROR]: (state, action) => ({
    ...state,
    similarScalePVData: null,
    reportError: action.payload,
  }),

  [GET_FORECAST_UV]: (state) => ({
    ...state,
    forecastUVData: null,
    reportError: null,
  }),
  [GET_FORECAST_UV_SUCCESS]: (state, action) => ({
    ...state,
    forecastUVData: action.payload.payload,
  }),
  [GET_FORECAST_UV_ERROR]: (state, action) => ({
    ...state,
    forecastUVData: null,
    reportError: action.payload,
  }),

  [GET_FORECAST_PV]: (state) => ({
    ...state,
    forecastPVData: null,
    reportError: null,
  }),
  [GET_FORECAST_PV_SUCCESS]: (state, action) => ({
    ...state,
    forecastPVData: action.payload.payload,
  }),
  [GET_FORECAST_PV_ERROR]: (state, action) => ({
    ...state,
    forecastPVData: null,
    reportError: action.payload,
  }),

  [GET_PAGE_USABILITY_CLICK_RATE]: (state) => ({
    ...state,
    pageUsabilityClickRateData: null,
    reportError: null,
  }),
  [GET_PAGE_USABILITY_CLICK_RATE_SUCCESS]: (state, action) => ({
    ...state,
    pageUsabilityClickRateData: action.payload.payload,
  }),
  [GET_PAGE_USABILITY_CLICK_RATE_ERROR]: (state, action) => ({
    ...state,
    pageUsabilityClickRateData: null,
    reportError: action.payload,
  }),

  [GET_PAGE_USABILITY_PAGE_DURATION]: (state) => ({
    ...state,
    pageUsabilityPageDurationData: null,
    reportError: null,
  }),
  [GET_PAGE_USABILITY_PAGE_DURATION_SUCCESS]: (state, action) => ({
    ...state,
    pageUsabilityPageDurationData: action.payload.payload,
  }),
  [GET_PAGE_USABILITY_PAGE_DURATION_ERROR]: (state, action) => ({
    ...state,
    pageUsabilityPageDurationData: null,
    reportError: action.payload,
  }),

  [GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD]: (state) => ({
    ...state,
    externalNewsKeywordWordcloudData: null,
    reportError: null,
  }),
  [GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_SUCCESS]: (state, action) => ({
    ...state,
    externalNewsKeywordWordcloudData: action.payload.payload,
  }),
  [GET_EXTERNAL_NEWS_KEYWORD_WORDCLOUD_ERROR]: (state, action) => ({
    ...state,
    externalNewsKeywordWordcloudData: null,
    reportError: action.payload,
  }),

  [GET_EXTERNAL_NEWS_KEYWORD_NETWORK]: (state) => ({
    ...state,
    externalNewsKeywordNetworkData: null,
    reportError: null,
  }),
  [GET_EXTERNAL_NEWS_KEYWORD_NETWORK_SUCCESS]: (state, action) => ({
    ...state,
    externalNewsKeywordNetworkData: action.payload.payload,
  }),
  [GET_EXTERNAL_NEWS_KEYWORD_NETWORK_ERROR]: (state, action) => ({
    ...state,
    externalNewsKeywordNetworkData: null,
    reportError: action.payload,
  }),

  [GET_EXTERNAL_REFERRER_SEARCH]: (state) => ({
    ...state,
    externalReferrerSearchData: null,
    reportError: null,
  }),
  [GET_EXTERNAL_REFERRER_SEARCH_SUCCESS]: (state, action) => ({
    ...state,
    externalReferrerSearchData: action.payload.payload,
  }),
  [GET_EXTERNAL_REFERRER_SEARCH_ERROR]: (state, action) => ({
    ...state,
    externalReferrerSearchData: null,
    reportError: action.payload,
  }),

  [GET_EXTERNAL_REFERRER_SNS]: (state) => ({
    ...state,
    externalReferrerSNSData: null,
    reportError: null,
  }),
  [GET_EXTERNAL_REFERRER_SNS_SUCCESS]: (state, action) => ({
    ...state,
    externalReferrerSNSData: action.payload.payload,
  }),
  [GET_EXTERNAL_REFERRER_SNS_ERROR]: (state, action) => ({
    ...state,
    externalReferrerSNSData: null,
    reportError: action.payload,
  }),

  [GET_BOT_TRAFFIC_SITE]: (state) => ({
    ...state,
    botTrafficSiteData: null,
    reportError: null,
  }),
  [GET_BOT_TRAFFIC_SITE_SUCCESS]: (state, action) => ({
    ...state,
    botTrafficSiteData: action.payload.payload,
  }),
  [GET_BOT_TRAFFIC_SITE_ERROR]: (state, action) => ({
    ...state,
    botTrafficSiteData: null,
    reportError: action.payload,
  }),

  [GET_BOT_TRAFFIC_PAGE]: (state) => ({
    ...state,
    botTrafficPageData: null,
    reportError: null,
  }),
  [GET_BOT_TRAFFIC_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    botTrafficPageData: action.payload.payload,
  }),
  [GET_BOT_TRAFFIC_PAGE_ERROR]: (state, action) => ({
    ...state,
    botTrafficPageData: null,
    reportError: action.payload,
  }),

  [SET_ERROR_NULL]: (state) => ({
    ...state,
    reportError: null,
  }),
});

export default reducer;
