import React from 'react';
import styled, { css } from 'styled-components';
import { IconUp } from '../../Atoms/Icon';
import { H3, H4, P1 } from '../../Atoms/Typo';
import Count from '../Count/NumberToKorean';

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 10px;
`;

const Rank = styled(H3)`
  color: ${(props) => props.theme.colors.grey400};
  margin-right: 24px;
`;

const Title = styled(P1)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
  }
`;

const Value = styled.div<{ $isUp: boolean }>`
  width: max-content;
  .voda_num_to_kor_count {
    ${H4} {
      font-size: 20px;
      ${(props) => {
        if (props.$isUp) {
          return css`
            color: ${props.theme.colors.success5};
          `;
        }
        return css`
          color: ${props.theme.colors.grey800};
        `;
      }}
    }
  }

  margin-left: 8px;
`;

interface SearchRankProps {
  rank: number;
  title: string;
  value: number;
  isUp: boolean;
}

const SearchRank = ({ rank, title, value, isUp }: SearchRankProps) => {
  const printIcon = () => {
    if (isUp) {
      return <IconUp />;
    }
    return null;
  };

  return (
    <Component>
      <TitleWrapper>
        <Rank>{rank}</Rank>
        <Title>{title}</Title>
      </TitleWrapper>
      <ValueWrapper>
        {printIcon()}
        <Value $isUp={isUp}>
          <Count value={value} />
        </Value>
      </ValueWrapper>
    </Component>
  );
};

export default SearchRank;
