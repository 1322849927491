import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../Atoms/Button';
import { P3 } from '../../Atoms/Typo';

const Component = styled.div<{ $activeID: number }>`
  display: flex;
  align-items: center;

  button {
    width: 86px;
    height: 36px;
    ${P3} {
      color: ${(props) => props.theme.colors.grey500};
    }

    background-color: white;

    :first-child {
      border-radius: 8px 0px 0px 8px;
    }
    :last-child {
      border-radius: 0px 8px 8px 0px;
    }
  }

  ${(props) => {
    return css`
      button {
        :nth-child(${props.$activeID + 1}) {
          ${P3} {
            color: white;
          }

          background-color: ${props.theme.colors.primary};
        }
      }
    `;
  }}
`;

interface ButtonGroupProps {
  buttonData: {
    id: number;
    title: string;
  }[];
  activeID: number;
  className?: string;
  onClick?: (id: number) => void;
}

const ButtonGroup = ({
  buttonData,
  activeID,
  className,
  onClick,
}: ButtonGroupProps) => {
  const onClickButton = (id: number) => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Component $activeID={activeID} className={className}>
      {buttonData.map((element) => {
        return (
          <Button
            key={element.id}
            onClick={() => {
              onClickButton(element.id);
            }}
          >
            <P3>{element.title}</P3>
          </Button>
        );
      })}
    </Component>
  );
};

export default ButtonGroup;
