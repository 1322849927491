import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetSessionCountParamType,
  GetSessionCountResponseType,
  GetWeeklySessionCountParamType,
  GetWeeklySessionCountResponseType,
} from '../../../api/serviceAnalytics/sessionAnalysis/type';

export const GET_SESSION_COUNT = 'serviceAnalytics/sessionAnalysis/GET_SESSION_COUNT' as const;
export const GET_SESSION_COUNT_SUCCESS = 'serviceAnalytics/sessionAnalysis/GET_SESSION_COUNT_SUCCESS' as const;
export const GET_SESSION_COUNT_ERROR = 'serviceAnalytics/sessionAnalysis/GET_SESSION_COUNT_ERROR' as const;

export const GET_WEEKLY_SESSION_COUNT = 'serviceAnalytics/sessionAnalysis/GET_WEEKLY_SESSION_COUNT' as const;
export const GET_WEEKLY_SESSION_COUNT_SUCCESS = 'serviceAnalytics/sessionAnalysis/GET_WEEKLY_SESSION_COUNT_SUCCESS' as const;
export const GET_WEEKLY_SESSION_COUNT_ERROR = 'serviceAnalytics/sessionAnalysis/GET_WEEKLY_SESSION_COUNT_ERROR' as const;

export const SET_ERROR_NULL_SERVICE_SESSION =
  'serviceAnalytics/sessionAnalysis/SET_ERROR_NULL_SERVICE_SESSION';

export const getSessionCountAsync = createAsyncAction(
  GET_SESSION_COUNT,
  GET_SESSION_COUNT_SUCCESS,
  GET_SESSION_COUNT_ERROR
)<GetSessionCountParamType, GetSessionCountResponseType, AxiosErrorType>();

export const getWeeklySessionCountAsync = createAsyncAction(
  GET_WEEKLY_SESSION_COUNT,
  GET_WEEKLY_SESSION_COUNT_SUCCESS,
  GET_WEEKLY_SESSION_COUNT_ERROR
)<
  GetWeeklySessionCountParamType,
  GetWeeklySessionCountResponseType,
  AxiosErrorType
>();

export const setErrorNullServiceSession = createAction(
  SET_ERROR_NULL_SERVICE_SESSION
)();
