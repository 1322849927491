import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { H6, P4 } from '../../Atoms/Typo';

import ElementThumbnailImage from '../Thumbnail/ElementThumbnailImage';

const Component = styled.div<{
  $isOpenTooltip: boolean;
  $left: number;
  $top: number;
  $tooltipCase: number;
}>`
  width: 211px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  z-index: 150;

  ${(props) => {
    switch (props.$tooltipCase) {
      case 0:
        return css`
          &:after {
            position: absolute;
            left: 14px;
            top: -10px;
            content: '';
            width: 0px;
            height: 0px;
            border-top: 0px solid none;
            border-bottom: 10px solid white;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
        `;

      case 1:
        return css`
          &:after {
            position: absolute;
            right: 14px;
            top: -10px;
            content: '';
            width: 0px;
            height: 0px;
            border-top: 0px solid none;
            border-bottom: 10px solid white;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
        `;

      case 10:
        return css`
          &:after {
            position: absolute;
            left: 14px;
            bottom: -10px;
            content: '';
            width: 0px;
            height: 0px;
            border-top: 10px solid white;
            border-bottom: 0px solid none;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
        `;

      case 11:
        return css`
          &:after {
            position: absolute;
            right: 14px;
            bottom: -10px;
            content: '';
            width: 0px;
            height: 0px;
            border-top: 10px solid white;
            border-bottom: 0px solid none;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
        `;
      default:
        return css``;
    }
  }}

  ${(props) => {
    if (props.$isOpenTooltip && props.$left !== null && props.$top !== null) {
      return css`
        display: flex;
        left: ${props.$left}px;
        top: ${props.$top}px;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

const SplitLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;

  ${P4} {
    color: ${(props) => props.theme.colors.grey600};
  }
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export interface MouseHeatmapTooltipType {
  img_src: string;
  data: {
    id: number;
    title: string;
    value: string;
  }[];
  isOpenTooltip: boolean;
  elementX: number;
  elementY: number;
  elementWidth: number;
  elementHeight: number;
}

export interface MouseHeatmapTooltipProps {
  img_src: string;
  data: {
    id: number;
    title: string;
    value: string;
  }[];
  isOpenTooltip: boolean;
  elementX: number;
  elementY: number;
  elementWidth: number;
  elementHeight: number;
  imgSize: {
    width: number;
    height: number;
  };
}

const MouseHeatmapTooltip = ({
  img_src,
  data,
  isOpenTooltip,
  elementX,
  elementY,
  elementWidth,
  elementHeight,
  imgSize,
}: MouseHeatmapTooltipProps) => {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [tooltipCase, setTooltipCase] = useState(0);

  const onSetTooltipCoordinate = () => {
    const x = elementX * imgSize.width;
    const y = elementY * imgSize.height;
    const width = elementWidth * imgSize.width;
    const height = elementHeight * imgSize.height;

    let tooltipCaseTemp = 0;
    let leftTemp = x - 4;
    let topTemp = y + height + 12;
    const tooltipWidth = 211;
    const tooltipHeight = 229;
    if (x > imgSize.width / 2) {
      leftTemp = x - tooltipWidth + width;
      tooltipCaseTemp += 1;
    }
    if (y > imgSize.height / 2) {
      topTemp = y - tooltipHeight;
      tooltipCaseTemp += 10;
    }
    setLeft(leftTemp);
    setTop(topTemp);
    setTooltipCase(tooltipCaseTemp);
  };

  useEffect(() => {
    onSetTooltipCoordinate();
  }, [img_src, imgSize]);

  return (
    <Component
      $isOpenTooltip={isOpenTooltip}
      $left={left}
      $top={top}
      $tooltipCase={tooltipCase}
    >
      <ElementThumbnailImage img_src={img_src} isBackground />
      <SplitLine />
      {data.map((element) => {
        return (
          <Item key={element.title}>
            <P4>{element.title}</P4>
            <H6>{element.value}</H6>
          </Item>
        );
      })}
    </Component>
  );
};

export default MouseHeatmapTooltip;
