import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  GetScrollEventsParamType,
  GetScrollEventsResponseType,
} from '../../../../api/pageAnalytics/uxAnalytics/scroll/type';
import { AxiosErrorType } from '../../../createRequestSaga';

export const GET_SCROLL_EVENTS = 'pageAnalytics/UXAnalytics/scroll/GET_SCROLL_EVENTS' as const;
export const GET_SCROLL_EVENTS_SUCCESS = 'pageAnalytics/UXAnalytics/scroll/GET_SCROLL_EVENTS_SUCCESS' as const;
export const GET_SCROLL_EVENTS_ERROR = 'pageAnalytics/UXAnalytics/scroll/GET_SCROLL_EVENTS_ERROR' as const;

export const SET_ERROR_NULL = 'scroll/SET_ERROR_NULL' as const;

export const getScrollEventsAsync = createAsyncAction(
  GET_SCROLL_EVENTS,
  GET_SCROLL_EVENTS_SUCCESS,
  GET_SCROLL_EVENTS_ERROR
)<GetScrollEventsParamType, GetScrollEventsResponseType, AxiosErrorType>();

export const setErrorNull = createAction(SET_ERROR_NULL)();
