import { takeLatest } from 'redux-saga/effects';
import {
  apiGetAIVORYContentUsageBySearchWord,
  apiGetAIVORYSearchKeywordNetwork,
  apiGetAIVORYSearchUsageWordcloud,
} from '../../../api/aivoryUsageAnalytics/popularSearchAnalysis/api';

import createRequestSaga from '../../createRequestSaga';

import {
  getAIVORYContentUsageBySearchWordAsync,
  getAIVORYSearchKeywordNetworkAsync,
  getAIVORYSearchUsageWordcloudAsync,
  GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD,
  GET_AIVORY_SEARCH_KETWORD_NETWORK,
  GET_AIVORY_SEARCH_USAGE_WORDCLOUD,
} from './actions';

const getAIVORYSearchKeywordNetworkSaga = createRequestSaga(
  getAIVORYSearchKeywordNetworkAsync,
  apiGetAIVORYSearchKeywordNetwork
);

const getAIVORYSearchUsageWordcloudSaga = createRequestSaga(
  getAIVORYSearchUsageWordcloudAsync,
  apiGetAIVORYSearchUsageWordcloud
);

const getAIVORYContentUsageBySearchWordSaga = createRequestSaga(
  getAIVORYContentUsageBySearchWordAsync,
  apiGetAIVORYContentUsageBySearchWord
);

export function* aivoryPopularSearchAnalysisSaga() {
  yield takeLatest(
    GET_AIVORY_SEARCH_KETWORD_NETWORK,
    getAIVORYSearchKeywordNetworkSaga
  );
  yield takeLatest(
    GET_AIVORY_SEARCH_USAGE_WORDCLOUD,
    getAIVORYSearchUsageWordcloudSaga
  );
  yield takeLatest(
    GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD,
    getAIVORYContentUsageBySearchWordSaga
  );
}

export { aivoryPopularSearchAnalysisSaga as default };
