import React from 'react';
import styled from 'styled-components';
import { H1 } from '../../../Atoms/Typo';

const Component = styled.div``;

interface ContentsHeadingProps {
  className?: string;
  title: string;
}

const ContentsHeading = ({ title, className }: ContentsHeadingProps) => {
  return (
    <Component className={className}>
      <H1>{title}</H1>
    </Component>
  );
};

export default ContentsHeading;
