import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { H4, P2, P3 } from '../../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Rank = styled.div`
  margin-bottom: 8px;
  ${P3} {
    font-weight: 500;
  }
`;

const BodyItemThumbnailWrapper = styled.div`
  width: 100%;

  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  padding: 24px;

  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 40px;
  height: 203px;
`;

const BodyItemThumbnailWrapperInner = styled.div`
  border: solid 1px ${(props) => props.theme.colors.grey100};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const PageScreenImage = styled.img`
  display: block;
  position: absolute;
  width: 100%;
`;

const DataDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${P2} {
    color: ${(props) => props.theme.colors.grey700};
  }
`;

interface PageScreenCardProps {
  img_src: string;
  rank: number;
  data: {
    id: number;
    title: string;
    value: string;
  }[];
  pageHeight: number;
  index: number;
  section_cnt: number;
  className?: string;
  onMouseOverImage?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClickImage?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeaveImage?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

const PageScreenCard = ({
  img_src,
  rank,
  data,
  className,
  pageHeight,
  index,
  section_cnt,
  onMouseOverImage,
  onClickImage,
  onMouseLeaveImage,
}: PageScreenCardProps) => {
  const ImgEl = useRef<HTMLImageElement>(null);
  const ImgWrapperEl = useRef<HTMLDivElement>(null);
  const [isImgLoad, setIsImgLoad] = useState(false);

  useEffect(() => {
    const ImgElCurrent = ImgEl.current;
    const ImgWrapperElCurrent = ImgWrapperEl.current;
    if (ImgWrapperElCurrent && ImgElCurrent && isImgLoad) {
      const barHeight = Math.round(pageHeight / section_cnt);

      const averageWidth = 1500;
      const averageHeight = Math.round((averageWidth * 9) / 16);

      const imgHeight = ImgElCurrent.offsetHeight;
      const imgWidth = ImgElCurrent.offsetWidth;

      const realHeight = ImgElCurrent.naturalHeight;
      const realWidth = ImgElCurrent.naturalWidth;

      const ratioHeight = imgHeight / realHeight;

      const wrapperHeight = ImgWrapperElCurrent.offsetHeight;

      const centerPosition = barHeight * (index + 1) - barHeight / 2;

      let imgTop = centerPosition - averageHeight / 2;
      let imgBottom = centerPosition + averageHeight / 2;

      if (imgTop < 0) {
        imgTop = 0;
        imgBottom = averageHeight;
      }

      if (imgBottom > pageHeight) {
        imgTop = pageHeight - averageHeight;
        imgBottom = pageHeight;
      }

      const centerToTopHeight =
        (wrapperHeight - averageHeight * ratioHeight) / 2;

      ImgElCurrent.style.top = `${-(
        imgTop * ratioHeight -
        centerToTopHeight
      )}px`;

      ImgElCurrent.style.clip = `rect(${
        imgTop * ratioHeight
      }px, ${imgWidth}px, ${imgBottom * ratioHeight}px, 0px`;
    }
  }, [ImgWrapperEl, ImgEl, pageHeight, isImgLoad]);

  return (
    <Component className={className}>
      <Rank>
        <P3>{rank}위</P3>
      </Rank>
      <BodyItemThumbnailWrapper>
        <BodyItemThumbnailWrapperInner ref={ImgWrapperEl}>
          <PageScreenImage
            ref={ImgEl}
            src={img_src}
            onLoad={() => {
              setIsImgLoad(true);
            }}
          />
        </BodyItemThumbnailWrapperInner>
      </BodyItemThumbnailWrapper>

      {data.map((element) => {
        return (
          <DataDisplay key={element.id}>
            <P2>{element.title}</P2>
            <H4>{element.value}</H4>
          </DataDisplay>
        );
      })}
    </Component>
  );
};

export default PageScreenCard;
