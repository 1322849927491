import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { P1, P2 } from '../Typo';

const Component = styled(NavLink)`
  position: relative;
  ${P1} {
    color: ${(props) => props.theme.colors.grey900};
    font-size: 18px;
    font-weight: bold;
    span {
      display: none;
    }
  }

  ${P2} {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
    font-weight: bold;
  }

  &.active {
    ${P1} {
      border-bottom: 2px solid ${(props) => props.theme.colors.grey900};
      span {
        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        margin-left: 10px;
      }
    }
    ${P2} {
      color: ${(props) => props.theme.colors.grey900};
    }
  }
  :hover {
    ${P1} {
      color: ${(props) => props.theme.colors.grey900};
    }
    ${P2} {
      color: ${(props) => props.theme.colors.grey900};
    }
  }
`;

interface DefaultNavLinkProps {
  to: string;
  title: string;
  exact?: boolean;
  subLink?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

const DefaultNavLink = ({
  to,
  title,
  exact,
  subLink,
  children,
  onClick,
}: DefaultNavLinkProps) => {
  return (
    <Component exact={exact !== undefined ? exact : true} to={to}>
      {children}
      {subLink ? (
        <P2>{title}</P2>
      ) : (
        <P1>
          {title}
          <span className="ri-arrow-right-line"></span>
        </P1>
      )}
    </Component>
  );
};

export default DefaultNavLink;
