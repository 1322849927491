import React, { memo } from 'react';
import styled, { css } from 'styled-components';

const Component = styled.div<{
  $isClicked: boolean;
  $isMouseOver: boolean;
  $x: number;
  $y: number;
  $width: number;
  $height: number;
  $depth: number;
}>`
  position: absolute;
  left: 0;
  top: 0;
  border: solid 1px ${(props) => props.theme.colors.warning5};
  cursor: pointer;

  ${(props) => {
    if (
      props.$width !== null &&
      props.$height !== null &&
      props.$x !== null &&
      props.$y !== null &&
      props.$depth !== null
    ) {
      return css`
        left: ${props.$x}px;
        top: ${props.$y}px;
        width: ${props.$width}px;
        height: ${props.$height}px;
        z-index: ${props.$depth + 10};
        :hover {
          border: solid 1px ${props.theme.colors.orange5};
          background-color: rgba(255, 153, 49, 0.4);
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$isClicked) {
      return css`
        border: solid 1px ${props.theme.colors.purple3};
        background-color: rgba(181, 151, 247, 0.6);
      `;
    }
    return css``;
  }}
`;

interface HeatmapElementBoxProps {
  isClicked: boolean;
  isMouseOver: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  depth: number;
  onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const HeatmapElementBox = ({
  depth,
  height,
  isClicked,
  isMouseOver,
  width,
  x,
  y,
  onMouseOver,
  onMouseLeave,
  onClick,
}: HeatmapElementBoxProps) => {
  return (
    <Component
      $isClicked={isClicked}
      $isMouseOver={isMouseOver}
      $x={x}
      $y={y}
      $depth={depth}
      $height={height}
      $width={width}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    />
  );
};

export default memo(HeatmapElementBox);
