import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import Header from '../../Molecule/Card/CardHeader';
import Title from '../../Molecule/Card/CardTitle/WordCloudTitle';
import WordCloudChart from '../../Molecule/Chart/WordCloudChart';
import Spinner from '../../Molecule/Spinner';

const Component = styled.div`
  width: 100%;
  height: 100%;
  max-height: 598px;

  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px 24px 40px 24px;
  border-radius: 16px;
`;

const Body = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  margin-top: 24px;
`;

export interface WordCloudChartCardProps {
  data?: {
    word: string;
    count: number;
    value: number;
  }[];
  isTopic: boolean;
  isLoading: boolean;
  onClickKeyword?: (word: string) => void;
}

const WordCloudChartCard = ({
  data,
  isTopic,
  isLoading,
  onClickKeyword,
}: WordCloudChartCardProps) => {
  const [mainWord, setMainWord] = useState('');

  useEffect(() => {
    if (data !== undefined) {
      data.some((element) => {
        if (element.value === 1) {
          setMainWord(element.word);
          return true;
        }
        return false;
      });
    }
  }, [data]);

  return (
    <Component>
      <Header>
        <Title mainWord={mainWord} isTopic={isTopic} />
      </Header>
      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <WordCloudChart data={data} onClickKeyword={onClickKeyword} />
        )}
      </Body>
    </Component>
  );
};

export default WordCloudChartCard;
