import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { GetPageUsabilityClickRateResponseType } from '../../../../api/report/type';
import DefaultLink from '../../../Atoms/Link';
import { H3, P1, P3 } from '../../../Atoms/Typo';
import Header from '../../../Molecule/Card/CardHeader';
import ReportSummary from '../../../Molecule/Card/CardSummary/ReportSummary';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import DounutChart from '../../../Molecule/Chart/DonutChart/Simple';
import Spinner from '../../../Molecule/Spinner';
import BarChartCard from '../BarChartCard';

const Component = styled.div`
  width: 100%;
  height: 100%;
  min-height: 806px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;

  padding: 24px 24px 80px 24px;
`;

const ReportRaw = styled.div`
  display: flex;
  ${H3} {
    margin-left: 4px;
  }
`;

const SubReport = styled(P3)`
  color: ${(props) => props.theme.colors.grey600};
  margin-top: 8px;
`;

const LinkWrapper = styled.div`
  width: fit-content;

  a {
    ${P3} {
      color: ${(props) => props.theme.colors.grey500};
    }

    :hover {
      ${P3} {
        color: ${(props) => props.theme.colors.grey500};
      }
    }
  }
  margin-top: 24px;
`;

const Underline = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey500};
  margin-top: 4px;
`;

const Body = styled.div`
  width: 100%;
  height: 356px;
`;

interface PageUsabilityReportCardProps {
  month: string;
  data: GetPageUsabilityClickRateResponseType['payload'] | null;
  isLoading: boolean;
}

interface PageUsabilityReportCardState {
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

const PageUsabilityClickRateReportCard = ({
  month,
  data,
  isLoading,
}: PageUsabilityReportCardProps) => {
  const [allSiterate, setAllSiteRate] = useState(0);
  const [report, setReport] = useState<React.ReactNode>();
  const [mySiteRate, setMySiteRate] = useState(0);
  const [chartData, setChartData] = useState<
    PageUsabilityReportCardState['rebuildData']
  >([]);

  useEffect(() => {
    if (data) {
      setAllSiteRate(data.all_page_use_per);
      const chart_data: {
        [key: string]: number | boolean;
        isHighlight: boolean;
      }[] = [];

      chart_data.push({
        평균: data.all_page_use_per,
        isHighlight: data.all_page_use_per > data.page_use_per,
      });
      chart_data.push({
        '내 웹 사이트': data.page_use_per,
        isHighlight: data.all_page_use_per <= data.page_use_per,
      });
      setMySiteRate(data.page_use_per);
      setChartData(chart_data);

      if (data.page_use_per >= 60) {
        setReport(
          <>
            <ReportRaw>
              <P1>{month} 한 달동안</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>평균 유효 클릭 비율은</P1>
              <H3>{data.page_use_per}%</H3>
              <P1>로</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>평균 유효 클릭 비율이 높습니다.</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>
                사용자가 클릭가능한 부분을 헷갈리지 않고 사용하고 있습니다.
              </P1>
            </ReportRaw>
          </>
        );
      } else if (data.page_use_per >= 30) {
        setReport(
          <>
            <ReportRaw>
              <P1>{month} 한 달동안</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>평균 유효 클릭 비율은</P1>
              <H3>{data.page_use_per}%</H3>
              <P1>로</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>사용자가 클릭 영역을 구별하기 어렵습니다.</P1>
            </ReportRaw>
          </>
        );
      } else {
        setReport(
          <>
            <ReportRaw>
              <P1>{month} 한 달동안</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>평균 유효 클릭 비율이</P1>
              <H3>낮습니다.</H3>
            </ReportRaw>
            <ReportRaw>
              <P1>페이지 UX분석에서 원인을 찾아보세요.</P1>
            </ReportRaw>
          </>
        );
      }
    }
  }, [data]);

  return (
    <Component>
      <Header>
        <div>
          <Title title="평균 유효 클릭 비율" />
          <ReportSummary>{report}</ReportSummary>
          <SubReport>
            대부분의 웹 사이트는 유효 클릭 비율이 {allSiterate}% 이상입니다.
          </SubReport>
          <LinkWrapper>
            <DefaultLink to="/pageAnalytics/uxAnalysisList">
              <P3>페이지 UX 분석으로 이동</P3>
            </DefaultLink>
            <Underline />
          </LinkWrapper>
        </div>
      </Header>

      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title="평균 유효 클릭 비율"
            data={chartData}
            value={mySiteRate}
            isLabelInChart
            desc=""
            valueString={`${mySiteRate}%`}
            isRate
          />
        )}
      </Body>
    </Component>
  );
};

export default PageUsabilityClickRateReportCard;
