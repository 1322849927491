import moment from 'moment';
import { createReducer } from 'typesafe-actions';
import { datePickerAction, datePickerState } from './types';

import {
  GET_FORECAST_DATE,
  GET_FORECAST_DATE_ERROR,
  GET_FORECAST_DATE_SUCCESS,
  SET_END_DATE,
  SET_IS_DATE_PICKER_TOGGLE,
  SET_START_DATE,
} from './actions';

const initialState: datePickerState = {
  startDate: moment().subtract(4, 'weeks').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  isDatePickerToggle: false,
  futureDays: 0,
};

const reducer = createReducer<datePickerState, datePickerAction>(initialState, {
  [SET_START_DATE]: (state, action) => ({
    ...state,
    startDate: action.payload,
  }),
  [SET_END_DATE]: (state, action) => ({
    ...state,
    endDate: action.payload,
  }),
  [SET_IS_DATE_PICKER_TOGGLE]: (state, action) => ({
    ...state,
    isDatePickerToggle: action.payload,
  }),

  [GET_FORECAST_DATE]: (state) => ({
    ...state,
    futureDays: 0,
  }),
  [GET_FORECAST_DATE_SUCCESS]: (state, action) => ({
    ...state,
    futureDays: action.payload.payload.future_days,
  }),
  [GET_FORECAST_DATE_ERROR]: (state) => ({
    ...state,
    futureDays: 0,
  }),
});

export default reducer;
