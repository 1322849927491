import { createReducer } from 'typesafe-actions';
import {
  GET_PAGE_ELEMENTS_FLOW,
  GET_PAGE_ELEMENTS_FLOW_ERROR,
  GET_PAGE_ELEMENTS_FLOW_SUCCESS,
  SET_TARGET_RANK,
  SET_ERROR_NULL,
} from './actions';
import { mouseflowAction, mouseflowState } from './types';

const initialState: mouseflowState = {
  mouseflowData: null,
  mouseflowError: null,
  targetRank: -1,
};

const reducer = createReducer<mouseflowState, mouseflowAction>(initialState, {
  [GET_PAGE_ELEMENTS_FLOW]: (state) => ({
    ...state,
    mouseflowError: null,
    mouseflowData: null,
  }),
  [GET_PAGE_ELEMENTS_FLOW_SUCCESS]: (state, action) => ({
    ...state,
    mouseflowData: action.payload.payload,
  }),
  [GET_PAGE_ELEMENTS_FLOW_ERROR]: (state, action) => ({
    ...state,
    mouseflowData: null,
    mouseflowError: action.payload,
  }),

  [SET_TARGET_RANK]: (state, action) => ({
    ...state,
    targetRank: action.payload,
  }),
  [SET_ERROR_NULL]: (state) => ({
    ...state,
    mouseflowError: null,
  }),
});

export default reducer;
