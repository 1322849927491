import { takeLatest } from 'redux-saga/effects';
import { apiGetAIVORYTopicUsageWordcloud } from '../../../api/aivoryUsageAnalytics/contentKeywordAnalysis/api';

import createRequestSaga from '../../createRequestSaga';

import {
  getAIVORYTopicUsageWordcloudAsync,
  GET_AIVORY_TOPIC_USAGE_WORDCLOUD,
} from './actions';

const getAIVORYTopicUsageWordcloudSaga = createRequestSaga(
  getAIVORYTopicUsageWordcloudAsync,
  apiGetAIVORYTopicUsageWordcloud
);

export function* aivoryContentKeywordAnalysisSaga() {
  yield takeLatest(
    GET_AIVORY_TOPIC_USAGE_WORDCLOUD,
    getAIVORYTopicUsageWordcloudSaga
  );
}

export { aivoryContentKeywordAnalysisSaga as default };
