import React from 'react';
import styled from 'styled-components';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import Line from '../../../Atoms/Line';
import { H4, P3 } from '../../../Atoms/Typo';

import DefaultCardHeading from '../../../Molecule/Heading/Card/DefaultCardHeading';
import Spinner from '../../../Molecule/Spinner';

const Component = styled(CardLayout)`
  padding: 24px;
`;

const DataDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }

  ${P3} {
    font-weight: 500;
  }
`;

export interface PageUsabilityCardProps {
  isLoading: boolean;
  data: {
    effectiveClickRate: number | null;
    pageAttentionScreensCount: number | null;
    pageDuration: number | null;
  };
}

const PageUsabilityCard = ({ data, isLoading }: PageUsabilityCardProps) => {
  return (
    <Component>
      <CardHeaderLayout>
        <DefaultCardHeading title="페이지 사용성" />
      </CardHeaderLayout>
      <Line isColumn />
      {isLoading ? (
        <CardBodyLayout>
          <Spinner />
        </CardBodyLayout>
      ) : (
        <CardBodyLayout>
          <DataDisplay>
            <P3>유효 클릭 비율</P3>
            <H4>{data.effectiveClickRate}%</H4>
          </DataDisplay>
          <DataDisplay>
            <P3>페이지 체류시간</P3>
            <H4>{data.pageDuration}초</H4>
          </DataDisplay>
          <DataDisplay>
            <P3>주목 화면 개수</P3>
            <H4>{data.pageAttentionScreensCount}개</H4>
          </DataDisplay>
        </CardBodyLayout>
      )}
    </Component>
  );
};

export default PageUsabilityCard;
