import React from 'react';
import styled, { css } from 'styled-components';

import errorImg from '../../../assets/images/icon-noimg@3x.png';

const Component = styled.div<{ $isBackground: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100px;
  padding: 24px 16px;

  ${(props) => {
    if (props.$isBackground) {
      return css`
        background-color: ${props.theme.colors.grey200};
      `;
    }
    return css`
      border-radius: 8px;
      border: solid 1px ${props.theme.colors.grey300};
    `;
  }}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

interface ElementThumbnailImageProps {
  img_src: string;
  isBackground: boolean;
  className?: string;
  onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ElementThumbnailImage = ({
  img_src,
  isBackground,
  className,
  onMouseOver,
  onMouseLeave,
  onClick,
}: ElementThumbnailImageProps) => {
  return (
    <Component
      className={className}
      $isBackground={isBackground}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Image
        src={img_src}
        onError={(event) => {
          const target = event.target as HTMLImageElement;
          target.src = errorImg;
        }}
        alt=""
      />
    </Component>
  );
};

export default ElementThumbnailImage;
