import axios from 'axios';

import config from '../../config-api.json';
import {
  GetAIVORYContentUsageBySearchWordParamType,
  GetAIVORYContentUsageBySearchWordResponseType,
  GetAIVORYSearchKeywordNetworkParamType,
  GetAIVORYSearchKeywordNetworkResponseType,
  GetAIVORYSearchUsageWordcloudParamType,
  GetAIVORYSearchUsageWordcloudResponseType,
} from './type';

// axios.defaults.withCredentials = true;
const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetAIVORYSearchKeywordNetwork(
  info: GetAIVORYSearchKeywordNetworkParamType
) {
  const response = await axios.get<GetAIVORYSearchKeywordNetworkResponseType>(
    `${apiInfo.api_url}/aivory/search_keyword_network/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetAIVORYSearchUsageWordcloud(
  info: GetAIVORYSearchUsageWordcloudParamType
) {
  const response = await axios.get<GetAIVORYSearchUsageWordcloudResponseType>(
    `${apiInfo.api_url}/aivory/search_usage_wordcloud/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetAIVORYContentUsageBySearchWord(
  info: GetAIVORYContentUsageBySearchWordParamType
) {
  const response = await axios.get<GetAIVORYContentUsageBySearchWordResponseType>(
    `${apiInfo.api_url}/aivory/content_usage_by_search_word/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        search_word: info.search_word,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
