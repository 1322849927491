import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface HeaderProps {
  children?: React.ReactNode;
}

const Header = ({ children }: HeaderProps) => {
  return <Component>{children}</Component>;
};

export default Header;
