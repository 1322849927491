import React, { useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
import styled from 'styled-components';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface WordCloudChartProps {
  data?: {
    word: string;
    count: number;
    value: number;
  }[];
  onClickKeyword?: (word: string) => void;
}

const WordCloudChart = ({ data, onClickKeyword }: WordCloudChartProps) => {
  const colorDomain = ['#bdbdbd', '#d1bafc', '#9d7bf0', '#7851e7'];
  const [wordsState, setWords] = useState<
    {
      text: string;
      value: number;
      rate: string;
      color_value: number;
    }[]
  >([]);

  const onGetWordTooltip = (word: any) => {
    const result = `<div class='wordcloud-tooltip'>
            ${word.value} (${word.rate}%)
        </div>`;
    return result;
  };

  useEffect(() => {
    const result: {
      text: string;
      value: number;
      rate: string;
      color_value: number;
    }[] = [];

    if (data !== undefined) {
      let sum = 0;

      data.forEach((element) => {
        sum += element.count;
      });

      data.forEach((element) => {
        result.push({
          text: element.word,
          value: element.count,
          rate: ((element.count / sum) * 100).toFixed(2),
          color_value: element.value,
        });
      });
      setWords(result);
    }
  }, [data]);

  const onGetWordColor = (word: any) => {
    if (word.color_value >= 0.75) {
      return '#7851e7';
    }
    if (word.color_value >= 0.5) {
      return '#9d7bf0';
    }
    if (word.color_value >= 0.25) {
      return '#d1bafc';
    }
    return '#bdbdbd';
  };

  const onWordClick = (word: any) => {
    if (onClickKeyword) {
      onClickKeyword(word.text);
    }
  };

  return (
    <Component>
      {data === undefined || data.length === 0 ? (
        <DataError />
      ) : (
        <ReactWordcloud
          words={wordsState}
          callbacks={{
            getWordTooltip: onGetWordTooltip,
            getWordColor: onGetWordColor,
            onWordClick,
          }}
          options={{
            enableTooltip: true,
            deterministic: true,
            colors: colorDomain,
            fontFamily: 'Roboto, Noto Sans KR, sans-serif',
            fontWeight: '400',
            rotations: 0,
            fontSizes: [20, 64],
            padding: 8,
            tooltipOptions: {
              allowHTML: true,
            },
          }}
        />
      )}
    </Component>
  );
};

export default WordCloudChart;
