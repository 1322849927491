import { takeLatest } from 'redux-saga/effects';
import {
  apiGetGlobalBrowserEnvStatics,
  apiGetGlobalDeviceEnvStatics,
  apiGetGlobalOSEnvStatics,
  apiGetGlobalSessionEnvStatistics,
  apiGetNationalBrowserEnvStatics,
  apiGetNationalDeviceEnvStatics,
  apiGetNationalOSEnvStatics,
  apiGetNationalSessionEnvStatistics,
} from '../../../api/serviceAnalytics/environmentAnalysis/api';

import createRequestSaga from '../../createRequestSaga';
import {
  getNationalSessionEnvStatisticsAsync,
  getNationalDeviceEnvStaticsAsync,
  getNationalOSEnvStaticsAsync,
  getNationalBrowserEnvStaticsAsync,
  getGlobalSessionEnvStatisticsAsync,
  getGlobalDeviceEnvStaticsAsync,
  getGlobalOSEnvStaticsAsync,
  getGlobalBrowserEnvStaticsAsync,
  GET_NATIONAL_SESSION_ENV_STATISTICS,
  GET_NATIONAL_DEVICE_ENV_STATICS,
  GET_NATIONAL_OS_ENV_STATICS,
  GET_NATIONAL_BROWSER_ENV_STATICS,
  GET_GLOBAL_SESSION_ENV_STATISTICS,
  GET_GLOBAL_DEVICE_ENV_STATICS,
  GET_GLOBAL_OS_ENV_STATICS,
  GET_GLOBAL_BROWSER_ENV_STATICS,
} from './actions';

const getNationalSessionEnvStatisticsSaga = createRequestSaga(
  getNationalSessionEnvStatisticsAsync,
  apiGetNationalSessionEnvStatistics
);

const getNationalDeviceEnvStaticsSaga = createRequestSaga(
  getNationalDeviceEnvStaticsAsync,
  apiGetNationalDeviceEnvStatics
);

const getNationalOSEnvStaticsSaga = createRequestSaga(
  getNationalOSEnvStaticsAsync,
  apiGetNationalOSEnvStatics
);

const getNationalBrowserEnvStaticsSaga = createRequestSaga(
  getNationalBrowserEnvStaticsAsync,
  apiGetNationalBrowserEnvStatics
);

const getGlobalSessionEnvStatisticsSaga = createRequestSaga(
  getGlobalSessionEnvStatisticsAsync,
  apiGetGlobalSessionEnvStatistics
);

const getGlobalDeviceEnvStaticsSaga = createRequestSaga(
  getGlobalDeviceEnvStaticsAsync,
  apiGetGlobalDeviceEnvStatics
);

const getGlobalOSEnvStaticsSaga = createRequestSaga(
  getGlobalOSEnvStaticsAsync,
  apiGetGlobalOSEnvStatics
);

const getGlobalBrowserEnvStaticsSaga = createRequestSaga(
  getGlobalBrowserEnvStaticsAsync,
  apiGetGlobalBrowserEnvStatics
);

export function* environmentAnalysisSaga() {
  yield takeLatest(
    GET_NATIONAL_SESSION_ENV_STATISTICS,
    getNationalSessionEnvStatisticsSaga
  );
  yield takeLatest(
    GET_NATIONAL_DEVICE_ENV_STATICS,
    getNationalDeviceEnvStaticsSaga
  );
  yield takeLatest(GET_NATIONAL_OS_ENV_STATICS, getNationalOSEnvStaticsSaga);
  yield takeLatest(
    GET_NATIONAL_BROWSER_ENV_STATICS,
    getNationalBrowserEnvStaticsSaga
  );
  yield takeLatest(
    GET_GLOBAL_SESSION_ENV_STATISTICS,
    getGlobalSessionEnvStatisticsSaga
  );
  yield takeLatest(
    GET_GLOBAL_DEVICE_ENV_STATICS,
    getGlobalDeviceEnvStaticsSaga
  );
  yield takeLatest(GET_GLOBAL_OS_ENV_STATICS, getGlobalOSEnvStaticsSaga);
  yield takeLatest(
    GET_GLOBAL_BROWSER_ENV_STATICS,
    getGlobalBrowserEnvStaticsSaga
  );
}

export { environmentAnalysisSaga as default };
