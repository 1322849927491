import { createReducer } from 'typesafe-actions';
import {
  GET_AIVORY_CONTENTS_USAGE_ANALYSIS,
  GET_AIVORY_CONTENTS_USAGE_ANALYSIS_ERROR,
  GET_AIVORY_CONTENTS_USAGE_ANALYSIS_SUCCESS,
  GET_AIVORY_SEARCH_USAGE_ANALYSIS,
  GET_AIVORY_SEARCH_USAGE_ANALYSIS_ERROR,
  GET_AIVORY_SEARCH_USAGE_ANALYSIS_SUCCESS,
  SET_ERROR_NULL_AIVORY_SEARCH_USAGE,
} from './actions';
import { searchUsageAnalysisAction, searchUsageAnalysisState } from './types';

const initialState: searchUsageAnalysisState = {
  isLoadingContentsUsageAnalysis: false,
  isLoadingSearchUsageAnalysis: false,
  contentsUsageAnalysisData: null,
  searchUsageAnalysisData: null,

  aivorySearchUsageAnalysisError: null,
};

const reducer = createReducer<
  searchUsageAnalysisState,
  searchUsageAnalysisAction
>(initialState, {
  [GET_AIVORY_SEARCH_USAGE_ANALYSIS]: (state) => ({
    ...state,
    isLoadingSearchUsageAnalysis: true,
    searchUsageAnalysisData: null,
    aivorySearchUsageAnalysisError: null,
  }),
  [GET_AIVORY_SEARCH_USAGE_ANALYSIS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingSearchUsageAnalysis: false,
    searchUsageAnalysisData: action.payload.payload,
  }),
  [GET_AIVORY_SEARCH_USAGE_ANALYSIS_ERROR]: (state, action) => ({
    ...state,
    isLoadingSearchUsageAnalysis: false,
    searchUsageAnalysisData: null,
    aivorySearchUsageAnalysisError: action.payload,
  }),

  [GET_AIVORY_CONTENTS_USAGE_ANALYSIS]: (state) => ({
    ...state,
    isLoadingContentsUsageAnalysis: true,
    contentsUsageAnalysisData: null,
    aivorySearchUsageAnalysisError: null,
  }),
  [GET_AIVORY_CONTENTS_USAGE_ANALYSIS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingContentsUsageAnalysis: false,
    contentsUsageAnalysisData: action.payload.payload,
  }),
  [GET_AIVORY_CONTENTS_USAGE_ANALYSIS_ERROR]: (state, action) => ({
    ...state,
    isLoadingContentsUsageAnalysis: false,
    contentsUsageAnalysisData: null,
    aivorySearchUsageAnalysisError: action.payload,
  }),

  [SET_ERROR_NULL_AIVORY_SEARCH_USAGE]: (state) => ({
    ...state,
    aivorySearchUsageAnalysisError: null,
  }),
});

export default reducer;
