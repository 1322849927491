import React from 'react';
import styled, { css } from 'styled-components';
import { H4 } from '../../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const cardTitleVar = css`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey800};
`;

const CartTitleNormal = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey800};
`;

const CardTitleEM = styled.div`
  ${cardTitleVar}
  color: ${(props) => props.theme.colors.primary};
  margin-left: 4px;
  margin-right: 4px;
`;

interface TitleProps {
  isTopic: boolean;
  mainWord: string;
}

const Title = ({ isTopic, mainWord }: TitleProps) => {
  return (
    <Component>
      {isTopic ? (
        <>
          <CartTitleNormal>사용자에게 자주 보여진 키워드는</CartTitleNormal>
          <CardTitleEM>&apos;{mainWord}&apos;</CardTitleEM>
          <CartTitleNormal>입니다.</CartTitleNormal>
        </>
      ) : (
        <>
          <CartTitleNormal>가장 많이 찾은 검색어는</CartTitleNormal>
          <CardTitleEM>&apos;{mainWord}&apos;</CardTitleEM>
          <CartTitleNormal>입니다.</CartTitleNormal>
        </>
      )}
    </Component>
  );
};

export default Title;
