import React from 'react';
import styled, { css } from 'styled-components';
import { H4, P2, P3 } from '../../../Atoms/Typo';
import ElementThumbnailImage from '../../../Molecule/Thumbnail/ElementThumbnailImage';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Rank = styled.div`
  margin-bottom: 8px;
  ${P3} {
    font-weight: 500;
  }
`;

const ElementImage = styled(ElementThumbnailImage)<{ $isClicked: boolean }>`
  margin-bottom: 16px;
  cursor: pointer;

  ${(props) => {
    if (props.$isClicked) {
      return css`
        border: 1px solid ${props.theme.colors.purple3};
        background-color: rgba(181, 151, 247, 0.2);
      `;
    }
    return css``;
  }}
`;

const DataDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${P2} {
    color: ${(props) => props.theme.colors.grey700};
  }
`;

interface ElementCardProps {
  img_src: string;
  rank: number;
  data: {
    id: number;
    title: string;
    value: string;
  }[];
  isClicked: boolean;
  className?: string;
  onMouseOverImage?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClickImage?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeaveImage?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

const ElementCard = ({
  img_src,
  rank,
  data,
  isClicked,
  className,
  onMouseOverImage,
  onClickImage,
  onMouseLeaveImage,
}: ElementCardProps) => {
  return (
    <Component className={className}>
      <Rank>
        <P3>{rank}위</P3>
      </Rank>
      <ElementImage
        img_src={img_src}
        isBackground={false}
        $isClicked={isClicked}
        onMouseOver={onMouseOverImage}
        onMouseLeave={onMouseLeaveImage}
        onClick={onClickImage}
      />
      {data.map((element) => {
        return (
          <DataDisplay key={element.id}>
            <P2>{element.title}</P2>
            <H4>{element.value}</H4>
          </DataDisplay>
        );
      })}
    </Component>
  );
};

export default ElementCard;
