import { createReducer } from 'typesafe-actions';
import {
  GET_TOTAL_USER_COUNT,
  GET_TOTAL_USER_COUNT_SUCCESS,
  GET_TOTAL_USER_COUNT_ERROR,
  GET_WEEKLY_USER_COUNT,
  GET_WEEKLY_USER_COUNT_SUCCESS,
  GET_WEEKLY_STATISTICS_ERROR,
  SET_ERROR_NULL_SERVICE_UV,
} from './actions';
import { userAnalysisAction, userAnalysisState } from './types';

const initialState: userAnalysisState = {
  isGetTUCLoading: false,
  isGetWUCLoading: false,
  TUCData: null,
  WUCData: null,
  userAnalysisError: null,
};

const reducer = createReducer<userAnalysisState, userAnalysisAction>(
  initialState,
  {
    [GET_TOTAL_USER_COUNT]: (state) => ({
      ...state,
      isGetTUCLoading: true,
      TUCData: null,
      userAnalysisError: null,
    }),
    [GET_TOTAL_USER_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      isGetTUCLoading: false,
      TUCData: action.payload.payload,
    }),
    [GET_TOTAL_USER_COUNT_ERROR]: (state, action) => ({
      ...state,
      isGetTUCLoading: false,
      TUCData: null,
      userAnalysisError: action.payload,
    }),

    [GET_WEEKLY_USER_COUNT]: (state) => ({
      ...state,
      isGetWUCLoading: true,
      WUCData: null,
      userAnalysisError: null,
    }),
    [GET_WEEKLY_USER_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      isGetWUCLoading: false,
      WUCData: action.payload.payload,
    }),
    [GET_WEEKLY_STATISTICS_ERROR]: (state, action) => ({
      ...state,
      isGetWUCLoading: false,
      WUCData: null,
      userAnalysisError: action.payload,
    }),

    [SET_ERROR_NULL_SERVICE_UV]: (state) => ({
      ...state,
      userAnalysisError: null,
    }),
  }
);

export default reducer;
