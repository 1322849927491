import React from 'react';
import styled, { css } from 'styled-components';

const Component = styled.div<{
  $size: number;
  $isBlock: boolean;
}>`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};

  ${(props) => {
    if (props.$isBlock) {
      return css`
        width: 100%;
        height: 100%;
      `;
    }
    return css``;
  }}
`;

interface DotProps {
  size?: number;
  isBlock?: boolean;
  className?: string;
}

const Dot = ({ size = 8, isBlock = false, className }: DotProps) => {
  return <Component className={className} $isBlock={isBlock} $size={size} />;
};

export default Dot;
