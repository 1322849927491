import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import { ReactComponent as MapIllustSVG } from './map-illust.svg';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

const MapIllust = styled(MapIllustSVG)<{ $activateID: number }>`
  width: 247px;
  height: 341px;
  path {
    fill: ${(props) => props.theme.colors.grey300} !important;
    ${(props) => {
      return css`
        &:nth-child(${props.$activateID}) {
          fill: ${props.theme.colors.purple3} !important;
        }
      `;
    }}
  }
`;

interface MapProps {
  region: string;
}

const Map = ({ region }: MapProps) => {
  const [activateID, setActivateID] = useState(0);
  const code = {
    수도권: 4,
    강원: 3,
    경상: 2,
    충청: 5,
    전라: 1,
    제주: 6,
  };
  useEffect(() => {
    setActivateID(
      code[region as '수도권' | '강원' | '경상' | '충청' | '전라' | '제주']
    );
  }, [region]);
  return (
    <Component>
      <MapIllust $activateID={activateID} />
    </Component>
  );
};

export default Map;
