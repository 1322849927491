import { takeLatest } from 'redux-saga/effects';
import {
  apiGetPageViewCount,
  apiGetWeeklyPageViewCount,
  apiGetRankPageViewCount,
} from '../../../api/serviceAnalytics/pageviewAnalysis/api';

import createRequestSaga from '../../createRequestSaga';
import {
  GET_PAGE_VIEW_COUNT,
  GET_WEEKLY_PAGE_VIEW_COUNT,
  GET_RANK_PAGE_VIEW_COUNT,
  getPageViewCountAsync,
  getWeeklyPageViewCountAsync,
  getRankPageViewCountAsync,
} from './actions';

const getPageViewCountSaga = createRequestSaga(
  getPageViewCountAsync,
  apiGetPageViewCount
);

const getWeeklyPageViewCountSaga = createRequestSaga(
  getWeeklyPageViewCountAsync,
  apiGetWeeklyPageViewCount
);

const getRankPageViewCountSaga = createRequestSaga(
  getRankPageViewCountAsync,
  apiGetRankPageViewCount
);

export function* servicePageViewAnalysisSaga() {
  yield takeLatest(GET_PAGE_VIEW_COUNT, getPageViewCountSaga);
  yield takeLatest(GET_WEEKLY_PAGE_VIEW_COUNT, getWeeklyPageViewCountSaga);
  yield takeLatest(GET_RANK_PAGE_VIEW_COUNT, getRankPageViewCountSaga);
}

export { servicePageViewAnalysisSaga as default };
