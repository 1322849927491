import React from 'react';
import styled, { DefaultTheme, StyledComponent, css } from 'styled-components';
import { IconBot } from '../../../Atoms/Icon';
import { P1, P3 } from '../../../Atoms/Typo';
import Header from '../../../Molecule/Card/CardHeader/ReportHeader';
import CardImage from '../../../Molecule/Card/CardImage';

const Component = styled.div<{ $isBot?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => {
    if (props.$isBot) {
      return css`
        border-radius: 8px;
        border: solid 1px ${props.theme.colors.grey200};
        padding: 0px 16px 24px 16px;
      `;
    }
    return css``;
  }}
`;

const Body = styled.div<{ $isBot?: boolean }>`
  width: 100%;
  height: 263px;
  margin-top: 24px;

  ${(props) => {
    if (props.$isBot) {
      return css`
        margin-top: 0px;
        margin-left: -16px;
        margin-right: -16px;
      `;
    }
    return css``;
  }}
`;

const PageTitle = styled(P3)`
  margin-top: 8px;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
`;

const PageUrl = styled.a`
  ${P3} {
    color: ${(props) => props.theme.colors.grey500};
  }

  margin-top: 4px;
  :hover {
    ${P3} {
      color: ${(props) => props.theme.colors.grey500};
    }
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BotRateWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 24px;
`;

const BotIconCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  margin-right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 18px;
  }
`;

const BotRate = styled(P1)`
  font-weight: 500;
`;

interface PopularPageCardProps {
  cardTitle: string;
  cardValue: number;
  pageTitle: string;
  pageUrl: string;
  imgUrl: string;
  Icon?: StyledComponent<
    React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >,
    DefaultTheme,
    any,
    never
  >;
  isBot?: boolean;
  botPercent?: number;
  noData?: boolean;
}

const PopularPageCard = ({
  cardTitle,
  cardValue,
  pageTitle,
  pageUrl,
  imgUrl,
  Icon,
  isBot,
  botPercent,
  noData,
}: PopularPageCardProps) => {
  return (
    <Component $isBot={isBot}>
      {isBot ? null : (
        <Header title={cardTitle} value={cardValue} Icon={Icon} />
      )}

      <Body $isBot={isBot}>
        <CardImage imgSrc={imgUrl} noData={noData} />
      </Body>
      <PageTitle>{pageTitle}</PageTitle>
      <PageUrl href={pageUrl} target="_blank">
        <P3>{pageUrl}</P3>
      </PageUrl>
      {isBot ? (
        <BotRateWrapper>
          <BotIconCircle>
            <IconBot />
          </BotIconCircle>
          <BotRate>{botPercent || 0}%</BotRate>
        </BotRateWrapper>
      ) : null}
    </Component>
  );
};

export default PopularPageCard;
