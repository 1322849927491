import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../Atoms/Button';
import { P3 } from '../../Atoms/Typo';

const Component = styled.div<{ $activeID: number }>`
  display: flex;
  align-items: center;

  button {
    width: 86px;
    height: 36px;
    ${P3};
    color: ${(props) => props.theme.colors.grey500};
    background-color: white;
    :first-child {
      border-radius: 8px 0px 0px 8px;
    }
    :last-child {
      border-radius: 0px 8px 8px 0px;
    }
  }

  ${(props) => {
    return css`
      button {
        :nth-child(${props.$activeID + 1}) {
          color: white;
          background-color: ${props.theme.colors.primary};
        }
      }
    `;
  }}
`;

interface TabButtonProps {
  buttonData: {
    id: number;
    title: string;
  }[];
  activeID: number;
  onClickTab?: (id: number) => void;
}

const TabButton = ({ buttonData, activeID, onClickTab }: TabButtonProps) => {
  const onClickButton = (id: number) => {
    if (onClickTab) {
      onClickTab(id);
    }
  };

  return (
    <Component $activeID={activeID}>
      {buttonData.map((element) => {
        return (
          <Button
            key={element.id}
            onClick={() => {
              onClickButton(element.id);
            }}
          >
            {element.title}
          </Button>
        );
      })}
    </Component>
  );
};

export default TabButton;
