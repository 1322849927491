import React from 'react';

import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../components/Organisms/Header';

import SupportCard from '../../components/Organisms/Card/SupportCard';

const Support = () => {
  return (
    <ContentsLayout>
      <Header title="고객센터" isDatePicker={false} />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <SupportCard
            header="문의하기"
            bodyText="VODA를 사용하다 궁금하신 부분이 있다면 contact@nerdfactory.ai로 연락주신다면 친절하게 도와드리겠습니다. :)"
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Support;
