import { takeLatest } from 'redux-saga/effects';
import {
  apiGetTotalUserCount,
  apiGetWeeklyUserCount,
} from '../../../api/serviceAnalytics/userAnalysis/api';

import createRequestSaga from '../../createRequestSaga';
import {
  GET_TOTAL_USER_COUNT,
  GET_WEEKLY_USER_COUNT,
  getTotalUserCountAsync,
  getWeeklyUserCountAsync,
} from './actions';

const getTotalUserCountSaga = createRequestSaga(
  getTotalUserCountAsync,
  apiGetTotalUserCount
);

const getWeeklyUserCountSaga = createRequestSaga(
  getWeeklyUserCountAsync,
  apiGetWeeklyUserCount
);

export function* serviceUserAnalysisSaga() {
  yield takeLatest(GET_TOTAL_USER_COUNT, getTotalUserCountSaga);
  yield takeLatest(GET_WEEKLY_USER_COUNT, getWeeklyUserCountSaga);
}

export { serviceUserAnalysisSaga as default };
