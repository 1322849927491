import React from 'react';
import styled from 'styled-components';
import { P2 } from '../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;

  ${P2} {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
  }
`;

interface TimerProps {
  time: string;
}

const Timer = ({ time }: TimerProps) => {
  return (
    <Component className="voda_timer">
      <P2>{time}</P2>
    </Component>
  );
};

export default Timer;
