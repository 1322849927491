import React from 'react';
import styled from 'styled-components';
import noImg from '../../../../assets/images/no_img1@3x.png';

const Component = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
`;

const NoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
interface CardImageProps {
  imgSrc: string;
  noData?: boolean;
}

const CardImage = ({ imgSrc, noData }: CardImageProps) => {
  const handleImgError = (e: any) => {
    e.target.src = noImg;
  };

  return (
    <Component>
      {noData ? <NoImg src={noImg} alt="" /> : <Img src={imgSrc} alt="" />}
      {/* <Img src={imgSrc} alt="" onError={handleImgError} /> */}
    </Component>
  );
};

export default CardImage;
