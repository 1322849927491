import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';

import Filter from '../../Molecule/Chart/Filter';
import Modal from '../../Molecule/Chart/Filter/Modal';
import MultiLineChart from '../../Molecule/Chart/MultiLineChart';
import LegendTitleAllUser from '../../Molecule/Chart/Legend';
import Spinner from '../../Molecule/Spinner';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';

const Component = styled(CardLayout)`
  height: 467px;
  box-sizing: border-box;
  padding: 30px 0 0;
`;

const HeaderLayout = styled(CardHeaderLayout)`
  padding: 0 30px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 0 30px; */
`;

const CardHeaderTitle = styled(DefaultCardHeading)`
  margin-bottom: 8px;
`;

const CardBody = styled(CardBodyLayout)`
  padding: 0 30px;
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  background-color: ${(props) => props.theme.colors.grey100};
  border-radius: 0 0 14px 14px;
`;

const LegendItem = styled(LegendTitleAllUser)`
  margin-right: 16px;
`;

export interface MultiLineChartCardProps {
  legendData: {
    title: string;
    status:
      | 'all'
      | 'return'
      | 'new'
      | 'all_future'
      | 'return_future'
      | 'new_future';
  }[];
  Data: {
    [key: string]: {
      total: number;
      old: number;
      new: number;
      is_future?: boolean;
    };
  } | null;
  isLoading: boolean;
  selectedTimeSort: string;
  selectedUserSort: string;
  lineLabel: string;
  bar1Label?: string;
  bar2Label?: string;
  headerTitle?: string;
  setSelectedTimeSort: React.Dispatch<React.SetStateAction<string>>;
  setSelectedUserSort: React.Dispatch<React.SetStateAction<string>>;
  onChangeFliter: () => void;
}

const MultiLineChartCard = ({
  legendData,
  Data,
  selectedTimeSort,
  selectedUserSort,
  isLoading,
  lineLabel,
  bar1Label,
  bar2Label,
  headerTitle,
  setSelectedTimeSort,
  setSelectedUserSort,
  onChangeFliter,
}: MultiLineChartCardProps) => {
  const [selectedTimeBefore, setSelectedTimeBefore] = useState('day');
  const [selectedUserBefore, setSelectedUserBefore] = useState('all');
  const [isFliterOpen, setIsFliterOpen] = useState(false);

  const onClickFilterIcon = () => {
    if (isFliterOpen) {
      onChangeFliter();
    }
    setIsFliterOpen((prev) => {
      return !prev;
    });
  };

  const handleChangeRadio = (id: 'day' | 'week' | 'month' | 'hour') => {
    setSelectedTimeSort(id);
  };

  const handleChangeCheck = (id: 'all' | 'return' | 'new') => {
    setSelectedUserSort(id);
  };

  const onClickConfirmFilter = () => {
    onChangeFliter();
    setIsFliterOpen(false);
  };

  const onClickCancelFilter = () => {
    setSelectedTimeSort(selectedTimeBefore);
    setSelectedUserSort(selectedUserBefore);
    setIsFliterOpen(false);
  };

  useEffect(() => {
    if (isFliterOpen) {
      setSelectedTimeBefore(selectedTimeSort);
      setSelectedUserBefore(selectedUserSort);
    }
  }, [isFliterOpen]);

  return (
    <Component>
      <Modal
        isModalOpen={isFliterOpen}
        clickedRadioId={selectedTimeSort}
        clickedCheckButtonId={selectedUserSort}
        onClickRadio={handleChangeRadio}
        onClickCheckButton={handleChangeCheck}
        onClickCancel={onClickCancelFilter}
        onClickConfirm={onClickConfirmFilter}
      />

      <HeaderLayout>
        <CardHeader>
          {headerTitle ? <CardHeaderTitle title={headerTitle} /> : null}
        </CardHeader>
        <Filter onClick={onClickFilterIcon} />
      </HeaderLayout>

      <CardBody className="card-body">
        {isLoading ? (
          <Spinner />
        ) : (
          <MultiLineChart
            Data={Data}
            selectedTimeSort={selectedTimeSort}
            lineLabel={lineLabel}
            bar1Label={bar1Label}
            bar2Label={bar2Label}
          />
        )}
      </CardBody>
      <LegendWrapper>
        {legendData.map((element) => {
          return (
            <LegendItem
              key={element.title}
              title={element.title}
              status={element.status}
            />
          );
        })}
      </LegendWrapper>
    </Component>
  );
};

export default MultiLineChartCard;
