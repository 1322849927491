import axios from 'axios';

import config from '../config-api.json';
import { GetForecastDateResponseType } from './type';

// axios.defaults.withCredentials = true;
const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetForecastDate() {
  const response = await axios.get<GetForecastDateResponseType>(
    `${apiInfo.api_url}/forecast/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export { apiGetForecastDate as defaults };
