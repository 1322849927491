import axios from 'axios';

import config from '../../config-api.json';
import {
  GetAIVORYDashboardStaticsParamType,
  GetAIVORYDashboardStaticsResponseType,
} from './type';

// axios.defaults.withCredentials = true;
const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetAIVORYDashboardStatics(
  info: GetAIVORYDashboardStaticsParamType
) {
  const response = await axios.get<GetAIVORYDashboardStaticsResponseType>(
    `${apiInfo.api_url}/aivory/dashboard/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export { apiGetAIVORYDashboardStatics as defaults };
