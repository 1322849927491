import { takeLatest } from 'redux-saga/effects';
import { apiGetPageFlow } from '../../../api/pageAnalytics/userFlowAnalysis/api';

import {
  getPageFlowConversionAsync,
  getPageFlowInflowAsync,
  GET_PAGE_FLOW_CONVERSION,
  GET_PAGE_FLOW_INFLOW,
} from './actions';
import createRequestSaga from '../../createRequestSaga';

const getPageFlowInflowSaga = createRequestSaga(
  getPageFlowInflowAsync,
  apiGetPageFlow
);

const getPageFlowConversionSaga = createRequestSaga(
  getPageFlowConversionAsync,
  apiGetPageFlow
);

export function* userFlowAnalysisSaga() {
  yield takeLatest(GET_PAGE_FLOW_INFLOW, getPageFlowInflowSaga);
  yield takeLatest(GET_PAGE_FLOW_CONVERSION, getPageFlowConversionSaga);
}

export { userFlowAnalysisSaga as default };
