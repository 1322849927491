import React, { useState, useEffect } from 'react';
import styled, {
  DefaultTheme,
  StyledComponent,
  css,
  keyframes,
} from 'styled-components';

import { Button } from '../../Atoms/Button';
import DefaultNavLink from '../../Atoms/NavLink';
import { P1 } from '../../Atoms/Typo';

const visibleAni = ($height: number) => keyframes`
    0%{
        height: 0px;
    }
    100%{
        height: ${$height}px;
    }
`;

const closeAni = ($height: number) => keyframes`
    0%{
        height: ${$height}px;
    }
    100%{
        height: 0px;
    }
`;

const Component = styled.div``;

const DropDownButton = styled(Button)<{ $isActive: boolean | null }>`
  padding-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f3f8;
  margin-bottom: 15px;

  ${(props) => {
    if (props.$isActive) {
      return css`
        ${P1} {
          border-bottom: 2px solid ${props.theme.colors.grey900};
        }
      `;
    }
    return css`
      color: ${props.theme.colors.grey900};
    `;
  }}

  svg {
    &:last-child {
      transition: transform 0.2s ease-out;
      width: 12px;
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${P1} {
    font-size: 18px;
    font-weight: bold;
    span {
      font-size: 16px;
      font-weight: normal;
      margin-left: 10px;
    }
  }

  svg {
    width: 16px;
    margin-right: 8px;
  }
`;

const DropDownBody = styled.div<{
  $isActive: boolean | null;
  $subMenuCnt: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #f1f3f8;

  overflow: hidden;

  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  a {
    display: flex;
    align-items: center;
    /* height: 52px !important; */
    padding: 0px 0px 10px 115px;
  }
  ${(props) => {
    if (props.$isActive) {
      return css`
        animation-name: ${visibleAni(props.$subMenuCnt * 33.5)};
        margin-bottom: 18px;
      `;
    }
    if (props.$isActive === false) {
      return css`
        animation-name: ${closeAni(props.$subMenuCnt * 33.5)};
      `;
    }
    return css`
      height: 0px;
    `;
  }}
`;

interface NavDropDownButtonProps {
  menuId: number;
  Icon: StyledComponent<
    React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >,
    DefaultTheme,
    any,
    never
  >;
  title: string;
  subMenu: {
    title: string;
    to: string;
    exact?: boolean;
  }[];
  initIsActive: boolean;
  // setActiveMenuId: React.Dispatch<React.SetStateAction<number>>;
  onClickDropDownButton: (id: number) => void;
}

const NavDropDownButton = ({
  title,
  Icon,
  subMenu,
  initIsActive,
  menuId,
  // setActiveMenuId,
  onClickDropDownButton,
}: NavDropDownButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const onClick = () => {
    setIsOpen((prev) => {
      return !prev;
    });
  };

  const printIcon = () => {
    switch (menuId) {
      case 1:
        return <span className="ri-user-reveived-line"></span>;
      case 2:
        return <span className="ri-user-search-line"></span>;
      case 3:
        return <span className="ri-layout-3-line"></span>;
      case 4:
        return <span className="ri-user-reveived-line"></span>;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (initIsActive) {
      setIsOpen(true);
    } else if (isOpen === true) {
      setIsOpen(false);
    }
  }, [initIsActive]);

  useEffect(() => {
    if (isOpen) {
      onClickDropDownButton(menuId);
    }
  }, [isOpen]);

  return (
    <Component>
      <DropDownButton $isActive={isOpen} isBlock onClick={onClick}>
        <TitleBox>
          <Icon className={isOpen ? 'focus' : 'default'}>{printIcon()}</Icon>
          <P1>
            {title}
            {isOpen ? <span className="ri-arrow-right-line"></span> : null}
          </P1>
        </TitleBox>
      </DropDownButton>
      <DropDownBody $isActive={isOpen} $subMenuCnt={subMenu.length}>
        {subMenu.map((element) => {
          return (
            <DefaultNavLink
              key={element.to}
              to={element.to}
              title={element.title}
              exact={element.exact}
              subLink
            />
          );
        })}
      </DropDownBody>
    </Component>
  );
};

export default NavDropDownButton;
