import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../Atoms/Button';
import { IconSetting2 } from '../../../Atoms/Icon';

const Component = styled.div`
  button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: ${(props) => props.theme.colors.grey500};
      }
    }
  }
`;

interface FilterProps {
  onClick: () => void;
}

const Filter = ({ onClick }: FilterProps) => {
  return (
    <Component>
      <Button
        onClick={() => {
          onClick();
        }}
      >
        <IconSetting2 />
      </Button>
    </Component>
  );
};

export default Filter;
