import React from 'react';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';

const Component = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PaginationCustom = styled(Pagination)`
  .MuiPaginationItem-root {
    height: 36px;
    width: 36px;
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.colors.grey600};
  }
  .MuiPaginationItem-page.Mui-selected {
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
  }
`;

interface DefaultPagenationProps {
  count: number;
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const DefaultPagenation = ({ count, onChange }: DefaultPagenationProps) => {
  return (
    <Component>
      <PaginationCustom
        count={count}
        onChange={onChange}
        defaultPage={1}
        shape="rounded"
      />
    </Component>
  );
};

export default DefaultPagenation;
