import React from 'react';
import styled from 'styled-components';
import { P3 } from '../../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const RangeValue = styled(P3)`
  line-height: 1;
  color: ${(props) => props.theme.colors.grey600};
`;

const Range = styled.div`
  width: 176px;
  height: 24px;
  background: linear-gradient(to left, #9d7bf0, #e8dcfd 91%);
  margin-left: 8px;
  margin-right: 8px;
`;

interface HeatmapLegendProps {
  min: number;
  max: number;
}

const HeatmapLegend = ({ min, max }: HeatmapLegendProps) => {
  return (
    <Component>
      <RangeValue>{min}</RangeValue>
      <Range />
      <RangeValue>{max}</RangeValue>
    </Component>
  );
};

export default HeatmapLegend;
