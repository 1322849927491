import React, { useState } from 'react';
import styled from 'styled-components';
import { WeeklyUserCountPayload } from '../../../api/serviceAnalytics/userAnalysis/type';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';

import HeatmapChart from '../../Molecule/Chart/HeatmapChart';
import HeatmapLegend from '../../Molecule/Chart/Legend/HeatmapLegend';
import Spinner from '../../Molecule/Spinner';

import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';

const Component = styled(CardLayout)`
  height: 497px;
`;

const Body = styled(CardBodyLayout)`
  margin-top: 40px;
`;

export interface HeatmapChartCardProps {
  Data: WeeklyUserCountPayload | null;
  label1: string;
  label2: string;
  totalLabel: string;
  loading: boolean;
}

const HeatmapChartCard = ({
  Data,
  label1,
  label2,
  totalLabel,
  loading,
}: HeatmapChartCardProps) => {
  const [max, setHeatmapMax] = useState(0);
  const [min, setHeatmapMin] = useState(0);
  return (
    <Component>
      <CardHeaderLayout>
        <DefaultCardHeading title="시간대별 그래프" />
        <HeatmapLegend min={min} max={max} />
      </CardHeaderLayout>
      <Body>
        {loading ? (
          <Spinner />
        ) : (
          <HeatmapChart
            Data={Data}
            label1={label1}
            label2={label2}
            totalLabel={totalLabel}
            setHeatmapMax={setHeatmapMax}
            setHeatmapMin={setHeatmapMin}
          />
        )}
      </Body>
    </Component>
  );
};

export default HeatmapChartCard;
