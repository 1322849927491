import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { PageUsabilityPageDurationPayload } from '../../../../api/report/type';
import { H3, P1 } from '../../../Atoms/Typo';
import Header from '../../../Molecule/Card/CardHeader';
import ReportSummary from '../../../Molecule/Card/CardSummary/ReportSummary';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';

import Spinner from '../../../Molecule/Spinner';
import BarChartCard from '../BarChartCard';

const Component = styled.div`
  width: 100%;
  min-height: 806px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;

  padding: 24px 24px 80px 24px;
`;

const ReportRaw = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  ${H3} {
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const Body = styled.div`
  width: 100%;
  height: 356px;
`;

interface PageUsabilityReportCardProps {
  data: PageUsabilityPageDurationPayload | null;
  isLoading: boolean;
}

interface PageUsabilityReportCardState {
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

const PageUsabilityPageDurationReportCard = ({
  data,
  isLoading,
}: PageUsabilityReportCardProps) => {
  const [duration, setDuration] = useState(0);
  const [report, setReport] = useState<React.ReactNode>();

  const [currentMonth, setCurrentMonth] = useState('');
  const [rebuildDurationData, setRebuildDurationData] = useState<
    PageUsabilityReportCardState['rebuildData']
  >([]);
  const convertSec = (sec: number) => {
    const cvMin = Math.floor(sec / 60);
    const cvSec = Math.floor(sec % 60);
    if (cvMin === 0) {
      return `${cvSec}초`;
    }
    return `${cvMin}분 ${cvSec}초`;
  };

  useEffect(() => {
    if (data) {
      const month_obj = data.duration_time_list;
      const month_arr = Object.keys(month_obj);

      setCurrentMonth(`${(month_arr[2] || '').split('-')[1]}월`);

      const rebuildData: PageUsabilityReportCardState['rebuildData'] = [];

      if (month_arr.length) {
        month_arr.forEach((element, index) => {
          rebuildData.push({
            [`${element.split('-')[1]}월`]: month_obj[element],
            isHighlight: index === 2,
          });

          if (index === 2) {
            setDuration(month_obj[element]);
          }
        });
      }

      setRebuildDurationData(rebuildData);

      if (data.growth_time > 0) {
        setReport(
          <>
            <ReportRaw>
              <P1>지난 달 보다</P1>
              <H3>{convertSec(Math.abs(data.growth_time))}</H3>
              <P1>더 오래 이용했습니다.</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>더 알찬 콘텐츠를 제공해</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>페이지 체류시간을 늘려보세요.</P1>
            </ReportRaw>
          </>
        );
      } else {
        setReport(
          <>
            <ReportRaw>
              <P1>지난 달 보다</P1>
              <H3>{convertSec(Math.abs(data.growth_time))}</H3>
              <P1>짧게 이용했습니다.</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>더 알찬 콘텐츠를 제공해</P1>
            </ReportRaw>
            <ReportRaw>
              <P1>페이지 체류시간을 늘려보세요.</P1>
            </ReportRaw>
          </>
        );
      }
    }
  }, [data]);

  return (
    <Component>
      <Header>
        <div>
          <Title title="페이지 평균 이용 시간" />
          <ReportSummary>{report}</ReportSummary>
        </div>
      </Header>

      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title={`${currentMonth} 페이지 평균 이용 시간`}
            value={duration}
            desc=""
            data={rebuildDurationData}
            isLabelInChart={false}
            isTime
          />
        )}
      </Body>
    </Component>
  );
};

export default PageUsabilityPageDurationReportCard;
