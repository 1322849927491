import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetPageViewCountParamType,
  GetPageViewCountResponseType,
  GetWeeklyPageViewCountParamType,
  GetWeeklyPageViewCountResponseType,
  GetRankPageViewCountParamType,
  GetRankPageViewCountResponseType,
} from '../../../api/serviceAnalytics/pageviewAnalysis/type';

export const GET_PAGE_VIEW_COUNT = 'serviceAnalytics/pageViewAnalysis/GET_PAGE_VIEW_COUNT' as const;
export const GET_PAGE_VIEW_COUNT_SUCCESS = 'serviceAnalytics/pageViewAnalysis/GET_PAGE_VIEW_COUNT_SUCCESS' as const;
export const GET_PAGE_VIEW_COUNT_ERROR = 'serviceAnalytics/pageViewAnalysis/GET_PAGE_VIEW_COUNT_ERROR' as const;

export const GET_WEEKLY_PAGE_VIEW_COUNT = 'serviceAnalytics/pageViewAnalysis/GET_WEEKLY_PAGE_VIEW_COUNT' as const;
export const GET_WEEKLY_PAGE_VIEW_COUNT_SUCCESS = 'serviceAnalytics/pageViewAnalysis/GET_WEEKLY_PAGE_VIEW_COUNT_SUCCESS' as const;
export const GET_WEEKLY_PAGE_VIEW_ERROR = 'serviceAnalytics/pageViewAnalysis/GET_WEEKLY_PAGE_VIEW_ERROR' as const;

export const GET_RANK_PAGE_VIEW_COUNT = 'serviceAnalytics/pageViewAnalysis/GET_RANK_PAGE_VIEW_COUNT' as const;
export const GET_RANK_PAGE_VIEW_COUNT_SUCCESS = 'serviceAnalytics/pageViewAnalysis/GET_RANK_PAGE_VIEW_COUNT_SUCCESS' as const;
export const GET_RANK_PAGE_VIEW_ERROR = 'serviceAnalytics/pageViewAnalysis/GET_RANK_PAGE_VIEW_ERROR' as const;

export const SET_ERROR_NULL_SERVICE_PV =
  'serviceAnalytics/pageViewAnalysis/SET_ERROR_NULL_SERVICE_PV';

export const getPageViewCountAsync = createAsyncAction(
  GET_PAGE_VIEW_COUNT,
  GET_PAGE_VIEW_COUNT_SUCCESS,
  GET_PAGE_VIEW_COUNT_ERROR
)<GetPageViewCountParamType, GetPageViewCountResponseType, AxiosErrorType>();

export const getWeeklyPageViewCountAsync = createAsyncAction(
  GET_WEEKLY_PAGE_VIEW_COUNT,
  GET_WEEKLY_PAGE_VIEW_COUNT_SUCCESS,
  GET_WEEKLY_PAGE_VIEW_ERROR
)<
  GetWeeklyPageViewCountParamType,
  GetWeeklyPageViewCountResponseType,
  AxiosErrorType
>();

export const getRankPageViewCountAsync = createAsyncAction(
  GET_RANK_PAGE_VIEW_COUNT,
  GET_RANK_PAGE_VIEW_COUNT_SUCCESS,
  GET_RANK_PAGE_VIEW_ERROR
)<
  GetRankPageViewCountParamType,
  GetRankPageViewCountResponseType,
  AxiosErrorType
>();

export const setErrorNullServicePV = createAction(SET_ERROR_NULL_SERVICE_PV)();
