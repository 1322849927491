import axios from 'axios';
import {
  GetPageViewCountParamType,
  GetPageViewCountResponseType,
  GetWeeklyPageViewCountParamType,
  GetWeeklyPageViewCountResponseType,
  GetRankPageViewCountParamType,
  GetRankPageViewCountResponseType,
} from './type';

import config from '../../config-api.json';

// axios.defaults.withCredentials = true;
const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetPageViewCount(info: GetPageViewCountParamType) {
  const response = await axios.get<GetPageViewCountResponseType>(
    `${apiInfo.api_url}/page_count/`,
    {
      params: {
        unit: info.unit,
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetWeeklyPageViewCount(
  info: GetWeeklyPageViewCountParamType
) {
  const response = await axios.get<GetWeeklyPageViewCountResponseType>(
    `${apiInfo.api_url}/weekly_page_count/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetRankPageViewCount(
  info: GetRankPageViewCountParamType
) {
  const response = await axios.get<GetRankPageViewCountResponseType>(
    `${apiInfo.api_url}/rank_page_count/`,
    {
      params: {
        ordering: info.ordering,
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
