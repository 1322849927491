import axios from 'axios';
import config from '../config-api.json';
import {
  GetBotTrafficPageResponseType,
  GetBotTrafficSiteResponseType,
  GetExternalNewsKeywordNetworkResponseType,
  GetExternalNewsKeywordWordcloudResponseType,
  GetExternalReferrerResponseType,
  GetForecastResponseType,
  GetLocalPVResponseType,
  GetLocalRegionResponseType,
  GetLocalUVResponseType,
  GetNewVSReturnDurationResponseType,
  GetNewVSReturnPVResponseType,
  GetNewVSReturnUVResponseType,
  GetPageUsabilityClickRateResponseType,
  GetPageUsabilityPageDurationResponseType,
  GetPopularPageGrowthResponseType,
  GetPopularPageMostResponseType,
  GetSearchWordResponseType,
  GetServiceActivationResponseType,
  GetSimilarAgencyPVResponseType,
  GetSimilarAgencyTypeResponseType,
  GetSimilarAgencyUVResponseType,
  GetSimilarScalePVResponseType,
  GetSimilarScaleTypeResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetServiceActivationUV() {
  const response = await axios.get<GetServiceActivationResponseType>(
    `${apiInfo.api_url}/report_uv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetServiceActivationPV() {
  const response = await axios.get<GetServiceActivationResponseType>(
    `${apiInfo.api_url}/report_pv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNewVSReturnUV() {
  const response = await axios.get<GetNewVSReturnUVResponseType>(
    `${apiInfo.api_url}/report_compare_uv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNewVSReturnDuration() {
  const response = await axios.get<GetNewVSReturnDurationResponseType>(
    `${apiInfo.api_url}/report_compare_usage/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNewVSReturnPV() {
  const response = await axios.get<GetNewVSReturnPVResponseType>(
    `${apiInfo.api_url}/report_compare_pv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPopularPageMost() {
  const response = await axios.get<GetPopularPageMostResponseType>(
    `${apiInfo.api_url}/report_popular_page/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPopularPageGrowth() {
  const response = await axios.get<GetPopularPageGrowthResponseType>(
    `${apiInfo.api_url}/report_growth_pv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetSearchWordGrowth() {
  const response = await axios.get<GetSearchWordResponseType>(
    `${apiInfo.api_url}/report_rise_keyword/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetSearchWordMost() {
  const response = await axios.get<GetSearchWordResponseType>(
    `${apiInfo.api_url}/report_toprate_keyword/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetLocalRegion() {
  const response = await axios.get<GetLocalRegionResponseType>(
    `${apiInfo.api_url}/report_region/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetLocalUV() {
  const response = await axios.get<GetLocalUVResponseType>(
    `${apiInfo.api_url}/report_region_uv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetLocalPV() {
  const response = await axios.get<GetLocalPVResponseType>(
    `${apiInfo.api_url}/report_region_pv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetSimilarAgencyType() {
  const response = await axios.get<GetSimilarAgencyTypeResponseType>(
    `${apiInfo.api_url}/report_agency/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetSimilarAgencyUV() {
  const response = await axios.get<GetSimilarAgencyUVResponseType>(
    `${apiInfo.api_url}/report_agency_uv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetSimilarAgencyPV() {
  const response = await axios.get<GetSimilarAgencyPVResponseType>(
    `${apiInfo.api_url}/report_agency_pv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetSimilarScaleType() {
  const response = await axios.get<GetSimilarScaleTypeResponseType>(
    `${apiInfo.api_url}/report_scale/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetSimilarScalePV() {
  const response = await axios.get<GetSimilarScalePVResponseType>(
    `${apiInfo.api_url}/report_scale_pv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetForecastUV() {
  const response = await axios.get<GetForecastResponseType>(
    `${apiInfo.api_url}/report_forecast_uv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetForecastPV() {
  const response = await axios.get<GetForecastResponseType>(
    `${apiInfo.api_url}/report_forecast_pv/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPageUsabilityClickRate() {
  const response = await axios.get<GetPageUsabilityClickRateResponseType>(
    `${apiInfo.api_url}/report_click_rate/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPageUsabilityPageDuration() {
  const response = await axios.get<GetPageUsabilityPageDurationResponseType>(
    `${apiInfo.api_url}/report_dt/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetExternalNewsKeywordWordcloud() {
  const response = await axios.get<GetExternalNewsKeywordWordcloudResponseType>(
    `${apiInfo.api_url}/report_newskeyword_wordcloud/`,
    {
      // params: {
      //   date: info.date,
      //   tenant: info.tenant_id,
      // },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetExternalNewsKeywordNetwork() {
  const response = await axios.get<GetExternalNewsKeywordNetworkResponseType>(
    `${apiInfo.api_url}/report_newskeyword_wordnetwork/`,
    {
      // params: {
      //   date: info.date,
      //   tenant: info.tenant_id,
      // },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetExternalReferrerSearch() {
  const response = await axios.get<GetExternalReferrerResponseType>(
    `${apiInfo.api_url}/report_search_ref/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetExternalReferrerSNS() {
  const response = await axios.get<GetExternalReferrerResponseType>(
    `${apiInfo.api_url}/report_sns_ref/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetBotTrafficSite() {
  const response = await axios.get<GetBotTrafficSiteResponseType>(
    `${apiInfo.api_url}/report_bot/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetBotTrafficPage() {
  const response = await axios.get<GetBotTrafficPageResponseType>(
    `${apiInfo.api_url}/report_page_bot/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
