import { createReducer } from 'typesafe-actions';

import {
  GET_SCROLL_EVENTS,
  GET_SCROLL_EVENTS_ERROR,
  GET_SCROLL_EVENTS_SUCCESS,
  SET_ERROR_NULL,
} from './actions';
import { uxAnalyticsScrollAction, uxAnalyticsScrollState } from './types';

const initialState: uxAnalyticsScrollState = {
  scrollEventsData: null,
  uxAnalyticsScrollError: null,
};

const reducer = createReducer<uxAnalyticsScrollState, uxAnalyticsScrollAction>(
  initialState,
  {
    [GET_SCROLL_EVENTS]: (state) => ({
      ...state,
      uxAnalyticsScrollError: null,
    }),
    [GET_SCROLL_EVENTS_SUCCESS]: (state, action) => ({
      ...state,
      scrollEventsData: action.payload.payload,
    }),
    [GET_SCROLL_EVENTS_ERROR]: (state, action) => ({
      ...state,
      uxAnalyticsScrollError: action.payload,
    }),

    [SET_ERROR_NULL]: (state) => ({
      ...state,
      uxAnalyticsScrollError: null,
    }),
  }
);

export default reducer;
